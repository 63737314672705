// MenuHeader.jsx
import React, { useState, useCallback } from 'react';
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Button,
  Tooltip,
  Drawer,
} from '@mui/material';
import CustomSelect from '../../Common/CustomSelect';
import { useMenu } from '../../../contexts/MenuContext';
import { useResponsive } from '../../../hooks/useResponsive';
import { AutoAwesome } from '@mui/icons-material';
import OptionsButton from '../../Common/OptionsButton';
import { useDailyUsageContext } from '../../../contexts/DailyUsageContext';
import EditMenu from '../menu/EditMenu';
import { assignDefaultMenu } from '../../../services/apiService';
import { useRestaurant } from '../../../contexts/RestaurantContext';
import { useAlert } from '../../../contexts/AlertContext';
import { useMembership } from '../../../hooks/useMembership';

const MenuHeader = ({
  selectOptions,
  handlePreview,
  openMenuWizard,
  openDeleteConfirmation,
  openAIWizard,
  reloadMenus,
}) => {
  const { selectedMenu, menus, setSelectedMenu, loadCombinedMenu } = useMenu();
  const { isMobile } = useResponsive();
  const { usage } = useDailyUsageContext();
  const { selectedRestaurant } = useRestaurant();
  const { showToast } = useAlert();
  const { hasPaidModule } = useMembership();

  const [editingMenu, setEditingMenu] = useState(null);
  const [isEditMenuDrawerOpen, setIsEditMenuDrawerOpen] = useState(false);

  const getTooltipMessage = () => {
    if (!hasPaidModule) {
      return "Acquista un modulo a tua scelta per utilizzare Tabo Assistant";
    }
    if (usage.disabled) {
      return "Hai raggiunto il limite giornaliero di utilizzo";
    }
    return "";
  };

  const openEditMenuDrawer = () => {
    const menuToEdit = menus.find(
      (menu) => menu.id.toString() === selectedMenu.toString()
    );
    if (menuToEdit) {
      setEditingMenu(menuToEdit);
      setIsEditMenuDrawerOpen(true);
    } else {
      console.error("No menu found to edit for the selectedMenu:", selectedMenu);
    }
  };

  const closeEditMenuDrawer = () => setIsEditMenuDrawerOpen(false);

  // Handler for menu change:
  const handleMenuChange = async (event) => {
    const newMenuId = event.target.value;
    setSelectedMenu(newMenuId);
    try {
      await loadCombinedMenu(newMenuId);
    } catch (error) {
      console.error("Error refreshing data for new menu:", error);
    }
  };

  // New handler to assign the selected menu as default
  const handleAssignDefault = useCallback(async () => {
    if (!selectedRestaurant || !selectedMenu) {
      console.error("Missing selected restaurant or menu.");
      return;
    }
    try {
      await assignDefaultMenu(selectedRestaurant, selectedMenu);
      showToast({
        message: 'Menu predefinito impostato con successo',
        variant: 'success',
      })
      reloadMenus();
    } catch (error) {
      console.error("Error assigning default menu:", error);
    }
  }, [selectedRestaurant, selectedMenu, reloadMenus, showToast]);

  // Mobile options for OptionsButton
  const mobileOptions = [
    {
      label: 'Crea con Tabo',
      onClick: () => {
        if (usage.disabled) return;
        openAIWizard();
      },
    },
    { label: 'Aggiungi Menu', onClick: openMenuWizard },
    { label: 'Anteprima Menu', onClick: handlePreview },
  ];

  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="flex-start"
        width="100%"
        padding="20px"
      >
        {/* Left Section */}
        <Box display="flex" flexDirection="column">
          <FormControl variant="outlined">
            <InputLabel id="menu-select-label">Menu</InputLabel>
            <CustomSelect
              labelId="menu-select-label"
              value={selectedMenu || ''}
              onChange={handleMenuChange}
              label="Menu"
              sx={{ maxWidth: isMobile ? 200 : null }}
            >
              {selectOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </CustomSelect>
          </FormControl>
          <Box display="flex" width="100%">
            <Button
              variant="text"
              style={{ fontSize: isMobile ? '12px' : '14px' }}
              onClick={openEditMenuDrawer}
            >
              Modifica
            </Button>
            <Button
              variant="text"
              style={{ fontSize: isMobile ? '12px' : '14px' }}
              onClick={() => openDeleteConfirmation(selectedMenu, 'menu')}
            >
              Elimina
            </Button>
            <Button
              variant="text"
              style={{ fontSize: isMobile ? '12px' : '14px' }}
              onClick={handleAssignDefault}
            >
              {isMobile ? "Predefinito" : "Imposta predefinito"}
            </Button>
          </Box>
        </Box>

        {/* Right Section */}
        <Box display="flex" alignItems="center">
          {isMobile ? (
            <OptionsButton
              label="Opzioni"
              options={mobileOptions}
              sx={{ fontSize: { xs: '14px', sm: '16px' } }}
            />
          ) : (
            <>
              <Tooltip title={getTooltipMessage()}>
                <span>
                  <Button
                    variant="outlined"
                    color="primary"
                    sx={{ marginRight: '16px', fontSize: isMobile ? '11px' : '13px' }}
                    endIcon={<AutoAwesome />}
                    onClick={openAIWizard}
                    disabled={usage.disabled || !hasPaidModule}
                  >
                    Crea con Tabo
                  </Button>
                </span>
              </Tooltip>
              <Button
                variant="text"
                sx={{ fontSize: isMobile ? '12px' : '15px', fontWeight: 550 }}
                onClick={openMenuWizard}
              >
                Aggiungi Menu
              </Button>
              <Button
                variant="text"
                color="secondary"
                sx={{ fontSize: isMobile ? '12px' : '15px', fontWeight: 550 }}
                onClick={handlePreview}
              >
                Anteprima Menu
              </Button>
            </>
          )}
        </Box>
      </Box>

      {/* Drawer for editing the menu */}
      <Drawer
        anchor="right"
        open={isEditMenuDrawerOpen}
        onClose={closeEditMenuDrawer}
        sx={{
          '& .MuiDrawer-paper': {
            width: { xs: '90%', sm: '70%', md: '50%', lg: '35%' },
            padding: '2.5%'
          }
        }}
      >
        <EditMenu
          onClose={closeEditMenuDrawer}
          editingMenu={editingMenu}
          setEditingMenu={setEditingMenu}
          reloadMenus={reloadMenus}
        />
      </Drawer>
    </>
  );
};

export default MenuHeader;
