import React, { useState } from 'react';
import { useMenu } from '../../../contexts/MenuContext';
import { Droppable, Draggable } from '@hello-pangea/dnd';
import { NavigateNext, MoreVert } from '@mui/icons-material';
import {
  Menu,
  MenuItem,
  IconButton,
  Typography,
  Collapse
} from '@mui/material';
import { styled } from '@mui/material/styles';
import OptionsButton from '../../Common/OptionsButton';
import logo from '../../../assets/images/logo_no-pic.png';

const ListContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  userSelect: 'none',
}));

const CategoryContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexGrow: 1,
  alignItems: 'flex-start',
  width: '100%',
}));

const TabsContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  flexGrow: 1,
}));

const ItemContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexGrow: 1,
  alignItems: 'flex-start',
  width: '95%',
  marginLeft: '5%',
}));

const HeaderContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
}));

const IconColumn = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  width: 30,
  cursor: 'pointer',
}));

const CategoryTab = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  position: 'relative', // Additional line
  boxSizing: 'border-box',
  backgroundColor: 'white',
  fontSize: 17,
  color: 'var(--color-black)',
  border: '1px solid var(--color-lightgrey)',
  borderRadius: 10,
  width: '100%',
  padding: '8px 12px',
  marginTop: 6,
  marginBottom: 6,
  cursor: 'grab',
}));

const CategoryDescriptionTab = styled('div')(({ theme }) => ({
  display: 'flex',
  width: '100%',
  alignItems: 'center',
  justifyContent: 'left',
  color: 'var(--color-grey)',
  fontSize: '0.85rem',
}));

const MenuItemTab = styled('div')(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  boxSizing: 'border-box',
  backgroundColor: 'white',
  fontSize: 15,
  border: '1px solid lightgrey',
  borderRadius: 10,
  width: '100%',
  padding: 12,
  marginTop: 4,
  marginBottom: 4,
}));

const ItemName = styled('div')(({ theme }) => ({
  flexGrow: 1,
  fontSize: 13,
  fontWeight: 550
}));

const PriceDisplay = styled('span')(({ theme }) => ({
  marginRight: 35,
  fontSize: 14,
  color: theme.palette.secondary.main,
}));

const MenuItemDetails = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  fontSize: 14,
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
}));

const TextDetails = styled('div')(({ theme }) => ({
  flex: 1,
  marginRight: 10,
}));

const PictureContainer = styled('div')(({ theme }) => ({
  width: 100,
  height: 100,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  overflow: 'hidden',
  position: 'relative',
  borderRadius: 10,
  border: '1px solid var(--color-light)',

  '& img': {
    width: 'auto',
    height: '100%',
    minWidth: '100%',
    objectFit: 'cover',
    borderRadius: 10,
    border: '1px solid darkgrey',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
}));

const ItemPlaceholder = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  border: '1px dashed lightgrey',
  borderRadius: 10,
  padding: 10,
  height: 50,
  fontSize: 16,
  color: '#3e3effe3',
  cursor: 'pointer',
  marginTop: 5,
  marginBottom: 15,
  '&:hover': {
    backgroundColor: '#e9ecef',
    borderStyle: 'solid',
  },
}));

const renderToggleIcon = (isExpanded) => (
  <IconButton>
    <NavigateNext
      sx={{
        transition: 'transform 0.3s ease-in-out',
        transform: isExpanded ? 'rotate(90deg)' : 'rotate(0deg)',
        color: 'var(--color-secondary)',
      }}
    />
  </IconButton>
);

const CategoryList = ({
  expandedCategories,
  expandedItems,
  toggleCategory,
  toggleItem,
  handleAddMenuItemSelection,
  openEditCategorySidebar,
  openEditMenuItemSidebar,
  openEditComboSidebar,
  openDeleteConfirmation,
  addMenuItemOptions,
}) => {
  const {
    menuCategories,
    menuItems,
    combos,
    selectedMenu,
    allergens,
    dietGroups,
  } = useMenu();

  const [anchorElEdit, setAnchorElEdit] = useState(null);
  const [currentItem, setCurrentItem] = useState(null);

  const openEditMenu = Boolean(anchorElEdit);

  const handleMenuItemClick = (option, categoryId) => {
    handleAddMenuItemSelection(option, categoryId);
  };

  const handleMoreVertClick = (event, item) => {
    setAnchorElEdit(event.currentTarget);
    setCurrentItem(item);
  };

  const handleEditMenuClose = () => {
    setAnchorElEdit(null);
    setCurrentItem(null);
  };

  const handleEditClick = () => {
    if (currentItem.type === 'category') {
      openEditCategorySidebar(currentItem.data);
    } else if (currentItem.type === 'menuItem') {
      openEditMenuItemSidebar(currentItem.data);
    } else if (currentItem.type === 'combo') {
      openEditComboSidebar(currentItem.data);
    }
    handleEditMenuClose();
  };

  const handleDeleteClick = () => {
    openDeleteConfirmation(
      currentItem.data.id,
      currentItem.type,
      selectedMenu,
      currentItem.categoryId
    );
    handleEditMenuClose();
  };

  const renderItemAllergensAndDietGroups = (items) => {
    if (!items) return <div />;

    // Retrieve allergen names
    const itemAllergenNames = items.allergens.map((allergenId) => {
      const allergen = allergens.find((a) => a.id === allergenId);
      return allergen ? allergen.name : 'Unknown Allergen';
    });

    // Retrieve diet group names
    const itemDietGroupNames = items.diet_groups.map((dietGroupId) => {
      const dietGroup = dietGroups.find((d) => d.id === dietGroupId);
      return dietGroup ? dietGroup.name : 'Unknown Diet Group';
    });

    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '6px',
          padding: '16px 0 8px 0',
        }}
      >
        <Typography color="primary" variant="body2">
          {itemAllergenNames.length ? itemAllergenNames.join(', ') : 'Aggiungi allergeni se necessario'}
        </Typography>
        {itemDietGroupNames.length > 0 && (
          <Typography color="secondary" variant="body2">
            {itemDietGroupNames}
          </Typography>
        )}
      </div>
    );
  };

  return (
    <ListContainer>
      <Droppable droppableId="droppableCategories" type="CATEGORY">
        {(provided) => (
          <div ref={provided.innerRef} {...provided.droppableProps}>
            {menuCategories.map((category, index) => (
              <Draggable
                key={category.id}
                draggableId={`category-${category.id}`}
                index={index}
              >
                {(provided) => (
                  <ListContainer ref={provided.innerRef} {...provided.draggableProps}>
                    <CategoryContainer>
                      <TabsContainer>
                        <CategoryTab {...provided.dragHandleProps}>
                          <HeaderContainer>
                            <IconColumn onClick={() => toggleCategory(category.id)}>
                              {renderToggleIcon(expandedCategories[category.id])}
                            </IconColumn>
                            <Typography variant="h6" sx={{ flexGrow: 1 }}>
                              {category.name}
                            </Typography>
                          </HeaderContainer>
                          <CategoryDescriptionTab>
                            {category.description || 'No description provided'}
                          </CategoryDescriptionTab>
                          <IconButton
                            onClick={(event) =>
                              handleMoreVertClick(event, {
                                type: 'category',
                                data: category,
                              })
                            }
                            sx={{
                              position: 'absolute',
                              top: '50%',
                              right: 0,
                              transform: 'translateY(-50%)',
                            }}
                          >
                            <MoreVert sx={{ opacity: 0.75 }} />
                          </IconButton>
                        </CategoryTab>
                      </TabsContainer>
                    </CategoryContainer>

                    {/* Wrap expanded category content in Collapse */}
                    <Collapse in={expandedCategories[category.id]} timeout="auto" unmountOnExit>
                      <Droppable
                        droppableId={`droppableItems-${category.id}`}
                        type="ITEM"
                      >
                        {(provided) => (
                          <div ref={provided.innerRef} {...provided.droppableProps}>
                            {menuItems
                              .filter((item) =>
                                item.categories.includes(category.id)
                              )
                              .map((item, itemIndex) => (
                                <Draggable
                                  key={item.id}
                                  draggableId={`item-${item.id}`}
                                  index={itemIndex}
                                >
                                  {(provided) => (
                                    <div
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                    >
                                      <ItemContainer>
                                        <TabsContainer>
                                          <MenuItemTab
                                            {...provided.dragHandleProps}
                                          >
                                            <HeaderContainer>
                                              <IconColumn
                                                onClick={() => toggleItem(item.id)}
                                              >
                                                {renderToggleIcon(
                                                  expandedItems[item.id]
                                                )}
                                              </IconColumn>
                                              <ItemName>{item.name}</ItemName>
                                              <PriceDisplay>
                                                €{item.price}
                                              </PriceDisplay>
                                              <IconButton
                                                onClick={(event) =>
                                                  handleMoreVertClick(event, {
                                                    type: 'menuItem',
                                                    data: item,
                                                    categoryId: category.id,
                                                  })
                                                }
                                                sx={{
                                                  position: 'absolute',
                                                  top: 34,
                                                  right: 0,
                                                  transform: 'translateY(-50%)',
                                                }}
                                              >
                                                <MoreVert sx={{ opacity: 0.75 }} />
                                              </IconButton>
                                            </HeaderContainer>
                                            <Collapse
                                              in={expandedItems[item.id]}
                                              timeout="auto"
                                              unmountOnExit
                                            >
                                              <MenuItemDetails>
                                                <TextDetails>
                                                  <Typography variant="body">
                                                    {item.description}
                                                  </Typography>
                                                  {renderItemAllergensAndDietGroups(
                                                    item
                                                  )}
                                                </TextDetails>
                                                <PictureContainer>
                                                  <img
                                                    src={
                                                      item.picture
                                                        ? item.picture
                                                        : logo
                                                    }
                                                    alt={
                                                      item.name || 'default image'
                                                    }
                                                  />
                                                </PictureContainer>
                                              </MenuItemDetails>
                                            </Collapse>
                                          </MenuItemTab>
                                        </TabsContainer>
                                      </ItemContainer>
                                    </div>
                                  )}
                                </Draggable>
                              ))}
                            {/* The rest of your code for combos and the Add placeholder */}
                            <Droppable
                              droppableId={`droppableCombos-${category.id}`}
                              type="COMBOS"
                            >
                              {(provided) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.droppableProps}
                                >
                                  {combos
                                    .filter((combo) =>
                                      combo.categories.includes(category.id)
                                    )
                                    .map((combo, comboIndex) => (
                                      <Draggable
                                        key={combo.id}
                                        draggableId={`combo-${combo.id}`}
                                        index={comboIndex}
                                      >
                                        {(provided) => (
                                          <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                          >
                                            <ItemContainer>
                                              <TabsContainer>
                                                <MenuItemTab
                                                  {...provided.dragHandleProps}
                                                >
                                                  <HeaderContainer>
                                                    <IconColumn
                                                      onClick={() =>
                                                        toggleItem(combo.id)
                                                      }
                                                    >
                                                      {renderToggleIcon(
                                                        expandedItems[combo.id]
                                                      )}
                                                    </IconColumn>
                                                    <ItemName>
                                                      {combo.name}
                                                    </ItemName>
                                                    <PriceDisplay>
                                                      €{combo.price}
                                                    </PriceDisplay>
                                                    <IconButton
                                                      onClick={(event) =>
                                                        handleMoreVertClick(
                                                          event,
                                                          {
                                                            type: 'combo',
                                                            data: combo,
                                                            categoryId:
                                                              category.id,
                                                          }
                                                        )
                                                      }
                                                      sx={{
                                                        position: 'absolute',
                                                        top: 34,
                                                        right: 0,
                                                        transform: 'translateY(-50%)',
                                                      }}
                                                    >
                                                      <MoreVert sx={{ opacity: 0.75 }} />
                                                    </IconButton>
                                                  </HeaderContainer>
                                                  <Collapse
                                                    in={expandedItems[combo.id]}
                                                    timeout="auto"
                                                    unmountOnExit
                                                  >
                                                    <MenuItemDetails>
                                                      <TextDetails>
                                                        <Typography variant="body">
                                                          {combo.description}
                                                        </Typography>
                                                        <Typography color="secondary" sx={{ fontSize: '13px' }}>
                                                          Include:{' '}
                                                          {combo.item_names}
                                                        </Typography>
                                                        {renderItemAllergensAndDietGroups(
                                                          combo
                                                        )}
                                                      </TextDetails>
                                                      <PictureContainer>
                                                        <img
                                                          src={
                                                            combo.picture
                                                              ? combo.picture
                                                              : logo
                                                          }
                                                          alt={
                                                            combo.name ||
                                                            'default image'
                                                          }
                                                        />
                                                      </PictureContainer>
                                                    </MenuItemDetails>
                                                  </Collapse>
                                                </MenuItemTab>
                                              </TabsContainer>
                                            </ItemContainer>
                                          </div>
                                        )}
                                      </Draggable>
                                    ))}
                                  {provided.placeholder}
                                </div>
                              )}
                            </Droppable>
                            {provided.placeholder}
                            <ItemContainer>
                              <TabsContainer>
                                <ItemPlaceholder>
                                  <OptionsButton
                                    label="Aggiungi"
                                    options={addMenuItemOptions.map((option) => ({
                                      label: option.label,
                                      onClick: () => handleMenuItemClick(option, category.id),
                                    }))}
                                    fontSize="0.9rem"
                                    customcolor="inherit"
                                  />
                                </ItemPlaceholder>
                              </TabsContainer>
                            </ItemContainer>
                          </div>
                        )}
                      </Droppable>
                    </Collapse>
                  </ListContainer>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>

      <Menu
        anchorEl={anchorElEdit}
        open={openEditMenu}
        onClose={handleEditMenuClose}
        autoFocus={false}
      >
        <MenuItem onClick={handleEditClick}>Modifica</MenuItem>
        <MenuItem onClick={handleDeleteClick}>Elimina</MenuItem>
      </Menu>
    </ListContainer>
  );
};

export default CategoryList;