import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Box, Typography } from '@mui/material';
import expired from '../../assets/icons/expired.png';

const SessionExpired = () => {
  const navigate = useNavigate();

  const handleLoginRedirect = () => {
    navigate('/login');
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '100vh',
        textAlign: 'center',
        px: 2,
      }}
    >
      <Box>
        <img
          src={expired}
          alt="Session Expired"
          style={{ width: '100px', marginBottom: '20px' }}
        />
      </Box>
      <Typography variant="h2" component="h1" gutterBottom>
        La tua sessione è scaduta
      </Typography>
      <Typography variant="subtitle1" gutterBottom>
        Accedi nuovamente per continuare
      </Typography>
      <Button sx={{ mt: 5 }} variant="contained" color="primary" onClick={handleLoginRedirect}>
        Effettua l'accesso
      </Button>
    </Box>
  );
};

export default SessionExpired;
