// RegistrationForm.jsx v2.00 (Italian Version w/ reCAPTCHA v3)
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  Checkbox,
  Typography,
  LinearProgress,
  Alert,
  Box,
  FormControlLabel,
  InputAdornment,
  IconButton
} from '@mui/material';
import RoundedTextField from '../Common/RoundedTextField';
import { VisibilityOutlined, VisibilityOffOutlined } from '@mui/icons-material';
import { registerUser } from '../../services/authApiService';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import logo from '../../assets/logos/logo_small.png';
import CustomLoader from '../Common/CustomLoader';

const RegistrationForm = () => {
  // Gestione dello stato per gli input del form
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordStrength, setPasswordStrength] = useState(0);
  const [acceptedTerms, setAcceptedTerms] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { executeRecaptcha } = useGoogleReCaptcha();

  const navigate = useNavigate(); // Hook per navigare

  // Funzione per calcolare la forza della password
  const getPasswordStrength = (password) => {
    if (password.length === 0) return 0; // Nessun feedback se non viene digitato nulla

    const lengthRequirement = password.length >= 8;
    const digitRequirement = /[0-9]/.test(password);
    const uppercaseRequirement = /[A-Z]/.test(password);
    const lowercaseRequirement = /[a-z]/.test(password);
    const specialCharRequirement = /[^A-Za-z0-9]/.test(password);

    let strength = 0;
    if (password.length > 0) strength = 1;
    if (lengthRequirement && digitRequirement && lowercaseRequirement) strength += 2;
    if (uppercaseRequirement && lowercaseRequirement && lengthRequirement) strength += 1;
    if (specialCharRequirement) strength += 1;

    return strength; // Restituisce un valore tra 0 e 5
  };

  // Funzione per ottenere il testo descrittivo della forza della password
  const getPasswordStrengthText = (strength) => {
    switch (strength) {
      case 0:
        return "La password deve contenere almeno 8 caratteri, un numero e una lettera.";
      case 1:
      case 2:
        return "La tua password è troppo debole.";
      case 3:
        return "La tua password sembra buona, ma potrebbe essere più forte.";
      case 4:
        return "Bene! La tua password è forte.";
      case 5:
        return "Eccellente! La tua password è molto forte.";
      default:
        return "La password deve contenere almeno 8 caratteri, un numero e una lettera.";
    }
  };

  // Gestori degli input
  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event) => {
    const newPassword = event.target.value;
    setPassword(newPassword);
    setPasswordStrength(getPasswordStrength(newPassword));
  };

  const handleClickShowPassword = () => {
    setShowPassword((prev) => !prev);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleTermsChange = (event) => {
    setAcceptedTerms(event.target.checked);
  };

  // Validazione del form
  const validateForm = () => {
    const errors = {};
    const emailRegex = /\S+@\S+\.\S+/;
    const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d).{8,}$/;

    if (!email || !emailRegex.test(email)) {
      errors.email = "Inserisci una e-mail valida";
    }

    if (!password || !passwordRegex.test(password)) {
      errors.password = "La password deve contenere almeno 8 caratteri, un numero e una lettera.";
    }

    if (!acceptedTerms) {
      errors.terms = "Accetta i nostri Termini e Condizioni e l'Informativa sulla Privacy per continuare.";
    }

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  // Form submission handler with reCAPTCHA v3
  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!validateForm()) return;

    if (!executeRecaptcha) {
      console.error('Execute recaptcha not yet available');
      return;
    }

    setIsSubmitting(true);
    try {
      const captchaToken = await executeRecaptcha('register');
      const userData = { email, password, captcha: captchaToken };

      const response = await registerUser(userData);
      const { verification_uuid } = response;
      navigate(`/verify/${verification_uuid}`);
    } catch (error) {
      if (error.response && error.response.data) {
        setFormErrors(error.response.data);
      } else {
        setFormErrors({ general: "Si è verificato un errore inaspettato. Riprova più tardi." });
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  // Determina il colore in base alla forza della password
  const getPasswordStrengthColor = (strength) => {
    if (strength < 1) return 'primary';
    if (strength <= 2) return 'error';
    if (strength <= 3) return 'secondary';
    if (strength >= 4) return 'success';
    return 'success';
  };

  // Render the loader if the form is submitting
  if (isSubmitting) {
    return <CustomLoader open={true} text="Registrazione in corso..." />;
  }

  return (
    <Box
      sx={{
        minHeight: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      {/* Your form container */}
      <Box
        sx={{
          width: { xs: '90%', sm: '56%', md: '42%', lg: '28%', xl: '24%' },
          p: { xs: '1', sm: '2', md: '3', lg: '4', xl: '5' },
        }}
      >
        {/* Logo */}
        <Box sx={{ width: '100%', textAlign: 'center', mb: 2 }}>
          <img src={logo} alt="Logo" style={{ width: '50px' }} />
        </Box>
        {/* Testo introduttivo */}
        <Box sx={{ width: '100%', mb: 1 }}>
          <Typography variant="h3" sx={{ paddingBottom: '5px', textAlign: 'center' }}>
            Crea un account
          </Typography>
          <Typography variant="body1" textAlign="center">
            È facile e gratuito. Hai già un account?{' '}
            <a href="/login" style={{ textDecoration: 'none', color: '#3e3eff' }}>Esegui l'accesso</a>
          </Typography>
        </Box>

        {/* Form di registrazione */}
        <Box
          component="form"
          onSubmit={handleSubmit}
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
          noValidate
          autoComplete="off"
        >
          {/* Input per l'email */}
          <RoundedTextField
            fullWidth
            type="email"
            placeholder="Il tuo indirizzo e-mail"
            value={email}
            onChange={handleEmailChange}
            error={!!formErrors.email}
            helperText={formErrors.email}
            autoComplete="email"
          />

          {/* Input per la password */}
          <RoundedTextField
            fullWidth
            type={showPassword ? 'text' : 'password'}
            placeholder="Scegli una password sicura"
            value={password}
            onChange={handlePasswordChange}
            variant="outlined"
            error={!!formErrors.password}
            helperText={formErrors.password}
            autoComplete="new-password"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label={showPassword ? 'Nascondi password' : 'Mostra password'}
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOffOutlined fontSize="small" /> : <VisibilityOutlined fontSize="small" />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          {/* Indicatore della forza della password */}
          <Box sx={{ width: '99%', mt: 2 }}>
            <LinearProgress
              value={(passwordStrength / 5) * 100}
              variant="determinate"
              color={getPasswordStrengthColor(passwordStrength)}
              sx={{ height: 6, borderRadius: '20px' }}
            />
            <Typography variant="body2" sx={{ mt: 1 }}>
              {getPasswordStrengthText(passwordStrength)}
            </Typography>
          </Box>

          {/* Checkbox per Termini e Condizioni */}
          <Box sx={{ width: '100%', mt: 2, mb: 1 }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={acceptedTerms}
                  onChange={handleTermsChange}
                  name="acceptedTerms"
                  color="primary"
                  sx={{ ml: 1, p: 1 }}
                />
              }
              label={
                <Typography variant="body2" textAlign="center">
                  Spuntando questa casella accetti i{' '}
                  <a href="https://www.tabomenu.com/terms-conditions-english/" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: '#3e3eff' }}>Termini e Condizioni</a> e confermi di aver letto e compreso l'
                  <a href="https://www.tabomenu.com/privacy-policy/" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: '#3e3eff' }}>Informativa sulla Privacy</a>.
                </Typography>
              }
            />
            {/* Mostra eventuali errori relativi ai Termini */}
            {formErrors.terms && (
              <Alert severity="error" sx={{ width: '100%', mt: 1 }}>
                {formErrors.terms}
              </Alert>
            )}
          </Box>

          {/* Errore generale del form */}
          {formErrors.general && (
            <Alert severity="error" sx={{ width: '100%', mt: 1 }}>
              {formErrors.general}
            </Alert>
          )}

          {/* Pulsante di registrazione */}
          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            sx={{ mt: 2, mb: 2, borderRadius: '20px', py: 1.3, fontSize: '16px' }}
          >
            Registrati
          </Button>
          <Typography variant="caption" sx={{ textAlign: 'center', mt: 1, color: 'gray' }}>
            Questo sito è protetto da reCAPTCHA. Si applicano la{' '}
            <a href="https://policies.google.com/privacy" target="_blank" rel="noopener noreferrer">Privacy Policy</a>{' '}
            e i{' '}
            <a href="https://policies.google.com/terms" target="_blank" rel="noopener noreferrer">Termini di Servizio</a>{' '}
            di Google.
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default RegistrationForm;
