// PictureUpload.jsx
import React, { useState, useRef } from 'react';
import AvatarEditor from 'react-avatar-editor';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slider,
  Typography,
} from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

const PictureUpload = ({ existingFile, previewUrl, onFileSelect, onClearImage }) => {
  // Use the previewUrl if available; if not, fallback to existingFile.
  const effectivePreview = previewUrl || existingFile;
  
  const [selectedFile, setSelectedFile] = useState(null);
  const [showEditor, setShowEditor] = useState(false);
  const [scale, setScale] = useState(1);
  const fileInputRef = useRef(null);
  const editorRef = useRef(null);
  const MAX_FILE_SIZE = 2 * 1024 * 1024; // 2 MB in bytes

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (!file) return;

    const validTypes = ['image/jpeg', 'image/png'];
    if (!validTypes.includes(file.type)) {
      alert("Seleziona un file immagine (jpeg o png).");
      return;
    }

    if (file.size > MAX_FILE_SIZE) {
      alert("La dimensione del file non deve superare 2 MB.");
      return;
    }

    setSelectedFile(file);
    setShowEditor(true); // Show the editor

    // Clear the file input value to allow re-uploading the same file later
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  const handleCrop = () => {
    if (editorRef.current) {
      // Get the cropped image as a data URL in PNG format
      const canvas = editorRef.current.getImage().toDataURL("image/png");
      const blob = dataURLToBlob(canvas);
      // Create a new File, preserving the original file's name and type
      const file = new File([blob], selectedFile.name, { type: blob.type });
      // Pass the new file and its preview URL back to the parent
      onFileSelect(file, URL.createObjectURL(blob));
      setShowEditor(false);
    }
  };

  const handleScaleChange = (event, newValue) => {
    setScale(newValue);
  };

  const dataURLToBlob = (dataURL) => {
    const byteString = atob(dataURL.split(',')[1]);
    const mimeString = dataURL.split(',')[0].split(':')[1].split(';')[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mimeString });
  };

  return (
    <Box>
      {!effectivePreview ? (
        <Button
          variant="outlined"
          component="label"
          margin="normal"
          startIcon={<CloudUploadIcon />}
        >
          Carica immagine
          <input
            type="file"
            hidden
            accept="image/png, image/jpeg"
            onChange={handleFileChange}
            ref={fileInputRef}
          />
        </Button>
      ) : (
        <Box display="flex" flexDirection="column">
          <img
            src={effectivePreview}
            alt="Anteprima"
            style={{
              width: '100px',
              height: 'auto', // Keeps aspect ratio
              border: '1px solid grey',
              borderRadius: '20px',
              alignSelf: 'center',
            }}
          />
          <Button onClick={onClearImage} color="secondary" style={{ justifyContent: 'center' }}>
            Rimuovi immagine
          </Button>
        </Box>
      )}

      <Dialog open={showEditor} onClose={() => setShowEditor(false)}>
        <DialogTitle>Ingrandisci e centra l'immagine</DialogTitle>
        <DialogContent>
          <AvatarEditor
            ref={editorRef}
            image={selectedFile}
            color={[255, 255, 255, 0.6]}
            scale={scale}
            rotate={0}
          />
          <Box mt={2}>
            <Typography variant="body2">Zoom</Typography>
            <Slider
              value={scale}
              onChange={handleScaleChange}
              min={1}
              max={2}
              step={0.1}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCrop} color="primary">
            Salva
          </Button>
          <Button onClick={() => setShowEditor(false)} color="secondary">
            Annulla
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default PictureUpload;
