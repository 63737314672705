// NFCProductDrawer.jsx
import React from 'react';
import {
  Drawer,
  Box,
  Typography,
  IconButton,
  TextField,
  Button
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const NFCProductDrawer = ({
  open,
  product,
  quantity,
  onClose,
  onQuantityChange,
  onConfirmOrder
}) => {
  const handleQuantityChange = (e) => {
    const val = parseInt(e.target.value, 10);
    onQuantityChange(isNaN(val) || val < 1 ? 1 : val);
  };

  return (
    <Drawer anchor="right" open={open} onClose={onClose}>
      {/* If no product is selected, we can show a placeholder 
          or simply hide the content. But don't return null for the Drawer itself. */}
      {product ? (
        <Box sx={{ width: { xs: 300, sm: 400 }, p: 3, position: 'relative' }}>
          {/* Close Button */}
          <IconButton
            onClick={onClose}
            sx={{ position: 'absolute', top: 8, right: 8 }}
          >
            <CloseIcon />
          </IconButton>

          {/* Product Info */}
          <Typography variant="h5" sx={{ mb: 1 }}>
            {product.name}
          </Typography>
          <Typography variant="h4" color="primary" sx={{ mb: 2 }}>
            {product.price}€
            <Typography component="span" variant="body1" sx={{ ml: 1 }}>
              {product.period}
            </Typography>
          </Typography>

          {/* Shipping / Payment info */}
          <Typography variant="body1" sx={{ mb: 1 }}>
            Consegna rapida • Disponibilità immediata
          </Typography>
          <Typography variant="body2" sx={{ mb: 2 }}>
            Spedito da Poste Italiane
          </Typography>

          {/* Quantity */}
          <TextField
            label="Quantità"
            type="number"
            fullWidth
            value={quantity}
            onChange={handleQuantityChange}
            sx={{ mb: 2 }}
            InputProps={{ inputProps: { min: 1 } }}
          />

          {/* Confirm Button */}
          <Button variant="contained" onClick={onConfirmOrder} fullWidth>
            Ordina ora
          </Button>

          <Typography variant="body2" color="text.secondary" sx={{ mt: 2 }}>
            Pagamento sicuro con Stripe
          </Typography>
        </Box>
      ) : (
        // If you prefer, just hide content or show fallback text
        <Box sx={{ width: { xs: 300, sm: 400 }, p: 3 }}>
          <IconButton
            onClick={onClose}
            sx={{ position: 'absolute', top: 8, right: 8 }}
          >
            <CloseIcon />
          </IconButton>
          <Typography>Seleziona un prodotto per maggiori informazioni.</Typography>
        </Box>
      )}
    </Drawer>
  );
};

export default NFCProductDrawer;
