// src/components/orders/NFCShop.jsx

import React from 'react';
import { Box, Card, CardContent, Typography, Divider, Stack } from '@mui/material';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import nfc from '../../../assets/icons/nfc.png'

// Example array of products (could be fetched from your backend instead)
const productList = [
  {
    id: 'tabostand',
    name: 'TaboStand',
    price: 1.0,
    period: '/unit',
    iconSrc: nfc,
    iconAlt: 'TaboStand Icon',
    features: [
      'Checkout session trigger',
      'Real-time ordering',
      'Seamless integration',
    ]
  },
  {
    id: 'tabocard',
    name: 'TaboCard',
    price: 2.0,
    period: '/unit',
    iconSrc: nfc,
    iconAlt: 'TaboCard Icon',
    features: [
      'Additional product details',
      'Future expansions',
      'Easy to use',
    ]
  },
];

const NFCShop = ({ onCardClick }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        gap: 2,
        justifyContent: 'center',
        mt: 2,
        p: 2
      }}
    >
      {productList.map((product) => (
        <Card
          key={product.id}
          onClick={() => onCardClick(product)}
          sx={{
            position: 'relative',
            width: 260,
            borderRadius: '20px',
            cursor: 'pointer',
            boxShadow: 2,
            transition: 'all 0.3s ease',
            ':hover': { boxShadow: 5 },
          }}
        >
          <CardContent
            sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
          >
            <Typography color="primary" variant="h6" sx={{ mb: 1 }}>
              {product.name}
            </Typography>

            {/* Icon */}
            {product.iconSrc && (
              <Box sx={{ mb: 1 }}>
                <Box
                  component="img"
                  src={product.iconSrc}
                  alt={product.iconAlt}
                  sx={{ height: 70 }}
                />
              </Box>
            )}

            {/* Price */}
            <Typography variant="h3" sx={{ mb: 1 }}>
              {product.price}
              <Box component="span" sx={{ fontSize: '0.75em', fontWeight: 'normal' }}>
                €
              </Box>
              <Box component="span" sx={{ fontSize: '0.5em', fontWeight: 'normal' }}>
                {product.period}
              </Box>
            </Typography>

            <Divider sx={{ width: '100%', my: 2 }} />

            {/* Features */}
            <Stack spacing={1} sx={{ mb: 2, width: '100%' }}>
              {product.features.map((feature, idx) => (
                <Box key={idx} sx={{ display: 'flex', alignItems: 'center' }}>
                  <CheckCircleOutlineOutlinedIcon
                    sx={{ color: 'green', mr: 1, fontSize: 18 }}
                  />
                  <Typography variant="body2">{feature}</Typography>
                </Box>
              ))}
            </Stack>

            <Typography variant="body2" color="text.secondary">
              Clicca per maggiori informazioni
            </Typography>
          </CardContent>
        </Card>
      ))}
    </Box>
  );
};

export default NFCShop;
