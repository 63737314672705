import React, { useState, useEffect, useRef } from 'react';
import { Button, Box, Typography, CircularProgress, Snackbar, Alert, TextField } from '@mui/material';
import { initiateStripePayment } from '../../services/stripeApiService';

const PaymentWidget = ({ paymentType, sessionKey, billDetails, orderId }) => {
  const [amountDue, setAmountDue] = useState(billDetails.amount_due || 0);
  const [tipAmount, setTipAmount] = useState(0);
  const [selectedTip, setSelectedTip] = useState(null);
  const [loading, setLoading] = useState(false);
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const [paymentError, setPaymentError] = useState(false);
  const [numOfSplits, setNumOfSplits] = useState(2);
  const [showCustomInput, setShowCustomInput] = useState(false);
  const customInputRef = useRef(null);

  // Tip options
  const tipOptions = [1, 2, 5];

  useEffect(() => {
    const updateAmountDue = () => {
      if (!billDetails) return;

      // Check if the order is already fully paid
      if (parseFloat(billDetails.amount_due) === 0) {
        // If the bill is fully paid, set amountDue to 0 for all payment types
        setAmountDue(0);
        return;
      }

      if (paymentType === 'your_items') {
        const userItems = billDetails?.session_items?.filter(item => item.session_key === sessionKey) || [];
        const totalForUserItems = userItems.reduce((total, item) => total + item.price * item.quantity, 0);
        setAmountDue(totalForUserItems);
      } else if (paymentType === 'full_bill') {
        setAmountDue(parseFloat(billDetails.amount_due));  // Use amount_due directly from the backend
      } else if (paymentType === 'split_bill') {
        const splitAmount = parseFloat(billDetails.amount_due) / numOfSplits;
        setAmountDue(splitAmount);
      }
    };

    updateAmountDue();
  }, [paymentType, sessionKey, billDetails, numOfSplits]);

  const handleTipClick = (tip) => {
    if (selectedTip === tip) {
      setTipAmount(0);
      setSelectedTip(null);
    } else {
      setTipAmount(tip);
      setSelectedTip(tip);
    }
  };

  const handlePayment = async () => {
    setLoading(true);

    // Map the frontend payment options to backend payment_option values
    let contributionType = '';
    let amountPaid = amountDue + tipAmount;  // Amount including tip

    if (paymentType === 'your_items') {
      contributionType = 'individual';
    } else if (paymentType === 'full_bill') {
      contributionType = 'full_bill';
    } else if (paymentType === 'split_bill') {
      contributionType = 'split';
    }

    const paymentData = {
      contribution_type: contributionType,  // Correct key for backend contribution type
      amount: amountPaid,  // Amount being paid
      digital_paid_amount: amountPaid,  // Assign full amount to digital
      session_key: paymentType === 'your_items' ? sessionKey : undefined,  // Send session key for individual payments
      tip: tipAmount,  // Include tip if any
      number_of_splits: paymentType === 'split_bill' ? numOfSplits : undefined,  // Number of splits for split payment
      payment_method: 'digital',  // Hardcoded to digital
    };

    try {
      const checkoutUrl = await initiateStripePayment(orderId, paymentData);
      window.location.href = checkoutUrl;  // Redirect to Stripe Checkout
    } catch (error) {
      setLoading(false);
      setPaymentError(true);
      console.error('Payment failed:', error);
    }
  };

  // Handle split selection
  const handleSplitSelection = (splits) => {
    setNumOfSplits(splits);
    setShowCustomInput(false);
    if (customInputRef.current) {
      customInputRef.current.value = '';  // Clear custom input
    }
  };

  const handleCustomSplitsChange = (e) => {
    const value = e.target.value;
    if (value) {
      setNumOfSplits(parseInt(value, 10));
    } else {
      setNumOfSplits(4); // Default to 4 if input is cleared
    }
  };

  const handleCustomClick = () => {
    setShowCustomInput(true);
    setNumOfSplits(4); // Ensure that the input starts with 4 when custom is clicked
    setTimeout(() => {
      customInputRef.current?.select(); // Automatically select the input text
    }, 0); // Timeout ensures the input field renders first
  };

  const closeSnackbar = () => {
    setPaymentSuccess(false);
    setPaymentError(false);
  };

  return (
    <Box p={3} display="flex" flexDirection="column" alignItems="center">
      {paymentType === 'split_bill' && (
        <Box display="flex" width="100%" flexDirection="column" alignItems="center" sx={{ padding: '20px' }}>
          <Typography variant="h6" gutterBottom>Split Bill By</Typography>
          <Box display="flex" justifyContent="center" gap={3} pt={1} pb={2}>
            <Button
              variant={numOfSplits === 2 ? 'contained' : 'outlined'}
              color="primary"
              onClick={() => handleSplitSelection(2)}
              sx={{ minWidth: '100px' }}
            >
              x2
            </Button>
            <Button
              variant={numOfSplits === 3 ? 'contained' : 'outlined'}
              color="primary"
              onClick={() => handleSplitSelection(3)}
              sx={{ minWidth: '100px' }}
            >
              x3
            </Button>
            {showCustomInput ? (
              <TextField
                label="Split by"
                aria-label="Enter number of splits"
                type="number"
                size="small"
                value={numOfSplits}
                onChange={handleCustomSplitsChange}
                inputRef={customInputRef}
                InputProps={{ inputProps: { min: 2, max: 16 } }}
                sx={{ minWidth: '100px' }}
              />
            ) : (
              <Button
                variant="outlined"
                color="primary"
                onClick={handleCustomClick}
                sx={{ minWidth: '100px' }}
              >
                Custom
              </Button>
            )}
          </Box>
        </Box>
      )}

      {/* Add Tip Section */}
      <Box display="flex" flexDirection="column" alignItems="center" sx={{ padding: '80px 20px 20px 20px' }}>
        <Typography variant="h6" gutterBottom>Add Tip</Typography>
        <Box display="flex" justifyContent="center" gap={3} pt={1} pb={2}>
          {tipOptions.map((tip) => (
            <Button
              key={tip}
              variant={selectedTip === tip ? 'contained' : 'outlined'}
              color="secondary"
              size="small"
              onClick={() => handleTipClick(tip)}
            >
              €{tip}
            </Button>
          ))}
        </Box>
      </Box>

      {/* Display the final amount due including tip */}
      <Typography variant="h4" gutterBottom>
        Amount Due: €{(parseFloat(amountDue) + parseFloat(tipAmount)).toFixed(2)}
      </Typography>

      <Button
        variant="contained"
        color="primary"
        onClick={handlePayment}
        disabled={loading}
        fullWidth
        sx={{ padding: '12px', fontSize: '18px', marginTop: '20px' }}
      >
        {loading ? <CircularProgress size={24} color="inherit" /> : 'Confirm Payment'}
      </Button>

      {/* Snackbar for success or error */}
      <Snackbar open={paymentSuccess} autoHideDuration={6000} onClose={closeSnackbar}>
        <Alert onClose={closeSnackbar} severity="success">
          Payment Successful!
        </Alert>
      </Snackbar>

      <Snackbar open={paymentError} autoHideDuration={6000} onClose={closeSnackbar}>
        <Alert onClose={closeSnackbar} severity="error">
          Payment Failed. Please try again.
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default PaymentWidget;
