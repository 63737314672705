// MenuAreaAssignmentWidget.jsx
import React, { useState } from 'react';
import { useRestaurant } from '../../contexts/RestaurantContext';
import { useMenu } from '../../contexts/MenuContext';
import { Select, MenuItem, FormControl, InputLabel, Button, Alert, Box } from '@mui/material';
import { assignMenuToRestaurantAndArea } from '../../services/apiService';

const MenuAreaAssignmentWidget = ({ onAssignmentStatusChange, hideAssignButton = false }) => {
  const [selectedAreas, setSelectedAreas] = useState([]);
  const [error, setError] = useState('');
  const { userRestaurants, selectedRestaurant, setSelectedRestaurant, areas, areasLoading } = useRestaurant();
  const { selectedMenu } = useMenu();

  const handleAssignMenu = async () => {
    setError('');
    if (!selectedRestaurant) {
      setError('Seleziona almeno un ristorante.');
      return;
    }
    try {
      const response = await assignMenuToRestaurantAndArea(selectedMenu, selectedAreas);
      if (response && response.status === 200) {
        alert('Menu successfully assigned to selected areas.');
        if (onAssignmentStatusChange) {
          onAssignmentStatusChange({ selectedRestaurant, selectedAreas });
        }
      } else {
        setError('An unexpected error occurred.');
      }
    } catch (error) {
      if (error.response?.data?.error === "Time overlap with existing menu.") {
        setError("C'è già un menu assegnato a quest'area nel periodo selezionato.");
      } else if (error.response?.data?.error) {
        setError(error.response.data.error);
      } else {
        console.error('Error assigning menu to areas:', error);
        setError('An unexpected error occurred.');
      }
    }
  };

  return (
    <Box>
      {areasLoading ? (
        <Box>
          <Alert severity="info" sx={{ mb: 2 }}>Caricamento delle aree...</Alert>
        </Box>
      ) : areas.length === 0 ? (
        <Box>
          <Alert severity="info" sx={{ mb: 2 }}>
            <b>Non hai ancora creato un ristorante con le sue aree.</b><br />
            Puoi creare un ristorante e le sue zone nella sezione "Il Mio Ristorante".<br />
            Successivamente assegna i tuoi menu tramite il pulsante "Associa" nella sezione Menu.
          </Alert>
        </Box>
      ) : (
        <>
          <FormControl fullWidth variant="outlined" margin="normal">
            <InputLabel id="restaurant-select-label">Ristorante</InputLabel>
            <Select
              labelId="restaurant-select-label"
              value={selectedRestaurant}
              onChange={(e) => {
                const newRestaurant = e.target.value;
                setSelectedRestaurant(newRestaurant);
                if (onAssignmentStatusChange) {
                  onAssignmentStatusChange({ selectedRestaurant: newRestaurant, selectedAreas });
                }
              }}
              label="Ristorante"
            >
              {userRestaurants.map((restaurant) => (
                <MenuItem key={restaurant.id} value={restaurant.id}>
                  {restaurant.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth variant="outlined" margin="normal">
            <InputLabel id="area-select-label">Area</InputLabel>
            <Select
              labelId="area-select-label"
              multiple
              value={selectedAreas}
              onChange={(e) => {
                const newAreas = e.target.value;
                setSelectedAreas(newAreas);
                if (onAssignmentStatusChange) {
                  onAssignmentStatusChange({ selectedRestaurant, selectedAreas: newAreas });
                }
              }}
              label="Area"
            >
              {areas.map((area) => (
                <MenuItem key={area.id} value={area.id}>
                  {area.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </>
      )}

      {!hideAssignButton && areas.length > 0 && (
        <Box sx={{ marginTop: 2 }}>
          <Button variant="outlined" color="primary" onClick={handleAssignMenu}>
            Assegna Menu
          </Button>
          {error && (
            <Alert severity="error" sx={{ marginTop: 2 }}>
              {error}
            </Alert>
          )}
        </Box>
      )}
    </Box>
  );
};

export default MenuAreaAssignmentWidget;
