import api from './apiInstance'; // Import the Axios instance with interceptors

// Use environment variable for API base URL
const API_BASE_URL = process.env.REACT_APP_API_URL;

// Function to fetch menus based on restaurant ID
export const fetchMenusByRestaurant = async (restaurantId) => {
  try {
    const response = await api.get(`${API_BASE_URL}users/menus/?restaurant_id=${restaurantId}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching menus:', error);
    throw error;
  }
};

// Function to fetch menu items for a specific menu
export const fetchMenuItemsByMenu = async (menuId) => {
  try {
    const response = await api.get(`${API_BASE_URL}users/menu-items/?menu_id=${menuId}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching menu items:', error);
    throw error;
  }
};

// Function to fetch combos for a specific menu
export const fetchCombosByMenu = async (menuId) => {
  try {
    const response = await api.get(`${API_BASE_URL}users/combos/?menu_id=${menuId}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching combos:', error);
    throw error;
  }
};

// Function to fetch restaurant details for end-users
export const fetchUserRestaurantDetails = async (restaurantId) => {
  try {
    const response = await api.get(`${API_BASE_URL}users/restaurants/${restaurantId}/`);
    return response.data;
  } catch (error) {
    console.error('Error fetching restaurant details for end users:', error);
    throw error;
  }
};

// Function to fetch live menu details for preview or customer view
export const fetchLiveMenuDetails = async (menuId, restaurantId = null, tableId = null) => {
  let url = `${API_BASE_URL}users/live-menu/${menuId}/`;

  // Adjust the URL depending on the presence of restaurantId and tableId
  if (restaurantId && tableId) {
    url = `${API_BASE_URL}users/live-menu/restaurant/${restaurantId}/table/${tableId}/${menuId}/`;
  } else if (restaurantId) {
    // If only restaurantId is provided
    url = `${API_BASE_URL}users/live-menu/restaurant/${restaurantId}/${menuId}/`;
  }

  try {
    const response = await api.get(url);
    return response.data;
  } catch (error) {
    console.error('Error fetching live menu details:', error);
    throw error;
  }
};

// Function to fetch default menu details for a restaurant
export const fetchDefaultMenuDetails = async (restaurantId) => {
  const url = `${API_BASE_URL}users/default-menu/restaurant/${restaurantId}/`;

  try {
    const response = await api.get(url);
    return response.data;
  } catch (error) {
    console.error('Error fetching default menu details:', error);
    throw error;
  }
};

// Function to confirm and place and order
export const placeOrder = async (tableId, orderData) => {
  try {
    const response = await api.post(`${API_BASE_URL}users/place-order/${tableId}/`, orderData);
    return response.data;
  } catch (error) {
    console.error('Error placing order:', error);
    throw error;
  }
};

// Function to fetch latest bill details
export const fetchBillDetails = async (orderId) => {
  try {
    const response = await api.get(`${API_BASE_URL}users/request-bill/${orderId}/`, {
      params: {
        session_key: localStorage.getItem('session_key'), // Assuming you store session_key in localStorage
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching bill details:', error);
    throw error;
  }
};

// Function to generate a receipt for the order
export const generateReceipt = async (orderId) => {
  try {
    const response = await api.post(`users/create-receipt/${orderId}/`);
    return response.data; // This will be the generated receipt data
  } catch (error) {
    console.error('Error generating receipt:', error);
    throw error.response ? error.response.data : error;
  }
};

// Function to create a reservation
export const createReservation = async (reservationData) => {
  try {
    const response = await api.post(`${API_BASE_URL}users/reservations/`, reservationData);
    return response.data;
  } catch (error) {
    console.error('Error creating reservation:', error);
    throw error;
  }
};

// Function to fetch a reservation by ID
export const fetchReservation = async (reservationId) => {
  try {
    const response = await api.get(`${API_BASE_URL}users/reservations/${reservationId}/`);
    return response.data;
  } catch (error) {
    console.error('Error fetching reservation:', error);
    throw error;
  }
};

export const updateReservation = async ({ reservationId, updateData }) => {
  try {
    const response = await api.patch(
      `${API_BASE_URL}users/reservations/${reservationId}/`,
      updateData,
    );
    return response.data;
  } catch (error) {
    console.error('Error updating reservation status:', error);
    throw error;
  }
};

// Function to cancel a reservation
export const cancelReservation = async (reservationId) => {
  try {
    const response = await api.delete(`${API_BASE_URL}users/reservations/${reservationId}/`);
    return response.data;
  } catch (error) {
    console.error('Error cancelling reservation:', error);
    throw error;
  }
};

// Function to fetch available dates for a specified number of guests
export const fetchAvailableDates = async (restaurantId, guestCount) => {
  try {
    const response = await api.get(`${API_BASE_URL}users/restaurants/${restaurantId}/available-dates/`, {
      params: { guest_count: guestCount },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching available dates:', error);
    throw error;
  }
};

// Function to fetch available times for a specific date and guest count
export const fetchAvailableTimes = async (restaurantId, reservationDate, guestCount, areaId = null) => {
  try {
    const response = await api.get(`${API_BASE_URL}users/restaurants/${restaurantId}/available-times/`, {
      params: { 
        date: reservationDate, 
        guest_count: guestCount,
        area: areaId,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching available times:', error);
    throw error;
  }
};

