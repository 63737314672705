import React, { useState } from 'react';
import {
  Box,
  Typography,
  Divider,
  Checkbox,
  FormControlLabel,
  IconButton,
  Button,
} from '@mui/material';
import CustomTimePicker from '../../Common/CustomTimePicker';
import AddIcon from '@mui/icons-material/Add';
import DeleteOutlineRounded from '@mui/icons-material/DeleteOutlineRounded';
import { deepCopy } from '../../../utils/deepCopy';

const timeToMinutes = (timeStr) => {
  if (!timeStr || typeof timeStr !== 'string') return null;
  const [hh, mm] = timeStr.split(':');
  return parseInt(hh, 10) * 60 + parseInt(mm, 10);
};

const minutesToTime = (mins) => {
  const hh = String(Math.floor(mins / 60)).padStart(2, '0');
  const mm = String(mins % 60).padStart(2, '0');
  return `${hh}:${mm}`;
};

const MIN_SLOT_GAP = 60;
const MAX_TIME = "23:30";

const OpeningHours = ({
  openingHours,
  setOpeningHours,
  defaultTimeSlot = { opening_time: "12:30", closing_time: "15:00" },
  handleAddTimeSlot = () => { },
  handleRemoveTimeSlot = () => { },
  handleDayOpenChange = () => { },
}) => {
  // State to track if a global copy has been applied and to store the original state.
  const [globalCopyApplied, setGlobalCopyApplied] = useState(false);
  const [originalOpeningHours, setOriginalOpeningHours] = useState(null);

  // Helper: convert day index to Italian day name.
  const getDayName = (dayIndex) => {
    const days = [
      'Lunedì',
      'Martedì',
      'Mercoledì',
      'Giovedì',
      'Venerdì',
      'Sabato',
      'Domenica',
    ];
    return days[dayIndex];
  };

  // Copies the time slots from the selected day to all days.
  const handleDayCopy = (sourceDayIndex) => {
    if (!originalOpeningHours) {
      setOriginalOpeningHours(deepCopy(openingHours));
    }
    const sourceSlots = openingHours[sourceDayIndex].time_slots;
    setOpeningHours((prev) => {
      const updated = deepCopy(prev);
      updated.forEach(day => {
        day.is_open = true; // Ensure every day is marked as open.
        day.time_slots = sourceSlots.map(slot => {
          const { id, ...slotWithoutId } = slot;
          return slotWithoutId;
        });
      });
      return updated;
    });
    setGlobalCopyApplied(true);
  };

  // Resets the openingHours state to its original value.
  const handleResetCopy = () => {
    if (originalOpeningHours) {
      setOpeningHours(deepCopy(originalOpeningHours));
    }
    setGlobalCopyApplied(false);
  };

  return (
    <Box width="100%" paddingX={{ xs: 1, sm: 1, md: 2, lg: 3 }}>
      <Typography variant="body2" fontWeight="550" sx={{ py: 1 }}>
        Aggiungi gli orari di apertura per prenotazioni e ordini online
      </Typography>
      {globalCopyApplied && (
        <Box display="flex" justifyContent="flex-end" mb={2}>
          <Button variant="text" onClick={handleResetCopy}>
            Ricomincia
          </Button>
        </Box>
      )}
      {openingHours.map((day, dayIndex) => (
        <Box key={`day-${day.day_of_week}-${dayIndex}`} mt={2}>
          <Divider sx={{ mb: 2 }} />
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <FormControlLabel
              control={
                <Checkbox
                  size="small"
                  checked={day.is_open}
                  onChange={(e) => handleDayOpenChange(e, dayIndex)}
                  color="primary"
                />
              }
              label={getDayName(day.day_of_week)}
            />
            {/* Show the copy button only if the day is open and no global copy has been applied */}
            {!globalCopyApplied && day.is_open && (
              <Button
                variant="text"
                onClick={() => handleDayCopy(dayIndex)}
                sx={{ fontSize: '12px', fontWeight: 550 }}
              >
                Copia per tutti i giorni
              </Button>
            )}
          </Box>
          {day.is_open &&
            day.time_slots.map((slot, slotIndex) => {
              // Calculate the minimum allowed opening time for each slot.
              let minOpening;
              if (slotIndex === 0) {
                minOpening = "07:00";
              } else {
                const prevSlot = day.time_slots[slotIndex - 1];
                const prevClose = prevSlot.closing_time || defaultTimeSlot.closing_time;
                const prevCloseMins = timeToMinutes(prevClose) || 0;
                minOpening = minutesToTime(prevCloseMins + MIN_SLOT_GAP);
              }
              const currentOpening = slot.opening_time || defaultTimeSlot.opening_time;
              let minClosing = "00:00";
              if (currentOpening) {
                const openMins = timeToMinutes(currentOpening);
                minClosing = minutesToTime(openMins + MIN_SLOT_GAP);
              } else {
                minClosing = defaultTimeSlot.closing_time;
              }
              // Determine if the add button should be disabled.
              const isLastSlot = slotIndex === day.time_slots.length - 1;
              const closingMins = slot.closing_time ? timeToMinutes(slot.closing_time) : null;
              const disableAdd = !isLastSlot || (closingMins !== null && closingMins >= 1170); // 19:30 in minutes

              return (
                <Box
                  key={`day-${day.day_of_week}-slot-${slotIndex}`}
                  display="flex"
                  alignItems="center"
                  mt={2}
                  gap={2}
                  sx={{ flexWrap: { xs: 'wrap', sm: 'nowrap' } }}
                >
                  <CustomTimePicker
                    label="Orario di apertura"
                    value={slot.opening_time || defaultTimeSlot.opening_time || ""}
                    onChange={(newValue) => {
                      setOpeningHours((prev) => {
                        const updated = deepCopy(prev);
                        updated[dayIndex].time_slots[slotIndex].opening_time = newValue;
                        // Adjust closing time if it doesn't respect the minimum gap.
                        const openMins = timeToMinutes(newValue);
                        const currentCloseMins = timeToMinutes(
                          updated[dayIndex].time_slots[slotIndex].closing_time || ""
                        );
                        if (!currentCloseMins || currentCloseMins < openMins + MIN_SLOT_GAP) {
                          updated[dayIndex].time_slots[slotIndex].closing_time = minutesToTime(
                            openMins + MIN_SLOT_GAP
                          );
                        }
                        return updated;
                      });
                    }}
                    timeInterval={30}
                    minTime={minOpening}
                    maxTime={MAX_TIME}
                  />
                  <CustomTimePicker
                    label="Orario di chiusura"
                    value={slot.closing_time || defaultTimeSlot.closing_time || ""}
                    onChange={(newValue) => {
                      setOpeningHours((prev) => {
                        const updated = deepCopy(prev);
                        updated[dayIndex].time_slots[slotIndex].closing_time = newValue;
                        return updated;
                      });
                    }}
                    timeInterval={30}
                    minTime={minClosing}
                    maxTime={MAX_TIME}
                  />
                  <IconButton
                    onClick={() => handleAddTimeSlot(dayIndex)}
                    color="primary"
                    disabled={disableAdd}
                  >
                    <AddIcon fontSize="small" />
                  </IconButton>
                  {day.time_slots.length > 1 && (
                    <IconButton
                      onClick={() => handleRemoveTimeSlot(dayIndex, slotIndex)}
                      color="secondary"
                    >
                      <DeleteOutlineRounded fontSize="small" />
                    </IconButton>
                  )}
                </Box>
              );
            })}
        </Box>
      ))}
    </Box>
  );
};

export default OpeningHours;
