import React, { useState } from 'react';
import { Box, Button, MenuItem, Menu, FormControl, Tooltip } from '@mui/material';
import { AutoAwesome } from '@mui/icons-material';
import PlusOneRoundedIcon from '@mui/icons-material/PlusOneRounded';
import { useResponsive } from '../../../hooks/useResponsive';
import { useDailyUsageContext } from '../../../contexts/DailyUsageContext';
import { useMembership } from '../../../hooks/useMembership';

const CategoryActions = ({
  addCategoryOptions,
  handleAddCategorySelection,
  setCategoryActionSelection,
  toggleGlobalExpansion,
  hasCategories,
  menuCategories,
  expandedCategories,
  openAskTaboDialog,
}) => {
  const { isMobile } = useResponsive();
  const { usage } = useDailyUsageContext();
  const { hasPaidModule } = useMembership();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

    const getTooltipMessage = () => {
    if (!hasPaidModule) {
      return "Acquista un modulo a tua scelta per utilizzare Tabo Assistant";
    }
    if (usage.disabled) {
      return "Hai raggiunto il limite giornaliero di utilizzo";
    }
    return "";
  };

  const isExpanded =
    hasCategories &&
    menuCategories.every((cat) => expandedCategories[cat.id]);

  const handleToggle = () => {
    toggleGlobalExpansion(isExpanded ? false : true);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (option) => {
    setCategoryActionSelection(option);
    handleAddCategorySelection(option);
    handleClose();
  };

  const renderAskTaboButton = () => {
    const label = isMobile ? 'Chiedi' : 'Chiedi a Tabo';
    const button = (
      <Button
        variant="outlined"
        color="secondary"
        sx={{ fontSize: isMobile ? '11px' : '13px' }}
        endIcon={<AutoAwesome />}
        onClick={openAskTaboDialog}
        disabled={usage.disabled || !hasPaidModule}
      >
        {label}
      </Button>
    );
    if (usage.disabled || !hasPaidModule) {
      return (
        <Tooltip title={getTooltipMessage()}>
          <span>{button}</span>
        </Tooltip>
      );
    }
    return button;
  };

  // Determine the text for the toggle button based on device and state.
  const toggleButtonText = isMobile
    ? isExpanded
      ? 'Comprimi'
      : 'Espandi'
    : isExpanded
    ? 'Comprimi tutto'
    : 'Espandi tutto';

  // Determine the props for the add category button based on the device.
  const addCategoryButtonProps = isMobile
    ? {
        text: 'Categoria',
        endIcon: <PlusOneRoundedIcon fontSize="small" />,
        fontSize: '11px',
      }
    : {
        text: 'Aggiungi Categoria',
        endIcon: null,
        fontSize: '13px',
      };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        mb: '10px',
      }}
    >
      {hasCategories && (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'flex-end',
            justifyContent: 'space-between',
            pt: '10px',
            pb: '10px',
          }}
        >
          <Button
            variant="text"
            color="primary"
            style={{ fontSize: isMobile ? '11px' : '12px' }}
            onClick={handleToggle}
          >
            {toggleButtonText}
          </Button>
        </Box>
      )}
      <Box
        sx={{
          display: 'flex',
          alignItems: 'flex-end',
          justifyContent: 'flex-end',
          gap: 2,
          pt: '10px',
          pb: '10px',
        }}
      >
        {renderAskTaboButton()}
        <FormControl>
          <Button
            variant="outlined"
            color="primary"
            style={{ fontSize: addCategoryButtonProps.fontSize }}
            onClick={handleClick}
            endIcon={addCategoryButtonProps.endIcon}
          >
            {addCategoryButtonProps.text}
          </Button>
          <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
            {addCategoryOptions.map((option) => (
              <MenuItem key={option.value} onClick={() => handleMenuItemClick(option)}>
                {option.label}
              </MenuItem>
            ))}
          </Menu>
        </FormControl>
      </Box>
    </Box>
  );
};

export default CategoryActions;
