import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Modal, Box, Typography, Divider, Button, Drawer } from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import CreditScoreRoundedIcon from '@mui/icons-material/CreditScoreRounded';
import { Elements } from '@stripe/react-stripe-js';
import stripePromise from '../Profile/stripe';
import StripePaymentWidget from './StripePaymentWidget';
import { createSetupIntent, createNewSubscription, addModules } from '../../services/stripeApiService';
import CustomLoader from '../Common/CustomLoader';
import { formatPrice } from '../../utils/formatPrice';

const PurchaseModulesDialog = ({
  open,
  onClose,
  onPaymentSuccess,
  summaryData,
  isNewSubscription,
  trialStarted,
  modulesToAdd = [],
  paymentMethod, // { id, card_brand, card_last4, card_exp_month, card_exp_year }
  onPaymentMethodUpdated,
}) => {
  const navigate = useNavigate();
  const [dialogLoading, setDialogLoading] = useState(false);
  const [clientSecret, setClientSecret] = useState(null);
  const [error, setError] = useState('');
  // This flag controls whether to use the saved payment method or show the PaymentElement.
  const [useSavedPayment, setUseSavedPayment] = useState(!!paymentMethod);
  // Controls whether the invoice details drawer is open.
  const [lineItemsDrawerOpen, setLineItemsDrawerOpen] = useState(false);

  const showPaymentElement = open && !useSavedPayment;

  useEffect(() => {
    if (open && showPaymentElement && !clientSecret) {
      const fetchSetupIntent = async () => {
        try {
          const data = await createSetupIntent();
          setClientSecret(data.client_secret);
        } catch (err) {
          console.error('Error creating Setup Intent:', err);
          setError('Errore nel caricamento del modulo di pagamento.');
        }
      };
      fetchSetupIntent();
    }
    if (!open) {
      setClientSecret(null);
      setError('');
    }
  }, [open, showPaymentElement, clientSecret]);

  const finalizeSubscription = async (paymentMethodId) => {
    try {
      setDialogLoading(true);
      const newPriceIds = modulesToAdd.map((m) => m.stripe_price_id);
      let result;
      if (isNewSubscription) {
        result = await createNewSubscription(newPriceIds, paymentMethodId);
      } else {
        result = await addModules(newPriceIds, paymentMethodId);
      }
      if (result.success) {
        if (onPaymentSuccess) onPaymentSuccess(result);
        onClose();
        navigate('/subscribe/success', { state: { isNewSubscription } });
      } else {
        setError(result.error || "Errore durante l'aggiornamento dell'abbonamento.");
        navigate('/subscribe/cancel');
      }
    } catch (err) {
      console.error('Error finalizing subscription:', err);
      setError(err.message || "Errore durante la creazione/aggiornamento dell'abbonamento.");
      navigate('/subscribe/cancel');
    } finally {
      setDialogLoading(false);
    }
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: { xs: '100%', sm: '65%', md: '45%' },
          maxHeight: '100vh',
          overflowY: 'auto',
          p: { xs: 2, sm: 3 },
          bgcolor: 'background.paper',
          borderRadius: 2,
          //        position: 'relative',
        }}
      >
        {dialogLoading && (
          <CustomLoader size={65} open={true} variant="opaque" text="Finalizzo il pagamento..." />
        )}

        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Box>
            {trialStarted ? (
              <Typography variant="h5" sx={{ mb: 1, color: 'secondary.main' }}>
                Riepilogo del piano
              </Typography>
            ) : (
              <Typography variant="h5" sx={{ mb: 1, color: 'secondary.main' }}>
                Inizia la prova gratuita di 30 giorni
              </Typography>
            )}
          </Box>
          <LockOutlinedIcon sx={{ color: 'success.main', fontSize: '19px', opacity: 0.85 }} />
        </Box>

        {/* Modules Recap */}
        {trialStarted ? (
          <Typography variant="body1">
            Stai per aggiungere i seguenti moduli:
          </Typography>
        ) : (
          <Typography variant="body1" sx={{ color: 'grey', fontWeight: 550 }}>
            Prova senza addebiti i seguenti moduli:
          </Typography>
        )}
        <Box sx={{ display: 'flex', gap: 1, mt: 1, flexWrap: 'wrap' }}>
          {modulesToAdd.map((mod) => (
            <Typography
              key={mod.id}
              sx={{
                fontSize: '11px',
                color: 'darkgreen',
                fontWeight: 550,
                padding: '4px 8px',
                borderRadius: '6px',
                backgroundColor: 'lightgreen.main',
              }}
            >
              {mod.name}
            </Typography>
          ))}
        </Box>

        <Divider sx={{ my: 2 }} />

        {/* Pricing Recap Split into 2 Columns */}
        {summaryData && (
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
            {/* Left Column: Pricing Summary */}
            <Box>
              <Typography variant="body1" sx={{ color: 'grey', fontWeight: 550 }}>
                Prezzo mensile del piano: {formatPrice(summaryData.subtotal)}€
              </Typography>
              <Typography variant="body2" sx={{ color: 'grey', fontWeight: 500 }}>
                IVA (22%): {formatPrice(summaryData.total_vat)}€
              </Typography>
              <Typography variant="body1" sx={{ color: 'grey', fontWeight: 550 }}>
                Totale mensile: {formatPrice(summaryData.grand_total)}€
              </Typography>
              {isNewSubscription ? (
                summaryData.trial_period_days > 0 ? (
                  <Typography variant="body1" sx={{ mt: 1, color: 'primary.main', fontWeight: 550 }}>
                    Prova gratuita fino al {new Date(summaryData.next_payment_attempt * 1000).toLocaleDateString('it-IT')}, poi {formatPrice(summaryData.grand_total)}€ al mese
                  </Typography>
                ) : (
                  <Typography variant="body1" sx={{ mt: 1, color: 'darkgrey.main', fontWeight: 550 }}>
                    Paghi adesso: {formatPrice(summaryData.immediate_charge)}€
                  </Typography>
                )
              ) : (
                summaryData.immediate_charge > 0 ? (
                  <Typography variant="body1" sx={{ mt: 1, color: 'darkgrey.main', fontWeight: 550 }}>
                    Paghi adesso: {formatPrice(summaryData.immediate_charge)}€
                  </Typography>
                ) : (
                  <>
                    <Typography variant="body1" sx={{ mt: 1, color: 'primary.main', fontWeight: 550 }}>
                      Nessun addebito fino al {new Date(summaryData.next_payment_attempt * 1000).toLocaleDateString('it-IT')}
                    </Typography>
                    {summaryData.next_billing_date && summaryData.next_billing_charge && (
                      <Typography variant="body1">
                        Prossima fatturazione ({new Date(summaryData.next_billing_date).toLocaleDateString('it-IT')}):{' '}
                        <strong>{formatPrice(summaryData.next_billing_charge)}€</strong>
                      </Typography>
                    )}
                  </>
                )
              )}
            </Box>
            {/* Right Column: "Maggiori dettagli" Button */}
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Button variant="text" sx={{ fontSize: '13px', p: '0px' }} onClick={() => setLineItemsDrawerOpen(true)}>
                Maggiori dettagli
              </Button>
            </Box>
          </Box>
        )}

        <Divider sx={{ my: 2 }} />

        {/* Payment Method Section */}
        {paymentMethod && useSavedPayment ? (
          <>
            <Box sx={{ p: '3px 9px', display: 'flex', alignItems: 'center', justifyContent: 'space-between', my: 3, border: '1px solid #ccc', borderRadius: '6px' }}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant="body1" sx={{ fontSize: '14px', color: 'darkgrey.main', fontWeight: 550, mr: 1 }}>
                  Usa la tua
                </Typography>
                <CreditScoreRoundedIcon sx={{ fontSize: '17px', color: 'darkgreen', opacity: 0.75 }} />
                <Typography variant="body1" sx={{ fontSize: '14px', color: 'darkgrey.main', fontWeight: 600, textTransform: "uppercase" }}>
                  {paymentMethod.card_brand} ****{paymentMethod.card_last4} - {paymentMethod.card_exp_month}/{paymentMethod.card_exp_year}
                </Typography>
              </Box>
              <Button
                variant="text"
                sx={{ fontSize: '13px', fontWeight: 550 }}
                onClick={() => {
                  setUseSavedPayment(false);
                  onPaymentMethodUpdated(null);
                }}
              >
                Cambia
              </Button>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 4 }}>
              <Button variant="text" color="error" onClick={onClose}>
                Annulla
              </Button>
              <Button variant="contained" onClick={() => finalizeSubscription(paymentMethod.id)}>
                Ordina
              </Button>
            </Box>
          </>
        ) : (
          <>
            {clientSecret ? (
              <Elements key={clientSecret} stripe={stripePromise} options={{ clientSecret }}>
                <StripePaymentWidget
                  trialStarted={trialStarted}
                  onPaymentSuccess={(paymentMethodId) => {
                    onPaymentMethodUpdated(paymentMethodId);
                    setUseSavedPayment(true);
                    finalizeSubscription(paymentMethodId);
                  }}
                  onCancel={onClose}
                  onError={(msg) => setError(msg)}
                />
              </Elements>
            ) : (
              showPaymentElement && (
                <Typography variant="body2" sx={{ color: 'gray' }}>
                  Caricamento del modulo di pagamento...
                </Typography>
              )
            )}
          </>
        )}

        {error && (
          <Typography variant="body2" color="error" sx={{ mt: 2 }}>
            {error}
          </Typography>
        )}

        {/* Drawer for Invoice Line Items Details, inside the modal */}
        <Drawer
          anchor="top"
          open={lineItemsDrawerOpen}
          onClose={() => setLineItemsDrawerOpen(false)}
          disablePortal
          PaperProps={{ sx: { maxHeight: '85%' } }}
          slotProps={{ backdrop: { sx: { backgroundColor: 'transparent' } } }}
        >
          <Box sx={{ p: 2, height: '100%', overflowY: 'auto' }}>
            <Typography variant="h6" sx={{ mb: 2, color: 'darkgrey.main' }}>
              Dettagli del piano e della fatturazione
            </Typography>
            {summaryData && summaryData.line_items && summaryData.line_items.length > 0 ? (
              summaryData.line_items.map((item, idx) => {
                // Customize the description: replace English terms with Italian equivalents.
                const description = item.description
                  .replace('1 × ', '')
                  .replace('Tempo rimanente su ', '')
                  .replace('dopo il giorno', 'a partire dal')
                  .replace('month', 'mese')
                  .replace('at', 'a');
                const grandTotal = item.amount + item.vat;
                return (
                  <Box key={idx} sx={{ mb: 1, p: 1, borderBottom: '1px solid #ddd' }}>
                    <Typography variant="body2" sx={{ fontWeight: 550, color: 'primary.main' }}>
                      {description}
                    </Typography>
                    <Typography variant="body2">
                      IVA (22%): {formatPrice(item.vat)}€
                    </Typography>
                    <Typography variant="body2">
                      Totale (IVA inclusa): {formatPrice(grandTotal)}€
                    </Typography>
                    <Typography variant="body2">
                      Prossimo addebito: {new Date(item.period_start * 1000).toLocaleDateString('it-IT')}
                    </Typography>
                  </Box>
                );
              })
            ) : (
              <Typography variant="body2">Nessun dettaglio disponibile</Typography>
            )}
            <Box sx={{ mt: 2, textAlign: 'right' }}>
              <Button variant="text" onClick={() => setLineItemsDrawerOpen(false)}>
                Chiudi
              </Button>
            </Box>
          </Box>
        </Drawer>
      </Box>
    </Modal>
  );
};

export default PurchaseModulesDialog;
