import React, { useState, useEffect } from 'react';
import {
  Stepper,
  Step,
  StepLabel,
  StepContent,
  Button,
  Typography,
  TextField,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  FormControlLabel,
  Checkbox,
  Alert,
  Box,
} from '@mui/material';
import { useMenu } from '../../../contexts/MenuContext';
import {
  createMenu,
  handleMenuCategory,
  handleMenuItem,
  fetchAllergens,
  fetchDietGroups,
  assignMenuToRestaurantAndArea
} from '../../../services/apiService';
import PictureUpload from '../../Common/PictureUpload';
import CustomLoader from '../../Common/CustomLoader';
import MenuAreaAssignmentWidget from '../MenuAreaAssignmentWidget';
import CustomTimePicker from '../../Common/CustomTimePicker';

// Update steps array to include the new step.
const steps = [
  'Crea Menu',
  'Aggiungi Categoria',
  'Aggiungi Voce di Menu',
  'Informazioni Aggiuntive',
  'Associa e concludi'
];

const courseTypeOptions = [
  { value: 'Drinks', label: 'Bevande' },
  { value: 'Starter', label: 'Antipasti' },
  { value: 'Pasta', label: 'Primi/Pizza' },
  { value: 'Main Course', label: 'Secondi' },
  { value: 'Side Dish', label: 'Contorni' },
  { value: 'Dessert', label: 'Dessert' },
];

const dayOptions = [
  { value: 'MO', label: 'Lunedì' },
  { value: 'TU', label: 'Martedì' },
  { value: 'WE', label: 'Mercoledì' },
  { value: 'TH', label: 'Giovedì' },
  { value: 'FR', label: 'Venerdì' },
  { value: 'SA', label: 'Sabato' },
  { value: 'SU', label: 'Domenica' },
];

const MenuWizard = ({ loading, onComplete }) => {
  const [activeStep, setActiveStep] = useState(0);
  const [menuName, setMenuName] = useState('');
  const [categoryName, setCategoryName] = useState('');
  const [categoryDescription, setCategoryDescription] = useState('');
  const [courseType, setCourseType] = useState('');
  const [showCategoryDaySelector, setShowCategoryDaySelector] = useState(false);
  const [categoryAvailableDays, setCategoryAvailableDays] = useState([]);
  const [menuItemName, setMenuItemName] = useState('');
  const [menuItemDescription, setMenuItemDescription] = useState('');
  const [menuItemPrice, setMenuItemPrice] = useState('');
  const [selectedAllergens, setSelectedAllergens] = useState([]);
  const [selectedDietGroups, setSelectedDietGroups] = useState([]);
  const [allergenOptions, setAllergenOptions] = useState([]);
  const [dietGroupOptions, setDietGroupOptions] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(null);
  const [alertMessage, setAlertMessage] = useState(null);
  const [priceError, setPriceError] = useState('');
  const [skipAssignment, setSkipAssignment] = useState(false);
  const [areaAssignmentData, setAreaAssignmentData] = useState(null);
  const [showTimeSelector, setShowTimeSelector] = useState(false);
  const [activeFrom, setActiveFrom] = useState('07:00');
  const [activeUntil, setActiveUntil] = useState('23:00');
  const [timeError, setTimeError] = useState('');
  const [createdMenuId, setCreatedMenuId] = useState(null);
  const [createdCategoryId, setCreatedCategoryId] = useState(null);


  const { loadMenuCategories, loadMenuItems } = useMenu();

  const formatTime = (timeStr) => {
    return timeStr ? `${timeStr}:00` : null;
  };

  useEffect(() => {
    const loadOptions = async () => {
      try {
        const [allergens, dietGroups] = await Promise.all([
          fetchAllergens(),
          fetchDietGroups(),
        ]);
        setAllergenOptions(
          allergens.map((allergen) => ({ value: allergen.id, label: allergen.name }))
        );
        setDietGroupOptions(
          dietGroups.map((dietGroup) => ({ value: dietGroup.id, label: dietGroup.name }))
        );
      } catch (error) {
        console.error('Errore nel recuperare le opzioni:', error);
      }
    };

    loadOptions();
  }, []);

  // Price change handler with validation:
  const handlePriceChange = (e) => {
    let value = e.target.value;
    // Replace comma with dot
    value = value.replace(',', '.');
    // Regex: allow 0-4 digits optionally followed by a dot and up to 2 digits.
    const regex = /^\d{0,4}(\.\d{0,2})?$/;
    if (value === '' || regex.test(value)) {
      setPriceError('');
    } else {
      setPriceError('Il prezzo deve avere al massimo 4 cifre e 2 decimali.');
    }
    setMenuItemPrice(value);
  };

  const handleFinish = async () => {
    setAlertMessage(null);
    // Do not proceed if there's a price error.
    if (priceError) return;

    // Validate area assignment only if not skipping assignment.
    if (!skipAssignment) {
      if (
        !areaAssignmentData ||
        !areaAssignmentData.selectedAreas ||
        areaAssignmentData.selectedAreas.length === 0
      ) {
        setAlertMessage({
          type: 'warning',
          text: 'Seleziona almeno un ristorante e un’area oppure spunta "Salta per ora".',
        });
        return;
      }
    }
    try {
      // Only create menu, category, and items if not already done.
      if (!createdMenuId) {
        // Create Menu
        const newMenu = await createMenu({
          name: menuName,
          active_from: showTimeSelector ? formatTime(activeFrom) : null,
          active_until: showTimeSelector ? formatTime(activeUntil) : null,
        });
        setCreatedMenuId(newMenu.id);

        // Create Category
        const newCategory = await handleMenuCategory({
          name: categoryName,
          description: categoryDescription,
          menus: [newMenu.id],
          available_days:
            categoryAvailableDays.length > 0 ? categoryAvailableDays.join(',') : 'EVERYDAY',
          course_type: courseType,
        });
        setCreatedCategoryId(newCategory.id);

        // Create Menu Item
        const menuItemData = new FormData();
        menuItemData.append('name', menuItemName);
        menuItemData.append('description', menuItemDescription);
        menuItemData.append('price', menuItemPrice);
        menuItemData.append('categories', newCategory.id);
        if (selectedFile) {
          menuItemData.append('picture', selectedFile);
        }
        if (selectedAllergens.length > 0) {
          selectedAllergens.forEach((allergen) => {
            menuItemData.append('allergens', allergen);
          });
        }
        if (selectedDietGroups.length > 0) {
          selectedDietGroups.forEach((dietGroup) => {
            menuItemData.append('diet_groups', dietGroup);
          });
        }
        await handleMenuItem(menuItemData);
      }
      // Attempt assignment if not skipped.
      if (!skipAssignment) {
        try {
          await assignMenuToRestaurantAndArea(createdMenuId, areaAssignmentData.selectedAreas);
        } catch (error) {
          // Check for the specific overlap error.
          if (
            error.response &&
            error.response.data &&
            error.response.data.error === "Time overlap with existing menu."
          ) {
            setAlertMessage({
              type: 'error',
              text: "C'è già un menu assegnato a quest'area nel periodo selezionato.",
            });
            // Exit early so that you don't reload or finish the wizard.
            return;
          } else {
            setAlertMessage({
              type: 'error',
              text: "Errore nel completamento del Wizard. Per favore riprova.",
            });
            return;
          }
        }
      }
      // Refresh categories and items, then complete the wizard.
      await loadMenuCategories(createdMenuId);
      await loadMenuItems(createdCategoryId);
      onComplete();
    } catch (error) {
      // Handle any errors that might have occurred during creation.
      setAlertMessage({
        type: 'error',
        text: "Errore nel completamento del Wizard. Per favore riprova.",
      });
      console.error("Errore nel completamento del Wizard:", error);
    }
  };

  const handleNext = () => {
    setAlertMessage(null);
    // Validate fields depending on the current step
    if (activeStep === 0 && !menuName.trim()) {
      setAlertMessage({ type: 'warning', text: 'Il nome del menu è obbligatorio' });
      return;
    }
    if (activeStep === 1) {
      if (!categoryName.trim()) {
        setAlertMessage({ type: 'warning', text: 'Il nome della categoria è obbligatorio' });
        return;
      }
      if (!courseType) {
        setAlertMessage({ type: 'warning', text: 'La tipologia è obbligatoria' });
        return;
      }
    }
    if (activeStep === 2 && (!menuItemName.trim() || !menuItemPrice.trim())) {
      setAlertMessage({
        type: 'warning',
        text: 'Nome e prezzo sono obbligatori',
      });
      return;
    }
    setActiveStep((prev) => prev + 1);
  };

  const handleBack = () => {
    setAlertMessage(null);
    setActiveStep((prev) => prev - 1);
  };

  const handleDayChange = (event) => {
    const value = event.target.value;
    setCategoryAvailableDays(typeof value === 'string' ? value.split(',') : value);
  };

  const handleToggleDaySelector = (event) => {
    setShowCategoryDaySelector(event.target.checked);
    if (!event.target.checked) {
      setCategoryAvailableDays([]);
    }
  };

  const handleCourseTypeChange = (event) => {
    setCourseType(event.target.value);
  };

  const handleAllergenChange = (event) => {
    const { value } = event.target;
    setSelectedAllergens(value);
  };

  const handleDietGroupChange = (event) => {
    const { value } = event.target;
    setSelectedDietGroups(value);
  };

  const handleFileSelect = (file, previewUrl) => {
    setSelectedFile(file);
    setPreviewUrl(previewUrl);
  };

  const handleClearImage = () => {
    setSelectedFile(null);
    setPreviewUrl(null);
  };

  if (loading) {
    return <CustomLoader open={true} />;
  }

  return (
    <Box sx={{ width: '100%', p: 0 }}>
      <Stepper
        activeStep={activeStep}
        orientation="vertical"
        sx={{ width: { xs: '100%', sm: '75%' }, mx: 'auto' }}
      >
        {/* Step 0: Crea Menu */}
        <Step key="Crea Menu">
          <StepLabel>Dai un nome al tuo menu</StepLabel>
          <StepContent sx={{ mt: 2 }}>
            <Box sx={{ mb: 2 }}>
              <TextField
                fullWidth
                variant="outlined"
                placeholder='per es. "Menu pranzo"'
                label="Nome del menu"
                value={menuName}
                onChange={(e) => setMenuName(e.target.value)}
                margin="normal"
              />
            </Box>

            {/* Toggle for Time-Based Availability */}
            <Box sx={{ mb: 2 }}>
              <FormControlLabel
                control={
                  <Switch
                    checked={showTimeSelector}
                    onChange={(e) => {
                      const isEnabled = e.target.checked;
                      setShowTimeSelector(isEnabled);
                      // If disabling, clear time values and error.
                      if (!isEnabled) {
                        setActiveFrom('');
                        setActiveUntil('');
                        setTimeError('');
                      }
                    }}
                    name="showTimeSelector"
                    color="primary"
                  />
                }
                label="Disponibile solo in orari specifici?"
              />
            </Box>
            {showTimeSelector && (
              <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
                <CustomTimePicker
                  label="Da"
                  value={activeFrom}
                  onChange={(newValue) => {
                    setActiveFrom(newValue);
                    // Validate: if both times are set, activeFrom must be less than activeUntil
                    if (activeUntil && newValue >= activeUntil) {
                      setTimeError('L’orario "Da" deve essere precedente all’orario "Fino a".');
                    } else {
                      setTimeError('');
                    }
                  }}
                  timeInterval={30}
                  minTime="00:00"
                  maxTime="23:30"
                />
                <CustomTimePicker
                  label="Fino a"
                  value={activeUntil}
                  onChange={(newValue) => {
                    setActiveUntil(newValue);
                    if (activeFrom && activeFrom >= newValue) {
                      setTimeError('L’orario "Da" deve essere precedente all’orario "Fino a".');
                    } else {
                      setTimeError('');
                    }
                  }}
                  timeInterval={30}
                  minTime="00:00"
                  maxTime="23:30"
                />
              </Box>
            )}
            {timeError && (
              <Typography color="error" variant="body2">
                {timeError}
              </Typography>
            )}
          </StepContent>
        </Step>

        {/* Step 1: Aggiungi Categoria */}
        <Step key="Aggiungi Categoria">
          <StepLabel>Aggiungi una categoria</StepLabel>
          <StepContent sx={{ mt: 2 }}>
            <Box sx={{ mb: 2 }}>
              <Typography variant="body2">
                Le categorie rappresentano le varie sezioni del menu
              </Typography>
              <TextField
                fullWidth
                variant="outlined"
                label="Categoria"
                placeholder='per es. "Pizze"'
                value={categoryName}
                onChange={(e) => setCategoryName(e.target.value)}
                margin="normal"
              />
            </Box>
            <Box sx={{ mb: 2 }}>
              <Typography variant="body2">
                Per ogni categoria, puoi aggiungere una descrizione
              </Typography>
              <TextField
                fullWidth
                variant="outlined"
                label="Descrizione"
                placeholder='per es. "Lievitazione naturale"'
                value={categoryDescription}
                onChange={(e) => setCategoryDescription(e.target.value)}
                margin="normal"
              />
            </Box>
            <Box sx={{ mb: 2 }}>
              <Typography variant="body2">
                La tipologia ci aiuta ad organizzare l'ordine di uscita delle comande
              </Typography>
              <FormControl fullWidth margin="normal">
                <InputLabel>Tipologia</InputLabel>
                <Select
                  value={courseType}
                  onChange={handleCourseTypeChange}
                  label="Tipologia"
                  sx={{ height: '50px' }}
                >
                  {courseTypeOptions.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <Box sx={{ mb: 2 }}>
              <FormControlLabel
                control={
                  <Switch
                    checked={showCategoryDaySelector}
                    onChange={handleToggleDaySelector}
                    name="showCategoryDaySelector"
                    color="primary"
                  />
                }
                label="Disponibile solo in alcuni giorni?"
              />
              {showCategoryDaySelector && (
                <FormControl fullWidth margin="normal">
                  <InputLabel>Seleziona i giorni</InputLabel>
                  <Select
                    multiple
                    value={categoryAvailableDays}
                    onChange={handleDayChange}
                    label="Seleziona i giorni"
                    renderValue={(selected) =>
                      selected
                        .map(
                          (value) =>
                            dayOptions.find((option) => option.value === value)?.label
                        )
                        .join(', ')
                    }
                  >
                    {dayOptions.map((option) => (
                      <MenuItem
                        key={option.value}
                        value={option.value}
                        sx={{
                          fontWeight: categoryAvailableDays.includes(option.value)
                            ? 'bold'
                            : 'normal',
                        }}
                      >
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            </Box>
          </StepContent>
        </Step>

        {/* Step 2: Aggiungi Voce di Menu */}
        <Step key="Aggiungi Voce di Menu">
          <StepLabel>Aggiungi un piatto o bevanda</StepLabel>
          <StepContent sx={{ mt: 2 }}>
            <Box sx={{ mb: 2 }}>
              <TextField
                fullWidth
                variant="outlined"
                label="Nome"
                placeholder='ad es. "Margherita"'
                value={menuItemName}
                onChange={(e) => setMenuItemName(e.target.value)}
                margin="normal"
              />
            </Box>
            <Box sx={{ mb: 2 }}>
              <TextField
                fullWidth
                variant="outlined"
                label="Descrizione"
                placeholder='ad es. "Pomodoro e mozzarella"'
                value={menuItemDescription}
                onChange={(e) => setMenuItemDescription(e.target.value)}
                margin="normal"
              />
            </Box>
            <Box sx={{ mb: 2 }}>
              <TextField
                fullWidth
                variant="outlined"
                placeholder="€ Euro"
                label="Prezzo"
                type="text"
                inputMode="decimal"
                value={menuItemPrice}
                onChange={handlePriceChange}
                margin="normal"
                error={!!priceError}
                helperText={priceError}
              />
            </Box>
          </StepContent>
        </Step>

        {/* Step 3: Informazioni Aggiuntive */}
        <Step key="Informazioni Aggiuntive">
          <StepLabel>Informazioni Aggiuntive</StepLabel>
          <StepContent sx={{ mt: 2 }}>
            <Box sx={{ mb: 2 }}>
              <Typography variant="body2" sx={{ color: 'darkgrey', mb: 1 }}>
                Seleziona allergeni (se presenti)
              </Typography>
              <Alert severity="info" sx={{ fontWeight: 550 }} >
                Assicurati di inserire tutti gli allergeni potenzialmente presenti
                (anche da contaminazione) utilizzando la lista ufficiale prodotta dall'Unione Europea.
              </Alert>
              <FormControl fullWidth margin="normal">
                <InputLabel>Allergeni</InputLabel>
                <Select
                  multiple
                  label="Allergeni"
                  value={selectedAllergens}
                  onChange={handleAllergenChange}
                  renderValue={(selected) =>
                    selected
                      .map(
                        (item) =>
                          allergenOptions.find((option) => option.value === item)?.label
                      )
                      .join(', ')
                  }
                >
                  {allergenOptions.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      <FormControlLabel
                        control={
                          <Checkbox size="small" checked={selectedAllergens.indexOf(option.value) > -1} />
                        }
                        label={option.label}
                      />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <Box sx={{ mb: 2 }}>
              <Typography variant="body2">
                Gruppi alimentari (facoltativo)
              </Typography>
              <FormControl fullWidth margin="normal">
                <InputLabel>Gruppi Alimentari</InputLabel>
                <Select
                  multiple
                  label="Gruppi Alimentari"
                  value={selectedDietGroups}
                  onChange={handleDietGroupChange}
                  renderValue={(selected) =>
                    selected
                      .map(
                        (item) =>
                          dietGroupOptions.find((option) => option.value === item)?.label
                      )
                      .join(', ')
                  }
                >
                  {dietGroupOptions.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      <FormControlLabel
                        control={
                          <Checkbox size="small" checked={selectedDietGroups.indexOf(option.value) > -1} />
                        }
                        label={option.label}
                      />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <Box sx={{ mb: 2 }}>
              <Typography variant="body2" sx={{ fontWeight: 550, mt: 3, mb: 2 }}>
                Carica un'immagine per la voce di menu (facoltativo)
              </Typography>
              <Box display="flex">
                <PictureUpload
                  existingFile={null}
                  previewUrl={previewUrl}
                  onFileSelect={handleFileSelect}
                  onClearImage={handleClearImage}
                />
              </Box>
            </Box>
          </StepContent>
        </Step>

        {/* Step 4: Associa e concludi */}
        <Step key="Associa e concludi">
          <StepLabel>Associa e concludi</StepLabel>
          <StepContent sx={{ mt: 2, px: { sx: 1, sm: 2, md: 6 } }}>
            <Typography variant="body2" sx={{ mb: 0.5, fontWeight: 550 }}>
              Associa il menu alle zone del tuo ristorante:
            </Typography>
            {!skipAssignment && (
              <MenuAreaAssignmentWidget
                hideAssignButton={true}
                onAssignmentStatusChange={(data) => setAreaAssignmentData(data)}
              />
            )}
            <Box sx={{ mt: 0, mb: 1 }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={skipAssignment}
                    onChange={(e) => {
                      const isSkipping = e.target.checked;
                      setSkipAssignment(isSkipping);
                      if (isSkipping) {
                        setAlertMessage(null);
                      }
                    }}
                    color="primary"
                    size="small"
                  />
                }
                label="Salta per ora"
              />
              {skipAssignment && (
                <Alert severity="info" sx={{ mt: 2 }}>
                  Il menu non è assegnato a nessuna area. Clicca "Completa" per terminare.
                </Alert>
              )}
            </Box>
          </StepContent>
        </Step>
      </Stepper>

      {alertMessage && (
        <Box sx={{ width: { xs: '100%', sm: '75%' }, mx: 'auto' }}>
          <Alert sx={{ fontWeight: 550 }} severity={alertMessage.type}>
            {alertMessage.text}
          </Alert>
        </Box>
      )}

      {activeStep < steps.length && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            width: { xs: '100%', sm: '75%' },
            mx: 'auto',
            mt: 3,
          }}
        >
          <Button
            variant="outlined"
            onClick={handleBack}
            disabled={activeStep === 0}
          >
            Indietro
          </Button>
          <Button
            variant="contained"
            color="primary"
            sx={{ minWidth: '90px' }}
            onClick={activeStep === steps.length - 1 ? handleFinish : handleNext}
          >
            {activeStep === steps.length - 1 ? 'Completa' : 'Avanti'}
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default MenuWizard;
