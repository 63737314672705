import React, { useState, useEffect } from 'react';
import { useRestaurant } from '../../../contexts/RestaurantContext';
import { useMenu } from '../../../contexts/MenuContext';
import { updateMenu, assignMenuToRestaurantAndArea, removeMenuFromAreas } from '../../../services/apiService';
import {
  TextField,
  Button,
  Typography,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Alert,
  Switch,
  FormControlLabel,
} from '@mui/material';
import CustomTimePicker from '../../Common/CustomTimePicker';

const MenuForm = ({ onClose, reloadMenus, editingMenu, setEditingMenu }) => {
  // Contexts
  const { selectedRestaurant, userRestaurants, areas, areasLoading, setSelectedRestaurant } = useRestaurant();
  const { setMenus } = useMenu();

  // Local state for menu fields
  const [nomeMenu, setNomeMenu] = useState('');
  const [activeFrom, setActiveFrom] = useState('');
  const [activeUntil, setActiveUntil] = useState('');

  // State to conditionally show/hide time pickers
  const [showTimePickers, setShowTimePickers] = useState(false);

  // Local state for association fields
  const [selectedAreas, setSelectedAreas] = useState([]);
  // Store the original associations for removal later if needed
  const [originalAreas, setOriginalAreas] = useState([]);
  const [assignmentError, setAssignmentError] = useState('');

  // Flags to indicate intentional clearing
  const [isAssociationCleared, setIsAssociationCleared] = useState(false);

  // Reset form helper
  const resetForm = () => {
    setNomeMenu('');
    setActiveFrom('');
    setActiveUntil('');
    setSelectedAreas([]);
    setOriginalAreas([]);
    setAssignmentError('');
    setIsAssociationCleared(false);
    setShowTimePickers(false);
  };

  // Pre-fill form when editingMenu exists
  useEffect(() => {
    if (editingMenu) {
      setNomeMenu(editingMenu.name);
      setActiveFrom(editingMenu.active_from || '');
      setActiveUntil(editingMenu.active_until || '');
      // If there is a specific active period, show the time pickers
      if (editingMenu.active_from || editingMenu.active_until) {
        setShowTimePickers(true);
      }
      // Pre-fill the selected areas from assigned_areas
      const areasFromMenu = editingMenu.assigned_areas
        ? Array.isArray(editingMenu.assigned_areas)
          ? editingMenu.assigned_areas
          : [editingMenu.assigned_areas]
        : [];
      setSelectedAreas(areasFromMenu);
      // Store the original areas so we know what to remove if needed
      setOriginalAreas(areasFromMenu);
      setIsAssociationCleared(false);
    } else {
      resetForm();
    }
  }, [editingMenu]);

  // Handler for toggling the time pickers switch
  const handleTimeSwitchChange = (event) => {
    const checked = event.target.checked;
    setShowTimePickers(checked);
    if (!checked) {
      setActiveFrom('');
      setActiveUntil('');
    }
  };

  const handleUpdateMenu = async (event) => {
    event.preventDefault();
    setAssignmentError('');
    // Validate required fields
    if (!nomeMenu.trim()) {
      setAssignmentError("Il nome del menu non può essere vuoto.");
      return;
    }
    // - The user hasn't intentionally cleared associations (isAssociationCleared is false)
    // - AND either there were associations originally OR the user has entered some associations now.
    const associationsExist = originalAreas.length > 0 || selectedAreas.length > 0;
    if (!isAssociationCleared && associationsExist) {
      if (!selectedRestaurant) {
        setAssignmentError('Seleziona un ristorante.');
        return;
      }
      if (selectedAreas.length === 0) {
        setAssignmentError("Seleziona almeno un'area.");
        return;
      }
    }
    // Prepare menu data payload
    const menuData = {
      name: nomeMenu,
      restaurant: selectedRestaurant,
      active_from: showTimePickers && activeFrom ? activeFrom : null,
      active_until: showTimePickers && activeUntil ? activeUntil : null,
    };

    try {
      // Update menu details
      const updatedMenu = await updateMenu(editingMenu.id, menuData, selectedRestaurant);
      setMenus((prevMenus) =>
        prevMenus.map((menu) => (menu.id === updatedMenu.id ? updatedMenu : menu))
      );

      // Handle associations:
      if (!isAssociationCleared) {
        // If the user hasn't cleared associations and there are associations to process,
        // assign the menu to the selected areas.
        if (selectedAreas.length > 0) {
          await assignMenuToRestaurantAndArea(updatedMenu.id, selectedAreas);
        }
        // If the user left them empty and originally there was none, that's fine.
      } else {
        // If the association section was cleared, remove the menu from the original areas.
        await removeMenuFromAreas(updatedMenu.id, originalAreas);
      }

      reloadMenus();
      onClose();
      setEditingMenu(null);
    } catch (error) {
      if (error.response?.data?.error === "Time overlap with existing menu.") {
        setAssignmentError("C'è già un menu assegnato a quest'area nel periodo selezionato.");
      } else if (error.response?.data?.error) {
        setAssignmentError(error.response.data.error);
      } else {
        console.error('Errore nell’aggiornamento o nell’assegnazione del menu:', error);
        setAssignmentError('Si è verificato un errore inaspettato.');
      }
    }
  };

  // Handler for resetting associations
  const handleResetAssociation = () => {
    setSelectedAreas([]);
    // Optionally, you can reset the restaurant selection as well:
    setSelectedRestaurant('');
    setIsAssociationCleared(true);
  };

  return (
    <Box
      component="form"
      onSubmit={handleUpdateMenu}
      sx={{ display: 'flex', flexDirection: 'column', padding: 2 }}
    >
      <Typography variant="body2" sx={{ fontWeight: 550, mb: 3 }}>
        Modifica Menu
      </Typography>

      {/* Menu Name */}
      <TextField
        fullWidth
        variant="outlined"
        label="Nome del Menu"
        placeholder="es. Menu Pranzo"
        value={nomeMenu}
        onChange={(e) => setNomeMenu(e.target.value)}
        sx={{ mb: 5 }}
      />

      {/* Time pickers section with conditional rendering */}
      <Box sx={{ mb: 3 }}>
        <FormControlLabel
          control={
            <Switch
              checked={showTimePickers}
              onChange={handleTimeSwitchChange}
              color="primary"
            />
          }
          label="Pubblica in specifiche fasce orarie"
        />
        {showTimePickers && (
          <Box sx={{ display: 'flex', gap: 2, mt: 2 }}>
            <CustomTimePicker
              label="Attivo da"
              value={activeFrom}
              onChange={(val) => setActiveFrom(val)}
              placeholder="Orario Iniziale"
            />
            <CustomTimePicker
              label="Fino a"
              value={activeUntil}
              onChange={(val) => setActiveUntil(val)}
              placeholder="Orario Finale"
            />
          </Box>
        )}
      </Box>

      {/* Association Section */}
      <Box sx={{ mt: 4 }}>
        <Typography variant="body2" sx={{ fontWeight: 550, mb: 1 }}>
          Assegna ad aree del ristorante
        </Typography>

        {/* Restaurant Selection */}
        <FormControl fullWidth variant="outlined" margin="normal">
          <InputLabel id="restaurant-select-label">Seleziona Ristorante</InputLabel>
          <Select
            labelId="restaurant-select-label"
            value={selectedRestaurant || ''}
            onChange={(e) => {
              const newRestaurant = e.target.value;
              setSelectedRestaurant(newRestaurant);
              // Changing restaurant manually means the association is no longer cleared.
              setIsAssociationCleared(false);
            }}
            label="Seleziona Ristorante"
            sx={{ height: '50px' }}
          >
            {userRestaurants.map((restaurant) => (
              <MenuItem key={restaurant.id} value={restaurant.id}>
                {restaurant.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {/* Area Selection */}
        {areasLoading ? (
          <Alert severity="info" sx={{ mt: 2 }}>
            Caricamento delle aree...
          </Alert>
        ) : areas.length === 0 ? (
          <Alert severity="info" sx={{ mt: 2 }}>
            Non hai ancora creato un ristorante con le sue aree. Puoi farlo nella sezione "Il Mio Ristorante".
          </Alert>
        ) : (
          <FormControl fullWidth variant="outlined" margin="normal">
            <InputLabel id="area-select-label">Seleziona Aree</InputLabel>
            <Select
              labelId="area-select-label"
              multiple
              value={selectedAreas}
              onChange={(e) => {
                const newAreas = e.target.value;
                setSelectedAreas(newAreas);
                setIsAssociationCleared(false);
              }}
              label="Seleziona Aree"
              sx={{ height: '50px' }}
            >
              {areas.map((area) => (
                <MenuItem key={area.id} value={area.id}>
                  {area.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
        <Box sx={{ display: 'flex', justifyContent: 'flex-start', mb: 5 }}>
          <Button sx={{ fontSize: '13px', fontWeight: 550 }} onClick={handleResetAssociation}>
            Resetta
          </Button>
        </Box>

        {assignmentError && (
          <Alert severity="error" sx={{ mb: 2 }}>
            {assignmentError}
          </Alert>
        )}
      </Box>

      {/* Action Buttons */}
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
        <Button variant="outlined" color="error" onClick={onClose}>
          Cancella
        </Button>
        <Button variant="outlined" color="primary" type="submit">
          Conferma
        </Button>
      </Box>
    </Box>
  );
};

export default MenuForm;
