// LoginForm.jsx v1.30
import React, { useState } from 'react';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import { Box, Button, Typography, Alert, Link } from '@mui/material';
import { useAuth } from '../../contexts/AuthContext';
import RoundedTextField from '../Common/RoundedTextField';
import logo from '../../assets/logos/logo_small.png';

// Login form component
const LoginForm = () => {
  // State Management for Form Inputs
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [formErrors, setFormErrors] = useState({});
  const [loginError, setLoginError] = useState('');
  const [verificationUuid, setVerificationUuid] = useState(null); // State for verification UUID

  const navigate = useNavigate(); // Hook to navigate
  const { login } = useAuth();

  // Input Handlers
  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  // Form Validation
  const validateForm = () => {
    const errors = {};
    // Check if the email field is not empty
    if (!email) {
      errors.email = 'Please enter your email';
    }
    // Check if the password field is not empty
    if (!password) {
      errors.password = 'Please enter your password';
    }
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  // Form Submission handling
  const handleSubmit = async (event) => {
    event.preventDefault();
    if (validateForm()) {
      const userData = { email, password };
      try {
        await login(userData);
        navigate('/dashboard/my-restaurants'); // Redirect after successful login
      } catch (error) {
        // Reset previous login error and verification UUID
        setLoginError('');
        setVerificationUuid(null);

        // Check if the error has a response from the server
        if (error.response) {
          if (error.response.status === 403) {
            // User is not verified
            setLoginError('Please verify your email to log in.');
            setVerificationUuid(error.response.data.verification_uuid); // Store the verification UUID
          } else if (error.response.status === 400) {
            // Invalid credentials
            setLoginError('Invalid credentials. Please try again.');
          } else {
            // Other server-side errors
            setLoginError('An unexpected error occurred. Please try again.');
          }
        } else {
          // Network or client-side errors
          setLoginError('Unable to connect to the server. Please try again later.');
        }
      }
    }
  };

  return (
    <Box
      sx={{
        minHeight: '85vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Box
        sx={{
          width: { xs: '90%', sm: '56%', md: '42%', lg: '28%', xl: '24%' },
          p: { xs: '1', sm: '2', md: '3', lg: '4', xl: '5' },
        }}
      >
        <Box sx={{ width: '100%', textAlign: 'center', mb: 2 }}>
          <img src={logo} alt="Logo" style={{ width: '50px' }} />
        </Box>
        <Box sx={{ width: '100%', mt: 1 }}>
          <Typography variant="h3" sx={{ paddingBottom: '5px', textAlign: 'center' }}>
            Accedi al tuo ristorante
          </Typography>
          <Typography variant="body1" textAlign="center">
            Non sei ancora registrato?{' '}
            <Link component={RouterLink} to="/register" underline="hover">
              Crea un account
            </Link>
          </Typography>
        </Box>
        <Box
          component="form"
          onSubmit={handleSubmit}
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <RoundedTextField
            fullWidth
            size="medium"
            type="email"
            placeholder="La tua e-mail"
            value={email}
            onChange={handleEmailChange}
            error={!!formErrors.email}
            helperText={formErrors.email}
            autoComplete="email"
          />
          <RoundedTextField
            fullWidth
            type="password"
            placeholder="La tua password"
            value={password}
            onChange={handlePasswordChange}
            error={!!formErrors.password}
            helperText={formErrors.password}
            autoComplete="current-password"
          />
          {loginError && (
            <Alert severity="error" sx={{ width: '100%', mt: 2 }}>
              {loginError}
              {verificationUuid && (
                <Box sx={{ marginTop: '10px' }}>
                  <Typography variant="body1">
                    <Link component={RouterLink} to={`/verify/${verificationUuid}`} underline="hover">
                      Click here to verify your email.
                    </Link>
                  </Typography>
                </Box>
              )}
            </Alert>
          )}
          <Button
            type="submit"
            fullWidth
            variant="rounded"
            color="primary"
            sx={{ mt: 2, mb: 2, borderRadius: '20px', py: 1.3, fontSize: '16px' }}
          >
            Accedi
          </Button>
          <Box sx={{ width: '100%', mt: 1 }}>
            <Typography variant="body1">
              <Link component={RouterLink} to="/password-reset" underline="hover">
                Hai dimenticato la password?
              </Link>
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default LoginForm;
