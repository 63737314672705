import React from 'react';
import { Route, Routes } from 'react-router-dom';
import './App.css';
import AuthLayout from './components/Authentication/AuthLayout';
import RegistrationForm from './components/Authentication/RegistrationForm';
import EmailVerification from './components/Authentication/EmailVerification';
import SubscriptionPage from './components/Profile/SubscriptionPage';
import SubscriptionSuccess from './components/Profile/SubscriptionSuccess';
import SubscriptionCancel from './components/Profile/SubscriptionCancel';
import UserProfile from './components/Profile/UserProfile';
import LoginForm from './components/Authentication/LoginForm';
import PasswordReset from './components/Authentication/PasswordReset';
import PasswordResetForm from './components/Authentication/PasswordResetForm';
import PasswordEmailSent from './components/Authentication/PasswordEmailSent';
import Dashboard from './components/Dashboard/Dashboard';
import OnlineMenu from './components/Users/OnlineMenu';
import PaymentSuccessUrl from './components/Users/PaymentSuccessUrl';
import PaymentCancelUrl from './components/Users/PaymentCancelUrl';
import ReservationPage from './components/Users/ReservationPage';
import ReservationManagement from './components/Users/ReservationManagement';
import SessionExpired from './components/Common/SessionExpired';
import ProtectedRoute from './components/ProtectedRoute';

function App() {
  return (
    <div className="App">
      <main>
        <Routes>
          {/* Public Routes */}
          <Route path="/register" element={<RegistrationForm />} />
          <Route path="/verify/:verification_uuid" element={<EmailVerification />} />
          <Route path="/login" element={<LoginForm />} />
          <Route path="/password-reset" element={<AuthLayout><PasswordReset /></AuthLayout>} />
          <Route path="/password-email-sent" element={<AuthLayout><PasswordEmailSent /></AuthLayout>} />
          <Route path="/password-confirm/:uidb64/:token" element={<AuthLayout><PasswordResetForm /></AuthLayout>} />
          <Route path="/session-expired/*" element={<SessionExpired />} />
          <Route path="/users/live-menu/restaurant/:restaurantId/table/:tableId/:menuId" element={<OnlineMenu />} />
          <Route path="/users/menu-preview/restaurant/:restaurantId/:menuId" element={<OnlineMenu isPreview={true} />} />
          <Route path="/users/payment-success" element={<PaymentSuccessUrl />} />
          <Route path="/users/payment-cancel" element={<PaymentCancelUrl />} />
          <Route path="/users/reservation/restaurant/:restaurantId" element={<ReservationPage />} />
          <Route path="/users/reservation/:reservationId" element={<ReservationManagement />} />
          {/* Protected Routes */}
          <Route path="/subscribe" element={<ProtectedRoute><SubscriptionPage /></ProtectedRoute>} />
          <Route path="/subscribe/success" element={<ProtectedRoute><SubscriptionSuccess /></ProtectedRoute>} />
          <Route path="/subscribe/cancel" element={<ProtectedRoute><SubscriptionCancel /></ProtectedRoute>} />
          <Route path="/profile" element={<ProtectedRoute><UserProfile /></ProtectedRoute>} />
          <Route path="/dashboard/*" element={<ProtectedRoute><Dashboard /></ProtectedRoute>} />
        </Routes>
      </main>
    </div>
  );
}

export default App;
