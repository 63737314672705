import React, { createContext, useEffect, useState, useContext } from 'react';
import { connectToWebSocket, disconnectWebSocket } from '../services/websocketService';

const WebSocketContext = createContext();

export const WebSocketProvider = ({ children }) => {
    const [orderUpdates, setOrderUpdates] = useState([]);
    const [alertMessage, setAlertMessage] = useState(null);
    const url = `${process.env.REACT_APP_API_URL.replace("http", "ws")}ws/orders/`;
    const MAX_UPDATES = 50; // Maximum number of updates to store

    useEffect(() => {
        // Connect to WebSocket
        connectToWebSocket(url, (data) => {
            // Only trigger alert for specific order update actions
            if (data && data.action === 'updateOrder') {
                setAlertMessage(`Order #${data.order_id} updated successfully!`);
            }

            // Manage memory by limiting stored updates
            setOrderUpdates((prevUpdates) => {
                const updatedList = [...prevUpdates, data];
                return updatedList.length > MAX_UPDATES 
                    ? updatedList.slice(updatedList.length - MAX_UPDATES) 
                    : updatedList;
            });
        });

        // Clean up on unmount
        return () => {
            disconnectWebSocket();
        };
    }, [url]);

    return (
        <WebSocketContext.Provider value={{ orderUpdates, alertMessage, setAlertMessage }}>
            {children}
        </WebSocketContext.Provider>
    );
};

// Custom hook to access WebSocket context
export const useWebSocket = () => useContext(WebSocketContext);
