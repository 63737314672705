import React from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import logo from '../../assets/logo_tabomenu.png';
import { Button } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import LogoutIcon from '@mui/icons-material/Logout';
import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import { IconButton, Box } from '@mui/material';
import { useResponsive } from '../../hooks/useResponsive'; // Adjust the path as needed

const DashboardHeader = ({ toggleDrawer }) => {
  const { logout } = useAuth();
  const navigate = useNavigate();
  const { isDesktop, isMobile } = useResponsive();

  const handleLogout = async () => {
    try {
      await logout();
      navigate('/login'); // Redirect to login
    } catch (error) {
      console.error('Error during logout:', error);
    }
  };

  const handleProfile = () => {
    navigate('/profile');
  };

  return (
    <Box
      sx={{
        position: 'sticky',
        top: 0,
        zIndex: 1000,
        backgroundColor: 'white',
        display: 'flex',
        alignItems: 'center',
        padding: {xs: '5px 15px', sm: '5px 20px'},
        borderBottom: '1px solid',
        borderColor: 'grey.300',
        boxShadow: '0px 3px 6px -1px rgba(0,0,0,0.1)',
        gap: 1
      }}
    >
      {/* Show menu icon on non-desktop layouts */}
      {!isDesktop && (
        <IconButton color="secondary" aria-label="menu" onClick={toggleDrawer}>
          <MenuIcon />
        </IconButton>
      )}

      {/* Logo */}
      <Box
        component="img"
        src={logo}
        alt="Logo"
        sx={{ width: {xs: 125, sm: 150}, ml: 1 }}
      />

      {/* Spacer */}
      <Box flexGrow={1} />

      {/* Plan button */}
      {isMobile ? (
        <IconButton
        sx={{ opacity: '0.85' }}
        color="inherit"
        onClick={handleProfile}
        >
          <PermIdentityIcon/>
        </IconButton>
      ) : (
      <Button onClick={handleProfile}>
        Il mio piano
      </Button>
  )}

      {/* Logout label */}
      <IconButton
        sx={{ mr: 1, opacity: '0.85' }}
        color="inherit"
        onClick={handleLogout}
      >
        <LogoutIcon sx={{ fontSize: '24px' }} />
      </IconButton>
    </Box>
  );
};

export default DashboardHeader;
