// AreaSwiper.jsx

import React, { useState, useEffect } from 'react';
import { useRestaurant } from '../../../contexts/RestaurantContext';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css'; // core Swiper styles
import 'swiper/css/pagination'; // Pagination module styles
import { Pagination } from 'swiper/modules';
import FloorMap from './FloorMap';
import TablesLayout from './TablesLayout'; // Import the new TablesLayout component
import { Box, Typography, Button } from '@mui/material';
import ConfirmationDialog from '../../Common/ConfirmationDialog';
import { useAlert } from '../../../contexts/AlertContext';
import { useResponsive } from '../../../hooks/useResponsive';
import AutoAwesomeMosaicOutlinedIcon from '@mui/icons-material/AutoAwesomeMosaicOutlined';
import PlusOneRoundedIcon from '@mui/icons-material/PlusOneRounded';
import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded';

const AreaSwiper = ({ setCurrentAreaName }) => {
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [areaToDelete, setAreaToDelete] = useState(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const [openTablesLayout, setOpenTablesLayout] = useState(false);
  const [openAddTableDialog, setOpenAddTableDialog] = useState(false);
  const [loadingAddTable, setLoadingAddTable] = useState(false);

  const { areas, handleDeleteArea, handleAddTable } = useRestaurant();
  const { showToast } = useAlert();
  const { isMobile } = useResponsive();

  useEffect(() => {
    if (areas.length > 0) {
      setCurrentAreaName(areas[activeIndex]?.name || '');
    }
  }, [activeIndex, areas, setCurrentAreaName]);

  const onSlideChange = (swiper) => {
    setActiveIndex(swiper.activeIndex);
  };

  // Function to open the confirmation dialog instead of calling backend directly
  const handleAddTableClick = () => {
    const currentArea = areas[activeIndex];
    if (!currentArea) return;

    // Check if the area already has 24 tables (limit reached)
    if (currentArea.tables && currentArea.tables.length >= 24) {
      showToast({
        message: "Il limite per area è di 24 tavoli. Aggiungi un'altra area",
        variant: 'error'
      });
      return;
    }
    // Open the confirmation dialog
    setOpenAddTableDialog(true);
  };

  // Function to actually add the table after confirmation
  const handleConfirmAddTable = async () => {
    const currentArea = areas[activeIndex];
    if (!currentArea) return;
    setLoadingAddTable(true);
    try {
      const newTable = await handleAddTable(currentArea.id);
      if (newTable) {
        showToast({
          message: `Tavolo n. ${newTable.number} è stato aggiunto con successo`,
          variant: 'success'
        });
      }
    } catch (error) {
      console.error('Error adding table:', error);
      showToast({
        message: "Si è verificato un errore",
        variant: 'error'
      });
    } finally {
      setLoadingAddTable(false);
      setOpenAddTableDialog(false);
    }
  };

  // Function to cancel the Add Table action
  const handleCancelAddTable = () => {
    setOpenAddTableDialog(false);
  };

  const handleDeleteClick = (areaId) => {
    setAreaToDelete(areaId);
    setOpenDeleteDialog(true);
  };

  const handleConfirmDelete = async () => {
    if (!areaToDelete) return;
    try {
      await handleDeleteArea(areaToDelete);
      showToast({ message: "Area eliminata con successo", variant: 'info' });
      // await loadUserRestaurants();
      setOpenDeleteDialog(false);
      setAreaToDelete(null);

      // Adjust activeIndex if it’s now out of range
      if (activeIndex >= areas.length - 1) {
        setActiveIndex((prev) => Math.max(prev - 1, 0));
      }
    } catch (error) {
      console.error('Error deleting area:', error);
      showToast({ message: "Si è verificato un errore", variant: 'error' });
    }
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
    setAreaToDelete(null);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        position: 'relative',
        width: '100%',
      }}
    >
      {/* Header */}
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          width: '100%',
          paddingY: '10px'
        }}
      >
        <Box display="flex" width="30%" justifyContent="flex-start" alignItems="center">
          <Button
            variant="outlined"
            sx={{ fontSize: '14px', fontWeight: 550 }}
            onClick={handleAddTableClick}
            endIcon={isMobile ? <PlusOneRoundedIcon /> : null}
          >
            {isMobile ? 'Tavolo' : 'Aggiungi tavolo'}
          </Button>
        </Box>

        <Box display="flex" width="40%" justifyContent="center" alignSelf="flex-end">
          <Typography sx={{ fontSize: '14px', fontWeight: 550 }} color="secondary">
            {areas[activeIndex]?.name}
          </Typography>
        </Box>

        <Box display="flex" width="30%" justifyContent="flex-end" alignItems="center" gap="10px">
          {!isMobile ? (
            <Button
              variant="outlined"
              style={{ fontSize: '14px', fontWeight: 550 }}
              onClick={() => setOpenTablesLayout(true)}
            >
              Modifica
            </Button>
          ) : (
            <AutoAwesomeMosaicOutlinedIcon
              sx={{ fontSize: '30px', cursor: 'pointer', color: 'primary.main', opacity: 0.8, mr: 1.5 }}
              onClick={() => setOpenTablesLayout(true)}
            />
          )}
          {!isMobile ? (
            <Button
              variant="outlined"
              style={{ fontSize: '14px', fontWeight: 550 }}
              onClick={() => handleDeleteClick(areas[activeIndex]?.id)}
              color="error"
            >
              Elimina area
            </Button>
          ) : (
            <DeleteOutlineRoundedIcon
              sx={{ fontSize: '30px', cursor: 'pointer', color: 'error.main', opacity: 0.8 }}
              onClick={() => handleDeleteClick(areas[activeIndex]?.id)}
            />
          )}
        </Box>
      </Box>

      {/* Swiper Container */}
      <Box
        sx={{
          width: '100%',
          overflow: 'hidden',
          position: 'relative',
          fontWeight: 600,
          // Swiper navigation & pagination variables:
          '--swiper-pagination-color': 'var(--color-secondary)',
          '--swiper-pagination-left': '50%',
          '--swiper-pagination-right': 'auto',
          '--swiper-pagination-bottom': 'auto',
          '--swiper-pagination-top': '0px',
          '--swiper-pagination-fraction-color': 'inherit',
          '--swiper-pagination-progressbar-bg-color': 'rgba(0, 0, 0, 0.25)',
          '--swiper-pagination-progressbar-size': '4px',
          '--swiper-pagination-bullet-size': '8px',
          '--swiper-pagination-bullet-width': '8px',
          '--swiper-pagination-bullet-height': '8px',
          '--swiper-pagination-bullet-inactive-color': '#000',
          '--swiper-pagination-bullet-inactive-opacity': 0.15,
          '--swiper-pagination-bullet-opacity': 1,
          '--swiper-pagination-bullet-horizontal-gap': '4px',
          '--swiper-pagination-bullet-vertical-gap': '6px',
        }}
      >
        <Swiper
          spaceBetween={0}
          slidesPerView={'auto'}
          pagination={{ clickable: true }}
          onSlideChange={onSlideChange}
          modules={[Pagination]}
          allowTouchMove={false}
        >
          {areas.map((area) => (
            <SwiperSlide key={area.id}>
              <FloorMap areaId={area.id} />
            </SwiperSlide>
          ))}
        </Swiper>
      </Box>

      {/* ConfirmationDialog for deleting an area */}
      <ConfirmationDialog
        visible={openDeleteDialog}
        title="Conferma Eliminazione"
        message="Sei sicuro di voler eliminare quest'area? Questa azione non può essere annullata."
        onConfirm={handleConfirmDelete}
        onCancel={handleCloseDeleteDialog}
        confirmLabel="Elimina l'area"
        cancelLabel="Annulla"
      />

      {/* ConfirmationDialog for adding a table */}
      <ConfirmationDialog
        visible={openAddTableDialog}
        title="Aggiungi un tavolo"
        message="Stai per aggiungere un tavolo nell'area selezionata. Vuoi confermare?"
        onConfirm={handleConfirmAddTable}
        onCancel={handleCancelAddTable}
        confirmLabel="Conferma"
        cancelLabel="Annulla"
        loading={loadingAddTable}
      />

      {/* Render the TablesLayout dialog and pass in the tables for the current area */}
      <TablesLayout
        key={areas[activeIndex]?.id}
        open={openTablesLayout}
        onClose={() => setOpenTablesLayout(false)}
        tables={areas[activeIndex]?.tables || []}
        area={areas[activeIndex]}
      />
    </Box>
  );
};

export default AreaSwiper;
