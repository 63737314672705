import React, { useState, useEffect } from 'react';
import {
  Drawer,
  Box,
  Typography,
  Alert,
  TextField,
  Button,
  Divider,
  IconButton
} from '@mui/material';
import DeleteOutlineRounded from '@mui/icons-material/DeleteOutlineRounded';
import { useResponsive } from '../../../hooks/useResponsive';

const MAX_TABLES_PER_AREA = 24;

const CreateSingleArea = ({ open, onClose, onComplete }) => {
  const { isMobile } = useResponsive();
  // Wizard-like states but for a single area
  const [areaName, setAreaName] = useState('');
  const [totalTablesInput, setTotalTablesInput] = useState('');
  const [tableGroups, setTableGroups] = useState([]);
  const [error, setError] = useState('');

  // --- Similar logic to your wizard’s useEffect for “auto remainder” ---
  useEffect(() => {
    if (!totalTablesInput) return;
    const total = Number(totalTablesInput);
    if (isNaN(total) || total <= 0) return;

    if (total > MAX_TABLES_PER_AREA) {
      setError(`Numero massimo di tavoli per area raggiunto (${MAX_TABLES_PER_AREA})`);
      return;
    } else {
      setError('');
    }

    const manualGroups = tableGroups.filter((g) => !g.autoAdded);
    const autoGroup = tableGroups.find((g) => g.autoAdded);

    const fixedSum = manualGroups.reduce((sum, group) => sum + Number(group.numberOfTables), 0);
    const remainder = total - fixedSum;

    if (remainder < 0) {
      setError("La somma dei tavoli supera il totale dell'area");
      return;
    }

    if (remainder === 0) {
      // remove the autoGroup
      if (autoGroup) {
        setTableGroups(manualGroups);
      }
    } else {
      // positive remainder
      if (autoGroup) {
        // update the count
        if (Number(autoGroup.numberOfTables) !== remainder) {
          const newGroups = [...manualGroups, { ...autoGroup, numberOfTables: remainder }];
          setTableGroups(newGroups);
        }
      } else {
        // add an autoGroup
        setTableGroups([
          ...manualGroups,
          {
            id: Date.now(),
            numberOfTables: remainder,
            numberOfSeats: 4,
            autoAdded: true
          }
        ]);
      }
    }
  }, [totalTablesInput, tableGroups]);

  // Handler to reset everything
  const handleReset = () => {
    setAreaName('');
    setTotalTablesInput('');
    setTableGroups([]);
    setError('');
  };

  // Handler for changing seat groups
  const handleSeatGroupChange = (id, field, value) => {
    setTableGroups((prev) =>
      prev.map((group) => {
        if (group.id !== id) return group;
        let newValue = value;
        if (field === 'numberOfTables') {
          const intVal = parseInt(value, 10) || 1;
          newValue = Math.max(1, Math.min(intVal, 24));
        } else if (field === 'numberOfSeats') {
          const intVal = parseInt(value, 10) || 1;
          newValue = Math.max(1, Math.min(intVal, 12));
        }
        const updated = { ...group, [field]: newValue };
        if (group.autoAdded) {
          delete updated.autoAdded; // once changed, it's manual
        }
        return updated;
      })
    );
  };

  // Handler to remove a seat group
  const removeSeatGroup = (id) => {
    setTableGroups((prev) => {
      const toRemove = prev.find((g) => g.id === id);
      if (!toRemove) return prev;
      const remaining = prev.filter((g) => g.id !== id);
      if (remaining.length > 0) {
        // add back the table count to the first group
        remaining[0] = {
          ...remaining[0],
          numberOfTables:
            Number(remaining[0].numberOfTables) + Number(toRemove.numberOfTables),
        };
      }
      return remaining;
    });
  };

  // Validate seat groups sum
  const validSeatGroups = () => {
    const total = Number(totalTablesInput) || 0;
    const sum = tableGroups.reduce((acc, g) => acc + Number(g.numberOfTables), 0);
    return sum === total;
  };

  // On “Invia,” pass a single area object to the parent
  const handleSubmit = () => {
    if (!areaName.trim()) {
      setError("Il nome dell'area è obbligatorio");
      return;
    }
    if (!validSeatGroups()) {
      setError('La somma dei tavoli nei gruppi deve essere uguale al totale definito');
      return;
    }

    const newArea = {
      areaName: areaName.trim(),
      tableGroups: [...tableGroups],
    };

    onComplete(newArea);
  };

  const handleCancel = () => {
    onClose();
    // optionally clear state
    setAreaName('');
    setTotalTablesInput('');
    setTableGroups([]);
    setError('');
  };

  return (
    <Drawer anchor="right" open={open} onClose={handleCancel}
      PaperProps={{
        sx: { width: isMobile ? '100%' : 480, padding: '20px' }
      }}
    >
      <Typography variant="h5" sx={{ mb: 2 }}>
        Crea una nuova Area
      </Typography>

      {error && <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>}

      {/* Fields for areaName, totalTablesInput */}
      <Box display="flex" flexDirection="column" gap={2} sx={{ mb: 2 }}>
        <TextField
          label="Nome dell'Area"
          placeholder="es. Interno"
          value={areaName}
          onChange={(e) => setAreaName(e.target.value)}
        />

        <TextField
          label="Num. di Tavoli"
          type="number"
          value={totalTablesInput}
          onChange={(e) => {
            const val = e.target.value;
            if (!val) {
              setTotalTablesInput('');
              return;
            }
            const n = parseInt(val, 10);
            const clamped = Math.max(1, Math.min(n, 24));
            setTotalTablesInput(String(clamped));
          }}
          inputProps={{ min: 1, max: 24 }}
          sx={{ width: '160px' }}
        />

        {tableGroups.length > 0 && (
          <Button variant="text" size="small" onClick={handleReset}>
            Reset
          </Button>
        )}
      </Box>

      {/* If we have tableGroups, show the seat group details */}
      {tableGroups.length > 0 && (
        <>
          <Typography variant="body1" fontWeight={600} sx={{ mb: 1 }}>
            Raggruppa i tavoli per numero di posti
          </Typography>
          {tableGroups.map((group, index) => (
            <Box key={group.id} sx={{ mb: 1 }}>
              <Box display="flex" alignItems="center" gap={1}>
                <TextField
                  label="Tavoli"
                  type="number"
                  value={group.numberOfTables}
                  onChange={(e) => {
                    const val = e.target.value;
                    if (!val) {
                      handleSeatGroupChange(group.id, 'numberOfTables', '');
                      return;
                    }
                    const n = parseInt(val, 10);
                    const c = Math.max(1, Math.min(n, 24));
                    handleSeatGroupChange(group.id, 'numberOfTables', c);
                  }}
                  sx={{ width: '120px' }}
                  inputProps={{ min: 1, max: 24 }}
                />
                <Typography>da</Typography>
                <TextField
                  label="Posti"
                  type="number"
                  value={group.numberOfSeats}
                  onChange={(e) => {
                    const val = e.target.value;
                    if (!val) {
                      handleSeatGroupChange(group.id, 'numberOfSeats', '');
                      return;
                    }
                    const n = parseInt(val, 10);
                    const c = Math.max(1, Math.min(n, 12));
                    handleSeatGroupChange(group.id, 'numberOfSeats', c);
                  }}
                  sx={{ width: '120px' }}
                  inputProps={{ min: 1, max: 12 }}
                />

                {/* Only show remove icon if it's not the first group? Or if index > 0 */}
                {index > 0 && (
                  <IconButton onClick={() => removeSeatGroup(group.id)}>
                    <DeleteOutlineRounded fontSize="small" color="secondary" />
                  </IconButton>
                )}
              </Box>
              {index < tableGroups.length - 1 && <Divider sx={{ mt: 1 }} />}
            </Box>
          ))}
        </>
      )}

      {/* Bottom Buttons */}
      <Box mt={3} display="flex" justifyContent="space-between">
        <Button variant="outlined" color="error" onClick={handleCancel}>
          Cancella
        </Button>
        <Button variant="contained" onClick={handleSubmit}>
          Invia
        </Button>
      </Box>
    </Drawer>
  );
};

export default CreateSingleArea;
