import React from 'react';
import { Box, Dialog, DialogContent, DialogTitle, Button, Typography, Tooltip } from '@mui/material';
import { DragDropContext } from '@hello-pangea/dnd';
import MenuAreaAssignmentWidget from './MenuAreaAssignmentWidget';
import SidebarWidget from './SidebarWidget';
import ConfirmationDialog from '../Common/ConfirmationDialog';
import OnlineMenuModal from './OnlineMenuModal';
import MenuWizard from './menu/MenuWizard';
import AIMenuWizard from './menu/AIMenuWizard';
import MenuHeader from './menu/MenuHeader';
import CategoryList from './menu/CategoryList';
import AskTaboMenuDialog from './menu/AskTaboMenuDialog'
import CategoryActions from './menu/CategoryActions';
import useMenuHook from '../../hooks/useMenuHook';
import CustomLoader from '../Common/CustomLoader';
import { AutoAwesome } from '@mui/icons-material';
import food from '../../assets/icons/food.png'
import { useDailyUsageContext } from '../../contexts/DailyUsageContext';
import { useMembership } from '../../hooks/useMembership';


const MenuOverview = () => {
  const {
    loadCombinedMenu,
    showMenuAreaAssignment,
    expandedCategories,
    expandedItems,
    showConfirmationModal,
    isWizardOpen,
    loading,
    selectedMenu,
    itemToDelete,
    showPreview,
    setShowPreview,
    selectOptions,
    addCategoryOptions,
    addMenuItemOptions,
    openWizard,
    closeWizard,
    handleWizardCompletion,
    isAIWizardOpen,
    openAIWizard,
    closeAIWizard,
    askTaboDialogOpen,
    openAskTaboDialog,
    closeAskTaboDialog,
    handleNewMenuCreated,
    toggleCategory,
    toggleItem,
    toggleGlobalExpansion,
    onDragEnd,
    openEditMenuSidebar,
    handleAddCategorySelection,
    openEditCategorySidebar,
    handleAddMenuItemSelection,
    openEditMenuItemSidebar,
    openEditComboSidebar,
    openDeleteConfirmation,
    handleDeleteConfirmed,
    handlePreview,
    closeMenuAreaAssignment,
    closeConfirmationModal,
    openMenuAreaAssignment,
    setCategoryActionSelection,
    categoryActionSelection,
    setMenuItemActionSelection,
    menuItemActionSelection,
    reloadMenus,
    menuCategories,
    editingCategory,
    addNewCategory,
    updateCategories,
    editingMenuItem,
    addNewMenuItem,
    updateMenuItems,
    editingCombo,
    addNewCombo,
    updateCombos,
  } = useMenuHook();
  const { usage } = useDailyUsageContext();
  const { hasPaidModule } = useMembership();

  const getTooltipMessage = () => {
    if (!hasPaidModule) {
      return "Acquista un modulo a tua scelta per utilizzare Tabo Assistant";
    }
    if (usage.disabled) {
      return "Hai raggiunto il limite giornaliero di utilizzo";
    }
    return "";
  };

  // Show a loading spinner while data is being fetched
  if (loading) {
    return <CustomLoader open={true} />;
  }

  if (selectOptions.length === 0 && !isWizardOpen && !isAIWizardOpen && !askTaboDialogOpen) {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          height: '80vh',
        }}
      >
        <Box>
          <img
            src={food}
            alt="Menu Ristorante"
            style={{ width: '85px', opacity: 0.85 }}
          />
        </Box>
        <Typography variant="h3" align="center" gutterBottom>
          Crea il tuo primo menu digitale
        </Typography>
        <Typography variant="body1" align="center" sx={{ mb: 4, color: "grey" }}>
          Usa il Wizard per creare la tua prima voce di menù.<br />
          Oppure chiedi a Tabo per importare il menu da un file o impostarne una bozza.
        </Typography>
        <Box sx={{ display: 'flex', gap: 2, width: '100%', px: { xs: '5%', sm: '15%', md: '20%', lg: '30%' } }} >
          <Button
            sx={{ width: '100%', fontSize: '15px' }}
            variant="contained"
            onClick={openWizard}
          >
            Apri il Wizard
          </Button>
          <Tooltip title={getTooltipMessage()}>
            <span style={{ width: '100%' }}>
              <Button
                sx={{ width: '100%', fontSize: '15px' }}
                variant="outlined"
                color="secondary"
                endIcon={<AutoAwesome />}
                onClick={openAIWizard}
                disabled={usage.disabled || !hasPaidModule}
              >
                Chiedi a Tabo
              </Button>
            </span>
          </Tooltip>
        </Box>
      </Box>
    );
  }

  const getConfirmationDialogProps = () => {
    if (!itemToDelete) return { title: 'Elimina', message: 'Sei sicuro di voler eliminare questo elemento?' };
    switch (itemToDelete.type) {
      case 'menu':
        return { title: 'Elimina Menu', message: 'Sei sicuro di voler eliminare questo menu?' };
      case 'category':
        return { title: 'Elimina Categoria', message: 'Sei sicuro di voler eliminare questa categoria?' };
      case 'menuItem':
        return { title: 'Elimina Articolo', message: 'Sei sicuro di voler eliminare questo articolo?' };
      case 'combo':
        return { title: 'Elimina Combo', message: 'Sei sicuro di voler eliminare questa combo?' };
      default:
        return { title: 'Elimina', message: 'Sei sicuro di voler eliminare questo elemento?' };
    }
  };

  return (
    <>
      <SidebarWidget
        editingCategory={editingCategory}
        addNewCategory={addNewCategory}
        updateCategories={updateCategories}
        editingMenuItem={editingMenuItem}
        updateMenuItems={updateMenuItems}
        addNewMenuItem={addNewMenuItem}
        editingCombo={editingCombo}
        addNewCombo={addNewCombo}
        updateCombos={updateCombos}
      />

      {selectOptions.length > 0 && (
        <MenuHeader
          handlePreview={handlePreview}
          selectOptions={selectOptions}
          openMenuWizard={openWizard}
          openEditMenuSidebar={openEditMenuSidebar}
          openDeleteConfirmation={openDeleteConfirmation}
          toggleMenuAreaAssignment={openMenuAreaAssignment}
          showMenuAreaAssignment={showMenuAreaAssignment}
          closeMenuAreaAssignment={closeMenuAreaAssignment}
          MenuAreaAssignmentWidget={MenuAreaAssignmentWidget}
          handleNewMenuCreated={handleNewMenuCreated}
          openAIWizard={openAIWizard}
          reloadMenus={reloadMenus}
        />
      )}

      {/* Online menu preview modal */}
      <OnlineMenuModal
        open={showPreview}
        onClose={() => setShowPreview(false)}
        menuId={selectedMenu}
      />

      {/* Render the Menu Wizard */}
      {isWizardOpen && (
        <Dialog
          open={isWizardOpen}
          onClose={closeWizard}
          fullWidth
          maxWidth="md"
        >
          <DialogTitle alignSelf="flex-end">
            <Button onClick={closeWizard} color="secondary">
              Chiudi
            </Button>
          </DialogTitle>
          <DialogContent>
            <MenuWizard
              loading={loading}
              onComplete={handleWizardCompletion} />
          </DialogContent>
        </Dialog>
      )}

      {/* Render the AI Wizard */}
      {isAIWizardOpen && (
        <AIMenuWizard
          open={isAIWizardOpen}
          onClose={closeAIWizard}
          onMenuCreated={handleNewMenuCreated}
        />
      )}

      {/* Ask Tabo Dialog (No condition here to allow Drawer transtion) */}
      <AskTaboMenuDialog
        open={askTaboDialogOpen}
        onClose={closeAskTaboDialog}
        menuId={selectedMenu}
        refreshMenu={loadCombinedMenu}
      />

      {selectOptions.length > 0 && (
        <DragDropContext onDragEnd={onDragEnd}>
          <Box sx={{ padding: '20px' }}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                width: { xs: '100%', sm: '90%', md: '65%' },
                border: '1px solid var(--color-light)',
                borderRadius: '10px',
                px: '15px',
                pb: '10px'
              }}
            >
              <CategoryActions
                addCategoryOptions={addCategoryOptions}
                handleAddCategorySelection={handleAddCategorySelection}
                setCategoryActionSelection={setCategoryActionSelection}
                categoryActionSelection={categoryActionSelection}
                toggleGlobalExpansion={toggleGlobalExpansion}
                hasCategories={menuCategories && menuCategories.length > 0}
                menuCategories={menuCategories}
                expandedCategories={expandedCategories}
                openAskTaboDialog={openAskTaboDialog}
              />
              <CategoryList
                expandedCategories={expandedCategories}
                expandedItems={expandedItems}
                toggleCategory={toggleCategory}
                toggleItem={toggleItem}
                handleAddCategorySelection={handleAddCategorySelection}
                handleAddMenuItemSelection={handleAddMenuItemSelection}
                openEditCategorySidebar={openEditCategorySidebar}
                openEditMenuItemSidebar={openEditMenuItemSidebar}
                openEditComboSidebar={openEditComboSidebar}
                openDeleteConfirmation={openDeleteConfirmation}
                setCategoryActionSelection={setCategoryActionSelection}
                categoryActionSelection={categoryActionSelection}
                setMenuItemActionSelection={setMenuItemActionSelection}
                menuItemActionSelection={menuItemActionSelection}
                addCategoryOptions={addCategoryOptions}
                addMenuItemOptions={addMenuItemOptions}
              />
            </Box>
          </Box>
        </DragDropContext>
      )}

      {showConfirmationModal && itemToDelete && (
        <ConfirmationDialog
          visible={showConfirmationModal}
          {...getConfirmationDialogProps()}
          confirmLabel="Elimina"
          cancelLabel="Annulla"
          onConfirm={handleDeleteConfirmed}
          onCancel={closeConfirmationModal}
        />
      )}
    </>
  );
};

export default MenuOverview;
