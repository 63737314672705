import React, { useState, useEffect } from 'react';
import { useRestaurant } from '../../../contexts/RestaurantContext';
import {
  Box,
  Typography,
  Paper,
  IconButton,
  useTheme,
  useMediaQuery,
  Drawer,
  TextField,
  Button,
  Alert,
  Collapse,
} from '@mui/material';
import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded';
import ConfirmationDialog from '../../Common/ConfirmationDialog';
import { updateTable } from '../../../services/apiService';
import { useAlert } from '../../../contexts/AlertContext';

const FloorMap = ({ areaId }) => {
  // Get the current restaurant areas from context
  const { areas, handleDeleteTable } = useRestaurant();
  const { showToast } = useAlert();

  // Set up theme and media queries for responsive breakpoints
  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up('md')); // md and larger
  const isSm = useMediaQuery(theme.breakpoints.only('sm'));  // sm screens only
  const isXs = useMediaQuery(theme.breakpoints.only('xs'));  // xs screens only

  // Local state for tables and deletion dialog management
  const [tables, setTables] = useState([]);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [tableToDelete, setTableToDelete] = useState(null);

  // States for editing table details in the Drawer
  const [editingDrawerOpen, setEditingDrawerOpen] = useState(false);
  const [selectedTable, setSelectedTable] = useState(null);
  const [editNumber, setEditNumber] = useState('');
  const [editSeats, setEditSeats] = useState('');

  // State for error alert (message, severity, and open status)
  const [alert, setAlert] = useState({ message: '', severity: 'error', open: false });

  // Update the local tables when the selected area changes
  useEffect(() => {
    const currentArea = areas?.find((area) => area.id === areaId);
    if (currentArea) {
      setTables(currentArea.tables);
    }
  }, [areas, areaId]);

  // When a table is selected for editing, prefill the form fields
  useEffect(() => {
    if (selectedTable) {
      setEditNumber(selectedTable.number);
      setEditSeats(selectedTable.number_of_seats);
      // Clear any previous error alerts
      setAlert({ message: '', severity: 'error', open: false });
    }
  }, [selectedTable]);

  // Handler to open the delete confirmation dialog
  const handleDeleteIconClick = (tableId) => {
    setOpenDeleteDialog(true);
    setTableToDelete(tableId);
  };

  // Handler to confirm deletion of a table
  const handleConfirmDelete = async () => {
    try {
      await handleDeleteTable(tableToDelete);
      showToast({
        message: `Il Tavolo è stato eliminato`,
        variant: 'info',
      });
      setOpenDeleteDialog(false);
      setTableToDelete(null);
    } catch (error) {
      console.error('Error deleting table:', error);
    }
  };

  // Handler to close the delete confirmation dialog
  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
    setTableToDelete(null);
  };

  // Handler for clicking on a table to edit its details.
  // Opens the Drawer and sets the selected table.
  const handleTableClick = (table) => {
    setSelectedTable(table);
    setEditingDrawerOpen(true);
  };

  // Handler for form submission to update table details
  const handleUpdateTable = async (e) => {
    e.preventDefault();
    try {
      const updatedData = {
        number: parseInt(editNumber, 10),
        number_of_seats: parseInt(editSeats, 10),
      };
      // Call the API to update the table
      const updatedTable = await updateTable(selectedTable.id, updatedData);
      // Update local state by replacing the updated table
      setTables((prevTables) =>
        prevTables.map((t) => (t.id === updatedTable.id ? updatedTable : t))
      );
      // Close the Drawer and clear selected table
      setEditingDrawerOpen(false);
      setSelectedTable(null);
    } catch (error) {
      console.error('Failed to update table:', error);
      let errorMessage = '';
      if (error.response?.data?.number) {
        // Specific error for duplicate table number
        errorMessage = "Il numero del tavolo è già in uso.";
      } else {
        // Generic error message in Italian
        errorMessage = "Si è verificato un errore, riprova più tardi.";
      }
      // Set the alert state to display the error message
      setAlert({ message: errorMessage, severity: 'error', open: true });
    }
  };

  // Decide the number of columns based on the current breakpoint:
  let gridColumns = 8; // default for lg and xl screens
  if (isSm) {
    gridColumns = 4;
  } else if (isXs) {
    gridColumns = 2;
  }

  // Prepare the table data for rendering:
  // - For md and larger screens, we use the tables as provided (backend positions).
  // - For smaller screens, ignore the backend positions and sort tables by number.
  const displayTables = isMdUp
    ? tables
    : [...tables].sort((a, b) => a.number - b.number);

  // Utility function for clamping a value between min and max
  const clamp = (value, min, max) => {
    if (value === '') return '';
    const num = parseInt(value, 10);
    if (isNaN(num)) return '';
    return Math.min(Math.max(num, min), max).toString();
  };

  return (
    <>
      {/* Grid main container */}
      <Box sx={{ paddingY: '40px', paddingX: '20px', border: '1px solid #ccc', borderRadius: '10px' }}>
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: `repeat(${gridColumns}, 1fr)`,
            gap: 4, // spacing between grid items
          }}
        >
          {displayTables.map((table) => {
            // Layout styles based on backend positions (only used on md and up)
            const tableStyle = isMdUp
              ? {
                  // Add 1 because CSS grid lines start at 1.
                  gridColumn: `${table.position_x + 1} / span ${table.width || 1}`,
                  gridRow: `${table.position_y + 1} / span ${table.height || 1}`,
                }
              : {};

            return (
              <Paper
                key={table.id}
                onClick={() => handleTableClick(table)}
                sx={{
                  ...tableStyle,
                  minWidth: '65px',
                  paddingX: '15px',
                  paddingTop: '40px',
                  paddingBottom: '20px',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'flex-end',
                  borderRadius: '6px',
                  position: 'relative',
                  userSelect: 'none',
                  cursor: 'pointer',
                  boxShadow: (theme) => theme.shadows[1],
                  transition: 'box-shadow 0.3s ease-in-out',
                  '&:hover': {
                    boxShadow: (theme) => theme.shadows[4],
                  },
                }}
              >
                {/* Delete Icon: clicking opens the confirmation dialog.
                    Stop propagation so the table click does not trigger the Drawer. */}
                <IconButton
                  size="small"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleDeleteIconClick(table.id);
                  }}
                  sx={{ position: 'absolute', top: 5, right: 5 }}
                >
                  <DeleteOutlineRoundedIcon sx={{ fontSize: '18px' }} color="error" />
                </IconButton>

                {/* Table Number */}
                <Typography
                  style={{ fontSize: '13px', fontWeight: '550' }}
                  sx={{ color: theme.palette.primary.light }}
                >
                  Tavolo {table.number}
                </Typography>

                {/* Table Seats */}
                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                  {table.number_of_seats} posti
                </Typography>
              </Paper>
            );
          })}
        </Box>

        {/* Confirmation Dialog */}
        <ConfirmationDialog
          visible={openDeleteDialog}
          title="Conferma Eliminazione"
          message="Sei sicuro di voler eliminare questo tavolo?"
          onConfirm={handleConfirmDelete}
          onCancel={handleCloseDeleteDialog}
          confirmLabel="Rimuovi tavolo"
          cancelLabel="Cancella"
        />
      </Box>

      {/* Drawer for editing table details */}
      <Drawer
        anchor="right"
        open={editingDrawerOpen}
        onClose={() => setEditingDrawerOpen(false)}
      >
        <Box sx={{ width: 300, padding: 2 }}>
          <Typography variant="h6" sx={{ marginBottom: 2 }}>
            Modifica il Tavolo
          </Typography>

          {/* Display an error alert if one exists */}
          <Collapse in={alert.open}>
            <Alert
              severity={alert.severity}
              onClose={() => setAlert({ ...alert, open: false })}
              sx={{ marginBottom: 2 }}
            >
              {alert.message}
            </Alert>
          </Collapse>

          <form onSubmit={handleUpdateTable}>
            <TextField
              label="Numero Tavolo"
              size="medium"
              type="number"
              variant="outlined"
              fullWidth
              margin="normal"
              value={editNumber}
              onChange={(e) => setEditNumber(clamp(e.target.value, 1, 999))}
              inputProps={{ min: 1, max: 999 }}
            />
            <TextField
              label="Numero Posti"
              size="medium"
              type="number"
              variant="outlined"
              fullWidth
              margin="normal"
              value={editSeats}
              onChange={(e) => setEditSeats(clamp(e.target.value, 1, 12))}
              inputProps={{ min: 1, max: 12 }}
            />
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
              <Button
                variant="text"
                onClick={() => setEditingDrawerOpen(false)}
                sx={{ mr: 1 }}
              >
                Annulla
              </Button>
              <Button variant="contained" type="submit">
                Salva
              </Button>
            </Box>
          </form>
        </Box>
      </Drawer>
    </>
  );
};

export default FloorMap;
