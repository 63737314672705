// ItemForm v1.70
import React, { useState, useEffect } from 'react';
import { useMenu } from '../../../contexts/MenuContext';
import {
  handleMenuItem,
  updateCombo,
  createCombo,
  fetchAllergens,
  fetchDietGroups,
} from '../../../services/apiService';
import {
  TextField,
  Button,
  Typography,
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  FormControlLabel,
  Checkbox,
  Switch,
  RadioGroup,
  Radio,
  ListSubheader,
  Alert,
  InputAdornment,
  OutlinedInput
} from '@mui/material';
import PriceTextField from '../../Common/PriceTextField';
import PictureUpload from '../../Common/PictureUpload';

const ItemForm = ({
  context,
  onClose,
  editingMenuItem,
  addNewMenuItem,
  updateMenuItems,
  editingCombo,
  addNewCombo,
  updateCombos,
}) => {
  // Get shared state from MenuContext
  const { selectedCategory, selectedMenu, menuCategories, menuItems } = useMenu();

  // Toggle state: false = Menu Item, true = Combo
  const [isCombo, setIsCombo] = useState(false);

  /** 
   * Load common dropdown options once.
   * These options are shared between both branches.
   */
  const [commonAllergenOptions, setCommonAllergenOptions] = useState([]);
  const [commonDietGroupOptions, setCommonDietGroupOptions] = useState([]);

  useEffect(() => {
    const loadCommonOptions = async () => {
      try {
        const [allergens, dietGroups] = await Promise.all([
          fetchAllergens(),
          fetchDietGroups(),
        ]);
        setCommonAllergenOptions(
          allergens.map((a) => ({ value: a.id, label: a.name }))
        );
        setCommonDietGroupOptions(
          dietGroups.map((d) => ({ value: d.id, label: d.name }))
        );
      } catch (error) {
        console.error('Error fetching common options:', error);
      }
    };
    loadCommonOptions();
  }, []);

  // =========================
  // MENU ITEM (Non-Combo) State
  // =========================
  const [itemName, setItemName] = useState('');
  const [itemDescription, setItemDescription] = useState('');
  const [itemPrice, setItemPrice] = useState('');
  const [itemSelectedFile, setItemSelectedFile] = useState(null);
  const [itemExistingFile, setItemExistingFile] = useState(null);
  const [itemPreviewUrl, setItemPreviewUrl] = useState(null);
  const [itemClearExistingFile, setItemClearExistingFile] = useState(false);
  const [itemSelectedAllergens, setItemSelectedAllergens] = useState([]);
  const [itemSelectedDietGroups, setItemSelectedDietGroups] = useState([]);

  const resetItemForm = () => {
    setItemName('');
    setItemDescription('');
    setItemPrice('');
    setItemSelectedFile(null);
    setItemPreviewUrl(null);
    setItemSelectedAllergens([]);
    setItemSelectedDietGroups([]);
    setItemExistingFile(null);
    setItemClearExistingFile(false);
  };

  useEffect(() => {
    // If editing a menu item and we’re not in combo mode, initialize the fields.
    if (!isCombo && context === 'editMenuItem' && editingMenuItem) {
      setItemName(editingMenuItem.name);
      setItemDescription(editingMenuItem.description);
      setItemPrice(editingMenuItem.price);
      setItemSelectedAllergens(editingMenuItem.allergens);
      setItemSelectedDietGroups(editingMenuItem.diet_groups);
      setItemExistingFile(editingMenuItem.picture);
      setItemPreviewUrl(editingMenuItem.picture);
      setItemClearExistingFile(false);
    } else {
      resetItemForm();
    }
  }, [context, editingMenuItem, isCombo]);

  // =========================
  // COMBO State
  // =========================
  const [comboName, setComboName] = useState('');
  const [comboDescription, setComboDescription] = useState('');
  const [comboSelectedOptions, setComboSelectedOptions] = useState([]); // IDs of selected menu items
  const [comboFixedPrice, setComboFixedPrice] = useState("");
  const [comboUseFixedPrice, setComboUseFixedPrice] = useState(false);
  const [comboPriceModifier, setComboPriceModifier] = useState("");
  const [comboSelectedAllergens, setComboSelectedAllergens] = useState([]);
  const [comboSelectedDietGroups, setComboSelectedDietGroups] = useState([]);
  const [comboSelectedFile, setComboSelectedFile] = useState(null);
  const [comboExistingFile, setComboExistingFile] = useState(null);
  const [comboPreviewUrl, setComboPreviewUrl] = useState(null);
  const [comboClearExistingFile, setComboClearExistingFile] = useState(false);

  const resetComboForm = () => {
    setComboName('');
    setComboDescription('');
    setComboSelectedOptions([]);
    setComboFixedPrice("");
    setComboUseFixedPrice(false);
    setComboPriceModifier("");
    setComboSelectedAllergens([]);
    setComboSelectedDietGroups([]);
    setComboSelectedFile(null);
    setComboPreviewUrl(null);
    setComboExistingFile(null);
    setComboClearExistingFile(false);
  };

  useEffect(() => {
    // If editing a combo and in combo mode, initialize the fields.
    if (isCombo && context === 'editCombo' && editingCombo) {
      setComboName(editingCombo.name);
      setComboDescription(editingCombo.description);
      setComboSelectedOptions(editingCombo.items.map((itemId) => itemId));
      setComboFixedPrice(editingCombo.fixed_price || 0);
      setComboUseFixedPrice(editingCombo.use_fixed_price);
      setComboPriceModifier(editingCombo.price_modifier || 0);
      setComboSelectedAllergens(editingCombo.allergens);
      setComboSelectedDietGroups(editingCombo.diet_groups);
      setComboExistingFile(editingCombo.picture);
      setComboPreviewUrl(editingCombo.picture);
      setComboClearExistingFile(false);
    } else {
      resetComboForm();
    }
  }, [context, editingCombo, isCombo]);

  useEffect(() => {
    if (context === 'editCombo' || context === 'createCombo') {
      setIsCombo(true);
    }
  }, [context]);

  // =========================
  // Handlers for Menu Item branch
  // =========================
  const handleItemSubmit = async (event) => {
    event.preventDefault();
    // Determine whether to close based on the clicked button.
    const shouldClose = event.nativeEvent.submitter.getAttribute('data-shouldclose') === 'true';

    if (!itemName.trim() || !selectedCategory) {
      console.error('Item name and category must be selected');
      return;
    }
    try {
      const formData = new FormData();
      formData.append('name', itemName);
      formData.append('description', itemDescription);
      formData.append('price', itemPrice);
      selectedCategory.forEach((catId) =>
        formData.append('categories', catId)
      );
      if (itemSelectedFile) {
        formData.append('picture', itemSelectedFile);
      } else if (itemClearExistingFile) {
        formData.append('picture', '');
      }
      itemSelectedAllergens.forEach((allergen) =>
        formData.append('allergens', allergen)
      );
      itemSelectedDietGroups.forEach((dietGroup) =>
        formData.append('diet_groups', dietGroup)
      );

      let response;
      if (context === 'editMenuItem' && editingMenuItem) {
        response = await handleMenuItem(formData, editingMenuItem.id);
        updateMenuItems(response);
      } else {
        response = await handleMenuItem(formData);
        addNewMenuItem(response);
      }
      resetItemForm();
      // Only close the sidebar if the button clicked indicates so.
      if (shouldClose) {
        onClose();
      }
    } catch (error) {
      console.error('Error handling menu item:', error);
    }
  };

  // =========================
  // Handlers for Combo branch
  // =========================
  const handleComboSubmit = async (event) => {
    event.preventDefault();
    const shouldClose = event.nativeEvent.submitter.getAttribute('data-shouldclose') === 'true';

    if (!comboName.trim()) {
      console.error('Combo name cannot be empty');
      return;
    }
    try {
      const formData = new FormData();
      formData.append('name', comboName);
      formData.append('description', comboDescription);
      formData.append('menus', selectedMenu);

      (selectedCategory || []).forEach((catId) =>
        formData.append('categories', catId)
      );

      comboSelectedOptions.forEach((itemId) =>
        formData.append('items', itemId)
      );
      formData.append('use_fixed_price', comboUseFixedPrice);
      comboSelectedAllergens.forEach((allergen) =>
        formData.append('allergens', allergen)
      );
      comboSelectedDietGroups.forEach((dietGroup) =>
        formData.append('diet_groups', dietGroup)
      );
      if (comboSelectedFile) {
        formData.append('picture', comboSelectedFile);
      } else if (comboClearExistingFile) {
        formData.append('picture', '');
      }
      if (comboUseFixedPrice) {
        formData.append('fixed_price', comboFixedPrice);
      } else {
        formData.append('price_modifier', comboPriceModifier);
      }

      let response;
      if (context === 'editCombo' && editingCombo) {
        response = await updateCombo(editingCombo.id, formData);
        updateCombos(response);
      } else {
        response = await createCombo(formData);
        if (!response.categories) {
          response.categories = [];
        }
        addNewCombo(response);
      }
      resetComboForm();
      if (shouldClose) {
        onClose();
      }
    } catch (error) {
      console.error('Error handling combo:', error);
    }
  };

  // =========================
  // File Handlers (separate for each branch)
  // =========================
  const handleItemFileSelect = (file, previewUrl) => {
    setItemSelectedFile(file);
    setItemPreviewUrl(previewUrl);
    setItemClearExistingFile(false);
  };

  const handleItemClearImage = () => {
    setItemExistingFile(null);
    setItemSelectedFile(null);
    setItemPreviewUrl(null);
    setItemClearExistingFile(true);
  };

  const handleComboFileSelect = (file, previewUrl) => {
    setComboSelectedFile(file);
    setComboPreviewUrl(previewUrl);
    setComboClearExistingFile(false);
  };

  const handleComboClearImage = () => {
    setComboExistingFile(null);
    setComboSelectedFile(null);
    setComboPreviewUrl(null);
    setComboClearExistingFile(true);
  };

  // =========================
  // Change Handlers for Select controls
  // =========================
  // For Menu Item branch
  const handleItemAllergenChange = (event) => {
    const { value } = event.target;
    setItemSelectedAllergens(value);
  };

  const handleItemDietGroupChange = (event) => {
    const { value } = event.target;
    setItemSelectedDietGroups(value);
  };

  // For Combo branch
  const handleComboDietGroupChange = (event) => {
    const { value } = event.target;
    setComboSelectedDietGroups(value);
  };

  const handleComboSelectionChange = (event) => {
    const { value } = event.target;
    setComboSelectedOptions(
      typeof value === 'string' ? value.split(',') : value
    );
  };

  const handleComboRadioChange = (e) => {
    setComboUseFixedPrice(e.target.value === 'custom');
  };

  // Build grouped options for combo item selection
  const getGroupedOptions = () => {
    return menuCategories.map((category) => ({
      label: category.name,
      options: menuItems
        .filter((item) => item.categories.includes(category.id))
        .map((item) => ({
          label: item.name,
          value: item.id,
        })),
    }));
  };

  return (
    <Box sx={{ p: 2 }}>
      {/* Toggle switch to choose between Menu Item and Combo */}
      <FormControlLabel sx={{ mb: 2 }}
        control={
          <Switch
            checked={isCombo}
            onChange={(e) => setIsCombo(e.target.checked)}
            name="isCombo"
            color="primary"
          />
        }
        label="Crea combinazione"
      />

      {isCombo ? (
        // ----- COMBO FORM -----
        <Box component="form" onSubmit={handleComboSubmit}>
          <Typography variant="body1" sx={{ fontWeight: 550, mb: 1 }}>
            {context === 'editCombo' ? 'Modifica Combo' : 'Aggiungi Combo'}
          </Typography>
          <TextField
            fullWidth
            variant="outlined"
            label="Nome"
            placeholder='per es. "Menu Hamburger"'
            value={comboName}
            onChange={(e) => setComboName(e.target.value)}
            margin="normal"
          />
          <TextField
            fullWidth
            variant="outlined"
            label="Descrizione"
            placeholder='per es. "Hamburger, Patatine e Bevanda"'
            value={comboDescription}
            onChange={(e) => setComboDescription(e.target.value)}
            margin="normal"
          />
          <FormControl fullWidth margin="normal" sx={{ mb: 2 }}>
            <InputLabel>Seleziona voci del menu</InputLabel>
            <Select
              multiple
              value={comboSelectedOptions}
              onChange={handleComboSelectionChange}
              label="Seleziona voci del menu"
              renderValue={(selected) =>
                selected
                  .map((id) => {
                    const item = menuItems.find((i) => i.id === id);
                    return item ? item.name : '';
                  })
                  .join(', ')
              }
            >
              {getGroupedOptions().map((group) => [
                <ListSubheader key={group.label} sx={{ fontWeight: 600 }}>{group.label}</ListSubheader>,
                group.options.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                )),
              ])}
            </Select>
          </FormControl>
          <FormControl component="fieldset">
            <RadioGroup
              row
              value={comboUseFixedPrice ? 'custom' : 'default'}
              onChange={handleComboRadioChange}
            >
              <FormControlLabel
                value="default"
                control={<Radio color="primary" />}
                label="Somma i prezzi"
              />
              <FormControlLabel
                value="custom"
                control={<Radio color="primary" />}
                label="Definisci il prezzo"
              />
            </RadioGroup>
          </FormControl>
          {comboUseFixedPrice ? (
            <PriceTextField sx={{ height: '50px', width: '140px', mt: 1, mb: 1 }}
              fullWidth
              variant="outlined"
              label="Prezzo (€)"
              type="number"
              value={comboFixedPrice}
              onChange={(newValue) => setComboFixedPrice(parseFloat(newValue))}
              margin="normal"
            />
          ) : (
            <FormControl fullWidth sx={{ width: '140px', mt: 1, mb: 2 }}>
              <InputLabel>Sconto</InputLabel>
              <OutlinedInput
                type="number"
                value={comboPriceModifier}
                onChange={(e) => setComboPriceModifier(parseFloat(e.target.value))}
                endAdornment={<InputAdornment position="end">%</InputAdornment>}
                label="Sconto"
                inputProps={{ min: 0, max: 100 }}
                sx={{ height: '50px' }}
              />
            </FormControl>
          )}
          <Alert severity="info">
            Gli allergeni dei rispettivi elementi verranno aggiunti alla combo.
          </Alert>
          <FormControl fullWidth margin="normal">
            <InputLabel>Gruppo Alimentare</InputLabel>
            <Select
              sx={{ height: '50px' }}
              multiple
              value={comboSelectedDietGroups}
              onChange={handleComboDietGroupChange}
              renderValue={(selected) =>
                selected
                  .map(
                    (item) =>
                      commonDietGroupOptions.find((opt) => opt.value === item)?.label
                  )
                  .join(', ')
              }
              label="Gruppo Alimentare"
            >
              {commonDietGroupOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        size="small"
                        checked={comboSelectedDietGroups.indexOf(option.value) > -1}
                      />
                    }
                    label={option.label}
                  />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Box sx={{ mt: 2 }} >
            <PictureUpload
              existingFile={comboExistingFile}
              previewUrl={comboPreviewUrl}
              onFileSelect={handleComboFileSelect}
              onClearImage={handleComboClearImage}
            />
          </Box>
          <Box display="flex" justifyContent="flex-end" mt={4}>
          <Button
              variant="outlined"
              color="primary"
              type="submit"
              data-shouldclose="false"
            >
              Invia e Aggiungi
            </Button>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              data-shouldclose="true"
              sx={{ ml: 2 }}
            >
              Conferma
            </Button>
          </Box>
        </Box>
      ) : (
        // ----- MENU ITEM FORM -----
        <Box component="form" onSubmit={handleItemSubmit}>
          <Typography variant="body1" sx={{ fontWeight: 550, mb: 1 }}>
            {context === 'editMenuItem'
              ? 'Modifica voce di menu'
              : 'Aggiungi voce di menu'}
          </Typography>
          <TextField
            fullWidth
            variant="outlined"
            label="Nome"
            placeholder='per es. "Lasagna al pesto"'
            value={itemName}
            onChange={(e) => setItemName(e.target.value)}
            margin="normal"
          />
          <TextField
            fullWidth
            variant="outlined"
            label="Descrizione"
            placeholder='per es. "Sfoglie di pasta fresca e pesto di basilico"'
            value={itemDescription}
            onChange={(e) => setItemDescription(e.target.value)}
            margin="normal"
          />
          <PriceTextField
            fullWidth
            variant="outlined"
            label="Prezzo (€)"
            type="number"
            value={itemPrice}
            onChange={(newValue) => setItemPrice(parseFloat(newValue))}
            margin="normal"
          />
          <FormControl fullWidth margin="normal">
            <InputLabel>Allergeni</InputLabel>
            <Select
              sx={{ height: '50px' }}
              MenuProps={{
                PaperProps: {
                  sx: {
                    width: '300px',
                    '& .MuiMenuItem-root': {
                      fontSize: '16px',
                    },
                  },
                },
              }}
              multiple
              value={itemSelectedAllergens}
              onChange={handleItemAllergenChange}
              renderValue={(selected) =>
                selected
                  .map(
                    (item) =>
                      commonAllergenOptions.find((opt) => opt.value === item)?.label
                  )
                  .join(', ')
              }
              label="Allergeni"
            >
              {commonAllergenOptions.map((option) => (
                <MenuItem key={option.value} value={option.value} sx={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        size="small"
                        checked={itemSelectedAllergens.indexOf(option.value) > -1}
                      />
                    }
                    label={option.label}
                  />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth margin="normal">
            <InputLabel>Gruppi Alimentari</InputLabel>
            <Select
              sx={{ height: '50px' }}
              MenuProps={{
                PaperProps: {
                  sx: {
                    width: '300px',
                    '& .MuiMenuItem-root': {
                      fontSize: '16px',
                    },
                  },
                },
              }}
              multiple
              value={itemSelectedDietGroups}
              onChange={handleItemDietGroupChange}
              renderValue={(selected) =>
                selected
                  .map(
                    (item) =>
                      commonDietGroupOptions.find((opt) => opt.value === item)?.label
                  )
                  .join(', ')
              }
              label="Gruppi Alimentari"
            >
              {commonDietGroupOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        size="small"
                        checked={itemSelectedDietGroups.indexOf(option.value) > -1}
                      />
                    }
                    label={option.label}
                  />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Box sx={{ mt: 2 }} >
            <PictureUpload
              existingFile={itemExistingFile}
              previewUrl={itemPreviewUrl}
              onFileSelect={handleItemFileSelect}
              onClearImage={handleItemClearImage}
            />
          </Box>
          <Box display="flex" justifyContent="flex-end" mt={4}>
            <Button
              variant="outlined"
              color="primary"
              type="submit"
              data-shouldclose="false"
            >
              Invia e Aggiungi
            </Button>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              data-shouldclose="true"
              sx={{ ml: 2 }}
            >
              Invia
            </Button>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default ItemForm;
