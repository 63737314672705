// stripeApiService.js
import api from './apiInstance'; // Import the Axios instance with interceptors

// Use environment variable for API base URL
const API_BASE_URL = process.env.REACT_APP_API_URL;

// ---------- USERS SUBSCRIPTION ----------

// Function to fetch modules and bundles details
export const fetchModulesAndBundles = async () => {
  try {
    const response = await api.get(`${API_BASE_URL}api/modules-bundles/`);
    return response.data; // should contain { modules: [...], bundles: [...] }
  } catch (error) {
    console.error("Error fetching modules and bundles:", error);
    throw error;
  }
};

// Function to create a new subscription
export const createNewSubscription = async (priceIds, paymentMethodId) => {
  const response = await api.post(
    `${API_BASE_URL}api/subscriptions/create_new/`,
    { 
      price_ids: priceIds, 
      payment_method_id: paymentMethodId 
    }
  );
  return response.data;
};


// Function to add new modules to subscription
export const addModules = async (priceIds) => {
  try {
    const response = await api.post(
      `${API_BASE_URL}api/subscriptions/add_modules/`,
      { price_ids: priceIds }
    );
    return response.data;
  } catch (error) {
    console.error('Error adding modules:', error);
    throw error;
  }
};

// Function to preview a new subscription
export const previewNewSubscription = async (priceIds) => {
  try {
    const response = await api.post(
      `${API_BASE_URL}api/subscriptions/preview_new/`,
      { price_ids: priceIds }
    );
    return response.data;
  } catch (error) {
    console.error('Error previewing new subscription:', error);
    throw error;
  }
};

// Function to preview a subscription update
export const previewSubscriptionUpdate = async (priceIds) => {
  try {
    const response = await api.post(
      `${API_BASE_URL}api/subscriptions/preview_update/`,
      { price_ids: priceIds }
    );
    return response.data;
  } catch (error) {
    console.error('Error previewing subscription update:', error);
    throw error;
  }
};

// Function to update payment method
export const updatePaymentMethod = async (paymentMethodId) => {
  try {
    const response = await api.post(
      `${API_BASE_URL}api/subscriptions/update_payment_method/`,
      { payment_method_id: paymentMethodId }
    );
    return response.data;
  } catch (error) {
    console.error('Error updating payment method', error);
    throw error;
  }
};

// Function to create a setup intent for upgrading subscription
export const createSetupIntent = async () => {
  try {
    const response = await api.post(`${API_BASE_URL}api/subscriptions/create-setup-intent/`);
    return response.data; // Expected to return { client_secret: 'seti_...' }
  } catch (error) {
    console.error('Error creating setup intent:', error);
    throw error;
  }
};

// Function to update a Stripe subscription.
export const updateSubscription = async (priceIds) => {
  try {
    const response = await api.post(
      `${API_BASE_URL}api/subscriptions/update_subscription/`,
      {
        price_ids: priceIds,
      });
    return response.data;
  } catch (error) {
    console.error('Error updating subscription', error);
    throw error;
  }
};

// Function to cancels the user's selected module
export const cancelModule = async (moduleId) => {
  try {
    const response = await api.post(
      `${API_BASE_URL}api/subscriptions/cancel_module/`,
      { module_id: moduleId }
    );
    return response.data;
  } catch (error) {
    console.error('Error cancelling module:', error);
    throw error;
  }
};


// Function to cancels the user's subscription
export const cancelBundle = async () => {
  try {
    const response = await api.post(`${API_BASE_URL}api/subscriptions/cancel_bundle/`, {});
    return response.data;
  } catch (error) {
    console.error('Error cancelling bundle:', error);
    throw error;
  }
};

// Function to download invices
export const listInvoices = async () => {
  try {
    const response = await api.get(`${API_BASE_URL}api/stripe/invoices/`);
    return response.data;
  } catch (error) {
    console.error('Error listing invoices:', error);
    throw error;
  }
};

// ---------- STRIPE CONNECT ----------

// Function to create a Stripe connected account
export const createConnectedAccount = async (restaurantId) => {
  const url = `${API_BASE_URL}auth/create-connected-account/`;

  try {
    const response = await api.post(url, { restaurant_id: restaurantId });
    return response.data;
  } catch (error) {
    console.error('Error creating connected account:', error);
    throw error;
  }
};

// Function to create a Stripe account session
export const createAccountSession = async (restaurantId) => {
  const url = `${API_BASE_URL}auth/create-account-session/`;

  try {
    const response = await api.post(url, { restaurant_id: restaurantId });
    return response.data;
  } catch (error) {
    console.error('Error creating account session:', error);
    throw error;
  }
};

// ---------- END-USERS PAYMENTS ----------

// Function to initiate Stripe Payment
export const initiateStripePayment = async (orderId, paymentData) => {
  try {
    const response = await api.post(`users/initiate-stripe-payment/${orderId}/`, paymentData);
    return response.data.stripe_checkout_session_url;
  } catch (error) {
    console.error('Error initiating Stripe payment:', error);
    throw error;
  }
};

// Function to get confirmation on payment status
export const confirmStripePayment = async (sessionId) => {
  try {
    const response = await api.get(`users/confirm-payment`, {
      params: {
        session_id: sessionId,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error confirming Stripe payment:", error);
    throw error.response ? error.response.data : error;
  }
};

// ---------- NFC PAYMENTS AND ORDERS ----------

// Function to create a NFC order checkout session
export const createNfcOrderCheckout = async ({ shippingInfo, restaurantId, quantity }) => {
  const url = `${API_BASE_URL}api/nfc-orders/`;
  try {
    const payload = {
      shipping_info: shippingInfo,
      restaurant_id: restaurantId,
      quantity
    };
    const response = await api.post(url, payload);
    return response.data;  // { checkoutUrl: '...' }
  } catch (error) {
    console.error('Error creating NFC order checkout session:', error);
    throw error;
  }
};

// Function to fetch NFC orders for a given restaurant
export const fetchNfcOrders = async (restaurantId) => {
  const url = `${API_BASE_URL}api/nfc-orders/${restaurantId}/`;
  try {
    const response = await api.get(url);
    return response.data; // array of { id, status, quantity, price, ... }
  } catch (error) {
    console.error('Error fetching NFC orders:', error);
    throw error;
  }
};

// Function to fetch NFC devices for a given restaurant
export const fetchNfcDevices = async (restaurantId) => {
  // e.g. GET /api/nfc-devices/{restaurantId}/
  const url = `${API_BASE_URL}api/nfc-devices/${restaurantId}/`;
  try {
    const response = await api.get(url);
    return response.data; // array of devices with { id, identifier, number, table:{ id, number, area: { name } }, ... }
  } catch (error) {
    console.error('Error fetching NFC devices:', error);
    throw error;
  }
};

// Function to trigger auto-assignment of devices for a given restaurant
export const autoAssignDevices = async (restaurantId) => {
  const url = `${API_BASE_URL}api/nfc-devices/${restaurantId}/auto-assign/`;
  try {
    const response = await api.post(url);
    return response.data; // e.g., { message: '...' }
  } catch (error) {
    console.error('Error auto assigning devices:', error);
    throw error;
  }
};