import React from 'react';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import CloseIcon from '@mui/icons-material/Close';
import CategoryForm from './forms/CategoryForm';
import ItemForm from './forms/ItemForm';
import AssignmentForms from './forms/AssignmentForms';
import { useSidebar } from '../../contexts/SidebarContext';

const SidebarWidget = ({
  editingCategory,
  editingMenuItem,
  editingCombo,
  addNewCategory,
  addNewMenuItem,
  updateCategories,
  updateMenuItems,
  addNewCombo,
  updateCombos,
}) => {
  const { showSidebar, sidebarContext, closeSidebar } = useSidebar();

  // Render content based on sidebarContext.
  // We now merge the cases for menu items and combos into one:
  const renderContent = () => {
    switch (sidebarContext) {

      case 'createCategory':
      case 'editCategory':
        return (
          <CategoryForm
            context={sidebarContext}
            onClose={closeSidebar}
            editingCategory={editingCategory}
            addNewCategory={addNewCategory}
            updateCategories={updateCategories}
          />
        );

      case 'createMenuItem':
      case 'editMenuItem':
      case 'createCombo':
      case 'editCombo':
        return (
          <ItemForm
            context={sidebarContext}
            onClose={closeSidebar}
            editingMenuItem={editingMenuItem}
            editingCombo={editingCombo}
            addNewMenuItem={addNewMenuItem}
            updateMenuItems={updateMenuItems}
            addNewCombo={addNewCombo}
            updateCombos={updateCombos}
          />
        );

      case 'assignCategory':
      case 'assignMenuItem':
        return (
          <AssignmentForms
            context={sidebarContext}
            onClose={closeSidebar}
          />
        );

      default:
        return null;
    }
  };

  return (
    <Drawer
      anchor="right"
      open={showSidebar}
      onClose={closeSidebar}
      // Controls the built-in enter and exit durations (in ms)
      transitionDuration={{ enter: 300, exit: 300 }}
      PaperProps={{
        sx: {
          // Define width based on breakpoints
          width: {
            xs: '88%',
            sm: '65%',
            md: '50%',
            lg: '35%',
            xl: '600px',
          },
          // Optional: custom CSS transition on the width if needed
          transition: 'width 0.3s ease',
        },
      }}
    >
      <Box sx={{ p: 2, position: 'relative', width: '100%' }}>
        <IconButton
          onClick={closeSidebar}
          sx={{ position: 'absolute', top: 15, right: 15 }}
        >
          <CloseIcon />
        </IconButton>
        {renderContent()}
      </Box>
    </Drawer>
  );
};

export default SidebarWidget;
