// AreaCreationWizard.jsx
import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogContent,
  Box,
  Stepper,
  Step,
  StepLabel,
  Button,
  TextField,
  IconButton,
  Typography,
  Alert,
  Divider
} from '@mui/material';
import DeleteOutlineRounded from '@mui/icons-material/DeleteOutlineRounded';
import { useResponsive } from '../../../hooks/useResponsive';

// Constants for limits
const MAX_TABLES_PER_AREA = 24;
const MAX_AREAS = 5;

const AreaCreationWizard = ({ open, onClose, onComplete }) => {
  const { isMobile } = useResponsive();
  const [activeStep, setActiveStep] = useState(0);
  const [areasData, setAreasData] = useState([]);

  // Container #2 state: area name and total tables (entered by the user)
  const [areaName, setAreaName] = useState('');
  const [totalTablesInput, setTotalTablesInput] = useState('');

  // Container #3 state: seat groups for tables.
  // Each group: { id, numberOfTables, numberOfSeats, autoAdded? }
  const [tableGroups, setTableGroups] = useState([]);

  // For displaying validation errors
  const [error, setError] = useState('');

  // ------------------ CONTAINER #2 & AUTO-UPDATE LOGIC ------------------
  useEffect(() => {
    if (!totalTablesInput) return;
    const total = Number(totalTablesInput);
    if (isNaN(total) || total <= 0) return;
    if (total > MAX_TABLES_PER_AREA) {
      setError(`Numero massimo di tavoli per area raggiunto (${MAX_TABLES_PER_AREA})`);
      return;
    }
    setError('');

    // Separate manual groups (not autoAdded) from the auto group.
    const manualGroups = tableGroups.filter((g) => !g.autoAdded);
    const autoGroup = tableGroups.find((g) => g.autoAdded);

    // Calculate the sum of manually set tables.
    const fixedSum = manualGroups.reduce((sum, group) => sum + Number(group.numberOfTables), 0);
    const remainder = total - fixedSum;

    // If the manually set groups exceed the total, set an error.
    if (remainder < 0) {
      setError('La somma dei tavoli supera il totale dell\'area');
      return;
    }

    if (remainder === 0) {
      // If there is no remainder, we do not need an auto group.
      if (autoGroup) {
        // Remove the auto group.
        setTableGroups(manualGroups);
      }
      // Otherwise, manualGroups already exactly add up to total.
    } else {
      // There is a positive remainder.
      if (autoGroup) {
        // If an auto group exists but its count doesn't match the remainder, update it.
        if (Number(autoGroup.numberOfTables) !== remainder) {
          const newGroups = [...manualGroups, { ...autoGroup, numberOfTables: remainder }];
          setTableGroups(newGroups);
        }
      } else {
        // No auto group exists, so add one.
        setTableGroups([...manualGroups, { id: Date.now(), numberOfTables: remainder, numberOfSeats: 4, autoAdded: true }]);
      }
    }
    // Runs whenever totalTablesInput or tableGroups changes.
  }, [totalTablesInput, tableGroups]);


  // Handler for Reset button in Container #2: clear both main details and seat groups.
  const handleResetMainDetails = () => {
    setAreaName('');
    setTotalTablesInput('');
    setTableGroups([]);
    setError('');
  };

  // ------------------ CONTAINER #3 HANDLERS ------------------
  // Update a seat group's field. For "Numero di posti per tavolo", validates table and seat count.
  const handleSeatGroupChange = (id, field, value) => {
    setTableGroups(prev =>
      prev.map(group => {
        if (group.id === id) {
          let newValue = value;
          if (field === 'numberOfTables') {
            const intVal = parseInt(value, 10) || 1;
            newValue = Math.max(1, Math.min(intVal, 24));
          } else if (field === 'numberOfSeats') {
            const intVal = parseInt(value, 10) || 1;
            newValue = Math.max(1, Math.min(intVal, 12));
          }
          const updatedGroup = { ...group, [field]: newValue };
          if (group.autoAdded) {
            delete updatedGroup.autoAdded;
          }
          return updatedGroup;
        }
        return group;
      })
    );
  };

  // Remove a seat group by its id.
  const removeSeatGroup = (id) => {
    setTableGroups(prev => {
      const groupToRemove = prev.find(group => group.id === id);
      if (!groupToRemove) return prev;
      // Remove the group from the list.
      const remainingGroups = prev.filter(group => group.id !== id);
      if (remainingGroups.length > 0) {
        // Add the removed group's table count to the first group.
        remainingGroups[0] = {
          ...remainingGroups[0],
          numberOfTables: Number(remainingGroups[0].numberOfTables) + Number(groupToRemove.numberOfTables)
        };
      }
      return remainingGroups;
    });
  };

  // Validate that the sum of tables across all seat groups equals the total defined.
  const validSeatGroups = () => {
    const total = Number(totalTablesInput);
    const sum = tableGroups.reduce(
      (acc, group) => acc + Number(group.numberOfTables),
      0
    );
    return sum === total;
  };

  // ------------------ STEP HANDLERS ------------------
  const currentAreaPreview = areaName.trim() && tableGroups.length > 0 ? {
    areaName: areaName.trim(),
    tableGroups: tableGroups
  } : null;

  const handleContinue = () => {
    if (!validSeatGroups()) {
      setError('La somma dei tavoli nei gruppi deve essere uguale al totale definito');
      return;
    }
    setError('');
    setActiveStep(1);
  };

  const handleBack = () => {
    setActiveStep(0);
  };

  const handleAddAnotherArea = () => {
    const newArea = {
      areaName: areaName.trim(),
      tableGroups: [...tableGroups]
    };
    setAreasData(prev => {
      const updatedAreas = [...prev, newArea];
      if (updatedAreas.length >= MAX_AREAS) {
        handleSubmit(updatedAreas);
      } else {
        // Reset the form for the next area
        setAreaName('');
        setTotalTablesInput('');
        setTableGroups([]);
        setActiveStep(0);
      }
      return updatedAreas;
    });
  };

  // For removing one of the *saved* areas from areasData
  const handleRemoveArea = (index) => {
    setAreasData(prevAreas => {
      const updated = [...prevAreas];
      updated.splice(index, 1);
      return updated;
    });
  };

  // For removing/clearing the currentAreaPreview
  const handleRemoveCurrentPreview = () => {
    setAreaName('');
    setTotalTablesInput('');
    setTableGroups([]);
  };


  const handleSubmit = (finalAreasData = null) => {
    let allAreas = finalAreasData;
    if (!allAreas) {
      if (!areaName.trim()) {
        setError("Il nome dell'area è obbligatorio");
        return;
      }
      if (!validSeatGroups()) {
        setError('La somma dei tavoli nei gruppi deve essere uguale al totale definito');
        return;
      }
      const currentArea = {
        areaName: areaName.trim(),
        tableGroups: [...tableGroups]
      };
      allAreas = [...areasData, currentArea];
    }
    // Trigger the onComplete callback so the parent can call the API.
    onComplete(allAreas);
  };

  const handleClose = () => {
    onClose();
    // Reset state when closing the wizard
    setAreasData([]);
    setAreaName('');
    setTotalTablesInput('');
    setTableGroups([]);
    setActiveStep(0);
    setError('');
  };

  const steps = ["Definisci aree e tavoli", 'Conferma'];

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth="md"
      PaperProps={{
        sx: {
          height: { xs: '100%', sm: '90%' },
          width: { xs: '100%', sm: '85%', md: '60%' },
          position: 'relative'
        }
      }}
    >
      <DialogContent dividers sx={{ p: 2 }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
          {/* Main content area */}
          <Button color="secondary" onClick={onClose} style={{ fontSize: '13px', alignSelf: 'flex-end' }}>Chiudi</Button>
          <Box sx={{ flexGrow: 1, paddingX: '20px' }}>
            <Stepper sx={{ width: '80%', paddingLeft: '20%' }} alternativeLabel activeStep={activeStep}>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel
                    StepIconProps={{
                      sx: { fontSize: '1.40rem' }  // Decrease the icon size; adjust as needed.
                    }}
                  >
                    {!isMobile ? label : ''}
                  </StepLabel>
                </Step>
              ))}
            </Stepper>

            {activeStep === 0 && (
              <Box
                sx={{
                  mt: '6%',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  justifyContent: 'center',
                  gap: 2,
                  width: '100%'
                }}
              >
                {/* Container #2: Main details (always editable) */}
                <Typography variant="body2" fontWeight="550" sx={{ mb: '10px' }} >
                  Definisci una zona o area con il numero totale dei tavoli (max 24 per zona)
                </Typography>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: isMobile ? 'column' : 'row',
                    alignItems: isMobile ? 'flex-start' : 'center',
                    gap: 2,
                    width: '100%',
                    mb: 2
                  }}
                >
                  <TextField
                    sx={{ width: '50%' }}
                    label="Nome dell'Area"
                    placeholder="per es. Interno"
                    value={areaName}
                    onChange={(e) => setAreaName(e.target.value)}
                  />
                  <TextField
                    label="Num. di Tavoli"
                    type="number"
                    value={totalTablesInput}
                    onChange={(e) => {
                      const input = e.target.value;
                      if (!input) {
                        setTotalTablesInput('');
                        return;
                      }
                      const numericValue = parseInt(input, 10);
                      const clampedValue = Math.max(1, Math.min(numericValue, 24));
                      setTotalTablesInput(String(clampedValue));
                    }}
                    sx={{ width: '100px' }}
                    inputProps={{ min: 1, max: 24 }}
                  />

                  {tableGroups.length > 0 && (
                    <Button size="small" variant="text" onClick={handleResetMainDetails}>
                      Reset
                    </Button>
                  )}
                </Box>

                {tableGroups.length > 0 && (
                  <Typography variant="body2" sx={{ fontWeight: '550', mb: 1 }}>
                    Raggruppa i tavoli per numero di posti
                  </Typography>
                )}

                {/* Container #3: Seat groups (automatically managed) */}
                {tableGroups.length > 0 && (
                  <>
                    {tableGroups.map((group, index) => (
                      <Box key={group.id} sx={{ width: '100%' }}>
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            gap: 2,
                            mb: 1
                          }}
                        >
                          <TextField
                            label="Tavoli"
                            type="number"
                            value={group.numberOfTables}
                            onChange={(e) => {
                              const input = e.target.value;
                              if (!input) {
                                handleSeatGroupChange(group.id, 'numberOfTables', '');
                                return;
                              }
                              const numericValue = parseInt(input, 10);
                              const clampedValue = Math.max(1, Math.min(numericValue, 24));
                              handleSeatGroupChange(group.id, 'numberOfTables', clampedValue);
                            }}
                            sx={{ width: '150px' }}
                            inputProps={{ min: 1, max: 24 }}
                          />
                          <Typography variant="body1">da</Typography>
                          <TextField
                            label="Posti"
                            type="number"
                            value={group.numberOfSeats}
                            onChange={(e) => {
                              const input = e.target.value;
                              if (!input) {
                                handleSeatGroupChange(group.id, 'numberOfSeats', '');
                                return;
                              }
                              const numericValue = parseInt(input, 10);
                              const clampedValue = Math.max(1, Math.min(numericValue, 12));
                              handleSeatGroupChange(group.id, 'numberOfSeats', clampedValue);
                            }}
                            sx={{ width: '150px' }}
                            inputProps={{ min: 1, max: 12 }}
                          />
                          {index > 0 && (
                            <IconButton onClick={() => removeSeatGroup(group.id)}>
                              <DeleteOutlineRounded fontSize="small" color="secondary" />
                            </IconButton>
                          )}
                        </Box>
                        {index < tableGroups.length - 1 && <Divider />}
                      </Box>
                    ))}
                  </>
                )}

                {error && <Alert severity="error">{error}</Alert>}
              </Box>
            )}

            {activeStep === 1 && (
              <Box display="flex" flexDirection="column" textAlign="center" sx={{ mt: 3 }} gap={1}>
                {areasData.length + 1 < MAX_AREAS ? (
                  <>
                    <Typography variant="h4" gutterBottom sx={{ marginTop: '40px' }}>
                      Hai altre aree nel tuo ristorante?
                    </Typography>
                    <Typography variant="body1" sx={{ mb: 3 }}>
                      Clicca qui sotto, oppure seleziona "Chiudi e Invia" per completare.
                    </Typography>
                    <Button style={{ width: isMobile ? '65%' : '25%', alignSelf: "center" }} variant="contained" onClick={handleAddAnotherArea}>
                      Area successiva
                    </Button>
                  </>
                ) : (
                  <>
                    <Typography variant="h4" gutterBottom sx={{ marginTop: '40px' }}>
                      Hai raggiunto il numero massimo di aree.
                    </Typography>
                  </>
                )}

                {/* LIST OF AREAS ALREADY ADDED + CURRENT PREVIEW */}
                {(areasData.length > 0 || currentAreaPreview) && (
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    sx={{ mt: 4, width: '100%' }}
                  >
                    <Typography color="secondary" variant="body1" fontWeight="550" sx={{ mb: '10px' }}>
                      Aree aggiunte:
                    </Typography>

                    {/* Existing areas in areasData */}
                    {areasData.map((area, index) => (
                      <Box
                        key={index}
                        sx={{
                          width: '270px',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          mb: '10px',
                          borderBottom: '1px solid grey',
                          pb: '4px',
                        }}
                      >
                        <Typography variant="body2" fontWeight="550" sx={{ mr: 1 }}>
                          {index + 1}. {area.areaName}:{' '}
                          {area.tableGroups.reduce((sum, grp) => sum + Number(grp.numberOfTables), 0)} tavoli
                        </Typography>

                        {/* Delete icon for an area already saved in areasData */}
                        <IconButton onClick={() => handleRemoveArea(index)}>
                          <DeleteOutlineRounded sx={{ fontSize: '18px' }} color="secondary" />
                        </IconButton>
                      </Box>
                    ))}

                    {/* Current area being previewed but not yet in areasData */}
                    {currentAreaPreview && (
                      <Box
                        sx={{
                          width: '270px',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          mb: '10px',
                          borderBottom: '1px solid grey',
                          pb: '4px',
                        }}
                      >
                        <Typography variant="body2" fontWeight="550" sx={{ mr: 1 }}>
                          {areasData.length + 1}. {currentAreaPreview.areaName}{' '}
                          {currentAreaPreview.tableGroups.reduce((sum, grp) => sum + Number(grp.numberOfTables), 0)} tavoli
                        </Typography>

                        {/* Delete icon for the *in-progress* (previewed) area */}
                        <IconButton onClick={handleRemoveCurrentPreview}>
                          <DeleteOutlineRounded sx={{ fontSize: '18px' }} color="secondary" />
                        </IconButton>
                      </Box>
                    )}
                  </Box>
                )}
              </Box>
            )}
          </Box>

          {/* Bottom buttons container (always at the bottom) */}
          <Box sx={{ mt: 2, paddingBottom: '20px', display: 'flex', alignItems: 'center', width: '100%' }}>
            {activeStep === 0 ? (
              // For Step 0, only "Continua" is displayed.
              <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                  variant="contained"
                  onClick={handleContinue}
                  disabled={tableGroups.length === 0 || !validSeatGroups()}
                >
                  Continua
                </Button>
              </Box>
            ) : (
              // For Step 1, display the buttons with a spacer before the right-most group.
              <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                <Button variant="outlined" onClick={handleBack}>
                  Indietro
                </Button>
                {/* Add a flexible spacer to push subsequent buttons to the right */}
                <Box sx={{ flexGrow: 1 }} />
                <Button
                  type="button"
                  variant="contained"
                  onClick={() => handleSubmit()}
                >
                  Chiudi e Invia
                </Button>

              </Box>
            )}
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default AreaCreationWizard;
