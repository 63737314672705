// themes/MUI.theme.js
import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import { Grow } from '@mui/material';

let theme = createTheme({
  typography: {
    fontFamily: 'Source Sans Pro, sans-serif', // Default font for the entire app
    fontWeight: 400,
    fontSize: 17,
    lineHeight: 1.6,
    color: '#000000e3',
    h1: {
      fontFamily: 'Montserrat, sans-serif', // Specific font for headings
      fontWeight: 500,
      fontSize: '3.53rem',
      lineHeight: 1.4,
      color: '#000000e3',
    },
    h2: {
      fontFamily: 'Montserrat, sans-serif',
      fontWeight: 500,
      fontSize: '2.35rem',
      lineHeight: 1.25,
      color: '#000000e3',
    },
    h3: {
      fontFamily: 'Montserrat, sans-serif',
      fontWeight: 500,
      fontSize: '1.88rem',
      lineHeight: 1.2,
      color: '#000000e3',
    },
    h4: {
      fontWeight: 550,
      fontSize: '1.41rem',
      lineHeight: 1.2,
      color: '#000000e3',
    },
    h5: {
      fontWeight: 550,
      fontSize: '1.18rem',
      lineHeight: 1.2,
      color: '#000000e3',
    },
    h6: {
      fontWeight: 550,
      fontSize: '1rem',
      lineHeight: 1.25,
      color: '#000000e3',
    },
    button: {
      fontWeight: 550,
      fontSize: 15,
      lineHeight: 1.6,
      textTransform: 'none',
    },
    body1: {
      fontWeight: 500,
      fontSize: 15,
      lineHeight: 1.6,
      color: '#000000e3',
    },
    body2: {
      fontWeight: 500,
      fontSize: 13,
      lineHeight: 1.6,
      color: '#000000e3',
    },
  },
  palette: {
    primary: {
      main: '#3e3effe3',
    },
    secondary: {
      main: '#ff6a3ee3',
      contrastText: '#ffffff',
    },
    success: {
      main: '#2e7d32',
      contrastText: '#ffffff',
    },
    error: {
      main: '#c62828',
      contrastText: '#ffffff',
    },
    info: {
      main: '#1565c0',
      contrastText: '#ffffff',
    },
    warning: {
      main: '#ff8f00',
      contrastText: '#ffffff',
    },
    black: {
      main: '#000000e3',
    },
    dark: {
      main: '#1f1f1f',
    },
    darker: {
      main: '#121212',
    },
    darkgrey: {
      main: '#00000095',
    },
    grey: {
      main: '#00000065',
    },
    lightgrey: {
      main: '#00000025',
    },
    light: {
      main: '#EEEEEEE3',
    },
    lightgreen: {
      main: '#b2f79abb',
    }
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '5px',
        },
      },
      variants: [
        {
          props: { variant: 'rounded' },
          style: ({ theme }) => ({
            borderRadius: '30px',
            padding: '12px 24px',
            fontSize: '1.0rem',
            textTransform: 'none',
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
            '&:hover': {
              backgroundColor: theme.palette.primary.dark,
            },
            '&:disabled': {
              backgroundColor: theme.palette.light.main,
              color: theme.palette.grey.main,
            },
          }),
        },
      ],
      defaultProps: {
        // We can also set default props here, e.g.:
        // size: 'medium',
        // variant: 'contained',
      },
    },
    MuiAlert: {
      defaultProps: {
        variant: 'outlined',
      },
      styleOverrides: {
        root: ({ theme }) => ({
          borderRadius: 10,
        }),
        // Override specific variants to use palette colors:
        standardSuccess: ({ theme }) => ({
          backgroundColor: theme.palette.success.light,
          color: theme.palette.success.main,
        }),
        standardError: ({ theme }) => ({
          backgroundColor: theme.palette.error.light,
          color: theme.palette.error.main,
        }),
        standardInfo: ({ theme }) => ({
          backgroundColor: theme.palette.info.light,
          color: theme.palette.info.main,
        }),
        standardWarning: ({ theme }) => ({
          backgroundColor: theme.palette.warning.light,
          color: theme.palette.warning.main,
        }),
      },
    },
    // Add default props for TextField:
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiInputBase-root': {
            height: '50px', // Set a default height for the input area
          },
        },
      },
      defaultProps: {
        InputLabelProps: {
          shrink: true, // always render the label in its shrunk state
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          fontSize: '0.85rem', // Set a smaller font size for the input text
          '&::placeholder': {
            fontSize: '0.85rem', // Apply the same size to the placeholder
            opacity: 0.65, // Ensure the placeholder is fully visible
          },
          // Override Chrome's autofill styles:
          '&:-webkit-autofill, &:-webkit-autofill:hover, &:-webkit-autofill:focus, &:-webkit-autofill:active': {
            // Use your desired background color, here white is assumed.
            WebkitBoxShadow: '0 0 0 1000px white inset !important',
            boxShadow: '0 0 0 1000px white inset !important',
            transition: 'background-color 5000s ease-in-out 0s',
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: '0.9rem', // Set a smaller font size for the label
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          fontSize: '0.8rem', // Customize the font size for helper text
          // Optionally, for error state:
          ...(ownerState.error && {
            color: theme.palette.error.main,
          }),
        }),
      },
    },
    MuiMenu: {
      defaultProps: {
        TransitionComponent: Grow,
        TransitionProps: { timeout: 300 },
        anchorOrigin: { vertical: 'bottom', horizontal: 'right' },
        transformOrigin: { vertical: 'top', horizontal: 'right' },
      },
      styleOverrides: {
        paper: {
          borderRadius: 4,
          boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)',
        },
      },
    },
    MuiDrawer: {
      defaultProps: {
        // Set global transition duration for Drawer animations
        transitionDuration: { enter: 300, exit: 300 },
      },
      styleOverrides: {
        paper: {
          // Responsive width based on breakpoints:
          width: {
            xs: '88%',
            sm: '65%',
            md: '50%',
            lg: '35%',
            xl: '600px',
          },
          // Optional: additional custom CSS transition
          transition: 'width 0.3s ease',
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: ({ theme }) => ({
          [theme.breakpoints.down('sm')]: {
            width: '100%',
            height: '100%',
            margin: 0,
            maxWidth: '100%',
            maxHeight: '100%',
            borderRadius: 0,
          },
        }),
      },
    },
  },
});

// Apply responsive font scaling to ensure fonts adjust to screen sizes.
theme = responsiveFontSizes(theme);

export default theme;
