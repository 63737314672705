// TeamManagement.jsx
import React, { useState, useEffect } from 'react';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
  Paper, Button, Drawer, Typography, TextField, MenuItem, Select, Box,
  InputLabel, FormControl, IconButton, Alert, Card, CardContent
} from '@mui/material';
import EditNoteRoundedIcon from '@mui/icons-material/EditNoteRounded';
import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded';
import {
  addStaffMember, fetchUserRestaurants, fetchRoles,
  fetchStaffMembers, deleteStaffMember, updateStaffMember
} from '../../services/apiService';
import { useMembership } from '../../hooks/useMembership';
import { useAlert } from '../../contexts/AlertContext';
import CustomLoader from '../Common/CustomLoader';
import ConfirmationDialog from '../Common/ConfirmationDialog';
import team from '../../assets/icons/team.png';
import app_store from '../../assets/icons/app-store.png';
import google_play from '../../assets/icons/google-play.png';
import { useResponsive } from '../../hooks/useResponsive';
import theme from '../../themes/MUI.theme';

const TeamManagement = () => {
  const membership = useMembership();
  const { showToast } = useAlert();
  const { isMobile } = useResponsive();

  const [teamMembers, setTeamMembers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  // Drawer state for adding staff
  const [isAddDrawerOpen, setIsAddDrawerOpen] = useState(false);

  // Drawer state for editing staff
  const [isEditDrawerOpen, setIsEditDrawerOpen] = useState(false);

  // Add Staff form states
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [selectedRoleForForm, setSelectedRoleForForm] = useState('');
  const [selectedRestaurantForForm, setSelectedRestaurantForForm] = useState('');

  // Edit Staff form states
  const [selectedEditMember, setSelectedEditMember] = useState(null);
  const [editFirstName, setEditFirstName] = useState('');
  const [editLastName, setEditLastName] = useState('');
  const [editRole, setEditRole] = useState('');

  const [roles, setRoles] = useState([]);
  const [localUserRestaurants, setLocalUserRestaurants] = useState([]);

  // New states for deletion confirmation
  const [deleteDialogVisible, setDeleteDialogVisible] = useState(false);
  const [memberToDelete, setMemberToDelete] = useState(null);

  // Load data only if the membership has POS access
  useEffect(() => {
    if (!membership.hasOrders) return;

    const loadData = async () => {
      try {
        const [restaurants, rolesData, staffMembers] = await Promise.all([
          fetchUserRestaurants(),
          fetchRoles(),
          fetchStaffMembers(),
        ]);

        setLocalUserRestaurants(
          restaurants.map((restaurant) => ({
            value: restaurant.id,
            label: restaurant.name,
          }))
        );

        setRoles(
          rolesData.map((role) => ({
            value: role.id,
            label: role.name,
          }))
        );

        setTeamMembers(staffMembers);
      } catch (error) {
        console.error('Errore nel caricamento dei dati', error);
      } finally {
        setIsLoading(false);
      }
    };

    loadData();
  }, [membership.hasOrders]);

  // Conditionally render upgrade message if no POS access
  if (membership.isLoading) {
    return <CustomLoader />;
  }
  if (!membership.hasOrders) {
    return (
      <Box style={{ padding: '10%', textAlign: 'center' }}>
        <Typography variant="h5" gutterBottom>
          Il tuo piano attualmente non ha accesso alle funzioni POS.<br />
          Esegui un upgrade per sbloccarne la funzionalità.
        </Typography>
        <Button
          variant="contained"
          color="primary"
          size="medium"
          href="/subscription-plans"
          style={{ marginTop: '20px' }}
        >
          Maggiori informazioni
        </Button>
      </Box>
    );
  }

  // Show loader while loading data
  if (isLoading) {
    return <CustomLoader />;
  }

  // Handlers for Add Staff drawer
  const handleAddDrawerOpen = () => setIsAddDrawerOpen(true);
  const handleAddDrawerClose = () => {
    setIsAddDrawerOpen(false);
    setFirstName('');
    setLastName('');
    setSelectedRestaurantForForm('');
    setSelectedRoleForForm('');
  };

  // Handlers for Add Staff form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    const staffData = {
      first_name: firstName,
      last_name: lastName,
      restaurant_id: selectedRestaurantForForm,
      role: selectedRoleForForm,
    };

    try {
      await addStaffMember(staffData);
      showToast({
        message: 'Membro dello staff aggiunto con successo',
        variant: 'success'
      });
      handleAddDrawerClose();
      const updatedStaffMembers = await fetchStaffMembers();
      setTeamMembers(updatedStaffMembers);
    } catch (error) {
      showToast({
        message: 'Errore nell\'aggiungere membro dello staff',
        variant: 'error'
      });
      console.error('Errore nell\'aggiungere il membro staff:', error);
    }
  };

  // --- Deletion Handlers ---
  // Instead of immediately deleting, show the confirmation dialog.
  const handleDeleteClick = (id) => {
    setMemberToDelete(id);
    setDeleteDialogVisible(true);
  };

  const handleDeleteConfirm = async () => {
    try {
      await deleteStaffMember(memberToDelete);
      showToast({
        message: 'Membro staff eliminato con successo',
        variant: 'success'
      });
      const updatedStaffMembers = await fetchStaffMembers();
      setTeamMembers(updatedStaffMembers);
    } catch (error) {
      showToast({
        message: 'Errore nell\'eliminare il membro staff',
        variant: 'error'
      });
      console.error('Errore nell\'eliminare il membro staff:', error);
    } finally {
      setDeleteDialogVisible(false);
      setMemberToDelete(null);
    }
  };

  const handleDeleteCancel = () => {
    setDeleteDialogVisible(false);
    setMemberToDelete(null);
  };

  // Handlers for Edit Staff drawer
  const handleEdit = (member) => {
    setSelectedEditMember(member);
    setEditFirstName(member.first_name);
    setEditLastName(member.last_name);
    const role = roles.find((r) => r.label === member.role);
    setEditRole(role ? role.value : '');
    setIsEditDrawerOpen(true);
  };
  const handleEditDrawerClose = () => {
    setIsEditDrawerOpen(false);
    setSelectedEditMember(null);
    setEditFirstName('');
    setEditLastName('');
    setEditRole('');
  };
  const handleEditSubmit = async () => {
    try {
      await updateStaffMember(selectedEditMember.id, {
        first_name: editFirstName,
        last_name: editLastName,
        role_id: editRole
      });
      showToast({
        message: 'Membro staff aggiornato con successo',
        variant: 'success'
      });
      const updatedStaffMembers = await fetchStaffMembers();
      setTeamMembers(updatedStaffMembers);
      handleEditDrawerClose();
    } catch (error) {
      showToast({
        message: 'Errore nell\'aggiornare il membro staff',
        variant: 'error'
      });
      console.error('Errore nell\'aggiornare il membro staff:', error);
    }
  };

  // Render main content conditionally based on team members length
  const renderContent = () => {
    if (teamMembers.length === 0) {
      return (
        <Box display="flex" flexDirection="column" height="85vh" justifyContent="space-evenly">
          <Box sx={{ px: {xs: '2%', sm: '5%', md: '7.5%', lg: '10%'}, textAlign: 'center' }}>
            <Typography variant="h3" gutterBottom>
              Aggiungi membri del tuo staff
            </Typography>
            <Typography variant="subtitle2" color="grey" gutterBottom>
              Crea le utenze per il nostro gestionale smart
            </Typography>
            <Box sx={{ my: 1 }}>
              <Box component="img" src={team} alt="Team Icon" sx={{ height: 75, opacity: 0.75 }} />
            </Box>
            <Button variant="rounded" color="primary" onClick={handleAddDrawerOpen}>
              Aggiungi staff
            </Button>
          </Box>
          <Box display="flex" flexDirection="column" alignItems="center" gap={2}>
            <Box display="flex" gap={4}>
              <Box component="img" src={app_store} alt="App per iOS" sx={{ height: 35 }} />
              <Box component="img" src={google_play} alt="App per Android" sx={{ height: 35 }} />
            </Box>
            <Typography variant="caption" color="grey">
              Dsponibile su App Store e Google Play
            </Typography>
          </Box>
        </Box>
      );
    }

    // If there are team members, render based on screen size:
    if (isMobile) {
      // Mobile view: Render each member as a card
      return (
        <>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px' }}>
            <Alert severity="info" sx={{ fontSize: { xs: '12px', sm: '14px' }, fontWeight: 500, py: 0 }}>
              Il PIN per il primo accesso è <b>0000</b>
            </Alert>
            <Button variant="contained" color="primary" onClick={handleAddDrawerOpen}>
              Aggiungi
            </Button>
          </Box>
          {teamMembers.map((member) => (
            <Card key={member.id} sx={{ minHeight: '165px', width: '100%', mb: 2 }}>
              <CardContent sx={{ position: 'relative' }}>
                <Typography variant="h6" sx={{ mb: 2 }} >
                  {member.first_name} {member.last_name}
                </Typography>
                <Typography variant="body2">Nome Utente: {member.unique_identifier}</Typography>
                <Typography variant="body2">Ruolo: {member.role}</Typography>
                <Typography variant="body2">Ristorante: {member.restaurant}</Typography>
                <Box sx={{ position: 'absolute', mt: 1, bottom: 2, right: 2 }}>
                  <IconButton onClick={() => handleEdit(member)} color="primary">
                    <EditNoteRoundedIcon fontSize="small" />
                  </IconButton>
                  <IconButton onClick={() => handleDeleteClick(member.id)} color="secondary">
                    <DeleteOutlineRoundedIcon fontSize="small" />
                  </IconButton>
                </Box>
              </CardContent>
            </Card>
          ))}
        </>
      );
    } else {
      // Desktop view: Render the table
      return (
        <>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px' }}>
            <Alert severity="info" sx={{ fontWeight: 500, py: 0 }}>
              Il PIN per il primo accesso è <b>0000</b>
            </Alert>
            <Button variant="contained" color="primary" onClick={handleAddDrawerOpen}>
              Aggiungi
            </Button>
          </Box>
          <TableContainer component={Paper} sx={{ overflow: 'hidden' }}>
            <Table>
              <TableHead>
                <TableRow sx={{ backgroundColor: theme.palette.light.main }}>
                  <TableCell sx={{ fontWeight: 550 }}>Nome</TableCell>
                  <TableCell sx={{ fontWeight: 550 }}>Cognome</TableCell>
                  <TableCell sx={{ fontWeight: 550 }}>Nome Utente</TableCell>
                  <TableCell sx={{ fontWeight: 550 }}>Ruolo</TableCell>
                  <TableCell sx={{ fontWeight: 550 }}>Ristorante</TableCell>
                  <TableCell sx={{ fontWeight: 550, textAlign: 'center' }}>Azioni</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {teamMembers.map((member) => (
                  <TableRow key={member.id}>
                    <TableCell>{member.first_name}</TableCell>
                    <TableCell>{member.last_name}</TableCell>
                    <TableCell>{member.unique_identifier}</TableCell>
                    <TableCell>{member.role}</TableCell>
                    <TableCell>{member.restaurant}</TableCell>
                    <TableCell sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                      <IconButton onClick={() => handleEdit(member)} color="primary">
                        <EditNoteRoundedIcon fontSize="small" />
                      </IconButton>
                      <IconButton onClick={() => handleDeleteClick(member.id)} color="secondary">
                        <DeleteOutlineRoundedIcon fontSize="small" />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      );
    }
  };

  return (
    <Box style={{ padding: '20px' }}>
      {renderContent()}

      {/* Drawer for adding a new staff member */}
      <Drawer anchor="right" open={isAddDrawerOpen} onClose={handleAddDrawerClose}>
        <Box sx={{ width: 400, padding: 2 }}>
          <Typography variant="body1" gutterBottom marginBottom="20px">
            Aggiungi Membro Staff
          </Typography>
          <form onSubmit={handleSubmit}>
            <TextField
              label="Nome"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              fullWidth
              required
              margin="normal"
            />
            <TextField
              label="Cognome"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              fullWidth
              required
              margin="normal"
            />
            <FormControl fullWidth margin="normal" required>
              <InputLabel>Seleziona Ristorante</InputLabel>
              <Select
                value={selectedRestaurantForForm}
                onChange={(e) => setSelectedRestaurantForForm(e.target.value)}
                label="Seleziona Ristorante"
                sx={{ height: '50px' }}
              >
                {localUserRestaurants.map((restaurant) => (
                  <MenuItem key={restaurant.value} value={restaurant.value}>
                    {restaurant.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl fullWidth margin="normal" required>
              <InputLabel>Seleziona Ruolo</InputLabel>
              <Select
                value={selectedRoleForForm}
                onChange={(e) => setSelectedRoleForForm(e.target.value)}
                label="Seleziona Ruolo"
                sx={{ height: '50px' }}
              >
                {roles.filter((role) => role.label !== 'Admin').map((role) => (
                  <MenuItem key={role.value} value={role.value}>
                    {role.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Box display="flex" gap={1} marginTop="30px">
              <Button
                type="button"
                variant="outlined"
                color="error"
                fullWidth
                onClick={handleAddDrawerClose}
              >
                Cancella
              </Button>
              <Button type="submit" variant="contained" color="primary" fullWidth>
                Aggiungi Staff
              </Button>
            </Box>
          </form>
        </Box>
      </Drawer>

      {/* Drawer for editing staff member details */}
      <Drawer anchor="right" open={isEditDrawerOpen} onClose={handleEditDrawerClose}>
        <Box sx={{ width: 400, padding: 2 }}>
          <Typography variant="h4" gutterBottom>
            Modifica Membro Staff
          </Typography>
          <TextField
            label="Nome"
            value={editFirstName}
            onChange={(e) => setEditFirstName(e.target.value)}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Cognome"
            value={editLastName}
            onChange={(e) => setEditLastName(e.target.value)}
            fullWidth
            margin="normal"
          />
          <FormControl fullWidth margin="normal">
            <InputLabel>Seleziona Ruolo</InputLabel>
            <Select
              value={editRole}
              onChange={(e) => setEditRole(e.target.value)}
              label="Seleziona Ruolo"
            >
              {roles.filter((role) => role.label !== 'Admin').map((role) => (
                <MenuItem key={role.value} value={role.value}>
                  {role.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: 2 }}>
            <Button onClick={handleEditDrawerClose} color="secondary">
              Annulla
            </Button>
            <Button onClick={handleEditSubmit} color="primary">
              Aggiorna
            </Button>
          </Box>
        </Box>
      </Drawer>

      {/* Confirmation Dialog for deleting a staff member */}
      <ConfirmationDialog
        visible={deleteDialogVisible}
        title="Conferma eliminazione"
        message="Sei sicuro di voler eliminare questo membro dello staff?"
        onConfirm={handleDeleteConfirm}
        onCancel={handleDeleteCancel}
        confirmLabel="Elimina"
        cancelLabel="Annulla"
      />
    </Box>
  );
};

export default TeamManagement;
