import React, { useState } from 'react';
import { PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { Button, Typography, Box } from '@mui/material';
import { updatePaymentMethod } from '../../services/stripeApiService';
import CustomLoader from '../Common/CustomLoader';

const StripePaymentWidget = ({ trialStarted, onPaymentSuccess, onCancel, onError }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [submitting, setSubmitting] = useState(false);
  const [localError, setLocalError] = useState('');
  const [elementComplete, setElementComplete] = useState(false);

  // Track the completion state of the PaymentElement
  const handlePaymentElementChange = (event) => {
    setElementComplete(event.complete);
    if (event.error) {
      setLocalError(event.error.message);
    } else {
      setLocalError('');
    }
  };

  const handleSubmit = async () => {
    // Ensure Stripe.js and the element are ready
    if (!stripe || !elements) {
      const msg = 'Stripe.js is still loading. Please wait.';
      setLocalError(msg);
      onError && onError(msg);
      return;
    }
    // Prevent submission if the payment details are incomplete
    if (!elementComplete) {
      const msg = 'Completa i dettagli della carta.';
      setLocalError(msg);
      onError && onError(msg);
      return;
    }

    setSubmitting(true);
    try {
      const { error, setupIntent } = await stripe.confirmSetup({
        elements,
        confirmParams: {}, // Add additional parameters if needed.
        redirect: 'if_required',
      });

      if (error) {
        const msg = error.message || 'Errore durante la conferma del pagamento.';
        setLocalError(msg);
        onError && onError(msg);
        setSubmitting(false);
        return;
      }

      const paymentMethodId = setupIntent?.payment_method;
      if (!paymentMethodId) {
        const msg = 'Metodo di pagamento non ricevuto da Stripe.';
        setLocalError(msg);
        onError && onError(msg);
        setSubmitting(false);
        return;
      }

      // Update the payment method on the backend.
      const updateResult = await updatePaymentMethod(paymentMethodId);
      if (!updateResult.success) {
        const msg = 'Errore durante l\'aggiornamento del metodo di pagamento sul backend.';
        setLocalError(msg);
        onError && onError(msg);
        setSubmitting(false);
        return;
      }

      // On successful payment confirmation, call the parent callback.
      onPaymentSuccess(paymentMethodId);
    } catch (err) {
      const msg = err.message || 'Errore imprevisto.';
      setLocalError(msg);
      onError && onError(msg);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Box>
      <Typography variant="body1" sx={{ mb: 2 }}>
        Inserisci i dettagli della carta:
      </Typography>
      <Box sx={{ mb: 2 }}>
        <PaymentElement onChange={handlePaymentElementChange} />
      </Box>

      {localError && (
        <Typography variant="body2" color="error" sx={{ mb: 2 }}>
          {localError}
        </Typography>
      )}

      <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 3, gap: 2 }}>
        <Button
          variant="text"
          onClick={onCancel}
          disabled={submitting}
          sx={{ fontSize: '14px', color: 'error.light' }}
        >
          Annulla
        </Button>
        <Button
          variant="contained"
          onClick={handleSubmit}
          disabled={submitting || !elementComplete}
        >
          {submitting ? (
            <CustomLoader size={65} open={true} opaque={true} />
          ) : (
            trialStarted ? 'Ordina' : 'Inizia la prova'
          )}
        </Button>
      </Box>
    </Box>
  );
};

export default StripePaymentWidget;
