// src/components/SubscriptionSuccess.jsx
import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import greenCheck from '../../assets/icons/green-check.png';

const SubscriptionSuccess = () => {
    const navigate = useNavigate();
    const location = useLocation();
    // Default to true if state is missing
    const { isNewSubscription = true } = location.state || {};

  // Set title and message based on the subscription type.
  const title = isNewSubscription ? "Abbonamento attivo!" : "Piano aggiornato!";
  const message = isNewSubscription
    ? 'Grazie e benvenuto! Puoi subito iniziare a usare i moduli sottoscritti.\nClicca "Il mio Piano" per maggiori dettagli.'
    : "Grazie per l'acquisto! Puoi subito iniziare a utilizzare le nuove funzionalità.";

  return (
    <Box
      sx={{
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        p: 3,
        bgcolor: 'background.paper',
      }}
    >
      <Box>
        <img src={greenCheck} alt="Successo Abbonamento" width="75px" />
      </Box>
      <Typography variant="h4" sx={{ mt: 2, mb: 1 }}>
        {title}
      </Typography>
      <Typography
        variant="caption"
        align="center"
        sx={{ mb: 3, whiteSpace: 'pre-line' }} // pre-line preserves newlines
      >
        {message}
      </Typography>
      <Box sx={{ display: 'flex', gap: 2, mt: 4 }}>
        <Button variant="outlined" onClick={() => navigate('/profile')}>
          Il mio Piano
        </Button>
        <Button variant="contained" onClick={() => navigate('/dashboard/my-restaurants')}>
          Vai al Dashboard
        </Button>
      </Box>
    </Box>
  );
};

export default SubscriptionSuccess;
