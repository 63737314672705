// src/components/orders/Devices.jsx

import React, { useState } from 'react';
import {
  Box,
  Typography,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material';
import { useAlert } from '../../../contexts/AlertContext';
import CustomLoader from '../../Common/CustomLoader';

// Example services
import { autoAssignDevices } from '../../../services/stripeApiService';

const Devices = ({ devices, onOrderMore, reloadDevices, selectedRestaurant }) => {
  const [loading, setLoading] = useState(false);
  const { showToast } = useAlert();

  // Handler for auto-assignment
  const handleAutoAssign = async () => {
    if (!devices || devices.length === 0) return;
    try {
      setLoading(true);
      const response = await autoAssignDevices(selectedRestaurant);
      if (response.message) {
        showToast({ message: response.message, variant: 'success' });
      }
      // If you want to refresh devices after auto-assign:
      // const updated = await fetchNfcDevices(selectedRestaurant);
      // reloadDevices(updated);
    } catch (error) {
      console.error('Error auto assigning devices:', error);
      showToast({
        message: 'Errore nell’assegnazione dei dispositivi.',
        variant: 'error'
      });
    } finally {
      setLoading(false);
    }
  };

  if (!devices) {
    return <CustomLoader open={true} />;
  }

  if (devices.length === 0) {
    // The parent typically wouldn't even render this tab if length=0,
    // but let's handle it gracefully
    return (
      <Box textAlign="center" mt={4}>
        <Typography variant="h6">
          Non hai ancora dispositivi NFC
        </Typography>
        <Button
          variant="contained"
          sx={{ mt: 2 }}
          onClick={() => onOrderMore()}
        >
          Ordina dispositivi
        </Button>
      </Box>
    );
  }

  const unassignedCount = devices.filter((d) => !d.table).length;

  return (
    <Box sx={{ mt: 2 }}>
      {loading && <CustomLoader open={true} />}

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          mb: 2
        }}
      >
        <Typography variant="h5">Dispositivi NFC</Typography>
        <Button
          variant="contained"
          onClick={handleAutoAssign}
          disabled={unassignedCount === 0}
          sx={{ fontSize: '13px', p: '6px 9px' }}
        >
          Assegna automaticamente
        </Button>
      </Box>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Dispositivo</TableCell>
              <TableCell>Numero Seriale</TableCell>
              <TableCell>Assegnato a</TableCell>
              <TableCell>Zona</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {devices.map((device) => (
              <TableRow key={device.id}>
                <TableCell>Numero {device.number || '—'}</TableCell>
                <TableCell>{(device.identifier || '').slice(-8)}</TableCell>
                <TableCell>
                  {device.table ? `Tavolo ${device.table.number}` : 'Non assegnato'}
                </TableCell>
                <TableCell>
                  {device.table?.area?.name || 'N/A'}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* If user wants to buy more devices */}
      <Box mt={3} textAlign="right">
        <Button variant="outlined" onClick={() => onOrderMore()}>
          Ordina altri dispositivi
        </Button>
      </Box>
    </Box>
  );
};

export default Devices;
