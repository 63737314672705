// AskTaboMenuDialog.jsx v1.50
import React from 'react';
import TaboChat from '../../Common/TaboChat';
import { createMenuConversation, applyMenuPatch } from '../../../services/apiService';
import { useDailyUsageContext } from '../../../contexts/DailyUsageContext';

const AskTaboMenuDialog = ({ open, onClose, menuId, refreshMenu }) => {
  const { usage, increment } = useDailyUsageContext();

  // Wrap the API call for sending a message
  const handleSend = async (conversation, currentUserMessage) => {
    if (usage.disabled) {
      throw new Error("Hai raggiunto il limite di utilizzo giornaliero");
    }
    const response = await createMenuConversation(menuId, conversation, currentUserMessage);
    increment(); // Increment daily usage counter after the API call.
    return response;
  };

  // Wrap the API call for confirming a patch
  const handleConfirm = async (patchSnapshot) => {
    try {
      if (usage.disabled) {
        throw new Error("Hai raggiunto il limite di utilizzo giornaliero");
      }
      await applyMenuPatch(menuId, { patch: patchSnapshot });
      increment(); // Increment usage
      if (refreshMenu) await refreshMenu();
      onClose(); // Close the dialog after a successful update
    } catch (error) {
      // Let the TaboChat handle showing the error message.
      throw error;
    }
  };

  // Optional cancel handler (if any additional logic is needed)
  const handleCancel = () => {
    // You might perform additional actions on cancel here.
  };

  return (
    <TaboChat
      open={open}
      onClose={onClose}
      title="Tabo Assistant"
      placeholder="Scrivi un messaggio..."
      onSend={handleSend}
      onConfirm={handleConfirm}
      onCancel={handleCancel}
      disabled={usage.disabled}
      tooltip={usage.disabled ? "Hai raggiunto il limite di utilizzo giornaliero" : ""}
    />
  );
};

export default AskTaboMenuDialog;