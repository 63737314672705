import { useState, useEffect, useCallback } from 'react';

const useDailyUsage = (key, limit) => {
  const [usage, setUsage] = useState({
    count: 0,
    disabled: false,
    remaining: limit,
  });

  const getToday = () => new Date().toISOString().split('T')[0];

  const readUsageFromStorage = useCallback(() => {
    const today = getToday();
    try {
      const storedData = localStorage.getItem(key);
      if (storedData) {
        const data = JSON.parse(storedData);
        if (data.date === today) {
          return data.count;
        }
      }
    } catch (e) {
      console.error("Error reading usage data", e);
    }
    return 0;
  }, [key]);

  const updateUsage = useCallback((newCount) => {
    const today = getToday();
    try {
      localStorage.setItem(key, JSON.stringify({ count: newCount, date: today }));
    } catch (e) {
      console.error("Error writing usage data", e);
    }
    setUsage({
      count: newCount,
      disabled: newCount >= limit,
      remaining: limit - newCount,
    });
  }, [key, limit]);

  // Initialize on mount and whenever key or limit changes
  useEffect(() => {
    const currentCount = readUsageFromStorage();
    updateUsage(currentCount);
    // Listen for changes to localStorage from other tabs/windows
    const handleStorage = (event) => {
      if (event.key === key) {
        const newCount = readUsageFromStorage();
        setUsage({
          count: newCount,
          disabled: newCount >= limit,
          remaining: limit - newCount,
        });
      }
    };

    window.addEventListener('storage', handleStorage);
    return () => window.removeEventListener('storage', handleStorage);
  }, [key, limit, readUsageFromStorage, updateUsage]);

  const increment = useCallback(() => {
    updateUsage(usage.count + 1);
  }, [usage.count, updateUsage]);

  return [usage, increment];
};

export default useDailyUsage;
