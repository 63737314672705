// src/components/Subscription/CancelModulesDialog.jsx
import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Box,
  Button,
  Divider,
} from '@mui/material';
import { cancelBundle, cancelModule } from '../../services/stripeApiService';
import ConfirmationDialog from '../Common/ConfirmationDialog';
import { useAlert } from '../../contexts/AlertContext';
import CustomLoader from '../Common/CustomLoader';

const CancelModulesDialog = ({ open, onClose, subscription, refreshProfile }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { showToast } = useAlert();

  // New state to manage the confirmation dialog and store the module to cancel
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [moduleToCancel, setModuleToCancel] = useState(null);

  // Handler for canceling the bundle
  const handleCancelBundle = async () => {
    setLoading(true);
    setError(null);
    try {
      await cancelBundle();
      onClose();
      showToast({
        variant: 'info',
        message: 'Cancellazione confermata',
        duration: 3000
      });
      if (refreshProfile) refreshProfile();
    } catch (err) {
      console.error('Cancellation error:', err);
      setError(err.message || 'Errore durante la cancellazione del bundle');
      showToast({
        variant: 'error',
        message: 'Errore durante la cancellazione del bundle',
        duration: 3000
      });
    } finally {
      setLoading(false);
    }
  };

  // Handler for canceling a module
  const handleCancelModule = async (moduleId) => {
    setLoading(true);
    setError(null);
    try {
      await cancelModule(moduleId);
      onClose();
      showToast({
        variant: 'info',
        message: 'Cancellazione confermata',
        duration: 3000
      });
      if (refreshProfile) refreshProfile();
    } catch (err) {
      console.error('Cancellation error:', err);
      setError(err.message || 'Errore durante la cancellazione del modulo');
      showToast({
        variant: 'error',
        message: 'Errore durante la cancellazione del modulo',
        duration: 3000
      });
    } finally {
      setLoading(false);
    }
  };

  // New handler to trigger the confirmation dialog before module cancellation.
  const handleModuleCancelRequest = (moduleId) => {
    setModuleToCancel(moduleId);
    setConfirmationOpen(true);
  };

  return (
    <>
      <Dialog open={open} onClose={onClose} maxWidth="sm">
        {/* Wrapper for dialog content */}
        <Box sx={{ position: 'relative' }}>
          <DialogTitle sx={{ fontSize: '17px', fontWeight: 600, mb: '30px', color: 'grey' }}>
            Conferma cancellazione moduli
          </DialogTitle>
          <DialogContent>
            {error && (
              <Typography variant="body2" color="error" sx={{ mb: 2 }}>
                {error}
              </Typography>
            )}
            {subscription.bundle ? (
              <>
                <Typography variant="body1">
                  Hai un bundle attivo. Se procedi con l'annullamento perderai da subito tutte le funzionalità dei moduli associati.
                  Sei sicuro di voler procedere?
                </Typography>
                <Divider sx={{ pt: 3 }} />
              </>
            ) : (
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                {subscription.effective_modules
                  .filter((module) => module.name !== "Menu Digitale")
                  .map((module) => (
                    <Box
                      key={module.id}
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        gap: 3,
                        py: 2,
                        borderBottom: '1px solid #ccc',
                      }}
                    >
                      <Typography
                        variant="body1"
                        sx={{
                          fontSize: '13px',
                          color: 'darkgreen',
                          fontWeight: 550,
                        }}
                      >
                        {module.name}
                      </Typography>
                      <Button
                        variant="outlined"
                        color="error"
                        sx={{ fontSize: '11px', p: '4px', fontWeight: 550 }}
                        onClick={() => handleModuleCancelRequest(module.id)}
                        disabled={loading}
                      >
                        Cancella
                      </Button>
                    </Box>
                  ))}
              </Box>
            )}
            <Box sx={{ mt: 3 }}>
              <Typography variant="caption" color="grey">
                La cancellazione dei moduli comporta la sospensione immediata delle relative funzionalità.
                Eventuali rimborsi vengono applicati tramite modalità pro-rata.
              </Typography>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose} disabled={loading}>
              Annulla
            </Button>
            {subscription.bundle && (
              <Button
                onClick={handleCancelBundle}
                color="error"
                variant="text"
                disabled={loading}
              >
                {/* Optionally, you can remove this inline loader if using the overlay */}
                {'Cancella bundle'}
              </Button>
            )}
          </DialogActions>
          {loading && (
            // CustomLoader overlay that covers the dialog content
            <Box
              sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: 'rgba(255, 255, 255, 0.7)',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                zIndex: 10,
              }}
            >
              <CustomLoader />
            </Box>
          )}
        </Box>
      </Dialog>

      {/* Confirmation Dialog for Module Cancellation */}
      <ConfirmationDialog
        visible={confirmationOpen}
        title="Conferma cancellazione"
        message="Procedendo con la cancellazione perderai da subito l'accesso alle funzionalità di moduli selezionati. Eventuali coupon o prove gratuite non potranno più essere utilizzate in seguito."
        onConfirm={async () => {
          await handleCancelModule(moduleToCancel);
          setModuleToCancel(null);
          setConfirmationOpen(false);
          onClose(true);
        }}
        onCancel={() => {
          setModuleToCancel(null);
          setConfirmationOpen(false);
          onClose(true);
        }}
        confirmLabel="Conferma"
        cancelLabel="Annulla cancellazione"
        loading={loading}
      />
    </>
  );
};

export default CancelModulesDialog;
