import React, { useState } from 'react';
import DashboardHeader from './DashboardHeader';
import SideNavBar from './SideNavBar';
import MainContent from './MainContent';
import { Drawer, Box } from '@mui/material';
import { useResponsive } from '../../hooks/useResponsive'; // Adjust the path as needed

const Dashboard = () => {
  const [activeNav, setActiveNav] = useState('');
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  
  // Use our custom hook to get responsive flags
  const { isDesktop } = useResponsive();

  const toggleDrawer = () => setDrawerOpen(!isDrawerOpen);
  const closeDrawer = () => setDrawerOpen(false);

  return (
    <Box display="flex" flexDirection="column" height="100vh">
      <DashboardHeader 
        toggleDrawer={toggleDrawer} 
      />
      <Box display="flex" flexGrow={1} overflow="hidden">
        {/* Permanent sidebar only on desktop */}
        {isDesktop && (
          <Box height="100vh" position="sticky" top={0} flexShrink={0}>
            <SideNavBar setActiveNav={setActiveNav} activeNav={activeNav} />
          </Box>
        )}
        <Box flexGrow={1} overflow="auto">
          <MainContent />
        </Box>
      </Box>
      {/* Mobile/Tablet Drawer for SideNavBar */}
      <Drawer
        anchor="left"
        open={isDrawerOpen}
        onClose={closeDrawer}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
      >
        <SideNavBar 
          setActiveNav={setActiveNav} 
          activeNav={activeNav} 
          closeDrawer={closeDrawer} 
        />
      </Drawer>
    </Box>
  );
};

export default Dashboard;
