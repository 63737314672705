import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, TextField, Button, Typography } from '@mui/material';
import LockResetRoundedIcon from '@mui/icons-material/LockResetRounded';
import { resetPasswordEmail } from '../../services/authApiService';

const PasswordReset = () => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  // Basic regex for validating email format
  const isValidEmail = (email) => {
    const regex = /^\S+@\S+\.\S+$/;
    return regex.test(email);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setError(''); // Clear any existing errors

    if (!email) {
      setError('Please enter your email address');
      return;
    }

    if (!isValidEmail(email)) {
      setError('Please enter a valid email address');
      return;
    }

    try {
      // Trigger the password reset email via the API
      await resetPasswordEmail(email);
      // Redirect on success
      navigate('/password-email-sent');
    } catch (error) {
      if (error.response && error.response.status === 404) {
        setError('The email you have entered does not exist');
      } else {
        setError('An error occurred while trying to reset your password');
      }
    }
  };

  return (
    <Box
      sx={{
        padding: {
          xs: '4% 10% 2% 10%',
          sm: '4% 20% 2% 20%',
          md: '4% 35% 2% 35%',
        },
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Typography variant="h2" gutterBottom>
        No worries.
      </Typography>
      <LockResetRoundedIcon color="primary" sx={{ fontSize: 92, mb: 2 }} />
      <Typography fontSize="large">
        Give us your email below to create a new password:
      </Typography>
      <Box
        component="form"
        onSubmit={handleSubmit}
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <TextField
          fullWidth
          type="email"
          label="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          margin="normal"
          variant="outlined"
          error={Boolean(error)}
          helperText={error}
          autoComplete="email"
        />
        <Button
          type="submit"
          variant="contained"
          color="primary"
          fullWidth
          sx={{ mt: 2 }}
        >
          Next
        </Button>
      </Box>
    </Box>
  );
};

export default PasswordReset;
