import React, { useState, useEffect } from 'react';
import { useMenu } from '../../../contexts/MenuContext';
import { handleMenuCategory } from '../../../services/apiService';
import { TextField, Button, Typography, Box, Switch, FormControl, FormControlLabel, InputLabel, MenuItem, Select, Alert } from '@mui/material';

const courseTypeOptions = [
  { value: 'Drinks', label: 'Bevande' },
  { value: 'Starter', label: 'Antipasti' },
  { value: 'Pasta', label: 'Primi' },
  { value: 'Main Course', label: 'Portata Principale' },
  { value: 'Side Dish', label: 'Contorni' },
  { value: 'Dessert', label: 'Dessert' }
];

const dayOptions = [
  { value: 'MO', label: 'Monday' },
  { value: 'TU', label: 'Tuesday' },
  { value: 'WE', label: 'Wednesday' },
  { value: 'TH', label: 'Thursday' },
  { value: 'FR', label: 'Friday' },
  { value: 'SA', label: 'Saturday' },
  { value: 'SU', label: 'Sunday' }
];

const CategoryForm = ({ context, onClose, editingCategory, addNewCategory, updateCategories }) => {
  const { selectedMenu } = useMenu();
  const [newCategoryName, setNewCategoryName] = useState('');
  const [newCategoryDescription, setNewCategoryDescription] = useState('');
  const [selectedCourseType, setSelectedCourseType] = useState('');
  const [showCategoryDaySelector, setShowCategoryDaySelector] = useState(false);
  const [categoryAvailableDays, setCategoryAvailableDays] = useState([]);

  const resetForm = () => {
    setNewCategoryName('');
    setNewCategoryDescription('');
    setSelectedCourseType('');
    setCategoryAvailableDays([]);
    setShowCategoryDaySelector(false);
  };

  useEffect(() => {
    if (context === 'editCategory' && editingCategory) {
      setNewCategoryName(editingCategory.name || '');
      setNewCategoryDescription(editingCategory.description || '');
      setSelectedCourseType(editingCategory.course_type || '');
      if (editingCategory.available_days && editingCategory.available_days !== 'EVERYDAY') {
        setShowCategoryDaySelector(true);
        setCategoryAvailableDays(editingCategory.available_days.split(','));
      } else {
        setShowCategoryDaySelector(false);
        setCategoryAvailableDays([]);
      }
    } else if (context === 'createCategory') {
      resetForm();
    }
  }, [context, editingCategory]);

  const handleCreateOrUpdateCategory = async (event) => {
    event.preventDefault();
    if (!newCategoryName.trim()) return;

    try {
      const categoryData = {
        name: newCategoryName,
        description: newCategoryDescription,
        menus: [selectedMenu],
        available_days: categoryAvailableDays.length > 0 ? categoryAvailableDays.join(',') : 'EVERYDAY',
        course_type: selectedCourseType,
      };

      if (context === 'editCategory' && editingCategory) {
        const response = await handleMenuCategory(categoryData, editingCategory.id);
        updateCategories(response);
      } else {
        const response = await handleMenuCategory(categoryData);
        addNewCategory(response);
      }

      onClose();
    } catch (error) {
      console.error('Error handling menu category:', error);
    }
  };

  const handleDayChange = (event) => {
    const value = event.target.value;
    setCategoryAvailableDays(typeof value === 'string' ? value.split(',') : value);
  };

  const handleToggleDaySelector = (event) => {
    setShowCategoryDaySelector(event.target.checked);
    if (!event.target.checked) {
      setCategoryAvailableDays([]);
    }
  };

  const handleCourseTypeChange = (event) => {
    setSelectedCourseType(event.target.value);
  };

  return (
    <Box component="form" onSubmit={handleCreateOrUpdateCategory} sx={{ p: 2 }}>
      <Typography
        variant="body1"
        sx={{ fontWeight: 550, mb: 4, mt: 2 }}
      >
        {context === 'editCategory' ? 'Modifica Categoria' : 'Aggiungi Categoria'}
      </Typography>
      <TextField
        fullWidth
        variant="outlined"
        label="Nome"
        placeholder='per es. "Antipasti", "Primi", etc.'
        value={newCategoryName}
        onChange={(e) => setNewCategoryName(e.target.value)}
        margin="normal"
      />
      <TextField
        fullWidth
        variant="outlined"
        label="Descrizione"
        placeholder='per es. "I secondi vengono serviti con contorno di patate"'
        value={newCategoryDescription}
        onChange={(e) => setNewCategoryDescription(e.target.value)}
        margin="normal"
      />
      <Alert severity='info' sx={{ mt: 4 }} >
        <b>Suggerimento</b><br />
        Per i secondi e le pizze, seleziona "Portata Principale".<br />
        Ti aiuteremo a gestire automaticamente l'ordine delle comande.
      </Alert>
      <FormControl fullWidth margin="normal" sx={{ mb: 4 }}>
        <InputLabel>Tipologia</InputLabel>
        <Select
          value={selectedCourseType}
          onChange={handleCourseTypeChange}
          label="Tipologia"
          sx={{ height: "50px" }}
        >
          {courseTypeOptions.map(option => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControlLabel
        control={
          <Switch
            checked={showCategoryDaySelector}
            onChange={handleToggleDaySelector}
            name="showCategoryDaySelector"
            color="primary"
          />
        }
        label="Disponibile solo in alcuni giorni?"
      />
      {showCategoryDaySelector && (
        <FormControl fullWidth margin="normal">
          <InputLabel>Disponibilità</InputLabel>
          <Select
            multiple
            value={categoryAvailableDays}
            onChange={handleDayChange}
            label="Disponibilità"
            renderValue={(selected) => selected.map(value => dayOptions.find(option => option.value === value)?.label).join(', ')}
          >
            {dayOptions.map(option => (
              <MenuItem key={option.value} value={option.value} style={{ fontWeight: categoryAvailableDays.includes(option.value) ? 'bold' : 'normal' }}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
      <Box sx={{ display: 'flex', width: '100%', mt: 4, justifyContent: "flex-end" }}>
        <Button variant="contained" color="primary" type="submit">
          Conferma
        </Button>
      </Box>
    </Box>
  );
};

export default CategoryForm;
