import React, { createContext, useState, useContext, useCallback } from 'react';
import {
  fetchCombinedMenu, fetchMenus, fetchMenuCategories, fetchMenuItemsByCategory,
  fetchCombosByCategory, fetchAllergens, fetchDietGroups
} from '../services/apiService';

const MenuContext = createContext();

export const useMenu = () => useContext(MenuContext);

export const MenuProvider = ({ children }) => {
  // State definitions
  const [menus, setMenus] = useState([]);
  const [selectedMenu, setSelectedMenu] = useState('');
  const [menuCategories, setMenuCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [menuItems, setMenuItems] = useState([]);
  const [selectedMenuItem, setSelectedMenuItem] = useState(null);
  const [combos, setCombos] = useState([]);
  const [allergens, setAllergens] = useState([]);
  const [dietGroups, setDietGroups] = useState([]);
  const [selectedAllergens, setSelectedAllergens] = useState([]);
  const [selectedDietGroups, setSelectedDietGroups] = useState([]);
  const [allergenOptions, setAllergenOptions] = useState([]);
  const [dietGroupOptions, setDietGroupOptions] = useState([]);

  // Function to load menu with its categories, items, allergens, diet groups.
  const loadCombinedMenu = useCallback(async (menuId = null) => {
    try {
      // Call the combined endpoint
      const data = await fetchCombinedMenu(menuId);

      // Update menus (list of all menus)
      setMenus(data.menus);

      // Update the selected menu (if available)
      if (data.selected_menu) {
        setSelectedMenu(data.selected_menu.id.toString());
      } else {
        setSelectedMenu(null);
      }

      // Extract categories from categories_data (each entry has { category, items, combos })
      const categories = data.categories_data.map((cd) => cd.category);
      setMenuCategories(categories);

      // Aggregate all items from every category
      const aggregatedItems = data.categories_data.reduce((acc, cd) => {
        return acc.concat(cd.items);
      }, []);
      setMenuItems(aggregatedItems);

      // Aggregate all combos from every category
      const aggregatedCombos = data.categories_data.reduce((acc, cd) => {
        return acc.concat(cd.combos);
      }, []);
      setCombos(aggregatedCombos);
      // Update allergens and diet groups
      setAllergens(data.allergens);
      setDietGroups(data.diet_groups);
      // Return the categories so the caller can auto-expand them.
      return categories;
    } catch (error) {
      console.error("Error loading combined menu:", error);
    }
  }, [
    setMenus,
    setSelectedMenu,
    setMenuCategories,
    setMenuItems,
    setCombos,
    setAllergens,
    setDietGroups,
  ]);

  // Function to load users menu and select the most recent
  const loadAndSelectLatestMenu = useCallback(async () => {
    const fetchedMenus = await fetchMenus();

    const sortedMenus = fetchedMenus.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
    setMenus(sortedMenus);

    if (sortedMenus.length > 0) {
      setSelectedMenu(sortedMenus[0].id.toString());  // Select the latest menu
      await fetchMenuCategories(sortedMenus[0].id);
      await fetchMenuItemsByCategory(sortedMenus[0].id);
    } else {
      setSelectedMenu(null);
    }
  }, [setMenus, setSelectedMenu]);

  // Function to load categories for the selected menu
  const loadMenuCategories = useCallback(async (selectedMenu) => {
    if (selectedMenu) {
      try {
        const categories = await fetchMenuCategories(selectedMenu);
        setMenuCategories(categories);
      } catch (error) {
        console.error('Error loading menu categories:', error);
        // Handle error appropriately
      }
    }
  }, []); // Add any dependencies if necessary

  // Function to load menu items for the selected menu
  const loadMenuItems = useCallback(async (selectedMenu) => {
    if (selectedMenu) {
      try {
        // Fetch categories for the selected menu
        const categories = await fetchMenuCategories(selectedMenu);
        let allItems = [];

        // Fetch items for each category and aggregate them
        for (const category of categories) {
          const items = await fetchMenuItemsByCategory(category.id);
          allItems = [...allItems, ...items];
        }

        setMenuItems(allItems); // Update state with aggregated items
      } catch (error) {
        console.error('Error loading menu items:', error);
      } finally {
      }
    }
  }, [setMenuItems]);

  // Function to load combos for the selected menu
  const loadCombos = useCallback(async (selectedMenu) => {
    if (selectedMenu) {
      try {
        // Fetch categories for the selected menu
        const categories = await fetchMenuCategories(selectedMenu);
        let allCombos = [];

        // Fetch combos for each category and aggregate them
        for (const category of categories) {
          const combos = await fetchCombosByCategory(category.id);
          allCombos = [...allCombos, ...combos];
        }

        setCombos(allCombos); // Update state with aggregated combos
      } catch (error) {
        console.error('Error loading combos:', error);
      } finally {
      }
    }
  }, [setCombos]); // Dependency array to include any state setters used in the callback

  // Function to fetch allergens and diet groups
  const fetchAllergensAndDietGroups = useCallback(async () => {
    try {
      const allergensData = await fetchAllergens();
      const dietGroupsData = await fetchDietGroups();
      setAllergens(allergensData);
      setDietGroups(dietGroupsData);
    } catch (error) {
      console.error('Error fetching allergens or diet groups:', error);
    }
  }, []);

  // Definitions
  const value = {
    // State declarations
    menus, setMenus,
    selectedMenu, setSelectedMenu,
    menuCategories, setMenuCategories,
    selectedCategory, setSelectedCategory,
    menuItems, setMenuItems,
    selectedMenuItem, setSelectedMenuItem,
    combos, setCombos,
    allergens, setAllergens,
    dietGroups, setDietGroups,
    selectedAllergens, setSelectedAllergens,
    selectedDietGroups, setSelectedDietGroups,
    allergenOptions, setAllergenOptions,
    dietGroupOptions, setDietGroupOptions,
    // State-related functions
    loadCombinedMenu,
    loadAndSelectLatestMenu,
    loadMenuCategories,
    loadMenuItems,
    loadCombos,
    fetchAllergensAndDietGroups,
  };

  return (
    <MenuContext.Provider value={value}>
      {children}
    </MenuContext.Provider>
  );
};
