// authApiService.js
import api from './apiInstance'; // Import the Axios instance with interceptors

const API_BASE_URL = process.env.REACT_APP_API_URL;

// Function to register user
export const registerUser = async (userData) => {
  try {
    const response = await api.post('auth/register/', userData);
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Function to log in a user
export const login = async (credentials) => {
  try {
    const response = await api.post('auth/login/', credentials);
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Function to log out a user
export const logout = async () => {
  try {
    const response = await api.post('auth/logout/');
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Function to get an email to reset password
export const resetPasswordEmail = async (email) => {
  const url = `${API_BASE_URL}auth/password_reset/`;
  return api.post(url, { email }).then(response => response.data);
};

// Function to verify email
export const verifyEmail = (verificationUUID, verificationCode) => {
  return api.post(`${API_BASE_URL}auth/verify_email/`, {
    verification_uuid: verificationUUID,
    verification_code: verificationCode,
  });
};

// Function to resend verification code
export const resendVerificationCode = (verificationUUID) => {
  return api.post(`${API_BASE_URL}auth/resend_verification/`, {
    verification_uuid: verificationUUID,
  });
};

// Function to reset password
export const resetPassword = (resetPasswordEndpoint, newPasswordData) => {
  return api.post(resetPasswordEndpoint, newPasswordData);
};

// Function to update Staff Passcode
export const updateStaffPasscode = async (newPasscode) => {
  const url = `${API_BASE_URL}auth/update-passcode/`;
  const payload = {
    new_passcode: newPasscode
  };

  try {
    const response = await api.post(url, payload);
    return response.data;
  } catch (error) {
    console.error('Error updating passcode:', error.response?.data);
    throw error;
  }
};

// Function to fetch user profile
export const fetchUserProfile = async () => {
  const url = `${API_BASE_URL}auth/user/profile/`;

  try {
    const response = await api.get(url);
    return response.data;
  } catch (error) {
    console.error('Error fetching user profile:', error);
    throw error;
  }
};

// Function to update user's default restaurant
export const updateUserDefaultRestaurant = async (restaurantId) => {
  const url = `${API_BASE_URL}auth/user/profile/`;

  try {
    await api.patch(url, {
      default_restaurant: restaurantId
    });
  } catch (error) {
    console.error('Error updating default restaurant:', error);
    throw error; // Re-throw the error for handling in the component
  }
};
