// Payments.jsx v1.92
import React, { useState, useEffect } from 'react';
import { useMembership } from '../../hooks/useMembership';
import { loadConnectAndInitialize } from '@stripe/connect-js';
import {
  ConnectComponentsProvider,
  ConnectAccountOnboarding,
  ConnectPayments,
  ConnectPayouts,
  ConnectAccountManagement,
  ConnectDocuments,
  ConnectTaxSettings,
  ConnectTaxRegistrations,
  ConnectNotificationBanner,
} from '@stripe/react-connect-js';
import { useRestaurant } from '../../contexts/RestaurantContext';
import { createConnectedAccount, createAccountSession } from '../../services/stripeApiService';
import {
  Box,
  Typography,
  Dialog,
  Select,
  DialogTitle,
  DialogActions,
  DialogContent,
  MenuItem,
  Tabs,
  Tab,
  Button,
  FormControl,
  InputLabel,
  Divider,
} from '@mui/material';
import payment from '../../assets/icons/order-pay.png';
import CustomLoader from '../Common/CustomLoader';

const Payment = () => {
  const membership = useMembership();
  // Restaurant context
  const { userRestaurants, selectedRestaurant, setSelectedRestaurant, restaurantsLoading } = useRestaurant();

  // Local states
  const [connectedAccountId, setConnectedAccountId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [stripeConnectInstance, setStripeConnectInstance] = useState(null);
  const [componentType, setComponentType] = useState('payments');
  const [error, setError] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [accountStatus, setAccountStatus] = useState(null);
  const [onboardingDialogOpen, setOnboardingDialogOpen] = useState(false);
  const [hasInitiatedStripe, setHasInitiatedStripe] = useState(false);

  const noRestaurants = !restaurantsLoading && userRestaurants.length === 0;

  // Set a default restaurant if one is not already selected
  useEffect(() => {
    if (!selectedRestaurant && userRestaurants.length > 0) {
      setSelectedRestaurant(userRestaurants[0].id);
    }
  }, [userRestaurants, selectedRestaurant, setSelectedRestaurant]);

  // Initialize connected account and fetch account status.
  useEffect(() => {
    if (!hasInitiatedStripe || noRestaurants || !selectedRestaurant) return;

    const initializeAccount = async () => {
      try {
        setLoading(true);
        // Create or fetch the connected account for the selected restaurant
        const accountResponse = await createConnectedAccount(selectedRestaurant);
        if (accountResponse.error) {
          console.error('Error creating connected account:', accountResponse.error);
          setError('Error creating connected account');
        } else {
          setConnectedAccountId(accountResponse.account_id);
        }

        // Create a session and get the account status
        const sessionResponse = await createAccountSession(selectedRestaurant);
        setAccountStatus(sessionResponse.account_status);
        setLoading(false);
      } catch (err) {
        console.error('Error initializing account or fetching status:', err);
        setError('Failed to initialize account or fetch status');
        setLoading(false);
      }
    };

    initializeAccount();
  }, [hasInitiatedStripe, noRestaurants, selectedRestaurant]);

  // Initialize the Stripe Connect instance when all necessary data is available.
  useEffect(() => {
    if (
      !hasInitiatedStripe ||
      noRestaurants ||
      !connectedAccountId ||
      !selectedRestaurant ||
      stripeConnectInstance ||
      !accountStatus
    )
      return;

    const initializeStripeConnect = async () => {
      try {
        const stripeInstance = await loadConnectAndInitialize({
          publishableKey: process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY,
          locale: 'it',
          fetchClientSecret: async () => {
            const sessionResponse = await createAccountSession(selectedRestaurant);
            return sessionResponse.client_secret;
          },
          appearance: {
            overlays: 'dialog',
            variables: {
              fontFamily: 'Source Sans Pro, sans-serif',
              colorPrimary: '#3e3eff',
              colorBackground: '#FFFFFF',
              buttonPrimaryColorBackground: '#3e3eff',
              buttonPrimaryColorText: '#FFFFFF',
              buttonPrimaryColorBorder: '#3e3eff',
              buttonSecondaryColorBackground: '#EBEEF1',
              buttonSecondaryColorText: '#3e3eff',
              buttonSecondaryColorBorder: '#EBEEF1',
              buttonBorderRadius: '5px',
            },
          },
        });

        setStripeConnectInstance(stripeInstance);

        // If the account isn't fully enabled, automatically open the onboarding dialog.
        if (!accountStatus.charges_enabled || !accountStatus.payouts_enabled) {
          setOnboardingDialogOpen(true);
        }
      } catch (error) {
        console.error('Failed to initialize Stripe Connect:', error);
        setError('Failed to initialize Stripe Connect');
      }
    };

    initializeStripeConnect();
  }, [hasInitiatedStripe, noRestaurants, connectedAccountId, selectedRestaurant, stripeConnectInstance, accountStatus]);

  // Handle restaurant selection change from the dialog.
  const handleDialogOpen = () => setDialogOpen(true);
  const handleDialogClose = () => setDialogOpen(false);

  const handleRestaurantChange = (event) => {
    const selectedId = event.target.value;
    setSelectedRestaurant(selectedId);
    // Reset instance and account status when changing restaurant.
    setStripeConnectInstance(null);
    setAccountStatus(null);
    setDialogOpen(false);
  };

  // Handle tab change (Payments, Payouts, Account Management).
  const handleTabChange = (event, newValue) => {
    setComponentType(newValue);
  };

  // Handle the closing of the onboarding dialog.
  // If a Stripe account has been created, keep the Stripe UI active.
  // Otherwise, reset to show the introductory UI.
  const handleOnboardingClose = () => {
    if (connectedAccountId) {
      setOnboardingDialogOpen(false);
    } else {
      setOnboardingDialogOpen(false);
      setHasInitiatedStripe(false);
      setStripeConnectInstance(null);
      setAccountStatus(null);
    }
  };

  // ---------- RENDER LOGIC ----------

  if (membership.isLoading || restaurantsLoading || loading) {
    return <CustomLoader open={true} />;
  }

  // Conditional render based on membership:
  if (!membership.hasOrders) {
    return (
      <Box style={{ padding: '10%', textAlign: 'center' }}>
        <Typography variant="h5" gutterBottom>
          Il tuo piano attualmente non ha accesso alle funzioni Pagamenti.<br />
          Esegui un upgrade per sbloccarne la funzionalità.
        </Typography>
        <Button
          variant="contained"
          color="primary"
          size="medium"
          href="/subscription-plans"
          style={{ marginTop: '20px' }}
        >
          Maggiori informazioni
        </Button>
      </Box>
    );
  }



  // Show Introductory UI only if no account exists and the user hasn’t explicitly initiated Stripe onboarding.
  if (!hasInitiatedStripe && !connectedAccountId) {
    return (
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        minHeight="70vh"
        p={3}
      >
        <Box>
          <img
            src={payment}
            alt="Pagamenti digitali"
            style={{ width: '85px', marginBottom: '20px', opacity: 0.75 }}
          />
        </Box>
        <Typography variant="h3" align="center" gutterBottom>
          Tabomenu collabora con{' '}
          <span style={{ color: '#635BFF', fontWeight: 550 }}>
            Stripe
          </span>{' '}
          <br />
          <span style={{ fontSize: '22px' }}>per offrirti pagamenti digitali.</span>
        </Typography>
        <Typography variant="subtitle2" sx={{ mt: 2, color: 'grey' }} align="center" gutterBottom>
          {noRestaurants
            ? 'Crea un ristorante per poter subito cominciare ad accettare pagamenti'
            : 'Crea un profilo e accetta pagamenti sul tuo dispositivo o online. Bastano 5 minuti.'}
        </Typography>
        <Button
          variant="rounded"
          color="primary"
          onClick={() => setHasInitiatedStripe(true)}
          sx={{ mt: 2 }}
          disabled={noRestaurants}
        >
          Cominciamo
        </Button>
      </Box>
    );
  }

  // If there's an error, display it.
  if (error) {
    return (
      <Box p={3}>
        <Typography variant="h6" color="error">
          {error}
        </Typography>
      </Box>
    );
  }

  const isAccountEnabled = accountStatus && accountStatus.charges_enabled && accountStatus.payouts_enabled;

  return (
    <Box>
      {/* Render the Notification Banner */}
      {stripeConnectInstance && (
        <Box sx={{ mt: 2, mx: {xs: 2, sm: 3, md: 4} }}>
          <ConnectComponentsProvider connectInstance={stripeConnectInstance}>
            <ConnectNotificationBanner
              collectionOptions={{
                fields: 'eventually_due',
                futureRequirements: 'include',
              }}
            />
          </ConnectComponentsProvider>
        </Box>
      )}

      {/* Payments Header */}
      <Box
        px={2}
        py={1}
        mx={{ xs: 2, sm: 3, md: 4 }}
        mt={4}
        gap={{ xs: 2, sm: 3, md: 5 }}
        display="flex"
        flexDirection={{ xs: 'column', md: 'row' }}
        justifyContent="space-between"
        borderRadius="10px"
      >
        {/* Restaurant name section: appears first on xs/sm, aligned to right */}
        <Box
          display="flex"
          flexDirection="column"
          alignItems="flex-end"
          sx={{ order: { xs: 1, md: 2 } }}
        >
          <Typography variant="h6">
            {userRestaurants.find((r) => r.id === selectedRestaurant)?.name || 'Ristorante non selezionato'}
          </Typography>
          <Typography
            variant="body2"
            color="primary"
            sx={{ textDecoration: 'underline', cursor: 'pointer' }}
            onClick={handleDialogOpen}
          >
            Cambia
          </Typography>
        </Box>

        {/* Tabs section: appears second on xs/sm; consider making tabs scrollable on smaller screens */}
        <Box
          display="flex"
          justifyContent="flex-start"
          sx={{ mb: { xs: 1, md: 0 }, order: { xs: 2, md: 1 } }}
        >
          {isAccountEnabled ? (
            <Tabs
              value={componentType}
              onChange={handleTabChange}
              aria-label="Stripe Components Tabs"
              variant="scrollable" // makes tabs scrollable on small screens
              scrollButtons="auto"
              sx={{ ml: 0 }}
            >
              <Tab label="Transazioni" value="payments" />
              <Tab label="Bonifici" value="payouts" />
              <Tab label="Profilo" value="account_management" />
              <Tab label="Fiscalità" value="tax_settings" />
              <Tab label="Documenti" value="documents" />
            </Tabs>
          ) : (
            <Box /> // Placeholder when tabs are hidden
          )}
        </Box>
      </Box>

      <Divider sx={{ mx: 4, mb: 5, mt: 1 }} />

      {/* Restaurant selection dialog */}
      <Dialog open={dialogOpen} onClose={handleDialogClose} fullWidth maxWidth="sm">
        <DialogTitle>Seleziona ristorante</DialogTitle>
        <DialogContent>
          <FormControl sx={{ mt: 1 }} variant="outlined" fullWidth>
            <InputLabel id="restaurant-select-label">Ristorante</InputLabel>
            <Select
              labelId="restaurant-select-label"
              value={selectedRestaurant || ''}
              onChange={handleRestaurantChange}
              label="Restaurant"
            >
              {userRestaurants.map((option) => (
                <MenuItem key={option.id} value={option.id}>
                  {option.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="secondary">
            Cancella
          </Button>
        </DialogActions>
      </Dialog>

      {/* Render Stripe components based on the selected tab */}
      <Box
        flexGrow={1}
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{ mx: {xs: 2, sm: 3, md: 4}, overflowY: 'auto' }}

      >
        <ConnectComponentsProvider connectInstance={stripeConnectInstance}>
          {isAccountEnabled && componentType === 'payments' && <ConnectPayments />}
          {isAccountEnabled && componentType === 'payouts' && <ConnectPayouts />}
          {isAccountEnabled && componentType === 'account_management' && <ConnectAccountManagement />}
          {isAccountEnabled && componentType === 'documents' && <ConnectDocuments />}
          {isAccountEnabled && componentType === 'tax_settings' && (
            <Box display="flex" flexDirection="column" width="100%" gap={4}>
              <ConnectTaxSettings />
              <ConnectTaxRegistrations />
            </Box>
          )}
        </ConnectComponentsProvider>
      </Box>

      {/* Onboarding Dialog for incomplete accounts */}
      <Dialog
        open={onboardingDialogOpen}
        onClose={handleOnboardingClose}
        fullWidth
        maxWidth="md"
      >
        <DialogContent>
          <Box>
            <ConnectComponentsProvider connectInstance={stripeConnectInstance}>
              <ConnectAccountOnboarding />
            </ConnectComponentsProvider>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleOnboardingClose} color="secondary">
            Chiudi
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Payment;
