// AIMenuWizard.jsx
import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  Box,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  TextField,
  Alert,
  Checkbox,
  Tooltip,
  IconButton,
  Typography
} from '@mui/material';
import { createMenuWithAI, createMenuWithFile } from '../../../services/apiService';
import CustomLoader from '../../Common/CustomLoader';
import { useAlert } from '../../../contexts/AlertContext';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ArrowCircleUpRoundedIcon from '@mui/icons-material/ArrowCircleUpRounded';
import { useDailyUsageContext } from '../../../contexts/DailyUsageContext';

const AIMenuWizard = ({ open, onClose, onMenuCreated }) => {
  const { showToast } = useAlert();
  const { usage, increment } = useDailyUsageContext();
  // Local state for wizard steps and inputs
  const [activeStep, setActiveStep] = useState(0);
  const [wizardMode, setWizardMode] = useState(null); // 'file' or 'text'

  const [file, setFile] = useState(null);
  const [fileConfirmed, setFileConfirmed] = useState(true);

  // Key to force <input> re-render when the file is cleared
  const [fileInputKey, setFileInputKey] = useState(Date.now());

  const [prompt, setPrompt] = useState('');
  const [loading, setLoading] = useState(false);

  const message = (
    <span style={{ fontSize: '17px' }}>
      I menu creati da Tabo non contengono allergeni. Assicurati di includerli manualmente sulla base delle caratteristiche del prodotto e di potenziali fattori di contaminazione. Per aggiungere un allergene, clicca l'icona{" "}
      <MoreVertIcon sx={{ fontSize: '17px', verticalAlign: 'middle', mx: 0.5 }} /> e poi "Modifica".
    </span>
  );

  // Helper to reset the wizard's state to initial values.
  const resetWizard = () => {
    setActiveStep(0);
    setWizardMode(null);
    setFile(null);
    setFileConfirmed(true);
    setFileInputKey(Date.now());
    setPrompt('');
    setLoading(false);
  };

  // Handler to close the wizard (reset state and then call onClose)
  const handleClose = () => {
    resetWizard();
    onClose();
  };

  /**
   * Handler: Import a menu from a file (PDF/image)
   */
  const handleImportFromFile = async () => {
    if (!file) return;
    setLoading(true);
    try {
      increment(); // 1 api usage
      const responseData = await createMenuWithFile(file);
      if (responseData?.menu) {
        onMenuCreated(responseData.menu);
        showToast({
          message,
          variant: 'warning',
          hasButton: true,
          duration: 120000,
        });
      }
      handleClose();
    } catch (error) {
      console.error('Error creating menu with file:', error);
    } finally {
      setLoading(false);
    }
  };

  /**
   * Handler: Create a menu from the AI text prompt
   */
  const handleCreateMenuFromPrompt = async () => {
    if (!prompt.trim()) return; // No empty prompts
    setLoading(true);
    try {
      increment(); // 1 api usage
      const responseData = await createMenuWithAI(prompt.trim());
      if (responseData?.menu) {
        onMenuCreated(responseData.menu);
        showToast({
          message,
          variant: 'warning',
          hasButton: true,
          duration: 120000,
        });
      }
      handleClose();
    } catch (error) {
      console.error('Error creating menu with AI:', error);
    } finally {
      setLoading(false);
    }
  };

  // Define steps content for the wizard
  const steps = [
    {
      label: 'Importa il menu da file o inizia da una discrezione via chat.',
      content: (
        <Box sx={{ display: 'flex', gap: '5px', flexDirection: 'row', mt: 3 }}>
          <Button
            variant="outlined"
            onClick={() => {
              setWizardMode('file');
              setActiveStep(1);
            }}
          >
            Importa
          </Button>
          <Button
            variant="outlined"
            onClick={() => {
              setWizardMode('text');
              setActiveStep(1);
            }}
          >
            Descrivi
          </Button>
        </Box>
      )
    },
    // Step for file upload (wizardMode === 'file')
    {
      label: 'Allega il tuo menu',
      content: (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <Alert severity="info">
            Se carichi una foto, assicurati che il testo sia ben leggibile.<br />
            Supportiamo i formati PDF, JPEG e PNG.
          </Alert>
          <Button variant="outlined" component="label">
            Carica Menu
            <input
              key={fileInputKey} // Force re-render on each new key
              type="file"
              accept="image/jpeg,image/png,application/pdf"
              hidden
              onChange={(e) => {
                if (e.target.files && e.target.files[0]) {
                  setFile(e.target.files[0]);
                  setFileConfirmed(true);
                }
              }}
            />
          </Button>
          {file && (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Checkbox
                checked={fileConfirmed}
                onChange={(e) => {
                  const isChecked = e.target.checked;
                  setFileConfirmed(isChecked);
                  if (!isChecked) {
                    setFile(null);
                    // Force file input to reset
                    setFileInputKey(Date.now());
                  }
                }}
              />
              <Box>{file.name}</Box>
            </Box>
          )}
          <Tooltip title={usage.disabled ? "Hai raggiunto il limite giornaliero di utilizzo" : ""}>
            <span>
              <Button sx={{ mt: 2, width: '100px' }} variant="contained" onClick={handleImportFromFile} disabled={usage.disabled}>
                Invia
              </Button>
            </span>
          </Tooltip>
        </Box>
      )
    },
    // Step 1 for text mode: instructions
    {
      label: 'Comincia da una descrizione',
      content: (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <Alert severity="info">
            Puoi generare il tuo menu sulla base di informazioni generiche
            o trascrizione dettagliata delle voci. Tabo genererà un modello che potrai in seguito modificare.
          </Alert>
        </Box>
      )
    },
    // Step 2 for text mode: actual prompt
    {
      label: 'Descrizione',
      content: (
        <Box sx={{ position: 'relative', display: 'flex', flexDirection: 'column' }}>
          <Typography sx={{ fontSize: '34px', textAlign: 'center', color: 'gray' }}>
            Creiamo una bozza?
          </Typography>
          <TextField
            placeholder='Ad esempio: "Crea un modello dal nome "Sapori di mare" con 6 antipasti,  5 primi, 8 secondi e qualche dessert. Siamo un trattoria di pesce a Roma. Mantieni i prezzi nella media."'
            multiline
            rows={9}
            value={prompt}
            onChange={(e) => setPrompt(e.target.value)}
            fullWidth
            sx={{
              mt: 2,
              '& .MuiInputBase-root': {
                height: '240px',
                borderRadius: '20px',
              },
              '& .MuiInputBase-input': {
                fontSize: '16px',
                textAlignVertical: 'top',
                padding: '10px',
                paddingRight: '45px',
              },
              '& .MuiInputBase-inputMultiline': {
                height: '100%',
                fontSize: '15px',
                '&::placeholder': {
                  fontSize: '15px', // Increase the placeholder font size as desired
                  lineHeight: '1.55'
                },
              },
            }}
          />
          <IconButton
            onClick={handleCreateMenuFromPrompt}
            disableRipple
            sx={{
              position: 'absolute',
              bottom: 8,
              right: 8,
              '&:hover': {
                backgroundColor: 'transparent',
                '& .MuiSvgIcon-root': { color: 'secondary.light' },
              },
            }}
          >
            <ArrowCircleUpRoundedIcon sx={{ fontSize: '36px', color: 'var(--color-secondary)' }} />
          </IconButton>
        </Box>
      )
    }
  ];

  // Calculate total steps based on the chosen mode
  const totalSteps =
    wizardMode === 'file'
      ? 2 // Step 0 + Step 1 for file
      : wizardMode === 'text'
        ? 3 // Step 0 + Step 1 (instructions) + Step 2 (prompt)
        : 1; // Only step 0 if user hasn't chosen anything

  const handleNext = () => {
    if (activeStep < totalSteps - 1) {
      setActiveStep((prev) => prev + 1);
    }
  };

  const handleBack = () => {
    if (activeStep > 0) {
      setActiveStep((prev) => prev - 1);
    }
  };

  const renderStepContent = (index) => {
    // Step 0 is always the same (mode selection)
    if (index === 0) return steps[0].content;
    if (wizardMode === 'file' && index === 1) {
      return steps[1].content;
    }
    if (wizardMode === 'text') {
      if (index === 1) return steps[2].content; // instructions
      if (index === 2) return steps[3].content; // actual prompt
    }
    return null;
  };

  if (loading) {
    return <CustomLoader
      open={true}
      color="primary"
      size={60}
      text="Stiamo preparando il tuo menu..."
    />;
  }

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
      <DialogTitle>
        {wizardMode === 'file'
          ? 'Importa Menu'
          : wizardMode === 'text'
            ? 'Crea Menu da Descrizione'
            : 'Crea il tuo Menu'}
        <Button
          onClick={handleClose}
          color="secondary"
          sx={{ position: 'absolute', right: 8, top: 8 }}
        >
          Chiudi
        </Button>
      </DialogTitle>
      <DialogContent>
        <Stepper activeStep={activeStep} orientation="vertical">
          {Array.from({ length: totalSteps }).map((_, index) => (
            <Step key={index}>
              <StepLabel>
                {wizardMode === 'file'
                  ? index === 0
                    ? steps[0].label
                    : steps[1].label
                  : wizardMode === 'text'
                    ? // For "text" mode
                    index === 0
                      ? steps[0].label
                      : index === 1
                        ? steps[2].label
                        : steps[3].label
                    : steps[0].label}
              </StepLabel>
              <StepContent>{renderStepContent(index)}</StepContent>
            </Step>
          ))}
        </Stepper>
        {/* Navigation buttons only for text mode */}
        {wizardMode === 'text' && (
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
            <Button disabled={activeStep === 0} onClick={handleBack}>
              Indietro
            </Button>
            {activeStep < totalSteps - 1 && (
              <Button variant="contained" onClick={handleNext}>
                Avanti
              </Button>
            )}
          </Box>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default AIMenuWizard;
