// RestaurantDetails.jsx
import React from 'react';
import { Box, TextField, Typography } from '@mui/material';
import { MuiTelInput } from 'mui-tel-input';

const RestaurantDetails = ({
  restaurantName,
  setRestaurantName,
  description,
  setDescription,
  vatNumber,
  setVatNumber,
  phoneNumber,
  setPhoneNumber,
  errors = {},
}) => {
  return (
    <Box width="100%" paddingX={{ xs: '2%', sm: '4%', md: '8%', lg: '12%' }}>
      {/* Restaurant Name Field */}
      <TextField
        size="medium"
        sx={{ mt: '10px', mb: '20px' }}
        label="Denominazione"
        placeholder="Delize dell'Orto"
        fullWidth
        required
        variant="outlined"
        value={restaurantName}
        onChange={(e) => setRestaurantName(e.target.value)}
        margin="normal"
        error={Boolean(errors.restaurantName)}
        helperText={errors.restaurantName}
      />

      {/* Restaurant Description Field */}
      <TextField
        size="medium"
        sx={{ mt: '10px', mb: '20px' }}
        label="Slogan"
        placeholder="Dall'orto alla tavola"
        fullWidth
        variant="outlined"
        value={description}
        onChange={(e) => setDescription(e.target.value)}
        margin="normal"
        multiline
        error={Boolean(errors.description)}
        helperText={errors.description}
      />

      {/* VAT Number Fields */}
      <Box display="flex" alignItems="flex-start">
        {/* Hardcoded Country Code Field */}
        <TextField
          size="medium"
          sx={{ mt: '10px', mb: '20px', width: 130, mr: 2 }}
          label="Codice paese"
          variant="outlined"
          value="IT"
          disabled
        />
        <TextField
          size="medium"
          sx={{ mt: '10px', mb: '20px' }}
          label="Partita IVA"
          fullWidth
          required
          variant="outlined"
          value={vatNumber}
          onChange={(e) => setVatNumber(e.target.value)}
          margin="normal"
          error={Boolean(errors.vatNumber)}
          helperText={errors.vatNumber}
        />
      </Box>

      {/* Phone Number Field */}
      <Typography variant="body2" color="textSecondary">
        Numero di telefono (opzionale)
      </Typography>
      <MuiTelInput
        size="medium"
        sx={{ mt: '10px', mb: '20px' }}
        fullWidth
        variant="outlined"
        value={phoneNumber}
        defaultCountry="IT"
        onChange={(value) => setPhoneNumber(value)}
        margin="normal"
        error={Boolean(errors.phoneNumber)}
        helperText={errors.phoneNumber}
      />
    </Box>
  );
};

export default RestaurantDetails;
