import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Button,
  Box,
  Divider,
} from '@mui/material';

const ReviewOrderModal = ({
  isOpen,
  onClose,
  orderItems,
  onConfirm,
  onClear,
  showUpdateOrderButton,
}) => {
  if (!isOpen) return null;

  // Calcola il prezzo totale
  const calculateTotal = () => {
    const total = Object.values(orderItems).reduce((courseTotal, course) => {
      return (
        courseTotal +
        Object.values(course).reduce((itemTotal, item) => {
          return itemTotal + item.quantity * item.price;
        }, 0)
      );
    }, 0);
    return total.toFixed(2);
  };

  // Filtra le categorie escludendo "Drinks" per il calcolo dei titoli condizionali
  const nonDrinkCourses = Object.keys(orderItems).filter(
    (course) => course !== 'Drinks'
  );
  const numberOfNonDrinkCourses = nonDrinkCourses.length;

  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant="h5">{showUpdateOrderButton
          ? "Aggiungi all'ordine" : 'Conferma ordine'}
        </Typography>
        <Button onClick={onClose}>Chiudi</Button>
      </DialogTitle>
      <DialogContent dividers>
        {orderItems &&
          (numberOfNonDrinkCourses > 0 || orderItems['Drinks']) ? (
          <>
            {nonDrinkCourses.map((course, index) => (
              <Box key={course} sx={{ mb: 2 }}>
                <Typography variant="h6" sx={{ mt: 2, mb: 1 }}>
                  {numberOfNonDrinkCourses === 1
                    ? 'Il tuo ordine:'
                    : index === 0
                      ? 'Per cominciare:'
                      : index === numberOfNonDrinkCourses - 1
                        ? 'Per finire:'
                        : 'A seguire:'}
                </Typography>
                {Object.entries(orderItems[course]).map(
                  ([dishKey, dishDetails]) => (
                    <Box
                      key={dishKey}
                      sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}
                    >
                      <Typography>{dishDetails.name}</Typography>
                      <Typography>
                        {dishDetails.quantity} × €{dishDetails.price}
                      </Typography>
                      {dishDetails.note && (
                        <Typography variant="body2" color="text.secondary">
                          Note: {dishDetails.note}
                        </Typography>
                      )}
                    </Box>
                  )
                )}
              </Box>
            ))}
            {orderItems['Drinks'] && (
              <Box sx={{ mb: 2 }}>
                <Typography variant="h6" sx={{ mt: 2, mb: 1 }}>
                  Bevande:
                </Typography>
                {Object.entries(orderItems['Drinks']).map(
                  ([drinkKey, drinkDetails]) => (
                    <Box
                      key={drinkKey}
                      sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}
                    >
                      <Typography>{drinkDetails.name}</Typography>
                      <Typography>
                        {drinkDetails.quantity} × €{drinkDetails.price}
                      </Typography>
                      {drinkDetails.note && (
                        <Typography variant="body2" color="text.secondary">
                          Note: {drinkDetails.note}
                        </Typography>
                      )}
                    </Box>
                  )
                )}
              </Box>
            )}
          </>
        ) : (
          <Typography>Nessun articolo</Typography>
        )}
        <Divider sx={{ my: 2 }} />
        <Box sx={{ textAlign: 'right', fontWeight: 'bold', mt: 2 }}>
          Totale: €{calculateTotal()}
        </Box>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          sx={{ mt: 2 }}
          onClick={onConfirm}
        >
          Conferma ordine
        </Button>
        <Button
          variant="outlined"
          color="error"
          fullWidth
          sx={{ mt: 2 }}
          onClick={() => {
            if (onClear) onClear();
            onClose();
          }}
        >
          Svuota selezione
        </Button>
      </DialogContent>
    </Dialog>
  );
};

export default ReviewOrderModal;
