// ReservationWidget v2.11
import React, { useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import {
  Box,
  Typography,
  Stepper,
  Step,
  StepLabel,
  Card,
  CardActionArea,
  CardContent,
  Stack,
  Paper,
  TextField,
  Grid,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { CalendarTodayOutlined, AccessTimeOutlined, PeopleOutlined, CheckCircleOutlined, EventBusyOutlined } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import dayjs from 'dayjs';
import {
  createReservation,
  updateReservation,
  cancelReservation,
  fetchAvailableDates,
  fetchAvailableTimes,
} from '../../services/usersApiService';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import { MuiTelInput } from 'mui-tel-input';
import { useAlert } from '../../contexts/AlertContext';

const steps = ['Ospiti', 'Data', 'Orario', 'Conferma'];

// Custom connector styles
const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 20,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: theme.palette.primary.main,
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: theme.palette.primary.main,
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 2,
    border: 0,
    backgroundColor: '#eaeaf0',
    borderRadius: 1,
  },
}));

// Custom step icon
const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => {
  // Determine border and icon color based on state
  let borderColor = theme.palette.primary.light;
  let iconColor = theme.palette.primary.light;
  if (!ownerState.active && !ownerState.completed) {
    borderColor = theme.palette.grey[400];
    iconColor = theme.palette.grey[400];
  }
  if (ownerState.active) {
    borderColor = theme.palette.secondary.light;
    iconColor = theme.palette.secondary.light;
  }
  // Completed icons keep the primary blue (or you can change this as needed)
  // and add pointer cursor
  return {
    backgroundColor: '#fff', // White background
    border: `2px solid ${borderColor}`,
    zIndex: 1,
    color: iconColor,
    width: 38,
    height: 38,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    ...(ownerState.completed && {
      cursor: 'pointer',
    }),
  };
});

const stepIcons = {
  1: <PeopleOutlined sx={{ fontSize: '18px' }} />,
  2: <CalendarTodayOutlined sx={{ fontSize: '18px' }} />,
  3: <AccessTimeOutlined sx={{ fontSize: '20px' }} />,
  4: <CheckCircleOutlined sx={{ fontSize: '18px' }} />,
};

function ColorlibStepIcon(props) {
  const { active, completed, className } = props;
  return (
    <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
      {stepIcons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
}

const ReservationWidget = ({ reservationData = null, restaurantId }) => {
  const resolvedRestaurant = typeof restaurantId === 'object' ? restaurantId : null;
  const [activeStep, setActiveStep] = useState(0);
  const [formValues, setFormValues] = useState({
    reservation_date: reservationData ? dayjs(reservationData.reservation_date).toDate() : null,
    reservation_time: reservationData ? reservationData.reservation_time : '',
    number_of_guests: reservationData ? reservationData.number_of_guests : '',
    customer_name: reservationData ? reservationData.customer_name : '',
    customer_email: reservationData ? reservationData.customer_email : '',
    special_requests: reservationData ? reservationData.special_requests : '',
  });
  const [availableDates, setAvailableDates] = useState([]);
  const [availableTimes, setAvailableTimes] = useState([]);
  const [selectedArea, setSelectedArea] = useState(null);
  const [availableAreas, setAvailableAreas] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [reservationId, setReservationId] = useState(reservationData ? reservationData.id : null);
  const [reservationStatus, setReservationStatus] = useState(
    reservationData ? reservationData.status : 'pending'
  );

  const resolvedRestaurantId = reservationData
    ? reservationData.restaurant
    : typeof restaurantId === 'object'
      ? restaurantId.id
      : restaurantId;

  const [phoneNumber, setPhoneNumber] = useState('');
  const [showSummary, setShowSummary] = useState(false);
  const theme = useTheme();
  const { showToast } = useAlert();

  const title = reservationData
    ? "Gestisci la prenotazione"
    : `Prenota un tavolo online`;
  const subtitle = reservationData
    ? `Invia le modifiche al ristorante`
    : "Basta un minuto";

  useEffect(() => {
    if (reservationData) {
      setFormValues({
        reservation_date: dayjs(reservationData.reservation_date).toDate(),
        reservation_time: reservationData.reservation_time,
        number_of_guests: reservationData.number_of_guests,
        customer_name: reservationData.customer_name || '',
        customer_email: reservationData.customer_email || '',
        special_requests: reservationData.special_requests || '',
      });
      setReservationId(reservationData.id);
    }
  }, [reservationData]);

  useEffect(() => {
    if (restaurantId && restaurantId.areas) {
      setAvailableAreas(restaurantId.areas);
    }
  }, [restaurantId]);

  // Determine offline condition (only when creating a new reservation)
  const isOffline =
    !reservationData &&
    resolvedRestaurant &&
    resolvedRestaurant.is_reservation_page_published === false;

  // If offline, return the offline message
  if (isOffline) {
    return (
      <Box
        sx={{
          p: 3,
          m: {xs: 1, sm: 2, md: 3},
          textAlign: 'left',
          border: '1px solid #ccc',
          borderRadius: '10px',
          display: 'flex',
          alignItems: 'center'
        }}
      >
        <EventBusyOutlined sx={{ mr: 2, color: theme.palette.secondary.light }}/>
        <Typography variant="h6" color="textSecondary">
          Il ristorante non accetta prenotazioni al momento.<br/>Riprova più tardi.
        </Typography>
      </Box>
    );
  }

  const handleNext = () => {
    setActiveStep((prev) => prev + 1);
  };

  const handleAreaSelection = (area) => {
    setSelectedArea(area); // 'null' for "Tutte le zone"
    // Fetch times with the area filter applied
    fetchAvailableTimes(
      resolvedRestaurantId,
      dayjs(formValues.reservation_date).format('YYYY/MM/DD'),
      formValues.number_of_guests,
      area ? area.id : null
    )
      .then((times) => setAvailableTimes(times))
      .catch((error) => console.error('Errore nel recupero degli orari:', error));
  };

  const handleSelection = async (name, value) => {
    setFormValues((prev) => ({ ...prev, [name]: value }));

    if (name === 'number_of_guests') {
      try {
        const dates = await fetchAvailableDates(resolvedRestaurantId, value);
        setAvailableDates(dates);
      } catch (error) {
        console.error('Errore nel recupero delle date:', error);
      }
      handleNext();
    } else if (name === 'reservation_date') {
      try {
        const times = await fetchAvailableTimes(
          resolvedRestaurantId,
          dayjs(value).format('YYYY/MM/DD'),
          formValues.number_of_guests
        );
        setAvailableTimes(times);
      } catch (error) {
        console.error('Errore nel recupero degli orari:', error);
      }
      handleNext();
    } else {
      handleNext();
    }
  };

  const handleStepClick = (step) => {
    if (step <= activeStep) {
      setActiveStep(step);
    }
  };

  const handleInputChange = (name, value) => {
    setFormValues((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async () => {
    if (!formValues.customer_name || !formValues.customer_email) {
      showToast({
        message: 'Compila tutti i campi obbligatori.',
        variant: 'error',
      });
      return;
    }

    try {
      const reservationPayload = {
        ...formValues,
        restaurant: resolvedRestaurantId,
        reservation_date: dayjs(formValues.reservation_date).format('YYYY-MM-DD'),
        area: selectedArea ? selectedArea.id : null,
      };

      if (reservationId) {
        await updateReservation({ reservationId, updateData: reservationPayload });
        showToast({
          message: 'Prenotazione aggiornata con successo!',
          variant: 'success',
        });
      } else {
        const newReservation = await createReservation(reservationPayload);
        setReservationId(newReservation.id);
        setReservationStatus("pending");
        showToast({
          message: 'Aggiungi un numero di telefono per confermare',
          variant: 'info',
          duration: 3000,
        });
        setShowSummary(true);
        setDialogOpen(true); // Dialog will now appear automatically
      }
    } catch (error) {
      showToast({
        message: 'Impossibile elaborare la prenotazione.',
        variant: 'error',
      });
    }
  };

  const handleConfirmReservation = async () => {
    if (!phoneNumber) {
      showToast({
        message: 'Inserisci un numero di telefono valido.',
        variant: 'error',
      });
      return;
    }

    try {
      await updateReservation({
        reservationId,
        updateData: { customer_phone: phoneNumber, status: 'confirmed' },
      });
      setReservationStatus("confirmed");
      showToast({
        message: 'Prenotazione confermata!',
        variant: 'success',
      });
      setDialogOpen(false);
    } catch (error) {
      const phoneError = error.response?.data?.customer_phone?.[0];
      if (phoneError === "Ensure this field has no more than 20 characters.") {
        showToast({
          message: 'Inserisci un numero di telefono valido',
          variant: 'error',
        });
      } else {
        showToast({
          message: 'Si è verificato un errore.',
          variant: 'error',
        });
      }
    }
  };

  const handleCancelReservation = async () => {
    try {
      await cancelReservation(reservationId);
      showToast({
        message: 'Prenotazione annullata con successo.',
        variant: 'success',
      });
      setReservationId(null);
      setShowSummary(false);
    } catch (error) {
      showToast({
        message: 'Impossibile annullare la prenotazione.',
        variant: 'error',
      });
    }
  };

  const getStepLabel = (step) => {
    switch (step) {
      case 0:
        return formValues.number_of_guests ? `${formValues.number_of_guests} Ospiti` : 'Ospiti';
      case 1:
        return formValues.reservation_date ? dayjs(formValues.reservation_date).format('DD MMM') : 'Data';
      case 2:
        return formValues.reservation_time || 'Orario';
      default:
        return steps[step];
    }
  };

  const renderStepContent = (step) => {
    switch (step) {
      case 0: {
        const guestNumbers = Array.from({ length: 12 }, (_, i) => i + 1);
        return (
          <Box m={2}>
            <Grid container spacing={2}>
              {guestNumbers.map((num) => (
                <Grid item xs={6} sm={4} md={3} key={num}>
                  <Card
                    variant={formValues.number_of_guests === num ? 'outlined' : 'elevation'}
                    onClick={() => handleSelection('number_of_guests', num)}
                  >
                    <CardActionArea>
                      <CardContent>
                        <Typography align="center">{num}</Typography>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Box>
        );
      }
      case 1:
        return (
          <Box
            component={Paper}
            elevation={1}
            width="100%"
            maxWidth="400px"
            sx={{
              display: 'flex',
              justifyContent: 'center',
              margin: '10px auto',
              padding: '10px',
              maxHeight: '340px',
              [theme.breakpoints.down('sm')]: {
                maxWidth: '90%',
              },
            }}
          >
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DateCalendar
                value={formValues.reservation_date}
                onChange={(newDate) => handleSelection('reservation_date', newDate)}
                disablePast
                views={['day']}
                showDaysOutsideCurrentMonth
                shouldDisableDate={(date) =>
                  !availableDates.includes(dayjs(date).format('YYYY-MM-DD'))
                }
              />
            </LocalizationProvider>
          </Box>
        );
      case 2:
        return (
          <Box m={2}>
            <Box mb={2} display="flex" justifyContent="center" gap={1} flexWrap="wrap">
              <Button
                variant={!selectedArea ? 'contained' : 'outlined'}
                sx={{ fontSize: '13px', px: 1, height: '26px' }}
                onClick={() => handleAreaSelection(null)}
              >
                Tutte le zone
              </Button>
              {availableAreas.map((area) => (
                <Button
                  key={area.id}
                  variant={selectedArea && selectedArea.id === area.id ? 'contained' : 'outlined'}
                  sx={{ fontSize: '13px', px: 1, height: '26px' }}
                  onClick={() => handleAreaSelection(area)}
                >
                  {area.name}
                </Button>
              ))}
            </Box>
            <Grid container spacing={2}>
              {availableTimes.map((time) => (
                <Grid item xs={6} sm={4} md={3} key={time}>
                  <Card
                    variant={formValues.reservation_time === time ? 'outlined' : 'elevation'}
                    onClick={() => handleSelection('reservation_time', time)}
                  >
                    <CardActionArea>
                      <CardContent>
                        <Typography align="center">{time}</Typography>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Box>
        );
      case 3:
        return (
          <Box p={2}>
            <TextField
              label="Nome"
              value={formValues.customer_name}
              onChange={(e) => handleInputChange('customer_name', e.target.value)}
              fullWidth
              margin="normal"
              required
            />
            <TextField
              label="Email"
              value={formValues.customer_email}
              onChange={(e) => handleInputChange('customer_email', e.target.value)}
              fullWidth
              margin="normal"
              required
            />
            <TextField
              label="Richieste speciali"
              value={formValues.special_requests}
              onChange={(e) => handleInputChange('special_requests', e.target.value)}
              fullWidth
              margin="normal"
            />
            <Button
              variant="contained"
              color="primary"
              fullWidth
              sx={{ mt: 2 }}
              onClick={handleSubmit}
            >
              {reservationId ? 'Aggiorna Prenotazione' : 'Prenota un Tavolo'}
            </Button>
          </Box>
        );
      default:
        return null;
    }
  };

  const mainContent = showSummary ? (
    <Paper
      elevation={2}
      sx={{ p: 2, borderRadius: '10px' }}
      display="flex"
      flexDirection="column"
      alignItems="center"
      maxWidth="500px"
    >
      <Typography variant="h5" fontWeight={500} color="primary" gutterBottom>
        Riepilogo prenotazione
      </Typography>
      <Typography variant="body1" fontWeight={550} color="secondary" gutterBottom>
        {reservationData ? reservationData.restaurant.name : restaurantId.name}
      </Typography>
      <Typography variant="body1">
        Data: {dayjs(formValues.reservation_date).format('DD MMM YYYY')}
      </Typography>
      <Typography variant="body1">
        Orario: {formValues.reservation_time}
      </Typography>
      <Typography variant="body1">
        Ospiti: {formValues.number_of_guests}
      </Typography>
      <Typography variant="body1">
        Nome: {formValues.customer_name}
      </Typography>
      <Typography variant="body1">
        E-mail: {formValues.customer_email}
      </Typography>
      {formValues.special_requests && (
        <Typography variant="body1">
          Richieste speciali: {formValues.special_requests}
        </Typography>
      )}
      {reservationStatus === "pending" && (
        <Box display="flex" sx={{ mt: 2, height: '20px', flexWrap: { xs: 'wrap', sm: 'noWrap' } }}>
          <Typography variant="body2" sx={{ borderRadius: '6px', color: 'warning.dark', backgroundColor: 'lightyellow', px: 1 }}>
            In sospeso
          </Typography>
          <Button variant="text" sx={{ fontSize: '13px' }} onClick={() => setDialogOpen(true)}>
            Aggiungi numero di telefono per confermare
          </Button>
        </Box>
      )}
      {reservationStatus === "confirmed" && (
        <Box display="flex" sx={{ mt: 2 }}>
          <Typography variant="body2" sx={{ borderRadius: '6px', color: 'success.dark', backgroundColor: 'lightgreen', px: 1 }}>
            Prenotazione confermata
          </Typography>
        </Box>
      )}
      <Button variant="outlined" color="primary" sx={{ mt: { xs: 8, sm: 4 } }} onClick={() => setShowSummary(false)}>
        Modifica
      </Button>
    </Paper>
  ) : (
    <Box
      pb={7}
      position="relative"
      display="flex"
      flexDirection="column"
      alignItems="center"
      minHeight="575px"
      width="100%"
      maxWidth="500px"
      borderRadius="10px"
      boxShadow="0px 4px 6px -1px rgba(0, 0, 0, 0.1)"
    >
      <Box
        sx={{
          width: '100%',
          backgroundColor: 'var(--color-darkgrey)',
          padding: '15px 25px',
          marginBottom: '20px',
          borderRadius: '10px 10px 0 0',
        }}
      >
        <Typography variant="h6" gutterBottom color="white" m={0} p={0}>
          {title}
        </Typography>
        <Typography variant="body" color="white">
          {subtitle}
        </Typography>
      </Box>
      <Stack
        sx={{
          width: '100%',
          overflowX: 'auto',
          '& .MuiStepLabel-label': {
            whiteSpace: 'nowrap',
          },
        }}
        spacing={4}
      >
        <Stepper alternativeLabel activeStep={activeStep} connector={<ColorlibConnector />}>
          {steps.map((label, index) => (
            <Step key={label}>
              <StepLabel StepIconComponent={ColorlibStepIcon} onClick={() => handleStepClick(index)}>
                {getStepLabel(index)}
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      </Stack>
      <Box mt={2}>{renderStepContent(activeStep)}</Box>
      {reservationId && (
        <Box
          width={{ xs: '90%', md: '470px' }}
          position="absolute"
          bottom="10px"
          left="50%"
          sx={{ transform: 'translateX(-50%)' }}
        >
          <Button variant="outlined" color="error" fullWidth onClick={handleCancelReservation}>
            Annulla Prenotazione
          </Button>
        </Box>
      )}
    </Box>
  );

  return (
    <>
      {mainContent}
      <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
        <DialogTitle>Conferma la Prenotazione</DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ mb: 1 }}>
            Aggiungi il tuo numero di telefono per confermare
          </DialogContentText>
          <MuiTelInput
            value={phoneNumber}
            onChange={setPhoneNumber}
            fullWidth
            required
            defaultCountry="IT"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialogOpen(false)}>Annulla</Button>
          <Button onClick={handleConfirmReservation} variant="contained" color="primary">
            Conferma Prenotazione
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ReservationWidget;
