import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_URL;

// Create an Axios instance with base URL and credentials for CSRF
const api = axios.create({
  baseURL: API_BASE_URL,
  withCredentials: true,         // Include cookies in requests
  xsrfCookieName: 'csrftoken',   // Name of the CSRF token cookie
  xsrfHeaderName: 'X-CSRFToken', // Name of the CSRF token header
});

// Refresh token helper function
const refreshAccessToken = async () => {
  try {
    const response = await api.post('auth/token/refresh/');
    if (response.status === 200) {
      return true; // Indicate that the refresh was successful
    } else {
      console.error('Failed to refresh access token:', response.data);
      return false;
    }
  } catch (error) {
    console.error('Failed to refresh access token:', error);
    return false;
  }
};

// Response interceptor to handle token refresh
api.interceptors.response.use(
  response => response,
  async error => {
    const originalRequest = error.config;
    if (error.response && error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true; // Mark as retried
      const refreshSuccessful = await refreshAccessToken();
      if (refreshSuccessful) {
        return api(originalRequest);
      } else {
        // If refresh fails, handle session expiration and avoid further retries.
        handleSessionExpiration();
        return Promise.reject(error);
      }
    }
    return Promise.reject(error);
  }
);

// Handle session expiration by redirecting to login or session expiration page
const handleSessionExpiration = () => {
  const cookieDomain = process.env.REACT_APP_COOKIE_DOMAIN || 'localhost';

  // Clear any client-side state if necessary
  document.cookie = `access_token=; Max-Age=0; path=/; domain=${cookieDomain}; SameSite=Lax`;
  document.cookie = `refresh_token=; Max-Age=0; path=/; domain=${cookieDomain}; SameSite=Lax`;
  
  // Redirect to session expiration or login page
  window.location.href = '/session-expired';
};

export default api;
