// useMembership.js
import { useAuth } from '../contexts/AuthContext';

export const useMembership = () => {
  const { userProfile, isLoading } = useAuth();
  const subscription = userProfile?.subscription || {};
  // Extract module names from objects, or use an empty array if none exist.
  const modules = (subscription.effective_modules || []).map(mod => mod.name);

  // If any module is not the default free module ("Menu Digitale"), consider it paid.
  const hasPaidModule = modules.some(name => name !== 'Menu Digitale');

  return {
    isLoading,
    hasMenuDigitale: modules.includes('Menu Digitale'),
    hasOrders: modules.includes('Ordini e Pagamenti'),
    hasReservations: modules.includes('Prenotazioni'),
    hasKDS: modules.includes('Display Cucina'),
    hasPaidModule,
  };
};
