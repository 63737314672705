import React, { useState, useRef, useEffect } from 'react';
import { Typography, Box, TextField, Button } from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import { verifyEmail, resendVerificationCode } from '../../services/authApiService';
import { useAuth } from '../../contexts/AuthContext';
import { useAlert } from '../../contexts/AlertContext';
import logo from '../../assets/logos/logo_small.png';
import CustomLoader from '../Common/CustomLoader';

const EmailVerification = () => {
  // Extract verification_uuid from URL
  const { verification_uuid } = useParams();
  const navigate = useNavigate();
  const { loadUserProfile, isLoading: authLoading } = useAuth();

  // We no longer need local alert or animation state
  const [code, setCode] = useState(['', '', '', '', '', '']);
  const [isResending, setIsResending] = useState(false);
  const [isVerifying, setIsVerifying] = useState(false);
  const [resendTimer, setResendTimer] = useState(0);
  const inputsRef = useRef([]);

  const { showToast, showAlert } = useAlert();

  // Focus the first input field when the component mounts
  useEffect(() => {
    if (inputsRef.current[0]) {
      inputsRef.current[0].focus();
    }
  }, []);

  // Countdown effect for the resend timer
  useEffect(() => {
    if (resendTimer > 0) {
      const timerId = setInterval(() => {
        setResendTimer(prev => prev - 1);
      }, 1000);
      return () => clearInterval(timerId);
    }
  }, [resendTimer]);

  // Handle changes in the input fields
  const handleChange = (event, index) => {
    const { value } = event.target;
    // Allow only single numeric characters
    if (/^[0-9]{0,1}$/.test(value)) {
      const newCode = [...code];
      newCode[index] = value;
      setCode(newCode);

      // Move focus to the next input field if a digit is entered
      if (value && index < 5) {
        inputsRef.current[index + 1].focus();
      }

      // If the last digit is entered, submit the code
      if (index === 5 && value) {
        submitCode(newCode.join(''));
      }
    }
  };

  // Handle keydown events for navigation (e.g., backspace)
  const handleKeyDown = (event, index) => {
    if (event.key === 'Backspace' && !code[index] && index > 0) {
      inputsRef.current[index - 1].focus();
    }
  };

  // Handle paste events to allow pasting the entire code at once
  const handlePaste = (event, index) => {
    event.preventDefault();
    const pasteData = event.clipboardData.getData('Text').trim();

    // Validate that the pasted data is exactly 6 digits
    if (/^\d{6}$/.test(pasteData)) {
      const pasteDigits = pasteData.split('');
      setCode(pasteDigits);
      // Focus the last input field
      if (inputsRef.current[5]) {
        inputsRef.current[5].focus();
      }
      // Submit the code
      submitCode(pasteData);
    } else {
      // Instead of setting a local alert, show a toast via AlertContext
      showToast({ message: 'Please paste a valid 6-digit verification code.', variant: 'error', duration: 3000 });
      resetForm();
    }
  };

  /**
   * Submit the verification code to the backend.
   * On success, show a full-screen alert (with Lottie animation) via the AlertContext,
   * then redirect. On error, show an error toast.
   * @param {string} verificationCode - The concatenated 6-digit code.
   */
  const submitCode = async (verificationCode) => {
    setIsVerifying(true);
    try {
      const response = await verifyEmail(verification_uuid, verificationCode);
      const { data } = response;

      if (data.status === 'success') {
        // Show an alert that includes a Lottie animation (configured in NotificationContainer)
        showAlert({
          message: 'Ottimo! Procediamo...',
          variant: 'success',
          duration: 1000
        });
        // Load the user profile
        await loadUserProfile();
        // Redirect to subscription page after a short delay
        setTimeout(() => navigate('/subscribe'), 2000);
      } else if (data.status === 'already-verified') {
        showToast({
          message: 'Account già verificato',
          variant: 'info',
          duration: 2500
        });
        setTimeout(() => navigate('/subscribe'), 2000);
      } else {
        showToast({
          message: 'Errore generico. Riprova',
          variant: 'error',
          duration: 2500
        });
        resetForm();
      }
    } catch (error) {
      if (error.response && error.response.data) {
        const { error: errorMessage } = error.response.data;

        if (errorMessage === 'Invalid verification UUID or verification code.') {
          showToast({
            message: 'Il codice di verifica inserito è errato o scaduto',
            variant: 'error',
            duration: 3000
          });
          resetForm();
        } else if (errorMessage === 'Verification code has expired. Please request a new one.') {
          showToast({
            message: 'Il codice di verifica è scaduto. Richiedine un altro',
            variant: 'error',
            duration: 3000
          });
        } else {
          showToast({
            message: errorMessage,
            variant: 'error',
            duration: 3000
          });
        }
      } else {
        showToast({
          message: 'Si è verificato un errore. Riprova più tardi',
          variant: 'error',
          duration: 3000
        });
      }
      console.error('Verification error:', error);
      resetForm();
    } finally {
      setIsVerifying(false);
    }
  };

  // Resend the verification code
  const handleResend = async () => {
    setResendTimer(60);
    setIsResending(true);
    try {
      const response = await resendVerificationCode(verification_uuid);
      const { data } = response;

      if (data.response) {
        showToast({
          message: 'Un nuovo codice di verifica è stato inviato alla tua e-mail',
          variant: 'success',
          duration: 3000
        });
      } else if (data.status === 'already-verified') {
        showToast({
          message: 'Account già verificato',
          variant: 'info',
          duration: 2500
        });
        setTimeout(() => navigate('/subscribe'), 2000);
      } else {
        showToast({
          message: 'Si è verificato un errore. Riprova più tardi',
          variant: 'error',
          duration: 3000
        });
      }
    } catch (error) {
      if (error.response && error.response.data) {
        const { error: errorMessage } = error.response.data;
        showToast({
          message: errorMessage,
          variant: 'error',
          duration: 3000
        });
      } else {
        showToast({
          message: 'Si è verificato un errore. Riprova più tardi',
          variant: 'error',
          duration: 3000
        });
      }
      console.error('Resend verification error:', error);
    } finally {
      setIsResending(false);
    }
  };

  // Reset the input fields and focus the first input
  const resetForm = () => {
    setCode(['', '', '', '', '', '']);
    if (inputsRef.current[0]) {
      inputsRef.current[0].focus();
    }
  };

  if (isVerifying || authLoading) {
    return <CustomLoader open={true} text="Verifica in corso..." />;
  }

  return (
    <Box
      component="form"
      autoComplete="off"
      sx={{
        minHeight: '85vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '20px'
      }}
      onSubmit={(e) => e.preventDefault()} // Prevent form submission on Enter key
    >
      <Box
        sx={{
          width: { xs: '90%', sm: '60%', md: '50%', lg: '30%', xl: '25%' },
          p: { xs: '1', sm: '2', md: '3', lg: '4', xl: '5' },
        }}
      >
        {/* Logo */}
        <Box sx={{ width: '100%', textAlign: 'center', mb: 2 }}>
          <img src={logo} alt="Logo" style={{ width: '50px' }} />
        </Box>

        <Typography variant="h3" textAlign="center" gutterBottom>
          Verifica la tua e-mail
        </Typography>
        <Typography variant="body1" textAlign="center" marginBottom="30px">
          Un codice di 6 cifre è stato inviato alla tua e-mail.<br/>Inseriscilo qui sotto.
        </Typography>

        {/* Verification Code Inputs */}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: 2,
          }}
        >
          {code.map((digit, index) => (
            <TextField
              key={index}
              variant="outlined"
              inputProps={{
                maxLength: 1,
                style: { textAlign: 'center', fontSize: '24px', borderRadius: '20px' },
                'aria-label': `Digit ${index + 1}`,
              }}
              value={digit}
              onChange={(e) => handleChange(e, index)}
              onKeyDown={(e) => handleKeyDown(e, index)}
              onPaste={(e) => handlePaste(e, index)}
              inputRef={(el) => (inputsRef.current[index] = el)}
              sx={{
                width: {
                  xs: '45px', // Mobile
                  sm: '50px', // Tablets and above
                },
                height: {
                  xs: '50px',
                  sm: '60px',
                },
                marginX: 1,
                '& .MuiOutlinedInput-root': {
                  borderRadius: '10px',
                  '& fieldset': {
                    borderColor: '#ccc',
                  },
                  '&:hover fieldset': {
                    borderColor: '#3e3eff',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#3e3eff',
                  },
                },
              }}
            />
          ))}
        </Box>

        {/* Resend Verification Code Button */}
        <Box sx={{ mt: 4, display: 'flex', justifyContent: 'center' }}>
          <Button
            variant="text"
            color="primary"
            onClick={handleResend}
            disabled={isResending || resendTimer > 0}
          >
            {isResending
              ? 'Invio nuovamente...'
              : (resendTimer > 0 ? `Invia di nuova tra ${resendTimer}s` : 'Invia di nuovo')}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default EmailVerification;
