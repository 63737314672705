import React, { useState } from 'react';
import {
  Box,
  Divider,
  Checkbox,
  FormControlLabel,
  IconButton,
  Button,
} from '@mui/material';
import CustomTimePicker from '../../Common/CustomTimePicker';
import AddIcon from '@mui/icons-material/Add';
import DeleteOutlineRounded from '@mui/icons-material/DeleteOutlineRounded';
import { deepCopy } from '../../../utils/deepCopy';

const timeToMinutes = (timeStr) => {
  if (!timeStr || typeof timeStr !== 'string') return null;
  const [hh, mm] = timeStr.split(':');
  return parseInt(hh, 10) * 60 + parseInt(mm, 10);
};

const minutesToTime = (mins) => {
  const hh = String(Math.floor(mins / 60)).padStart(2, '0');
  const mm = String(mins % 60).padStart(2, '0');
  return `${hh}:${mm}`;
};

const MIN_SLOT_GAP = 15; // minutes
const MAX_TIME = "23:30"; // Maximum allowed time for a slot

const ReservationSlots = ({
  reservationHours,
  setReservationHours,
  defaultTimeSlotReservation = { opening_time: '12:00', closing_time: '14:00' },
  handleAddTimeSlot,
  handleRemoveTimeSlot,
  handleDayOpenChange,
}) => {
  // State to control if a global copy (from a day) has been applied,
  // and to store the original state for reset.
  const [globalCopyApplied, setGlobalCopyApplied] = useState(false);
  const [originalReservationHours, setOriginalReservationHours] = useState(null);

  // Helper to convert day index to Italian day name.
  const getDayName = (dayIndex) => {
    const days = [
      'Lunedì',
      'Martedì',
      'Mercoledì',
      'Giovedì',
      'Venerdì',
      'Sabato',
      'Domenica',
    ];
    return days[dayIndex];
  };

  // Handler: copies the slots from the selected day (sourceDayIndex) to all days.
  const handleDayCopy = (sourceDayIndex) => {
    // Save the current state if not already saved.
    if (!originalReservationHours) {
      setOriginalReservationHours(deepCopy(reservationHours));
    }
    const sourceSlots = reservationHours[sourceDayIndex].time_slots;
    setReservationHours((prev) => {
      const updated = deepCopy(prev);
      updated.forEach(day => {
        day.is_open = true; // mark every day as open
        // Deep-copy the slots from the selected day.
        day.time_slots = sourceSlots.map(slot => {
          const { id, ...slotWithoutId } = slot;
          return slotWithoutId;
        });
      });
      return updated;
    });
    setGlobalCopyApplied(true);
  };

  // Reset handler: revert to the original state and clear the copy flag.
  const handleResetCopy = () => {
    if (originalReservationHours) {
      setReservationHours(deepCopy(originalReservationHours));
    }
    setGlobalCopyApplied(false);
  };

  return (
    <Box width="100%" paddingX={{ xs: 1, sm: 1, md: 2, lg: 3 }}>
      {/* Global control: If a copy has been applied, show "Ricomincia" at the very top */}
      <Box display="flex" justifyContent="flex-end" mb={2}>
        {globalCopyApplied && (
          <Button variant="text" onClick={handleResetCopy}>
            Ricomincia
          </Button>
        )}
      </Box>
      {reservationHours.map((day, dayIndex) => (
        <Box key={`day-${day.day_of_week}-${dayIndex}`} mt={2}>
          <Divider sx={{ mb: 2 }} />
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <FormControlLabel
              control={
                <Checkbox
                  size="small"
                  checked={day.is_open}
                  onChange={(e) => handleDayOpenChange(e, dayIndex)}
                  color="primary"
                />
              }
              label={getDayName(day.day_of_week)}
            />
            {/* Show the per-day copy button only if a global copy has not been applied */}
            {!globalCopyApplied && day.is_open && (
              <Button sx={{ fontSize: '12px', fontWeight: 550 }} variant="text" onClick={() => handleDayCopy(dayIndex)}>
                Copia per tutti i giorni
              </Button>
            )}
          </Box>
          {day.is_open &&
            day.time_slots.map((slot, slotIndex) => {
              // Calculate the minimum allowed start time for the current slot.
              let minOpening;
              if (slotIndex === 0) {
                minOpening = "10:00"; // default starting time for the first slot
              } else {
                const prevSlot = day.time_slots[slotIndex - 1];
                const prevClose = prevSlot.closing_time || defaultTimeSlotReservation.closing_time;
                const prevCloseMins = timeToMinutes(prevClose) || 0;
                minOpening = minutesToTime(prevCloseMins + MIN_SLOT_GAP);
              }

              // Determine the minimum allowed closing time based on the opening time.
              const currentOpening = slot.opening_time;
              let minClosing = "00:00";
              if (currentOpening) {
                const openMins = timeToMinutes(currentOpening);
                minClosing = minutesToTime(openMins + MIN_SLOT_GAP);
              } else {
                minClosing = defaultTimeSlotReservation.closing_time;
              }

              // Determine whether to disable the add button.
              const isLastSlot = slotIndex === day.time_slots.length - 1;
              const closingMins = slot.closing_time ? timeToMinutes(slot.closing_time) : null;
              const disableAdd = !isLastSlot || (closingMins !== null && closingMins >= 1435); // 23:55 in minutes

              return (
                <Box
                  key={`day-${day.day_of_week}-slot-${slotIndex}`}
                  display="flex"
                  alignItems="center"
                  mt={2}
                  gap={2}
                  sx={{ flexWrap: { xs: 'wrap', sm: 'nowrap' } }}
                >
                  <CustomTimePicker
                    label="Ora inizio"
                    value={slot.opening_time}
                    onChange={(newValue) => {
                      setReservationHours((prev) => {
                        const updated = deepCopy(prev);
                        updated[dayIndex].time_slots[slotIndex].opening_time = newValue;
                        // Adjust the closing time if it doesn't respect the minimum gap.
                        const openMins = timeToMinutes(newValue);
                        const currentCloseMins = timeToMinutes(updated[dayIndex].time_slots[slotIndex].closing_time);
                        if (!currentCloseMins || currentCloseMins < openMins + MIN_SLOT_GAP) {
                          updated[dayIndex].time_slots[slotIndex].closing_time = minutesToTime(openMins + MIN_SLOT_GAP);
                        }
                        return updated;
                      });
                    }}
                    timeInterval={15}
                    minTime={minOpening}
                    maxTime={MAX_TIME}
                  />
                  <CustomTimePicker
                    label="Ora fine"
                    value={slot.closing_time}
                    onChange={(newValue) => {
                      setReservationHours((prev) => {
                        const updated = deepCopy(prev);
                        updated[dayIndex].time_slots[slotIndex].closing_time = newValue;
                        return updated;
                      });
                    }}
                    timeInterval={15}
                    minTime={minClosing}
                    maxTime={MAX_TIME}
                  />
                  <IconButton
                    onClick={() => handleAddTimeSlot(dayIndex)}
                    color="primary"
                    disabled={disableAdd}
                  >
                    <AddIcon fontSize="small" />
                  </IconButton>
                  {day.time_slots.length > 1 && (
                    <IconButton
                      onClick={() => handleRemoveTimeSlot(dayIndex, slotIndex)}
                      color="secondary"
                    >
                      <DeleteOutlineRounded fontSize="small" />
                    </IconButton>
                  )}
                </Box>
              );
            })}
        </Box>
      ))}
    </Box>
  );
};

export default ReservationSlots;
