import React from 'react';
import { Box, Paper } from '@mui/material';
import { styled } from '@mui/material/styles';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import itLocale from '@fullcalendar/core/locales/it';
import { fetchCalendarReservations } from '../../../services/apiService';

const StyledCalendarWrapper = styled('div')(({ theme }) => ({
  // Style overrides for FullCalendar
  '.fc-button': {
    backgroundColor: `${theme.palette.primary.main} !important`,
    border: `1px solid ${theme.palette.primary.main} !important`,
    color: `${theme.palette.primary.contrastText} !important`,
    borderRadius: '4px',
    fontSize: '0.9rem',
    textTransform: 'none',
  },
  '.fc-button:hover': {
    backgroundColor: `${theme.palette.primary.dark} !important`,
    borderColor: `${theme.palette.primary.dark} !important`,
  },
  '.fc-button:disabled': {
    backgroundColor: `${theme.palette.light.main} !important`,
    borderColor: `${theme.palette.light.main} !important`,
    color: `${theme.palette.grey.main} !important`,
  },
  '.fc-button-active': {
    backgroundColor: `white !important`,
    borderColor: `${theme.palette.primary.main} !important`,
    color: `${theme.palette.primary.main} !important`,
  },
  '.fc-toolbar': {
    marginBottom: theme.spacing(2),
  },
  '.fc-toolbar-title': {
    fontFamily: theme.typography.h4.fontFamily,
    fontSize: theme.typography.h4.fontSize,
    color: theme.palette.black.main,
  },
}));

const Calendar = ({ selectedRestaurant }) => {

  return (
    <Box sx={{ my: 2, p: 0.5 }}>
      <Paper elevation={2} sx={{ p: 2, borderRadius: '8px' }}>
        <StyledCalendarWrapper>
          <FullCalendar
            locale={itLocale}
            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
            initialView="timeGridWeek"
            slotMinTime="08:00:00"
            slotMaxTime="23:30:00"
            headerToolbar={{
              left: 'prev,next today',
              center: 'title',
              right: 'dayGridMonth,timeGridWeek,timeGridDay',
            }}
            slotLabelFormat={{
              hour: '2-digit',
              minute: '2-digit',
              hour12: false
            }}
            allDayText="Orario"
            columnHeaderFormat={{ weekday: 'long', day: 'numeric', month: 'short' }}
            buttonText={{
              today: 'Oggi',
              month: 'Mese',
              week: 'Settimana',
              day: 'Giorno',
            }}
            events={(fetchInfo, successCallback, failureCallback) => {
              const startDate = fetchInfo.startStr.split('T')[0];
              const endDate = fetchInfo.endStr.split('T')[0];
              fetchCalendarReservations(selectedRestaurant, startDate, endDate)
                .then(data => successCallback(data))
                .catch(error => {
                  console.error('Error fetching events:', error);
                  failureCallback(error);
                });
            }}
            height="auto"
          />
        </StyledCalendarWrapper>
      </Paper>
    </Box>
  );
};

export default Calendar;
