// src/components/SubscriptionCancel.jsx
import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import { useNavigate } from 'react-router-dom';

const SubscriptionCancel = () => {
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        p: 3,
        bgcolor: 'background.paper',
      }}
    >
      <CancelIcon color="error" sx={{ fontSize: 80 }} />
      <Typography variant="h4" sx={{ mt: 2, mb: 1 }}>
        Abbonamento Annullato
      </Typography>
      <Typography variant="body1" align="center" sx={{ mb: 3 }}>
        La procedura di pagamento è stata annullata.<br/>Se desideri riprovare, torna indietro e seleziona nuovamente le opzioni di abbonamento.
      </Typography>
      <Button variant="outlined" onClick={() => navigate('/subscribe')}>
        Indietro
      </Button>
    </Box>
  );
};

export default SubscriptionCancel;
