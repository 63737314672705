import React, { useState, useEffect, useMemo } from 'react';
import { Box, Typography, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import { useResponsive } from '../../hooks/useResponsive';
import logo from '../../assets/logo_small.png';
import CancelModulesDialog from './CancelModulesDialog';
import InvoiceListModal from './InvoiceListModal';

// Configuration outside the component.
const billingPeriodMapping = { monthly: 'Mensile', yearly: 'Annuale' };
const statusMapping = {
  trialing: 'Periodo di prova',
  active: 'In corso',
  past_due: 'Scaduto',
  canceled: 'Cancellato',
  unpaid: 'Saldo passivo',
  paused: 'In pausa',
  incomplete_expired: 'non attivo',
};

const UserProfile = () => {
  const { isAuthenticated, userProfile, loadUserProfile } = useAuth();
  const { isMobile, isPortrait } = useResponsive();
  const navigate = useNavigate();
  const [openCancelDialog, setOpenCancelDialog] = useState(false);
  const [openInvoiceModal, setOpenInvoiceModal] = useState(false);

  // Fetch fresh profile data when the component mounts.
  useEffect(() => {
    if (isAuthenticated) {
      loadUserProfile();
    }
  }, [isAuthenticated, loadUserProfile]);

  // Instead of early-returning, we set default values if not loaded.
  const safeUserProfile = userProfile || {};
  const email = safeUserProfile.email || '';
  const subscription = safeUserProfile.subscription || {};
  const {
    billing_period = '',
    effective_modules = [],
    bundle = null,
    total_subtotal = '0,00',
    total_price = '0,00',
    next_billing_date = null,
    status = '',
    stripe_subscription_id = null,
  } = subscription;

  // Memoized computed values.
  const isFree = useMemo(() => parseFloat(total_price) === 0, [total_price]);
  const hasPaidModules = useMemo(() => effective_modules.length > 1, [effective_modules]);
  const formattedNextBillingDate = useMemo(
    () =>
      next_billing_date
        ? new Date(next_billing_date).toLocaleDateString('it-IT', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
          })
        : null,
    [next_billing_date]
  );
  const showInvoicesButton = useMemo(() => stripe_subscription_id && !isFree, [
    stripe_subscription_id,
    isFree,
  ]);

  // Determine if we should show the left logo section.
  const showLeftSection = !isPortrait && !isMobile;

  // Style objects.
  const logoStyles = {
    display: 'block',
    mx: 'auto',
    height: showLeftSection ? '85px' : '50px',
    width: 'auto',
  };

  // If not authenticated or user profile is not available, render a placeholder.
  if (!isAuthenticated || !userProfile) {
    return (
      <Box sx={{ p: 3 }}>
        <Typography variant="h6">
          Effettua il login per visualizzare il tuo profilo.
        </Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ display: 'flex', flexWrap: 'wrap', overflowY: 'auto', height: '100vh' }}>
      {showLeftSection && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 4,
            width: '50%',
            height: '100%',
            bgcolor: 'light.main',
          }}
        >
          <Box component="img" src={logo} alt="Logo" sx={{ mt: '240px', ...logoStyles }} />
          <Typography variant="subtitle2" alignSelf="center" color="darkgrey.main" fontWeight="550">
            Ciò che ti serve. Quando ti serve.
          </Typography>
        </Box>
      )}

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: showLeftSection ? '50%' : '100%',
          justifyContent: 'space-around',
          alignItems: 'flex-start',
        }}
      >
        {/* Render logo on right section if left section is hidden */}
        {!showLeftSection && (
          <Box component="img" src={logo} alt="Logo" sx={{ mt: 3, ...logoStyles }} />
        )}

        {/* Main Subscription Information */}
        <Box sx={{ p: 3, mb: 3, width: '100%' }}>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
            <Button
              variant="text"
              color="primary"
              sx={{ fontSize: '13px' }}
              onClick={() => navigate('/dashboard/my-restaurants')}
            >
              Torna al dashboard
            </Button>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mt: 2 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
              <Typography variant="h4">Il tuo piano</Typography>
              <Typography
                sx={{
                  fontSize: '12px',
                  color: '#FFF',
                  fontWeight: 600,
                  padding: '4px 8px',
                  borderRadius: '8px',
                  backgroundColor: 'secondary.main',
                }}
              >
                {statusMapping[status] || 'Gratuito'}
              </Typography>
            </Box>
            {showInvoicesButton && (
              <Button
                variant="outlined"
                sx={{ fontSize: '12px', px: 1, py: 0.5, fontWeight: 600 }}
                onClick={() => setOpenInvoiceModal(true)}
              >
                Consulta le fatture
              </Button>
            )}
          </Box>

          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: 2 }}>
            {/* E-mail Section */}
            <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
              <Typography variant="body1">E-mail:</Typography>
              <Typography variant="body1" fontWeight={550} color="primary">
                {email}
              </Typography>
            </Box>

            {/* Bundle Section */}
            {bundle && (
              <Box sx={{ width: '100%' }}>
                <Typography variant="h6" sx={{ mt: 2 }}>
                  Bundle:
                </Typography>
                <Box sx={{ display: 'flex', gap: 1, mt: 1, flexWrap: 'wrap' }}>
                  <Typography
                    sx={{
                      fontSize: '14px',
                      fontWeight: 600,
                      padding: '4px 8px',
                      borderRadius: '6px',
                      backgroundColor: 'primary.light',
                      color: 'white',
                    }}
                  >
                    {bundle.name}
                  </Typography>
                </Box>
              </Box>
            )}

            {/* Active Modules Section */}
            <Box sx={{ width: '100%' }}>
              <Typography variant="subtitle1" sx={{ mt: 2 }}>
                Moduli attivi:
              </Typography>
              <Box sx={{ display: 'flex', gap: 1, mt: 1, flexWrap: 'wrap' }}>
                {effective_modules.map((module) => (
                  <Typography
                    key={module.id}
                    sx={{
                      fontSize: '12px',
                      fontWeight: 550,
                      padding: '4px 8px',
                      borderRadius: '6px',
                      backgroundColor: 'lightgreen.main',
                      color: 'darkgreen',
                    }}
                  >
                    {module.name}
                  </Typography>
                ))}
              </Box>
            </Box>
          </Box>

          {/* Price and Billing Section */}
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, mt: 4 }}>
            <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
              <Typography variant="body1" sx={{ color: 'darkgrey.main', fontWeight: 550 }}>Costo totale:</Typography>
              <Typography variant="body1" fontWeight={550} color="primary">
                {isFree ? 'Gratuito' : `${total_subtotal}€ (IVA esclusa)`}
              </Typography>
            </Box>
            {!isFree && (
              <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                <Typography variant="body1" sx={{ color: 'darkgrey.main', fontWeight: 550 }}>Periodo di fatturazione:</Typography>
                <Typography variant="body1" fontWeight={550} color="primary">
                  {billingPeriodMapping[billing_period] || billing_period}
                </Typography>
              </Box>
            )}
            {formattedNextBillingDate && (
              <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                <Typography variant="body1" sx={{ color: 'darkgrey.main', fontWeight: 550 }}>Prossima fatturazione:</Typography>
                <Typography variant="body1" fontWeight={550} color="primary">
                  {formattedNextBillingDate}
                </Typography>
              </Box>
            )}
          </Box>
        </Box>

        {/* Upgrade Section */}
        {!bundle && effective_modules.length !== 4 && (
          <Box sx={{ px: 3, mb: 3 }}>
            <Typography variant="h6" gutterBottom>
              Sfrutta al massimo il potenziale della tua attività
            </Typography>
            <Typography variant="body1" gutterBottom>
              Scegli pacchetti individuali in base alle tue esigenze
            </Typography>
            <Button
              variant="rounded"
              color="primary"
              sx={{ mt: 1, fontSize: '13px', py: 1, px: 2 }}
              onClick={() => navigate('/subscribe')}
            >
              Guarda le opzioni
            </Button>
          </Box>
        )}

        {/* Help Section */}
        <Box sx={{ p: 3 }}>
          <Typography variant="h6" gutterBottom>
            Serve aiuto?
          </Typography>
          <Typography variant="body1">
            Scrivici a{' '}
            <a
              href="mailto:supporto@tabomenu.com"
              style={{ textDecoration: 'none', color: 'primary.main' }}
            >
              supporto@tabomenu.com
            </a>
          </Typography>
          {hasPaidModules && (
            <Button
              color="error"
              sx={{ mt: 3, fontSize: '13px', fontWeight: 500, p: 0 }}
              onClick={() => setOpenCancelDialog(true)}
            >
              Cancella moduli
            </Button>
          )}
        </Box>
      </Box>

      {/* Cancel Modules Dialog */}
      <CancelModulesDialog
        open={openCancelDialog}
        onClose={() => setOpenCancelDialog(false)}
        subscription={subscription}
        isFree={isFree}
      />
      {/* Invoice List Modal */}
      <InvoiceListModal open={openInvoiceModal} onClose={() => setOpenInvoiceModal(false)} />
    </Box>
  );
};

export default UserProfile;
