// src/components/NFCOrders.jsx
import React, { useState, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import { useRestaurant } from '../../../contexts/RestaurantContext';
import { fetchNfcOrders } from '../../../services/stripeApiService';
import CustomLoader from '../../Common/CustomLoader';
import { formatPrice } from '../../../utils/formatPrice';

const NFCOrders = () => {
  const { selectedRestaurant } = useRestaurant();
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(false);

  const statusMapping = {
    pending: 'in sospeso',
    paid: 'pagato',
    shipped: 'spedito',
    delivered: 'consegnato',
    cancelled: 'cancellato',
    failed: 'non approvato',
  };

  // Fetch NFC Orders on component mount or when restaurant changes
  useEffect(() => {
    const loadOrders = async () => {
      if (!selectedRestaurant) return;
      setLoading(true);
      try {
        const data = await fetchNfcOrders(selectedRestaurant);
        setOrders(data);
      } catch (err) {
        console.error('Failed to fetch NFC orders:', err);
      } finally {
        setLoading(false);
      }
    };
    loadOrders();
  }, [selectedRestaurant]);

  return (
    <Box>
      <Typography variant="h5" gutterBottom>
        Ordini NFC
      </Typography>
      {loading ? (
        <CustomLoader open={true} />
      ) : orders.length > 0 ? (
        orders.map((order) => (
          <Box
            key={order.id}
            sx={{
              border: '1px solid #ccc',
              mt: 2,
              borderRadius: '10px',
              overflow: 'hidden',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                backgroundColor: 'light.main',
                px: 2,
                py: 1,
              }}
            >
              <Box>
                <Typography
                  variant="body1"
                  sx={{ color: 'darkgrey.main', fontWeight: 550 }}
                >
                  Ordine effettuato il:
                </Typography>
                <Typography
                  variant="body1"
                  sx={{ color: 'darkgrey.main', fontWeight: 550 }}
                >
                  {new Date(order.created_at).toLocaleDateString()}
                </Typography>
              </Box>
              <Box>
                <Typography
                  variant="body1"
                  sx={{ color: 'darkgrey.main', fontWeight: 550 }}
                >
                  Numero: {order.order_number}
                </Typography>
              </Box>
            </Box>
            <Box sx={{ p: 2 }}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant="body1" sx={{ mr: 1, color: 'dark.main' }}>
                  Stato:
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    backgroundColor: '#b2f79abb',
                    color: 'darkgreen',
                    px: 0.75,
                    borderRadius: '5px',
                    fontWeight: 550,
                  }}
                >
                  {statusMapping[order.status]}
                </Typography>
              </Box>
              <Typography variant="body1" sx={{ color: 'dark.main' }}>
                Quantità dispositivi: {order.quantity}
              </Typography>
              <Typography variant="body1" sx={{ color: 'dark.main' }}>
                Totale: {formatPrice(order.total)}€
              </Typography>
            </Box>
          </Box>
        ))
      ) : (
        <Typography>Nessun ordine presente.</Typography>
      )}
    </Box>
  );
};

export default NFCOrders;
