// AddressDetails.jsx
import React from 'react';
import {
  Box,
  TextField,
  Typography,
  Checkbox,
  FormControlLabel
} from '@mui/material';

const AddressDetails = ({
  legalAddress,
  setLegalAddress,
  sameAsLegalAddress,
  setSameAsLegalAddress,
  restaurantAddress,
  setRestaurantAddress,
  validating = false,
  isUpdating,
}) => {
  return (
    <>
      <Box width="100%" paddingX={{ xs: '2%', sm: '4%', md: '8%', lg: '12%' }}>
        <Typography variant="body2" fontWeight="550">Sede Legale</Typography>

        <TextField
          fullWidth
          label="Indirizzo"
          placeholder="La sede legale della tua attività"
          value={legalAddress.address_line_1}
          onChange={(e) =>
            setLegalAddress({ ...legalAddress, address_line_1: e.target.value })
          }
          margin="normal"
        />
        <TextField
          fullWidth
          label="CAP"
          placeholder="00195"
          value={legalAddress.postal_code}
          onChange={(e) =>
            setLegalAddress({ ...legalAddress, postal_code: e.target.value })
          }
          margin="normal"
        />
        <TextField
          fullWidth
          placeholder="Roma"
          label="Città"
          value={legalAddress.city}
          onChange={(e) =>
            setLegalAddress({ ...legalAddress, city: e.target.value })
          }
          margin="normal"
        />
        <TextField
          fullWidth
          placeholder="RM"
          label="Provincia (opzionale)"
          value={legalAddress.state}
          onChange={(e) =>
            setLegalAddress({ ...legalAddress, state: e.target.value })
          }
          margin="normal"
        />
        {/* Paese hardcoded e disabilitato */}
        <TextField
          fullWidth
          label="Paese"
          variant="outlined"
          value="Italia"
          disabled
          sx={{ mt: 2 }}
        />
      </Box>
      <Box width="100%" sx={{ px: {xs: '2%', sm: '4%', md: '8%', lg: '12%'} }}>
        {!isUpdating ? (
        <FormControlLabel sx={{ mt: 2, mb: 2 }}
          control={
            <Checkbox
              checked={sameAsLegalAddress}
              onChange={(e) => setSameAsLegalAddress(e.target.checked)}
              color="primary"
              size="small"
            />
          }
          label="L'indirizzo del ristorante è uguale alla sede legale"
        />
        ) : (
          <Box sx={{ py: isUpdating ? '20px' : '0px' }} />
        )}
        {!sameAsLegalAddress && (
          <>
            <Typography variant="body2" fontWeight="550">Indirizzo del ristorante</Typography>
            <TextField
              fullWidth
              label="Indirizzo"
              value={restaurantAddress.address_line_1}
              onChange={(e) =>
                setRestaurantAddress({
                  ...restaurantAddress,
                  address_line_1: e.target.value,
                })
              }
              margin="normal"
            />
            <TextField
              fullWidth
              label="CAP"
              value={restaurantAddress.postal_code}
              onChange={(e) =>
                setRestaurantAddress({
                  ...restaurantAddress,
                  postal_code: e.target.value,
                })
              }
              margin="normal"
            />
            <TextField
              fullWidth
              label="Città"
              value={restaurantAddress.city}
              onChange={(e) =>
                setRestaurantAddress({ ...restaurantAddress, city: e.target.value })
              }
              margin="normal"
            />
            <TextField
              fullWidth
              label="Provincia (opzionale)"
              value={restaurantAddress.state}
              onChange={(e) =>
                setRestaurantAddress({
                  ...restaurantAddress,
                  state: e.target.value,
                })
              }
              margin="normal"
            />
            {/* Paese hardcoded e disabilitato */}
            <TextField
              fullWidth
              label="Paese"
              variant="outlined"
              value="Italia"
              disabled
              sx={{ mt: 2 }}
            />
          </>
        )}
      </Box>
    </>
  );
};

export default AddressDetails;
