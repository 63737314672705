// EditRestaurantDrawer.jsx
import React, { useState, useEffect } from 'react';
import {
  Drawer,
  Tabs,
  Tab,
  Box,
  Button,
  Typography,
  Alert,
  Divider,
} from '@mui/material';
import CustomLoader from '../../Common/CustomLoader';

// Import your section components
import RestaurantDetails from './RestaurantDetails';
import AddressDetails from './AddressDetails';
import OpeningHours from './OpeningHours';
import Extras from './Extras';

// Import your API update function
import { updateRestaurant } from '../../../services/apiService';

// Helper: TabPanel component (only renders its children when active)
const TabPanel = (props) => {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`edit-restaurant-tabpanel-${index}`}
      aria-labelledby={`edit-restaurant-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 2 }}>{children}</Box>}
    </div>
  );
};

const EditRestaurantDrawer = ({ open, onClose, restaurant, onUpdate }) => {
  // Local state for the active tab
  const [activeTab, setActiveTab] = useState(0);
  // Loading and error state for the save action
  const [loading, setLoading] = useState(false);
  const [alertMessage, setAlertMessage] = useState(null);
  const [errors, setErrors] = useState({});

  // --- Form states ---
  // Restaurant Details
  const [restaurantName, setRestaurantName] = useState('');
  const [description, setDescription] = useState('');
  const [vatNumber, setVatNumber] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');

  // Address details
  const [legalAddress, setLegalAddress] = useState({
    name: '',
    address_line_1: '',
    postal_code: '',
    city: '',
    state: '',
    country: 'Italy',
  });
  const [sameAsLegalAddress, setSameAsLegalAddress] = useState(true);
  const [restaurantAddress, setRestaurantAddress] = useState({
    name: '',
    address_line_1: '',
    postal_code: '',
    city: '',
    state: '',
    country: 'Italy',
  });

  // Opening hours (initialize with 7 days)
  const [openingHours, setOpeningHours] = useState([
    { day_of_week: 0, is_open: false, time_slots: [{ opening_time: null, closing_time: null }] },
    { day_of_week: 1, is_open: false, time_slots: [{ opening_time: null, closing_time: null }] },
    { day_of_week: 2, is_open: false, time_slots: [{ opening_time: null, closing_time: null }] },
    { day_of_week: 3, is_open: false, time_slots: [{ opening_time: null, closing_time: null }] },
    { day_of_week: 4, is_open: false, time_slots: [{ opening_time: null, closing_time: null }] },
    { day_of_week: 5, is_open: false, time_slots: [{ opening_time: null, closing_time: null }] },
    { day_of_week: 6, is_open: false, time_slots: [{ opening_time: null, closing_time: null }] },
  ]);

  // Image uploads state
  const [logo, setLogo] = useState(null);
  const [coverImage, setCoverImage] = useState(null);
  const [previewLogoUrl, setPreviewLogoUrl] = useState(null);
  const [previewCoverImageUrl, setPreviewCoverImageUrl] = useState(null);

  // A default time slot to be used if none is provided
  const defaultTimeSlotLocal = { opening_time: "12:30", closing_time: "15:00" };

  // --- Initialize the form when the restaurant prop changes ---
  useEffect(() => {
    if (restaurant) {
      setRestaurantName(restaurant.name || '');
      setDescription(restaurant.description || '');
      if (restaurant.vat_number) {
        setVatNumber(restaurant.vat_number.substring(2)); // remove the "IT" prefix
      }
      setPhoneNumber(restaurant.phone_number || '');

      // Addresses
      if (restaurant.legal_address) {
        setLegalAddress(restaurant.legal_address);
      }
      if (restaurant.restaurant_address) {
        setSameAsLegalAddress(false);
        setRestaurantAddress(restaurant.restaurant_address);
      } else {
        setSameAsLegalAddress(true);
      }

      // Transform backend opening_hours (which is a flat array) into 7-day structure
      if (restaurant.opening_hours && restaurant.opening_hours.length > 0) {
        const days = Array.from({ length: 7 }, (_, index) => ({
          day_of_week: index,
          is_open: false,
          time_slots: [{ opening_time: null, closing_time: null }],
        }));
        restaurant.opening_hours.forEach((slot) => {
          const dayIndex = slot.day_of_week;
          days[dayIndex].is_open = true;
          if (
            days[dayIndex].time_slots.length === 1 &&
            days[dayIndex].time_slots[0].opening_time === null
          ) {
            days[dayIndex].time_slots[0] = {
              opening_time: slot.opening_time.substring(0, 5), // "HH:MM"
              closing_time: slot.closing_time.substring(0, 5),
            };
          } else {
            days[dayIndex].time_slots.push({
              opening_time: slot.opening_time.substring(0, 5),
              closing_time: slot.closing_time.substring(0, 5),
            });
          }
        });
        setOpeningHours(days);
      }
      // Images
      setPreviewLogoUrl(restaurant.restaurant_logo || null);
      setPreviewCoverImageUrl(restaurant.cover_image || null);
    }
  }, [restaurant]);

  // --- Handlers for Tabs ---
  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  // --- Handlers for Opening Hours ---
  const handleAddTimeSlot = (dayIndex) => {
    setOpeningHours((prev) => {
      const updated = [...prev];
      const day = updated[dayIndex];
      const lastSlot = day.time_slots[day.time_slots.length - 1];
      let newOpeningTime = lastSlot.closing_time || '18:00';
      let newClosingTime = '22:30';
      if (lastSlot.closing_time) {
        const [hours, minutes] = lastSlot.closing_time.split(':');
        newOpeningTime = `${String(Number(hours) + 3).padStart(2, '0')}:${minutes}`;
      }
      day.time_slots.push({ opening_time: newOpeningTime, closing_time: newClosingTime });
      return updated;
    });
  };

  const handleRemoveTimeSlot = (dayIndex, slotIndex) => {
    setOpeningHours((prev) => {
      const updated = [...prev];
      updated[dayIndex].time_slots.splice(slotIndex, 1);
      if (updated[dayIndex].time_slots.length === 0) {
        updated[dayIndex].time_slots.push({ opening_time: null, closing_time: null });
      }
      return updated;
    });
  };

  const handleDayOpenChange = (e, dayIndex) => {
    const updatedHours = [...openingHours];
    const day = updatedHours[dayIndex];
    day.is_open = e.target.checked;
    if (e.target.checked) {
      // Try to copy time slots from a previous open day
      let prevDayIndex = dayIndex;
      let found = false;
      while (true) {
        prevDayIndex = (prevDayIndex - 1 + updatedHours.length) % updatedHours.length;
        if (prevDayIndex === dayIndex) break;
        const prevDay = updatedHours[prevDayIndex];
        if (prevDay.is_open && prevDay.time_slots.length) {
          day.time_slots = prevDay.time_slots.map((slot) => ({
            opening_time: slot.opening_time || defaultTimeSlotLocal.opening_time,
            closing_time: slot.closing_time || defaultTimeSlotLocal.closing_time,
          }));
          found = true;
          break;
        }
      }
      if (!found) {
        day.time_slots = [{ opening_time: defaultTimeSlotLocal.opening_time, closing_time: defaultTimeSlotLocal.closing_time }];
      }
    } else {
      // Reset time slots when day is unchecked
      day.time_slots = [{ opening_time: null, closing_time: null }];
    }
    setOpeningHours(updatedHours);
  };

  // --- Handler for Save ---
  const handleSave = async () => {
    setLoading(true);
    setAlertMessage(null);
    setErrors({});

    if (!restaurantName.trim()) {
      setErrors((prev) => ({ ...prev, restaurantName: 'Il nome è obbligatorio' }));
      setLoading(false);
      return;
    }
    if (!vatNumber.trim()) {
      setErrors((prev) => ({ ...prev, vatNumber: 'La partita IVA è richiesta' }));
      setLoading(false);
      return;
    }

    const formData = new FormData();
    formData.append('name', restaurantName);
    formData.append('description', description);
    const fullVatNumber = 'IT' + vatNumber.trim();
    formData.append('vat_number', fullVatNumber);
    if (phoneNumber) {
      formData.append('phone_number', phoneNumber);
    }

    const legalAddressWithName = { ...legalAddress, name: restaurantName };
    const restaurantAddressWithName = sameAsLegalAddress
      ? legalAddressWithName
      : { ...restaurantAddress, name: restaurantName };

    formData.append('legal_address', JSON.stringify(legalAddressWithName));
    formData.append('restaurant_address', JSON.stringify(restaurantAddressWithName));

    const formattedOpeningHours = openingHours
      .filter((day) => day.is_open)
      .flatMap((day) =>
        day.time_slots.map((slot) => ({
          day_of_week: day.day_of_week,
          opening_time: slot.opening_time,
          closing_time: slot.closing_time,
        }))
      );
    formData.append('opening_hours', JSON.stringify(formattedOpeningHours));

    // Only append the file if it is a new File object.
    if (logo && logo instanceof File) {
      formData.append('restaurant_logo', logo);
    }
    if (coverImage && coverImage instanceof File) {
      formData.append('cover_image', coverImage);
    }

    try {
      await updateRestaurant(restaurant.id, formData);
      if (onUpdate) {
        onUpdate();
      }
      onClose();
    } catch (error) {
      console.error('Error updating restaurant:', error);
      setAlertMessage({
        type: 'error',
        text: 'Si è verificato un errore durante l’aggiornamento',
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <CustomLoader open={loading} />
      <Drawer
        anchor="right"
        open={open}
        onClose={onClose}
        sx={{
          '& .MuiDrawer-paper': {
            width: { xs: '100%', sm: '80%', md: '50%' },
            padding: 2,
          },
        }}
      >
        {/* Header */}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            mb: 2,
          }}
        >
          <Typography variant="h6">Modifica Ristorante</Typography>
          <Button onClick={onClose} color="secondary">
            Chiudi
          </Button>
        </Box>
        <Divider />
        {/* Tabs */}
        <Tabs
          value={activeTab}
          onChange={handleTabChange}
          sx={{ borderBottom: 1, borderColor: 'divider', mb: 2 }}
        >
          <Tab label="Dettagli" id="edit-restaurant-tab-0" aria-controls="edit-restaurant-tabpanel-0" />
          <Tab label="Indirizzi" id="edit-restaurant-tab-1" aria-controls="edit-restaurant-tabpanel-1" />
          <Tab label="Orari" id="edit-restaurant-tab-2" aria-controls="edit-restaurant-tabpanel-2" />
          <Tab label="Extra" id="edit-restaurant-tab-3" aria-controls="edit-restaurant-tabpanel-3" />
        </Tabs>
        {/* Tab Panels */}
        <TabPanel value={activeTab} index={0}>
          <RestaurantDetails
            restaurantName={restaurantName}
            setRestaurantName={setRestaurantName}
            description={description}
            setDescription={setDescription}
            vatNumber={vatNumber}
            setVatNumber={setVatNumber}
            phoneNumber={phoneNumber}
            setPhoneNumber={setPhoneNumber}
            errors={errors}
          />
        </TabPanel>
        <TabPanel value={activeTab} index={1}>
          <AddressDetails
            legalAddress={legalAddress}
            setLegalAddress={setLegalAddress}
            sameAsLegalAddress={sameAsLegalAddress}
            setSameAsLegalAddress={setSameAsLegalAddress}
            restaurantAddress={restaurantAddress}
            setRestaurantAddress={setRestaurantAddress}
            isUpdating={true}
          />
        </TabPanel>
        <TabPanel value={activeTab} index={2}>
          <OpeningHours
            openingHours={openingHours}
            setOpeningHours={setOpeningHours}
            defaultTimeSlot={defaultTimeSlotLocal}
            handleAddTimeSlot={handleAddTimeSlot}
            handleRemoveTimeSlot={handleRemoveTimeSlot}
            handleDayOpenChange={handleDayOpenChange}
          />
        </TabPanel>
        <TabPanel value={activeTab} index={3}>
          <Extras
            previewLogoUrl={previewLogoUrl}
            handleFileSelectLogo={(file, previewUrl) => {
              setLogo(file);
              setPreviewLogoUrl(previewUrl);
            }}
            handleClearLogo={() => {
              setLogo(null);
              setPreviewLogoUrl(null);
            }}
            previewCoverImageUrl={previewCoverImageUrl}
            handleFileSelectCoverImage={(file, previewUrl) => {
              setCoverImage(file);
              setPreviewCoverImageUrl(previewUrl);
            }}
            handleClearCoverImage={() => {
              setCoverImage(null);
              setPreviewCoverImageUrl(null);
            }}
          />
        </TabPanel>
        {alertMessage && (
          <Box sx={{ mt: 2 }}>
            <Alert severity={alertMessage.type}>{alertMessage.text}</Alert>
          </Box>
        )}
        {/* Save Button */}
        <Box flexGrow={1}></Box>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2, mr: 2 }}>
          <Button onClick={handleSave} variant="outlined" color="primary">
            Salva modifiche
          </Button>
        </Box>
      </Drawer>
    </>
  );
};

export default EditRestaurantDrawer;
