// Extras.jsx
import React from 'react';
import { Box, Typography } from '@mui/material';
import PictureUpload from '../../Common/PictureUpload';

const Extras = ({
  previewLogoUrl,
  handleFileSelectLogo,
  handleClearLogo,
  previewCoverImageUrl,
  handleFileSelectCoverImage,
  handleClearCoverImage,
}) => {
  return (
    <Box width="100%" height="100%" display="flex" flexDirection="column">
      <Box width="100%" mb={4} textAlign="center" marginY="5%">
        <Typography fontSize="large">
          Aggiungi logo e copertina per il menu digitale e gli ordini online (opzionale)
        </Typography>
      </Box>
      <Box width="100%" mb={4} textAlign="center">
        <Typography variant="body">
          Il logo del tuo ristorante
        </Typography>
        <Box display="flex" justifyContent="center" mt={2}>
          <PictureUpload
            existingFile={previewLogoUrl}
            previewUrl={previewLogoUrl}
            onFileSelect={handleFileSelectLogo}
            onClearImage={handleClearLogo}
          />
        </Box>
      </Box>
      <Box width="100%" mb={2} textAlign="center">
        <Typography variant="body">
          L'immagine di sfondo del tuo ristorante
        </Typography>
        <Box display="flex" justifyContent="center" mt={2}>
          <PictureUpload
            existingFile={previewCoverImageUrl}
            previewUrl={previewCoverImageUrl}
            onFileSelect={handleFileSelectCoverImage}
            onClearImage={handleClearCoverImage}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default Extras;
