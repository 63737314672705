// ErrorBoundary.jsx
import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import error from '../assets/icons/error-boundary.png';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state to show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // Log the error details if necessary.
    console.error("ErrorBoundary caught an error", error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <Box sx={{ display: 'flex', flexDirection: 'column', mt: '10%', p: 3, alignItems: 'center', justifyContent: 'center' }}>
          <Box>
            <img
              src={error}
              alt="Errore generale"
              style={{ width: '100px', marginBottom: '20px', opacity: '0.85' }}
            />
          </Box>
          <Typography variant="h6">
            Si è verificato un errore inaspettato.
          </Typography>
          <Typography variant="body1" sx={{ textAlign: 'center' }}>
            Se il problema persiste, contattaci all'indirizzo <a href="mailto:supporto@tabomenu.com">supporto@tabomenu.com</a>
          </Typography>
          <Button
            variant="outlined"
            sx={{ mt: 3 }}
            onClick={() => window.location.href = '/dashboard/my-restaurants'}
          >
            Torna alla Home
          </Button>
        </Box>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
