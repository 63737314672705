import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import {
  FoodBankRounded,
  RamenDiningRounded,
  Groups2Rounded,
  Insights,
  AddCard,
  EventAvailableOutlined,
  TapAndPlayRounded,
} from '@mui/icons-material';
import {
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  useTheme,
  Chip,
} from '@mui/material';
import { useMembership } from '../../hooks/useMembership';
import CustomLoader from '../Common/CustomLoader';

// Define labels for the required membership keys.
const membershipLabels = {
  hasOrders: 'Disponibile col piano Ordini',
  hasReservations: 'Disponibile col piano Prenotazioni'
};

const navItems = [
  { key: 'my-restaurants', label: 'Il Mio Ristorante', to: '/dashboard/my-restaurants', Icon: FoodBankRounded },
  { key: 'menu-overview', label: 'Menu', to: '/dashboard/menu-overview', Icon: RamenDiningRounded },
  { key: 'online-orders', label: 'Ordini Online', to: '/dashboard/online-orders', Icon: TapAndPlayRounded },
  { key: 'my-team', label: 'Team', to: '/dashboard/my-team', Icon: Groups2Rounded, requiredMembership: 'hasOrders' },
  { key: 'payments', label: 'Pagamenti', to: '/dashboard/payments', Icon: AddCard, requiredMembership: 'hasOrders' },
  { key: 'reservations', label: 'Prenotazioni', to: '/dashboard/reservations', Icon: EventAvailableOutlined, requiredMembership: 'hasReservations' },
  { key: 'analytics', label: 'Reportistica', to: '/dashboard/analytics', Icon: Insights },
];

const SideNavBar = ({ setActiveNav, activeNav, closeDrawer }) => {
  const theme = useTheme();
  const location = useLocation();
  const membership = useMembership();

  // Determine if the route is active.
  const isActive = (path) => location.pathname === path;

  const handleNavClick = (navKey) => {
    setActiveNav(navKey);
    if (closeDrawer) closeDrawer();
  };

  if (membership.isLoading) {
    return <CustomLoader />;
  }

  return (
    <Box
      component="nav"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: 220,
        height: '100vh',
        position: 'sticky',
        top: 0,
        borderRight: '1px solid',
        borderColor: 'grey.200',
        pt: 4,
        backgroundColor: 'white',
        overflowY: 'auto',
        zIndex: 999,
      }}
    >
      <List disablePadding>
        {navItems.map((item) => {
          // If the nav item requires a membership, check if the user has it.
          if (item.requiredMembership && !membership[item.requiredMembership]) {
            // UI FOR DISABLED FEATURES
            return (
              <ListItem key={item.key} disablePadding sx={{ width: '100%', position: 'relative' }}>
                {/* The Chip is rendered outside of the disabled button */}
                <Chip
                  clickable
                  component="a"
                  href="/subscribe"
                  label={membershipLabels[item.requiredMembership] || 'Upgrade needed'}
                  color="primary"
                  sx={{
                    position: 'absolute',
                    top: 4,
                    left: 24,
                    zIndex: 1,
                    // Override the root's padding:
                    py: 0.25,
                    px: 1.0,
                    height: 'auto',
                    // Adjust the internal label styling:
                    '& .MuiChip-label': {
                      fontSize: '0.7rem',
                      fontWeight: 550,
                      padding: '0px', // remove default label padding
                    },
                  }}
                />
                <ListItemButton
                  disabled
                  sx={{
                    pt: 3.5,  // extra top padding to create space for the chip
                    pb: 1,
                    px: 2,
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: 2,
                    }}
                  >
                    <item.Icon sx={{ fontSize: '24px', ml: '10px' }} />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Typography variant="body1" sx={{ color: '#333' }}>
                        {item.label}
                      </Typography>
                    }
                  />
                </ListItemButton>
              </ListItem>
            );
          }
          // For items without membership restrictions:
          const active = isActive(item.to) || activeNav === item.key;
          return (
            <ListItem key={item.key} disablePadding sx={{ width: '100%' }}>
              <ListItemButton
                component={NavLink}
                to={item.to}
                onClick={() => handleNavClick(item.key)}
                sx={{
                  py: 1,
                  px: 2,
                  '&:hover': {
                    backgroundColor: 'grey.100',
                  },
                  backgroundColor: active ? 'grey.100' : 'inherit',
                }}
              >
                <ListItemIcon
                  sx={{
                    color: active ? theme.palette.secondary.main : '#333',
                    minWidth: 0,
                    mr: 2,
                  }}
                >
                  <item.Icon sx={{ fontSize: '24px', ml: '10px', opacity: 0.85 }} />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Typography
                      variant="body1"
                      sx={{
                        color: active ? theme.palette.secondary.main : '#333',
                        fontWeight: active ? 'bold' : 'normal',
                      }}
                    >
                      {item.label}
                    </Typography>
                  }
                />
              </ListItemButton>
            </ListItem>
          );
        })}
      </List>
    </Box>
  );
};

export default SideNavBar;
