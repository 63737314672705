import React from 'react';
import { Dialog, DialogTitle, DialogContent, Typography } from '@mui/material';

const AllergenDialog = ({ open, onClose, allergens, itemName }) => {
  return (
    <Dialog open={open} onClose={onClose}>
      {itemName && (
        <DialogTitle variant="h6" color="primary">{itemName}</DialogTitle>
      )}
      <DialogContent>
      <Typography sx={{ textDecoration: 'underline', textAlign: 'left', mt: 2, mb: 1 }}>
        Allergeni:
      </Typography>
        {allergens.length > 0 &&
          allergens.map((allergen, index) => (
            <Typography sx={{ textAlign: 'left', fontWeight: 600 }} key={index}>{allergen}</Typography>
          ))}
      </DialogContent>
    </Dialog>
  );
};

export default AllergenDialog;
