import React from 'react';
import PropTypes from 'prop-types';
import { Select } from '@mui/material';
import { styled } from '@mui/system';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

// -----------------------------------------------------------------------------
// 1. Define a custom icon with a transition effect
// -----------------------------------------------------------------------------
const CustomSelectIcon = styled(ExpandMoreIcon)(({ theme }) => ({
  transition: 'transform 0.3s ease-in-out',
  fontSize: '22px',
  marginRight: '2px'
}));

// -----------------------------------------------------------------------------
// 2. Create a styled version of the Select component.
//    This includes default styles (e.g., dimensions, colors, borders, hover effects)
// -----------------------------------------------------------------------------
const StyledSelect = styled(Select)(({ theme }) => ({
  color: theme.palette.black.main,
  width: '275px',
  height: '45px',
  backgroundColor: 'white',
  borderRadius: '8px',
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: theme.palette.grey.main,
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: theme.palette.dark.main,
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: theme.palette.primary.main,
  },
  '& .MuiSelect-icon': {
    transition: 'transform 0.3s ease-in-out',
  },
  '&.Mui-expanded .MuiSelect-icon': {
    transform: 'rotate(180deg)',
  },
}));

// -----------------------------------------------------------------------------
// 3. Define default MenuProps for the dropdown menu
// -----------------------------------------------------------------------------
const defaultMenuProps = {
  PaperProps: {
    sx: {
      '& .MuiMenuItem-root': {
        backgroundColor: 'white',
        '&:hover': {
          backgroundColor: 'lightgrey',
        },
      },
    },
  },
};

// -----------------------------------------------------------------------------
// 4. Create the CustomSelect component using React.forwardRef
//    - Accepts a `menuProps` prop to allow overriding or extending the default menu settings
//    - Spreads any additional props to the underlying MUI Select component
// -----------------------------------------------------------------------------
const CustomSelect = React.forwardRef(function CustomSelect(props, ref) {
  const { menuProps, children, ...rest } = props;

  return (
    <StyledSelect
      ref={ref}
      IconComponent={CustomSelectIcon}
      // Merge the default MenuProps with any custom ones passed in props
      MenuProps={{ ...defaultMenuProps, ...menuProps }}
      {...rest}
    >
      {children}
    </StyledSelect>
  );
});

CustomSelect.propTypes = {
  /**
   * Props to customize the menu behavior.
   * For example, you can override styles or pass additional settings.
   */
  menuProps: PropTypes.object,
  /**
   * The options to be rendered inside the select.
   */
  children: PropTypes.node,
};

export default CustomSelect;
