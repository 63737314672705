import React from 'react';
import TextField from '@mui/material/TextField';

const RoundedTextField = ({ InputProps, sx, ...otherProps }) => {
  const roundedStyles = {
    '& .MuiOutlinedInput-root': {
      borderRadius: '20px',
      height: '60px',
 //     backgroundColor: '#f5f5f5',
      '& fieldset': {
        borderColor: '#ccc',
      },
      '&:hover fieldset': {
        borderColor: '#aaa',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#888',
      },
      '& input': {
        color: '#333',
      },
    },
  };

  return (
    <TextField
      {...otherProps}
      variant="outlined"
      margin="normal"
      sx={{ ...roundedStyles, ...sx }}
      InputProps={InputProps}
    />
  );
};

export default RoundedTextField;
