// PriceTextField.jsx
import React, { useState, useEffect } from "react";
import { Typography, TextField } from "@mui/material";
import { isValidPrice, formatPrice } from "../../utils/priceValidation";

const PriceTextField = ({
  value: propValue,
  onChange: propOnChange,
  label = "Prezzo (€)",
  ...rest
}) => {
  const [value, setValue] = useState(propValue || "");
  const [error, setError] = useState(false);
  const [helperText, setHelperText] = useState("");

  // Sync with parent prop changes if needed.
  useEffect(() => {
    setValue(propValue || "");
  }, [propValue]);

  const handleChange = (e) => {
    const newValue = e.target.value;

    if (newValue === "" || isValidPrice(newValue)) {
      setValue(newValue);
      setError(false);
      setHelperText("");
      // Notify the parent that the value has changed.
      propOnChange && propOnChange(newValue);
    } else {
      setError(true);
      setHelperText("Il prezzo deve avere massimo 4 cifre e 2 decimali");
    }
  };

  const handleBlur = () => {
    if (value !== "" && isValidPrice(value)) {
      const formatted = formatPrice(value);
      setValue(formatted);
      propOnChange && propOnChange(formatted);
    }
  };

  return (
    <>
    <TextField
      {...rest}
      label={label}
      type="text" // Using text for full regex control.
      value={value}
      onChange={handleChange}
      onBlur={handleBlur}
      error={error}
      inputProps={{ inputMode: "decimal", ...(rest.inputProps || {}) }}
    />
    { error && (
    <Typography color="error" sx={{ fontSize: '12px', mb: 1 }}>{helperText}</Typography>
    )}
    </>
  );
};

export default PriceTextField;
