// RestaurantWizard.jsx v1.31
import React, { useState } from 'react';
import { Stepper, Step, StepLabel, Button, Typography, Alert, Box } from '@mui/material';
import { createRestaurant } from '../../../services/apiService';
import { validateAddress } from '../../../services/externalApiService';
import { useRestaurant } from '../../../contexts/RestaurantContext';
import { useResponsive } from '../../../hooks/useResponsive';
import CustomLoader from '../../Common/CustomLoader';

// Import sub-components for each wizard step
import RestaurantDetails from './RestaurantDetails';
import AddressDetails from './AddressDetails';
import OpeningHours from './OpeningHours';
import Extras from './Extras';

// Simple container components for consistent styling
const WizardContainer = (props) => (
  <Box display="flex" flexDirection="column" height="100%" {...props} />
);

const StepContainer = (props) => (
  <Box
    display="flex"
    flexDirection="column"
    flexGrow={1}
    alignItems="center"
    justifyContent="center"
    {...props}
  />
);

// Define step labels
const steps = ['Dettagli', 'Indirizzi', 'Orari', 'Extra'];

const RestaurantWizard = ({ onComplete, onClose }) => {
  const { isMobile } = useResponsive();

  // Define state for all wizard data
  const [activeStep, setActiveStep] = useState(0);
  const [restaurantName, setRestaurantName] = useState('');
  const [description, setDescription] = useState('');
  const [vatCountryCode] = useState('IT'); // No longer editable, now is a constant.
  const [vatNumber, setVatNumber] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [validating, setValidating] = useState(false);

  // Address states
  const [legalAddress, setLegalAddress] = useState({
    name: '',
    address_line_1: '',
    postal_code: '',
    city: '',
    state: '',
    country: 'Italy', // default
  });
  const [sameAsLegalAddress, setSameAsLegalAddress] = useState(true);
  const [restaurantAddress, setRestaurantAddress] = useState({
    name: '',
    address_line_1: '',
    postal_code: '',
    city: '',
    state: '',
    country: 'Italy',
  });

  // Opening hours state (one object per day of week)
  const [openingHours, setOpeningHours] = useState([
    { day_of_week: 0, is_open: false, time_slots: [{ opening_time: null, closing_time: null }] },
    { day_of_week: 1, is_open: false, time_slots: [{ opening_time: null, closing_time: null }] },
    { day_of_week: 2, is_open: false, time_slots: [{ opening_time: null, closing_time: null }] },
    { day_of_week: 3, is_open: false, time_slots: [{ opening_time: null, closing_time: null }] },
    { day_of_week: 4, is_open: false, time_slots: [{ opening_time: null, closing_time: null }] },
    { day_of_week: 5, is_open: false, time_slots: [{ opening_time: null, closing_time: null }] },
    { day_of_week: 6, is_open: false, time_slots: [{ opening_time: null, closing_time: null }] },
  ]);
  const defaultTimeSlot = {
    opening_time: '12:30',
    closing_time: '15:00',
  };

  // Image uploads state
  const [logo, setLogo] = useState(null);
  const [coverImage, setCoverImage] = useState(null);
  const [previewLogoUrl, setPreviewLogoUrl] = useState(null);
  const [previewCoverImageUrl, setPreviewCoverImageUrl] = useState(null);

  // Loading, alerts, and error messages
  const [loading, setLoading] = useState(false);
  const [alertMessage, setAlertMessage] = useState(null);
  const [errors, setErrors] = useState({});

  const { loadUserRestaurants } = useRestaurant();

  // Example VAT and country options (if still needed in other steps)
  //  const vatCountryOptions = [{ code: 'IT' }, { code: 'FR' }, { code: 'DE' }];
  const countryOptions = [
    { code: 'IT', label: 'Italia' },
    { code: 'FR', label: 'France' },
    { code: 'DE', label: 'Germany' },
  ];

  // Helper function to format time values to "HH:mm"
  const formatTime = (time) => {
    if (!time) return null;
    if (typeof time === 'string') return time;
    const hours = time.getHours().toString().padStart(2, '0');
    const minutes = time.getMinutes().toString().padStart(2, '0');
    return `${hours}:${minutes}`;
  };

  // --- Submission handler (Finish) ---
  const handleFinish = async () => {
    setLoading(true);
    setAlertMessage(null);

    const formData = new FormData();
    formData.append('name', restaurantName);
    formData.append('description', description);
    const fullVatNumber = vatCountryCode + vatNumber.trim();
    formData.append('vat_number', fullVatNumber);
    if (phoneNumber) {
      formData.append('phone_number', phoneNumber);
    }

    // Address: 1. Build final objects for addresses
    const legalAddressWithName = { ...legalAddress, name: restaurantName };
    const restaurantAddressWithName = sameAsLegalAddress
      ? legalAddressWithName
      : { ...restaurantAddress, name: restaurantName };

    // Address 2. Convert them to JSON strings and append
    formData.append('legal_address', JSON.stringify(legalAddressWithName));
    formData.append('restaurant_address', JSON.stringify(restaurantAddressWithName));

    // Format opening hours and append them
    const formattedOpeningHours = openingHours
      .filter((day) => day.is_open)
      .flatMap((day) =>
        day.time_slots.map((slot) => ({
          day_of_week: day.day_of_week,
          opening_time: formatTime(slot.opening_time),
          closing_time: formatTime(slot.closing_time),
        }))
      );
    formData.append('opening_hours', JSON.stringify(formattedOpeningHours));

    // Append images if available
    if (logo) formData.append('restaurant_logo', logo);
    if (coverImage) formData.append('cover_image', coverImage);

    try {
      const newRestaurant = await createRestaurant(formData);
      loadUserRestaurants();
      onComplete(newRestaurant.id);
    } catch (error) {
      setAlertMessage({ type: 'error', text: 'Si è verificato un errore' });
      console.error('Error creating restaurant:', error);
    } finally {
      setLoading(false);
    }
  };

  // --- Navigation handlers ---
  const handleNext = async () => {
    setAlertMessage(null);
    // Reset errors when moving to next step
    setErrors({});

    // Step 0 validation (Restaurant Details)
    if (activeStep === 0) {
      const stepErrors = {};
      if (!restaurantName.trim()) {
        stepErrors.restaurantName = 'Il nome del ristorante è obbligatorio';
      }
      if (!vatNumber.trim()) {
        stepErrors.vatNumber = 'La partita IVA è richiesta';
      } else if (vatNumber.trim().length < 5) {
        stepErrors.vatNumber = 'Partita IVA non valida';
      }
      // If there are errors, set them and do not proceed
      if (Object.keys(stepErrors).length > 0) {
        setErrors(stepErrors);
        return;
      }
    }

    // Step 1 validation (Address Details)
    if (activeStep === 1) {
      setValidating(true);
      const address = {
        addressLines: [legalAddress.address_line_1],
        locality: legalAddress.city,
        postalCode: legalAddress.postal_code,
        regionCode: countryOptions.find((country) => country.label === legalAddress.country)?.code || 'IT',
      };
      const validatedAddress = await handleAddressValidation(address);
      setValidating(false);

      if (!validatedAddress) return;
      setLegalAddress({
        ...legalAddress,
        address_line_1: validatedAddress.addressLines[0],
        city: validatedAddress.locality,
        postal_code: validatedAddress.postalCode,
      });
    }

    // Step 2 validation (Opening Hours)
    if (activeStep === 2) {
      const hasErrors = openingHours.some((day) => {
        if (day.is_open) {
          return day.time_slots.some(
            (slot) =>
              !slot.opening_time ||
              !slot.closing_time ||
              slot.opening_time >= slot.closing_time
          );
        }
        return false;
      });
      if (hasErrors) {
        setAlertMessage({
          type: 'warning',
          text: 'Inserisci orari di apertura e chiusura validi per i giorni selezionati',
        });
        return;
      }
      // Additional overlapping time slot check can be added here
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  // --- Address validation helper ---
  const handleAddressValidation = async (addressToValidate) => {
    try {
      const result = await validateAddress(addressToValidate);
      const { verdict, correctedAddress } = result;
      if (verdict?.hasUnconfirmedComponents) {
        setAlertMessage({ type: 'warning', text: 'Controlla il campo indirizzo' });
        return null;
      }
      return correctedAddress || addressToValidate;
    } catch (error) {
      setAlertMessage({ type: 'warning', text: 'Indirizzo non valido' });
      return null;
    }
  };

  // --- Opening Hours Handlers ---
  const handleAddTimeSlot = (dayIndex) => {
    setOpeningHours((prev) => {
      const updated = [...prev];
      const day = updated[dayIndex];
      const lastSlot = day.time_slots[day.time_slots.length - 1];
      let newOpeningTime = lastSlot.closing_time || '18:00';
      let newClosingTime = '22:30';
      if (lastSlot.closing_time) {
        const [hours, minutes] = lastSlot.closing_time.split(':');
        newOpeningTime = `${String(Number(hours) + 3).padStart(2, '0')}:${minutes}`;
      }
      day.time_slots.push({ opening_time: newOpeningTime, closing_time: newClosingTime });
      return updated;
    });
  };

  const handleRemoveTimeSlot = (dayIndex, slotIndex) => {
    setOpeningHours((prev) => {
      const updated = [...prev];
      updated[dayIndex].time_slots.splice(slotIndex, 1);
      if (updated[dayIndex].time_slots.length === 0) {
        updated[dayIndex].time_slots.push({ opening_time: null, closing_time: null });
      }
      return updated;
    });
  };

  const handleDayOpenChange = (e, dayIndex) => {
    const updatedHours = [...openingHours];
    const day = updatedHours[dayIndex];
    day.is_open = e.target.checked;
    if (e.target.checked) {
      // Copy time slots from previous open day if available
      let prevDayIndex = dayIndex;
      let found = false;
      while (true) {
        prevDayIndex = (prevDayIndex - 1 + openingHours.length) % openingHours.length;
        if (prevDayIndex === dayIndex) break;
        const prevDay = updatedHours[prevDayIndex];
        if (prevDay.is_open && prevDay.time_slots.length) {
          day.time_slots = prevDay.time_slots.map((slot) => ({
            opening_time: slot.opening_time || defaultTimeSlot.opening_time,
            closing_time: slot.closing_time || defaultTimeSlot.closing_time,
          }));
          found = true;
          break;
        }
      }
      if (!found) {
        day.time_slots = [{ opening_time: defaultTimeSlot.opening_time, closing_time: defaultTimeSlot.closing_time }];
      }
    } else {
      // Reset time slots when unchecking the day
      day.time_slots = [{ opening_time: null, closing_time: null }];
    }
    setOpeningHours(updatedHours);
  };

  // --- File upload Handlers ---
  const handleFileSelectLogo = (file, previewUrl) => {
    setLogo(file);
    setPreviewLogoUrl(previewUrl);
  };

  const handleClearLogo = () => {
    setLogo(null);
    setPreviewLogoUrl(null);
  };

  const handleFileSelectCoverImage = (file, previewUrl) => {
    setCoverImage(file);
    setPreviewCoverImageUrl(previewUrl);
  };

  const handleClearCoverImage = () => {
    setCoverImage(null);
    setPreviewCoverImageUrl(null);
  };

  // --- Render the appropriate step component ---
  const renderStepComponent = () => {
    switch (activeStep) {
      case 0:
        return (
          <RestaurantDetails
            restaurantName={restaurantName}
            setRestaurantName={setRestaurantName}
            description={description}
            setDescription={setDescription}
            vatNumber={vatNumber}
            setVatNumber={setVatNumber}
            phoneNumber={phoneNumber}
            setPhoneNumber={setPhoneNumber}
            // Pass error messages for RestaurantDetails
            errors={errors}
          />
        );
      case 1:
        return (
          <AddressDetails
            legalAddress={legalAddress}
            setLegalAddress={setLegalAddress}
            sameAsLegalAddress={sameAsLegalAddress}
            setSameAsLegalAddress={setSameAsLegalAddress}
            restaurantAddress={restaurantAddress}
            setRestaurantAddress={setRestaurantAddress}
            countryOptions={countryOptions}
            validating={validating}
            isUpdating={false}
          />
        );
      case 2:
        return (
          <OpeningHours
            openingHours={openingHours}
            setOpeningHours={setOpeningHours}
            defaultTimeSlot={defaultTimeSlot}
            handleAddTimeSlot={handleAddTimeSlot}
            handleRemoveTimeSlot={handleRemoveTimeSlot}
            handleDayOpenChange={handleDayOpenChange}
          />
        );
      case 3:
        return (
          <Extras
            previewLogoUrl={previewLogoUrl}
            handleFileSelectLogo={handleFileSelectLogo}
            handleClearLogo={handleClearLogo}
            previewCoverImageUrl={previewCoverImageUrl}
            handleFileSelectCoverImage={handleFileSelectCoverImage}
            handleClearCoverImage={handleClearCoverImage}
          />
        );
      default:
        return null;
    }
  };

  // --- Handler for the "Chiudi" button ---
  const handleClose = () => {
    setActiveStep(0);
    setRestaurantName('');
    setDescription('');
    setVatNumber('');
    setPhoneNumber('');
    if (onClose) {
      onClose();
    }
  };

  return (
    <>
      <CustomLoader open={validating || loading} />
      <WizardContainer>
        <Button
          color="secondary"
          style={{ fontSize: '13px', alignSelf: 'flex-end' }}
          onClick={handleClose}
        >
          Chiudi
        </Button>
        <Stepper
          activeStep={activeStep}
          sx={{ width: '75%', marginTop: '10px', marginBottom: '20px', alignSelf: 'center' }}
        >
          {steps.map((label) => (
            <Step key={label}>
              {/* Only show label if not on mobile */}
              <StepLabel
                StepIconProps={{
                  sx: { fontSize: '1.40rem' }  // Decrease the icon size; adjust as needed.
                }}
              >
                {!isMobile ? label : ''}
              </StepLabel>
            </Step>
          ))}
        </Stepper>
        <StepContainer>
          {activeStep === steps.length ? (
            <Typography>Tutto a posto! Puoi chiudere il wizard</Typography>
          ) : (
            renderStepComponent()
          )}
          {alertMessage && (
            <Box width="100%" paddingX={{ xs: '2%', sm: '4%', md: '8%', lg: '12%' }}>
              <Alert severity={alertMessage.type}>
                {alertMessage.text}
              </Alert>
            </Box>
          )}
        </StepContainer>
        <Box
          display="flex"
          justifyContent="space-between"
          padding="1rem 0"
          width="95%"
          marginLeft="auto"
          marginRight="auto"
          marginTop="20px"
        >
          <Button disabled={activeStep === 0} onClick={handleBack} variant="outlined" color="primary">
            Indietro
          </Button>
          <Button
            onClick={activeStep === steps.length - 1 ? handleFinish : handleNext}
            variant="contained"
            color="primary"
          >
            {activeStep === steps.length - 1 ? 'Invia' : 'Continua'}
          </Button>
        </Box>
      </WizardContainer>
    </>
  );
};

export default RestaurantWizard;