// RestaurantOverview v1.11
import React, { useState, useEffect } from 'react';
import { useRestaurant } from '../../contexts/RestaurantContext';
import { useAlert } from '../../contexts/AlertContext';
import { Dialog, DialogContent, Box, Button, Typography } from '@mui/material';
import { deleteRestaurant, bulkCreateAreas } from '../../services/apiService';
import { updateUserDefaultRestaurant } from '../../services/authApiService';
import ConfirmationDialog from '../Common/ConfirmationDialog';
import RestaurantWizard from './restaurant/RestaurantWizard';
import EditRestaurantDrawer from './restaurant/EditRestaurantDrawer';
import AreaCreationWizard from './restaurant/AreaCreationWizard';
import CreateSingleArea from './restaurant/CreateSingleArea';
import RestaurantHeader from './restaurant/RestaurantHeader';
import AreaSwiper from './restaurant/AreaSwiper';
import CustomLoader from '../Common/CustomLoader';
import drinks from '../../assets/icons/drinks.png';
import greenCheck from '../../assets/icons/green-check.png';

const RestaurantOverview = () => {
  const {
    userRestaurants,
    restaurantsLoading,
    checkForFloorMap,
    loadUserRestaurants,
    selectedRestaurant,
    editingRestaurant,
    setEditingRestaurant,
    setSelectedRestaurant,
    areas,
    areasLoading
  } = useRestaurant();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [restaurantToDelete, setRestaurantToDelete] = useState(null);
  const [showSetupWizard, setShowSetupWizard] = useState(false);
  const [showAreaWizard, setShowAreaWizard] = useState(false);
  const [showAreaDrawer, setShowAreaDrawer] = useState(false);
  const [currentAreaName, setCurrentAreaName] = useState('');
  const { showToast } = useAlert();

  const restaurantOptions = userRestaurants.map(restaurant => ({
    value: restaurant.id,
    label: restaurant.name
  }));

  // Load user restaurants on mount
  useEffect(() => {
    loadUserRestaurants();
  }, [loadUserRestaurants]);

  // Set the first area name when areas change
  useEffect(() => {
    if (areas && areas.length > 0) {
      setCurrentAreaName(areas[0].name);
    }
  }, [areas]);

  // Check for floor map when selectedRestaurant changes
  useEffect(() => {
    if (selectedRestaurant) {
      checkForFloorMap(selectedRestaurant);
    }
  }, [selectedRestaurant, checkForFloorMap]);

  const handleRestaurantChange = (selectedOption) => {
    setSelectedRestaurant(selectedOption.value);
  };

  const handleToggleDefault = async () => {
    if (selectedRestaurant) {
      await updateUserDefaultRestaurant(selectedRestaurant);
    }
    showToast({
      message: "Ristorante impostato come predefinito",
      variant: 'success'
    });
  };

  const handleNewRestaurantCreated = async (newRestaurantId) => {
    setSelectedRestaurant(newRestaurantId);
    setShowSetupWizard(false);
    await loadUserRestaurants();
    await checkForFloorMap(newRestaurantId);
  };

  const handleOpenRestaurantWizard = () => setShowSetupWizard(true);
  const handleOpenAreaWizard = () => setShowAreaWizard(true);
  const handleCloseAreaWizard = () => setShowAreaWizard(false);

  const openEditDrawer = (restaurantId) => {
    const restaurantToEdit = userRestaurants.find(
      (restaurant) => restaurant.id === restaurantId
    );
    setEditingRestaurant(restaurantToEdit);
  };

  const handleWizardComplete = async (areasFromWizard) => {
    const payload = {
      restaurant: selectedRestaurant,
      areas: areasFromWizard.map(area => ({
        name: area.areaName,
        tableGroups: area.tableGroups.map(group => ({
          numberOfTables: parseInt(group.numberOfTables, 10),
          numberOfSeats: parseInt(group.numberOfSeats, 10)
        }))
      }))
    };

    try {
      await bulkCreateAreas(payload);
      await checkForFloorMap(selectedRestaurant);
    } catch (error) {
      console.error("Error creating areas and tables:", error);
    }
    setShowAreaWizard(false);
  };

  const toggleDeleteModal = (restaurantId) => {
    setRestaurantToDelete(restaurantId);
    setShowDeleteModal((prev) => !prev);
  };

  const handleDeleteRestaurant = async () => {
    if (!restaurantToDelete) return;
    try {
      await deleteRestaurant(restaurantToDelete);
      setRestaurantToDelete(null);
      setShowDeleteModal(false);
      setShowSetupWizard(false);
      loadUserRestaurants();
    } catch (error) {
      console.error('Error deleting restaurant:', error);
    }
  };

  // SINGLE AREA CREATION FLOW
  const handleOpenSingleAreaDrawer = () => setShowAreaDrawer(true);
  const handleCloseSingleAreaDrawer = () => setShowAreaDrawer(false);

  const handleSingleAreaCreated = async (newArea) => {
    const transformedArea = {
      name: newArea.areaName,
      tableGroups: newArea.tableGroups.map((g) => ({
        numberOfTables: g.numberOfTables,
        numberOfSeats: g.numberOfSeats,
      }))
    };

    const payload = {
      restaurant: selectedRestaurant,
      areas: [transformedArea]
    };

    try {
      await bulkCreateAreas(payload);
      await checkForFloorMap(selectedRestaurant);
      showToast({ message: 'Nuova area creata con successo', variant: 'success' });
    } catch (error) {
      console.error('Error creating area:', error);
      showToast({ message: 'Errore nella creazione area', variant: 'error' });
    } finally {
      handleCloseSingleAreaDrawer();
    }
  };

  // ---------------- Early Return Conditions ----------------

  // 1. Loading restaurants
  if (restaurantsLoading || areasLoading) {
    return <CustomLoader open={true} />;
  }

  // 2. No restaurants exist (show "Get Started" UI)
  if (userRestaurants.length === 0) {
    return (
      <Box
        sx={{
          padding: {
            xs: '25% 5% 2% 5%',
            sm: '20% 10% 2% 10%',
            md: '12% 15% 2% 15%',
            lg: '8% 20% 2% 20%',
          },
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box>
          <img
            src={drinks}
            alt="Registra Ristorante"
            style={{ width: '100px', marginBottom: '20px', opacity: 0.75 }}
          />
        </Box>
        <Typography variant="h3" gutterBottom>Registra la tua attività</Typography>
        <Typography sx={{ textAlign: 'center', color: 'grey' }} variant="subtitle2">
          Premi il pulsante qui sotto e configura il tutto in un paio di minuti.
        </Typography>
        <Button
          variant="rounded"
          color="primary"
          onClick={() => setShowSetupWizard(true)}
          sx={{ mt: 4 }}
        >
          Cominciamo
        </Button>
        <Dialog
          open={showSetupWizard}
          onClose={() => setShowSetupWizard(false)}
          fullWidth
          maxWidth="md"
          PaperProps={{
            sx: {
              overflowY: 'auto'
            }
          }}
        >
          <DialogContent>
            <RestaurantWizard
              onComplete={handleNewRestaurantCreated}
              onClose={() => setShowSetupWizard(false)}
            />
          </DialogContent>
        </Dialog>
      </Box>
    );
  }

  // 3. Show final step for creating areas and tables
  if (areas.length === 0) {
    // Get the current restaurant object using the selectedRestaurant id.
    const currentRestaurant = userRestaurants.find(
      (restaurant) => restaurant.id === selectedRestaurant
    );

    return (
      <>
        {/* Header with restaurant name and delete button */}
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: '20px', mr: '50px' }}>
          <Typography variant="subtitle2" fontWeight={550} color="darkgrey.main">
            {currentRestaurant?.name}
          </Typography>
        </Box>

        <Box display="flex" alignItems="center" justifyContent="center" padding="20px">
          <Box textAlign="center" marginTop="2.5%">
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5, mb: '100px' }}>
              <img src={greenCheck} style={{ width: '21px', opacity: 0.75, marginRight: '5px' }} alt="Green Check" />
              <Typography variant="caption" color="darkgrey.main" fontWeight={550}>
                1. Registra l'attività
              </Typography>
              <Box sx={{ flexGrow: 1, borderBottom: '1px solid #ccc', mx: 1, width: '150px' }} />
              <Typography variant="caption" color="grey.main" fontWeight={550}>
                2. Definisci il layout e completa
              </Typography>
            </Box>
            <Typography variant="h3" gutterBottom>Aggiungi aree e tavoli</Typography>
            <Typography sx={{ textAlign: 'center', color: 'grey' }} variant="subtitle2">
              Completa il setup per gestire ordini e prenotazioni
            </Typography>
            <Button
              variant="rounded"
              color="primary"
              onClick={handleOpenAreaWizard}
              sx={{ mt: 3 }}
            >
              Completa la configurazione
            </Button>
          </Box>
        </Box>

        {/* Area Wizard */}
        {showAreaWizard && (
          <AreaCreationWizard
            open={showAreaWizard}
            onClose={handleCloseAreaWizard}
            onComplete={handleWizardComplete}
          />
        )}
      </>
    );
  }


  // ---------------- Main UI ----------------
  return (
    <>
      <RestaurantHeader
        restaurantOptions={restaurantOptions}
        handleRestaurantChange={handleRestaurantChange}
        onCreateRestaurant={handleOpenRestaurantWizard}
        onCreateArea={handleOpenSingleAreaDrawer}
        toggleDeleteModal={toggleDeleteModal}
        handleToggleDefault={handleToggleDefault}
        openSidebarForEditing={openEditDrawer}
      />

      <EditRestaurantDrawer
        open={Boolean(editingRestaurant)}
        onClose={() => setEditingRestaurant(null)}
        restaurant={editingRestaurant}
        onUpdate={async () => {
          await loadUserRestaurants();
        }}
      />

      {showDeleteModal && (
        <ConfirmationDialog
          visible={showDeleteModal}
          title="Conferma eliminazione"
          message="Attenzione! Procedendo perderai tutte informazioni create. Sei sicuro di voler eliminare questo ristorante?"
          onConfirm={handleDeleteRestaurant}
          onCancel={() => toggleDeleteModal(null)}
          confirmLabel="Elimina"
          cancelLabel="Annulla"
        />
      )}

      <Dialog
        open={showSetupWizard}
        onClose={() => setShowSetupWizard(false)}
        fullWidth
        maxWidth="md"
        PaperProps={{
          sx: {
            height: { xs: '95vh', sm: '90vh', md: '85vh' },
            overflowY: 'auto'
          }
        }}
      >
        <DialogContent>
          <RestaurantWizard
            onComplete={handleNewRestaurantCreated}
            onClose={() => setShowSetupWizard(false)}
          />
        </DialogContent>
      </Dialog>

      <CreateSingleArea
        open={showAreaDrawer}
        onClose={handleCloseSingleAreaDrawer}
        onComplete={handleSingleAreaCreated}
      />

      <Box display="flex" alignItems="center" justifyContent="center" paddingX="20px">
        <AreaSwiper
          currentAreaName={currentAreaName}
          setCurrentAreaName={setCurrentAreaName}
        />
      </Box>
    </>
  );
};

export default RestaurantOverview;