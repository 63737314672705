// TaboChat.jsx v1.50
import React, { useState } from 'react';
import {
  SwipeableDrawer,
  Box,
  Button,
  TextField,
  Typography,
  IconButton,
  CircularProgress,
  Tooltip,
} from '@mui/material';
import CustomMarkdown from './CustomMarkdown';
import ArrowCircleUpRoundedIcon from '@mui/icons-material/ArrowCircleUpRounded';

const TaboChat = ({
  open,
  onClose,
  title = 'Chat',
  placeholder = 'Scrivi un messaggio...',
  onSend,    // async function: (conversation, currentUserMessage) => { reply, patch_flag, patch, message_to_user }
  onConfirm, // async function: (patchSnapshot) => { ... }
  onCancel,  // function: () => { ... }
  disabled = false,   // disables the send button if true
  tooltip = '',       // tooltip text when disabled
}) => {
  // Local state (same as your Dialog version)
  const [messages, setMessages] = useState([]);
  const [userInput, setUserInput] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [patchFlag, setPatchFlag] = useState(false);
  const [patchSnapshot, setPatchSnapshot] = useState({});
  const [patchMessage, setPatchMessage] = useState('');

  const resetDialog = () => {
    setMessages([]);
    setUserInput('');
    setError(null);
    setPatchFlag(false);
    setPatchSnapshot({});
    setPatchMessage('');
  };

  const handleClose = () => {
    resetDialog();
    onClose();
  };

  const handleSend = async () => {
    if (!userInput.trim()) return;
    setLoading(true);
    setError(null);

    const newMessages = [...messages, { role: 'user', content: userInput }];
    setMessages(newMessages);
    const currentUserMessage = userInput;
    setUserInput('');

    try {
      const response = await onSend(newMessages, currentUserMessage);
      const { reply, patch_flag, patch, message_to_user } = response;
      const assistantMsg = { role: 'assistant', content: reply };
      setMessages([...newMessages, assistantMsg]);
      setPatchFlag(patch_flag);
      setPatchSnapshot(patch);
      setPatchMessage(message_to_user);
    } catch (err) {
      setError('Si è verificato un errore. Riprova.');
    } finally {
      setLoading(false);
    }
  };

  const handleConfirm = async () => {
    if (onConfirm) {
      setLoading(true);
      setError(null);
      try {
        await onConfirm(patchSnapshot);
        setPatchFlag(false);
      } catch (err) {
        setError("Errore nell'aggiornamento. Riprova.");
      } finally {
        setLoading(false);
      }
    }
  };

  const handleCancel = () => {
    if (onCancel) onCancel();
    setPatchFlag(false);
  };

  const renderChatMessages = () => {
    if (messages.length === 0) {
      return (
        <Typography variant="h3" align="center" sx={{ color: '#333', mt: 4 }}>
          Come posso aiutarti?
        </Typography>
      );
    }
    return messages.map((msg, index) => (
      <Typography
        key={index}
        sx={{
          mt: 1,
          alignSelf: msg.role === 'assistant' ? 'flex-start' : 'flex-end',
          backgroundColor: msg.role === 'assistant' ? '#ff6a3e1f' : '#3e3eff1f',
          padding: '8px 12px',
          borderRadius: '20px',
          maxWidth: '80%',
        }}
      >
        <CustomMarkdown content={msg.content || patchMessage} />
      </Typography>
    ));
  };

  // Disable send button if loading or if disabled is set
  const buttonDisabled = loading || disabled;

  return (
    <SwipeableDrawer
      anchor="bottom"
      open={open}
      onClose={handleClose}
      onOpen={() => {}}
      disableSwipeToOpen={false}
      PaperProps={{
        sx: {
          minHeight: '50vh',
          maxHeight: '90vh',
          overflow: 'visible',
        },
      }}
    >
      <Box sx={{ p: 2, height: '100%', display: 'flex', flexDirection: 'column', overflow: 'hidden', }}>
        {/* Header */}
        <Typography variant="body1" align="left" sx={{ fontWeight: 550 }}>
          {title}
        </Typography>

        {/* Chat Content */}
        <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1, overflowY: 'auto', mt: 2 }}>
          {renderChatMessages()}

          {loading && (
            <Box
              sx={{
                mt: 1,
                alignSelf: 'flex-start',
                backgroundColor: '#f5f5f5',
                padding: '8px 12px',
                borderRadius: '8px',
                maxWidth: '80%',
                display: 'flex',
                alignItems: 'center',
                gap: '8px',
              }}
            >
              <CircularProgress size={20} />
              <Typography sx={{ fontSize: '16px' }} color="textSecondary">
                Sto pensando...
              </Typography>
            </Box>
          )}

          {error && (
            <Typography color="error" variant="body2" sx={{ mt: 2 }}>
              {error}
            </Typography>
          )}
        </Box>

        {/* Footer / Actions */}
        <Box sx={{ mt: 2 }}>
          {patchFlag && (
            <Box sx={{ mb: 1, display: 'flex', gap: 1 }}>
              <Button color="secondary" variant="text" onClick={handleCancel} disabled={loading}>
                Annulla
              </Button>
              <Button variant="text" onClick={handleConfirm} disabled={loading}>
                Conferma modifiche
              </Button>
            </Box>
          )}
          {/* Container for the TextField and IconButton */}
          <Box sx={{ position: 'relative', width: '100%' }}>
            <TextField
              fullWidth
              variant="outlined"
              placeholder={placeholder}
              value={userInput}
              onChange={(e) => setUserInput(e.target.value)}
              multiline
              rows={4}
              sx={{
                '& .MuiInputBase-input': {
                  fontSize: '16px',
                  textAlignVertical: 'top',
                  padding: '10px',
                  paddingRight: '45px',
                },
                '& .MuiInputBase-input::placeholder': {
                  fontSize: '16px',
                },
                '& .MuiOutlinedInput-root': {
                  borderRadius: '20px',
                  height: 'auto',
                },
              }}
            />
            {tooltip && buttonDisabled ? (
              <Tooltip title={tooltip}>
                <span>
                  <IconButton
                    onClick={handleSend}
                    disabled={buttonDisabled}
                    disableRipple
                    sx={{
                      position: 'absolute',
                      bottom: 16,
                      right: 16,
                      '&:hover': {
                        backgroundColor: 'transparent',
                        '& .MuiSvgIcon-root': { color: 'secondary.light' },
                      },
                    }}
                  >
                    <ArrowCircleUpRoundedIcon sx={{ fontSize: '36px', color: 'var(--color-secondary)' }} />
                  </IconButton>
                </span>
              </Tooltip>
            ) : (
              <IconButton
                onClick={handleSend}
                disabled={buttonDisabled}
                disableRipple
                sx={{
                  position: 'absolute',
                  bottom: 6,
                  right: 12,
                  '&:hover': {
                    backgroundColor: 'transparent',
                    '& .MuiSvgIcon-root': { color: 'secondary.light' },
                  },
                }}
              >
                <ArrowCircleUpRoundedIcon sx={{ fontSize: '40px', color: 'var(--color-secondary)' }} />
              </IconButton>
            )}
          </Box>
          <Typography sx={{fontSize: '12px', color: 'gray', mt: 1, textAlign: {xs: 'center', md: 'left'}, px: 2 }}>
            L'assistente potrebbe fornire informazioni parziali o errate.
          </Typography>
        </Box>
      </Box>
    </SwipeableDrawer>
  );
};

export default TaboChat;
