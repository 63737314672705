// AuthContext.js
import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import { login, logout, fetchUserProfile } from '../services/authApiService';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [roles, setRoles] = useState([]);
  const [userProfile, setUserProfile] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  // Memoize loadUserProfile to avoid infinite loops in useEffect consumers.
  const loadUserProfile = useCallback(async () => {
    try {
      const profileData = await fetchUserProfile();
      setUserProfile(profileData);
      setIsAuthenticated(true);
      if (profileData.roles) {
        setRoles(profileData.roles);
      }
    } catch (error) {
      setIsAuthenticated(false);
      setUserProfile(null);
    } finally {
      setIsLoading(false);
    }
  }, []);

  // Admin Login
  const loginUser = async (credentials) => {
    try {
      const data = await login(credentials);
      setIsAuthenticated(true);
      setRoles(data.roles);
      await loadUserProfile();
      return data;
    } catch (error) {
      console.error('Login failed:', error);
      setIsAuthenticated(false);
      setIsLoading(false);
      throw error;
    }
  };

  // Admin Logout
  const logoutUser = async () => {
    try {
      await logout();
      setIsAuthenticated(false);
      setRoles([]);
      setUserProfile(null);
      setIsLoading(false);
    } catch (error) {
      console.error('Logout failed:', error);
    }
  };

  useEffect(() => {
    // List of public routes where no user profile fetch should occur.
    const publicRoutes = [
      '/register',
      '/verify',
      '/login',
      '/password-reset',
      '/password-email-sent',
      '/password-confirm',
      '/session-expired',
    ];
    if (publicRoutes.some(route => window.location.pathname.startsWith(route))) {
      setIsLoading(false);
      return;
    }
    loadUserProfile();
  }, [loadUserProfile]);

  return (
    <AuthContext.Provider value={{
      isAuthenticated,
      roles,
      loadUserProfile,
      login: loginUser,
      logout: logoutUser,
      userProfile,
      isLoading,
    }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
