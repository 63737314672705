import React, { useEffect, useState } from 'react';
import { Box, Typography, Switch, FormControlLabel, IconButton, Tooltip } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { updateRestaurantPublishStatus } from '../../../services/apiService';
import { useAlert } from '../../../contexts/AlertContext';

const PageWidget = ({ selectedRestaurant }) => {
  const { showToast } = useAlert();
  const [isPublished, setIsPublished] = useState(
    selectedRestaurant?.is_reservation_page_published || false
  );
  const [reloadToken, setReloadToken] = useState(Date.now());
  const baseUrl = process.env.REACT_APP_URL;

  // Construct a clean URL (for external use)
  const cleanPreviewUrl = selectedRestaurant
    ? `${baseUrl}users/reservation/restaurant/${selectedRestaurant.id}`
    : '';
  // Use timestamp only for the iframe to force refresh internally
  const iframePreviewUrl = cleanPreviewUrl ? `${cleanPreviewUrl}?t=${reloadToken}` : '';

  useEffect(() => {
    if (selectedRestaurant) {
      setIsPublished(selectedRestaurant.is_reservation_page_published);
    }
  }, [selectedRestaurant]);

  const handlePublishToggle = async (e) => {
    const newStatus = e.target.checked;
    setIsPublished(newStatus); // Optimistic update
    setReloadToken(Date.now()); // Force iframe refresh

    try {
      await updateRestaurantPublishStatus(selectedRestaurant.id, newStatus);
    } catch (error) {
      console.error('Failed to update publish status:', error);
      setIsPublished(!newStatus);
      setReloadToken(Date.now());
    }
  };

  const handleCopyLink = () => {
    if (cleanPreviewUrl) {
      navigator.clipboard.writeText(cleanPreviewUrl);
      showToast({
        message: "Link copiato con successo!",
        variant: "success"
      })
    }
  };

  return (
    <Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end', mb: 2 }}>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          {isPublished && (
            <Typography
              sx={{
                borderRadius: '6px',
                fontSize: '12px',
                fontWeight: 550,
                backgroundColor: '#b2f79abb',
                color: 'darkgreen',
                width: '120px',
                height: 20,
                px: 0.85,
                textAlign: 'center',
                mb: 0.5,
              }}
            >
              Prenotazioni attive
            </Typography>
          )}
          <FormControlLabel
            sx={{ ml: 0.5 }}
            control={<Switch checked={isPublished} onChange={handlePublishToggle} />}
            label={isPublished ? "Disattiva" : "Riattiva prenotazioni"}
          />
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography sx={{ mr: 1 }}>Copia il link</Typography>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Tooltip title="Copia link">
              <IconButton onClick={handleCopyLink} size="small">
                <ContentCopyIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </Box>
        </Box>
      </Box>
      {iframePreviewUrl && (
        <iframe
          src={iframePreviewUrl}
          width="100%"
          height="600"
          title="Reservation Page Preview"
          style={{ border: '1px solid #ccc', borderRadius: '8px' }}
        />
      )}
    </Box>
  );
};

export default PageWidget;
