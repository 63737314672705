// AnalyticsHome.jsx v1.50
import React, { useState, useEffect } from 'react';
import {
  Typography, Grid, Card, CardContent, Box, useTheme, Button,
  Dialog, DialogTitle, DialogContent, DialogActions, Tooltip as MuiTooltip
} from '@mui/material';
import { fetchAnalyticsData, getAIInsights } from '../../services/apiService';
import {
  BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer,
  PieChart, Pie, Legend, Cell, ComposedChart, Line
} from 'recharts';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import { AutoAwesome } from '@mui/icons-material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import CustomLoader from '../Common/CustomLoader';
import { round } from 'lodash';
import logo from '../../assets/logo_small.png';
import InsightsDialog from './InsightsDialog';
import { useMembership } from '../../hooks/useMembership';
import { useDailyUsageContext } from '../../contexts/DailyUsageContext';

const AnalyticsHome = () => {
  const [analyticsData, setAnalyticsData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [startDate, setStartDate] = useState(dayjs().subtract(7, 'day')); // Default to last 7 days
  const [endDate, setEndDate] = useState(dayjs());
  const theme = useTheme();
  const { hasPaidModule } = useMembership();
  const { usage, increment } = useDailyUsageContext();
  // AI Insights dialog states
  const [insightsOpen, setInsightsOpen] = useState(false);
  const [insights, setInsights] = useState('');
  const [loadingInsights, setLoadingInsights] = useState(false);
  const [openReportDialog, setOpenReportDialog] = useState(false);

  const formattedStartDate = startDate.format('DD/MM/YYYY');
  const formattedEndDate = endDate.format('DD/MM/YYYY');
  const formatter = new Intl.NumberFormat('it-IT', {
    style: 'currency',
    currency: 'EUR',
  });
  const noDecimalFormatter = new Intl.NumberFormat('it-IT', {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });

  const getTooltipMessage = () => {
    if (!hasPaidModule) {
      return "Acquista un modulo a tua scelta per utilizzare Tabo Assistant";
    }
    if (usage.disabled) {
      return "Hai raggiunto il limite giornaliero di utilizzo";
    }
    return "";
  };

  const handleOpenReportDialog = () => {
    setOpenReportDialog(true);
  };

  const handleCloseReportDialog = () => {
    setOpenReportDialog(false);
  };

  // *** Generate the report with comparison_type = 'week' or 'month' ***
  const handleGenerateReport = async (comparisonType) => {
    // 1. Close the 'choose type' dialog
    setOpenReportDialog(false);
    // 2. Open the AI insights dialog in "loading" mode
    setInsights('');
    setLoadingInsights(true);
    setInsightsOpen(true);

    try {
      // 3. Call the AI endpoint
      const insightsResponse = await getAIInsights(analyticsData, comparisonType);
      increment(); // Increment usage for AI
      // 4. Set the GPT result
      setInsights(insightsResponse.insights);
    } catch (error) {
      console.error('Failed to get AI insights:', error);
      setInsights('Si è verificato un errore durante la generazione del report.');
    } finally {
      setLoadingInsights(false);
    }
  };

  const handleCloseInsights = () => {
    setInsightsOpen(false);
  };

  // Colors for these pie charts (you can define as needed)
  const profitableItemColors = [
    theme.palette.primary.main,
    theme.palette.primary.light,
    theme.palette.primary.dark,
    '#CCCCCC'
  ];
  const profitableDrinkColors = [
    theme.palette.secondary.main,
    theme.palette.secondary.light,
    theme.palette.secondary.dark,
    '#CCCCCC'
  ];

  useEffect(() => {
    const loadAnalyticsData = async () => {
      try {
        const data = await fetchAnalyticsData(startDate, endDate);
        setAnalyticsData(data);
      } catch (error) {
        console.error('Failed to load analytics data:', error);
      } finally {
        setLoading(false);
      }
    };

    loadAnalyticsData();
  }, [startDate, endDate]);

  if (loading || !analyticsData) {
    return <CustomLoader open text="Caricamento in corso..." />;
  }

  // Custom label for Revenue Split pie chart
  const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
    const RADIAN = Math.PI / 180;
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);
    return (
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor={x > cx ? 'start' : 'end'}
        dominantBaseline="central"
        style={{ fontSize: 12 }}
      >
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };

  // Custom label for these pie charts (display revenue %)
  const renderProfitableLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent }) => {
    const RADIAN = Math.PI / 180;
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);
    return (
      <text
        x={x}
        y={y}
        fill="#fff"
        textAnchor="middle"
        dominantBaseline="central"
        style={{ fontSize: 12 }}
      >
        {(percent * 100).toFixed(0)}%
      </text>
    );
  };

  // Destructure analytics KPI data
  const {
    total_sales,
    number_of_orders,
    average_order_value,
    average_table_turnover,
    trend_over_period,
    top_selling_items,
    top_selling_drinks,
    least_sold_items,
    orders_distribution_by_hour,
    revenue_split,
  } = analyticsData;

  // Prepare chart data for top-selling items
  const itemsChartData = top_selling_items.map((item) => ({
    name: item.item_name, // from annotated field in the service
    Ordinazioni: parseInt(item.total_quantity, 10),
  }));

  // Prepare chart data for top-selling drinks
  const drinksChartData = top_selling_drinks.map((drink) => ({
    name: drink.menu_item__name, // drinks KPI returns menu_item__name
    Ordinazioni: parseInt(drink.total_quantity, 10),
  }));

  // Prepare chart data for least sold items
  const leastItemsChartData = least_sold_items.map((item) => ({
    name: item.item_name,
    Ordinazioni: parseInt(item.total_quantity, 10),
  }));

  // Prepare chart data for orders distribution
  const ordersChartData = orders_distribution_by_hour.map(entry => ({
    hour: `${entry.hour < 10 ? '0' : ''}${entry.hour}:00`,
    Ordinazioni: entry.order_count,
  }));

  // Prepere data for revenue and orders trend
  const weeklyTrendChartData = (trend_over_period || []).map((w) => {
    const start = dayjs(w.week_start).format('DD/MM');
    const end = dayjs(w.week_end).format('DD/MM');
    return {
      weekRange: `${start} - ${end}`,
      total_sales: parseFloat(w.total_sales),
      orders_count: w.orders_count,
    };
  });

  // Prepare pie chart data for revenue split
  const revenueSplitData = [
    { name: 'Piatti', value: round(revenue_split.food_percentage) },
    { name: 'Bevande', value: round(revenue_split.drinks_percentage) },
  ];

  // Prepare pie chart data for top profitable items
  const topProfitableItemsData = () => {
    const { top_items, total_revenue } = analyticsData.top_profitable_items;
    const top3Sum = top_items.reduce((acc, cur) => acc + parseFloat(cur.revenue), 0);
    const others = total_revenue - top3Sum;
    // Build array: each top item gets a slice, plus an "Other" slice.
    return [
      ...top_items.map(item => ({
        name: item.item_name,
        revenue: parseFloat(item.revenue),
      })),
      { name: 'Altro', revenue: others > 0 ? parseFloat(others) : 0 },
    ];
  };

  // Prepare pie chart data for top profitable drinks
  const topProfitableDrinksData = () => {
    const { top_drinks, total_revenue } = analyticsData.top_profitable_drinks;
    const top3Sum = top_drinks.reduce((acc, cur) => acc + parseFloat(cur.revenue), 0);
    const others = total_revenue - top3Sum;
    return [
      ...top_drinks.map(drink => ({
        name: drink.drink_name,
        revenue: parseFloat(drink.revenue),
      })),
      { name: 'Altro', revenue: others > 0 ? parseFloat(others) : 0 },
    ];
  };

  const CustomCalendarIcon = (props) => (
    <CalendarMonthOutlinedIcon {...props} sx={{ fontSize: '21px', color: 'secondary.light' }} />
  );

  // Determine tooltip message for the "Tabo Report" button
  const tooltipMessage = getTooltipMessage();
  const aiButton = (
    <Button
      variant="outlined"
      color="primary"
      endIcon={<AutoAwesome />}
      onClick={handleOpenReportDialog}
      disabled={loadingInsights || usage.disabled || !hasPaidModule}
    >
      {loadingInsights ? 'Sto analizzando...' : 'Tabo Report'}
    </Button>
  );

  return (
    <Box sx={{ p: 3 }}>
      {/* AI Insights Button and Date Pickers */}
      <Box sx={{ display: 'flex', alignItems: 'flex-start', marginBottom: 2, marginTop: 2 }}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Box display="flex" flexDirection="column" >
            <Grid container spacing={2} justifyContent="flex-start" alignItems="center">
              <Grid item>
                <DatePicker
                  label="Data Inizio"
                  value={startDate}
                  onChange={(newValue) => setStartDate(newValue)}
                  slots={{
                    openPickerIcon: CustomCalendarIcon,
                  }}
                  slotProps={{
                    textField: {
                      sx: {
                        width: 150,
                        marginRight: 2,
                        '& .MuiInputBase-root': {
                          height: '40px',
                        },
                      },
                    },
                  }}
                />
              </Grid>
              <Grid item>
                <DatePicker
                  label="Data Fine"
                  value={endDate}
                  onChange={(newValue) => setEndDate(newValue)}
                  slots={{
                    openPickerIcon: CustomCalendarIcon,
                  }}
                  slotProps={{
                    textField: {
                      sx: {
                        width: 150,
                        '& .MuiInputBase-root': {
                          height: '40px',
                        },
                      },
                    },
                  }}
                />
              </Grid>
              {/* Date Shortcut Buttons */}
              <Box sx={{ display: 'flex', gap: 0.5, mt: 0.75, pl: 2 }}>
                <Button
                  variant="text"
                  color="primary"
                  sx={{ fontSize: 13, fontWeight: 600 }}
                  onClick={() => {
                    const today = dayjs();
                    setStartDate(today);
                    setEndDate(today);
                  }}
                >
                  Oggi
                </Button>
                <Button
                  variant="text"
                  color="primary"
                  sx={{ fontSize: 13, fontWeight: 600 }}
                  onClick={() => {
                    setStartDate(dayjs().subtract(7, 'day'));
                    setEndDate(dayjs());
                  }}
                >
                  Ultimi 7 giorni
                </Button>
                <Button
                  variant="text"
                  color="primary"
                  sx={{ fontSize: 13, fontWeight: 600 }}
                  onClick={() => {
                    setStartDate(dayjs().subtract(30, 'day'));
                    setEndDate(dayjs());
                  }}
                >
                  Ultimi 30 giorni
                </Button>
              </Box>
            </Grid>
          </Box>
        </LocalizationProvider>
        <Box sx={{ display: 'flex', width: '100%', justifyContent: 'flex-end' }}>
          {tooltipMessage ? (
            <MuiTooltip title={tooltipMessage}>
              <span style={{ display: 'inline-block' }}>
                {aiButton}
              </span>
            </MuiTooltip>
          ) : (
            aiButton
          )}
        </Box>
      </Box>

      {/* KPI Cards */}
      <Grid container spacing={3} sx={{ marginTop: 2 }}>
        <Grid item xs={12} md={3}>
          <Card elevation={1}>
            <CardContent>
              <Typography color="textSecondary" gutterBottom>
                Fatturato
              </Typography>
              <Typography variant="h5">
                {formatter.format(total_sales)}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={3}>
          <Card elevation={1}>
            <CardContent>
              <Typography color="textSecondary" gutterBottom>
                Numero di ordinazioni
              </Typography>
              <Typography variant="h5">{number_of_orders}</Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={3}>
          <Card elevation={1}>
            <CardContent>
              <Typography color="textSecondary" gutterBottom>
                Incasso medio
              </Typography>
              <Typography variant="h5">
                {formatter.format(average_order_value)}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={3}>
          <Card elevation={1}>
            <CardContent>
              <Typography color="textSecondary" gutterBottom>
                Durata media
              </Typography>
              <Typography variant="h5">
                {parseFloat(average_table_turnover).toFixed(1)} min
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        {/* Revenue and Orders Trend */}
        <Grid item xs={12}>
          <Card elevation={1}>
            <CardContent>
              <Typography variant="h6" gutterBottom sx={{ mb: 2 }}>
                Trend Settimanale
              </Typography>

              <ResponsiveContainer width="100%" height={300}>
                <ComposedChart data={weeklyTrendChartData} margin={{ right: 20 }}>
                  <XAxis dataKey="weekRange" />
                  <YAxis yAxisId="left" label={{ value: 'Ordini', angle: -90, position: 'insideLeft' }} />
                  <YAxis
                    yAxisId="right"
                    orientation="right"
                    tickFormatter={(value) => noDecimalFormatter.format(value)}
                  />
                  <Tooltip
                    formatter={(value, name, props) => {
                      if (props.dataKey === 'total_sales') {
                        return [formatter.format(value), 'Fatturato'];
                      }
                      if (props.dataKey === 'orders_count') {
                        return [value, 'Ordini'];
                      }
                      return [value, name];
                    }}
                  />
                  <Legend />
                  {/* Bar for Orders */}
                  <Bar dataKey="orders_count" name="Ordini" fill={theme.palette.primary.light} yAxisId="left" />
                  {/* Line for Revenue */}
                  <Line
                    type="monotone"
                    dataKey="total_sales"
                    name="Fatturato"
                    stroke={theme.palette.secondary.light}
                    strokeWidth={3}
                    yAxisId="right"
                    dot={false}
                  />
                </ComposedChart>
              </ResponsiveContainer>
            </CardContent>
          </Card>
        </Grid>

        {/* Orders Distribution by Hour Chart */}
        <Grid item xs={12}>
          <Card elevation={1}>
            <CardContent>
              <Typography variant="h6" gutterBottom sx={{ mb: 2 }}>
                Distribuzione oraria delle ordinazioni
              </Typography>
              <ResponsiveContainer width="100%" height={300}>
                <BarChart data={ordersChartData}>
                  <XAxis dataKey="hour" />
                  <YAxis />
                  <Tooltip />
                  <Bar dataKey="Ordinazioni" fill={theme.palette.primary.light} />
                </BarChart>
              </ResponsiveContainer>
            </CardContent>
          </Card>
        </Grid>

        {/* Top Profitable Items Pie Chart */}
        <Grid item xs={12} md={6}>
          <Card elevation={1}>
            <CardContent>
              <Typography variant="h6" gutterBottom sx={{ mb: 2 }}>
                Migliori piatti per fatturato
              </Typography>
              <ResponsiveContainer width="100%" height={300}>
                <PieChart>
                  <Pie
                    data={topProfitableItemsData()}
                    dataKey="revenue"
                    nameKey="name"
                    cx="50%"
                    cy="50%"
                    outerRadius={80}
                    innerRadius={30}
                    label={renderProfitableLabel}
                    labelLine={false}
                  >
                    {topProfitableItemsData().map((entry, index) => (
                      <Cell
                        key={`cell-profitable-item-${index}`}
                        fill={profitableItemColors[index % profitableItemColors.length]}
                      />
                    ))}
                  </Pie>
                  <Tooltip formatter={(value) => `€${value}`} />
                  <Legend />
                </PieChart>
              </ResponsiveContainer>
            </CardContent>
          </Card>
        </Grid>

        {/* Top Profitable Drinks Pie Chart */}
        <Grid item xs={12} md={6}>
          <Card elevation={1}>
            <CardContent>
              <Typography variant="h6" gutterBottom sx={{ mb: 2 }}>
                Migliori bevande per fatturato
              </Typography>
              <ResponsiveContainer width="100%" height={300}>
                <PieChart>
                  <Pie
                    data={topProfitableDrinksData()}
                    dataKey="revenue"
                    nameKey="name"
                    cx="50%"
                    cy="50%"
                    outerRadius={80}
                    innerRadius={30}
                    label={renderProfitableLabel}
                    labelLine={false}
                  >
                    {topProfitableDrinksData().map((entry, index) => (
                      <Cell
                        key={`cell-profitable-drink-${index}`}
                        fill={profitableDrinkColors[index % profitableDrinkColors.length]}
                      />
                    ))}
                  </Pie>
                  <Tooltip formatter={(value) => `€${value}`} />
                  <Legend />
                </PieChart>
              </ResponsiveContainer>
            </CardContent>
          </Card>
        </Grid>

        {/* Top-Selling Items Chart */}
        <Grid item xs={12} md={6} >
          <Card elevation={1}>
            <CardContent>
              <Typography variant="h6" gutterBottom sx={{ mb: 2 }}>
                Piatti più venduti
              </Typography>
              <ResponsiveContainer width="100%" height={300} sx={{ mb: 2 }}>
                <BarChart data={itemsChartData}>
                  <XAxis dataKey="name" />
                  <YAxis />
                  <Tooltip />
                  <Bar dataKey="Ordinazioni" fill={theme.palette.success.light} />
                </BarChart>
              </ResponsiveContainer>
            </CardContent>
          </Card>
        </Grid>

        {/* Top-Selling Drinks Chart */}
        {drinksChartData.length > 0 && (
          <Grid item xs={12} md={6} >
            <Card elevation={1}>
              <CardContent>
                <Typography variant="h6" gutterBottom sx={{ mb: 2 }}>
                  Bevande più vendute
                </Typography>
                <ResponsiveContainer width="100%" height={300} sx={{ mb: 2 }}>
                  <BarChart data={drinksChartData}>
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip />
                    <Bar dataKey="Ordinazioni" fill={theme.palette.secondary.main} />
                  </BarChart>
                </ResponsiveContainer>
              </CardContent>
            </Card>
          </Grid>
        )}

        {/* Revenue Split Pie Chart */}
        <Grid item xs={12} md={6}>
          <Card elevation={1}>
            <CardContent>
              <Typography variant="h6" gutterBottom sx={{ mb: 2 }}>
                Ripartizione fatturato (%)
              </Typography>
              <ResponsiveContainer width="100%" height={300}>
                <PieChart>
                  <Pie
                    data={revenueSplitData}
                    dataKey="value"
                    nameKey="name"
                    cx="50%"
                    cy="50%"
                    outerRadius={80}
                    innerRadius={0}
                    labelLine={false}
                    label={renderCustomizedLabel}
                  >
                    {revenueSplitData.map((entry, index) => (
                      <Cell
                        key={`cell-${index}`}
                        fill={index === 0 ? theme.palette.secondary.main : theme.palette.primary.main}
                      />
                    ))}
                  </Pie>
                  <Tooltip formatter={(value) => `${value}%`} />
                  <Legend />
                </PieChart>
              </ResponsiveContainer>
            </CardContent>
          </Card>
        </Grid>

        {/* Least Sold Items Chart */}
        <Grid item xs={12} md={6}>
          <Card elevation={1}>
            <CardContent>
              <Typography variant="h6" gutterBottom sx={{ mb: 2 }}>
                Piatti meno venduti
              </Typography>
              <ResponsiveContainer width="100%" height={300}>
                <BarChart data={leastItemsChartData}>
                  <XAxis dataKey="name" />
                  <YAxis label={{ value: 'Quantità', angle: -90, position: 'insideLeft' }} />
                  <Tooltip />
                  <Bar dataKey="Ordinazioni" fill={theme.palette.error.light} barSize={30} />
                </BarChart>
              </ResponsiveContainer>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      {/* Dialog to choose Settimanale or Mensile */}
      <Dialog
        open={openReportDialog}
        onClose={handleCloseReportDialog}
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle>Seleziona il tipo di report</DialogTitle>
        <DialogContent>
          <Typography variant="body2">
            Vuoi un confronto settimana su settimana o mese su mese?
          </Typography>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'flex-end', p: 2 }}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleGenerateReport('week')}
          >
            Report Settimanale
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => handleGenerateReport('month')}
          >
            Report Mensile
          </Button>
        </DialogActions>
      </Dialog>

      {/* AI Insights Dialog */}
      <InsightsDialog
        insightsOpen={insightsOpen}
        handleCloseInsights={handleCloseInsights}
        loadingInsights={loadingInsights}
        logo={logo}
        formattedStartDate={formattedStartDate}
        formattedEndDate={formattedEndDate}
        insights={insights}
      />
    </Box >
  );
};

export default AnalyticsHome;