// OnlineMenu v2.01 - Optimized
import React, { useEffect, useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import {
  fetchLiveMenuDetails,
  placeOrder,
  fetchBillDetails
} from '../../services/usersApiService';
import { aggregateAndOptimizeForUsers } from '../../utils/orderAggregation';
import ReviewOrderModal from './ReviewOrderModal';
import BillReviewModal from './BillReviewModal';
import PaymentDrawer from './PaymentDrawer';
import AllergenDialog from './AllergenDialog';
import OnlineMenuUI from '../../components/Common/OnlineMenuUI';
import { Box, CircularProgress } from '@mui/material';
import cover from '../../assets/images/default-cover-image.png';
import logo from '../../assets/logo_small.png';

const OnlineMenu = ({ menuId: propMenuId, isPreview = false, selectedRestaurant, tableId }) => {
  // Use either the menuId from props (preview) or from URL parameters (live)
  const { menuId: urlMenuId, restaurantId: urlRestaurantId, tableId: urlTableId } = useParams();
  const menuId = propMenuId || urlMenuId;
  const effectiveRestaurantId = selectedRestaurant || urlRestaurantId;
  const effectiveTableId = tableId || urlTableId;
  const [menuData, setMenuData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [orderItems, setOrderItems] = useState([]);
  const [isReviewModalOpen, setIsReviewModalOpen] = useState(false);
  const [isBillModalOpen, setIsBillModalOpen] = useState(false);
  const [billDetails, setBillDetails] = useState({ items: [], totalAmount: 0 });
  const [currentOrderId, setCurrentOrderId] = useState(() => localStorage.getItem('currentOrderId'));
  const [isPaymentDrawerOpen, setIsPaymentDrawerOpen] = useState(false);
  const [aggregatedOrder, setAggregatedOrder] = useState(null);
  const [allergenDialogOpen, setAllergenDialogOpen] = useState(false);
  const [currentAllergens, setCurrentAllergens] = useState([]);
  const [currentItemName, setCurrentItemName] = useState('');
  const [searchQuery, setSearchQuery] = useState('');

  // Fetch menu details
  useEffect(() => {
    const fetchMenuDetails = async () => {
      if (!menuId || menuId === 'default') {
        console.error("Menu ID is 'default' or missing, cannot fetch menu details.");
        setLoading(false);
        return;
      }
      setLoading(true);
      try {
        const data = await fetchLiveMenuDetails(menuId, effectiveRestaurantId, effectiveTableId);
        setMenuData(data);
      } catch (error) {
        console.error('Failed to fetch menu details:', error);
      } finally {
        setLoading(false);
      }
    };
    fetchMenuDetails();
  }, [menuId, effectiveRestaurantId, effectiveTableId]);

  // Initialize or retrieve session key
  useEffect(() => {
    let sessionKey = localStorage.getItem('session_key');
    if (!sessionKey) {
      sessionKey = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        const r = (Math.random() * 16) | 0;
        const v = c === 'x' ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      });
      localStorage.setItem('session_key', sessionKey);
    }
  }, []);

  // Memoized function to add an item to the order
  const addItemToOrder = useCallback((item, courseType) => {
    setOrderItems(prevItems => {
      const existingItem = prevItems.find(prevItem => prevItem.id === item.id);
      if (existingItem) {
        return prevItems.map(prevItem =>
          prevItem.id === item.id
            ? { ...prevItem, quantity: prevItem.quantity + 1, course_type: courseType }
            : prevItem
        );
      } else {
        return [...prevItems, { ...item, quantity: 1, course_type: courseType }];
      }
    });
  }, []);

  const handleAddItem = useCallback((item, courseType) => {
    addItemToOrder(item, courseType);
  }, [addItemToOrder]);

  // Memoized function to remove an item from the order
  const removeItemFromOrder = useCallback((itemId) => {
    setOrderItems(prevItems => {
      const existingItem = prevItems.find(item => item.id === itemId);
      if (existingItem && existingItem.quantity > 1) {
        return prevItems.map(item =>
          item.id === itemId ? { ...item, quantity: item.quantity - 1 } : item
        );
      } else {
        return prevItems.filter(item => item.id !== itemId);
      }
    });
  }, []);

  const updateCurrentOrderId = useCallback((orderId) => {
    setCurrentOrderId(orderId);
    localStorage.setItem('currentOrderId', orderId);
  }, []);

  // Memoized function to prepare order data
  const prepareOrderData = useCallback(() => {
    const sessionKey = localStorage.getItem('session_key');
    const orderItemsWithTypes = orderItems.map(item => ({
      id: item.id,
      quantity: item.quantity,
      type: item.type,
      course_type: item.course_type,
      session_key: sessionKey
    }));
    return {
      table: tableId,
      items: orderItemsWithTypes
    };
  }, [orderItems, tableId]);

  const sendOrder = useCallback(async () => {
    const orderData = prepareOrderData();
    try {
      const placedOrder = await placeOrder(tableId, orderData);
      updateCurrentOrderId(placedOrder.id);
      setOrderItems([]);
    } catch (error) {
      console.error('Failed to place order:', error);
    }
  }, [prepareOrderData, tableId, updateCurrentOrderId]);

  // Memoize review modal opening to optimize re-rendering
  const openReviewModal = useCallback(() => {
    const aggregatedOrderData = aggregateAndOptimizeForUsers(orderItems);
    setAggregatedOrder(aggregatedOrderData);
    setIsReviewModalOpen(true);
  }, [orderItems]);

  // Memoize bill modal opening
  const openBillModal = useCallback(async () => {
    try {
      setIsBillModalOpen(true);
      if (currentOrderId) {
        const updatedBillDetails = await fetchBillDetails(currentOrderId);
        setBillDetails(updatedBillDetails);
        // If the bill is fully paid, remove the order ID and session key
        if (parseFloat(updatedBillDetails.amount_due) === 0) {
          localStorage.removeItem('currentOrderId');
          localStorage.removeItem('session_key');
          setCurrentOrderId(null);
        }
      } else {
        console.error('No current order ID found');
      }
    } catch (error) {
      console.error('Failed to fetch bill details:', error);
    }
  }, [currentOrderId]);

  const openPaymentDrawer = useCallback(() => {
    setIsPaymentDrawerOpen(true);
  }, []);

  const closePaymentDrawer = useCallback(() => {
    setIsPaymentDrawerOpen(false);
  }, []);

  const openAllergenDialog = useCallback((allergens, itemName) => {
    setCurrentAllergens(allergens);
    setCurrentItemName(itemName);
    setAllergenDialogOpen(true);
  }, []);

  const closeAllergenDialog = useCallback(() => {
    setAllergenDialogOpen(false);
  }, []);

  if (loading) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh'
        }}
      >
        <CircularProgress size={100} color="secondary" />
      </Box>
    );
  }

  const restaurant = menuData?.restaurant || {};
  const categories = menuData?.categories || [];
  const coverImageUrl = restaurant.cover_image || cover;
  const logoImageUrl = restaurant.restaurant_logo || logo;

  // Compute button visibility based on order state and bill details
  const isCartEmpty = orderItems.length === 0;
  const isOrderPlaced = Boolean(currentOrderId);
  const isBillUnpaid = isOrderPlaced && parseFloat(billDetails.amount_due || 0) > 0;
  const showReviewOrderButton = !isOrderPlaced && orderItems.length > 0;
  const showUpdateOrderButton = isOrderPlaced && orderItems.length > 0;
  const showReviewBillButton = isCartEmpty && (isOrderPlaced || isBillUnpaid);

  return (
    <Box>
      <OnlineMenuUI
        coverImageUrl={coverImageUrl}
        logoImageUrl={logoImageUrl}
        restaurant={restaurant}
        categories={categories}
        searchQuery={searchQuery}
        onSearchQueryChange={setSearchQuery}
        orderItems={orderItems}
        onAddItem={handleAddItem}
        onRemoveItem={removeItemFromOrder}
        openAllergenDialog={openAllergenDialog}
        showReviewBillButton={showReviewBillButton}
        showUpdateOrderButton={showUpdateOrderButton}
        showReviewOrderButton={showReviewOrderButton}
        currentOrderId={currentOrderId}
        openBillModal={openBillModal}
        openReviewModal={openReviewModal}
        isPreview={isPreview}
      />
      <AllergenDialog
        open={allergenDialogOpen}
        onClose={closeAllergenDialog}
        allergens={currentAllergens}
        itemName={currentItemName}
      />
      <ReviewOrderModal
        isOpen={isReviewModalOpen}
        onClose={() => setIsReviewModalOpen(false)}
        orderItems={aggregatedOrder}
        onConfirm={() => {
          sendOrder();
          setIsReviewModalOpen(false);
        }}
        onClear={() => {
          setOrderItems([]);
        }}
        showUpdateOrderButton={showUpdateOrderButton}
      />
      <BillReviewModal
        isOpen={isBillModalOpen}
        onClose={() => setIsBillModalOpen(false)}
        billDetails={billDetails}
        orderId={currentOrderId}
        onOpenPaymentDrawer={openPaymentDrawer}
      />
      <PaymentDrawer
        open={isPaymentDrawerOpen}
        onClose={closePaymentDrawer}
        billDetails={billDetails}
        orderId={currentOrderId}
      />
    </Box>
  );
};

export default OnlineMenu;
