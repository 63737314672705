import React from 'react';
import { Dialog } from '@mui/material';
import { useRestaurant } from '../../contexts/RestaurantContext';

const OnlineMenuModal = ({ open, onClose, menuId }) => {
  const { selectedRestaurant } = useRestaurant();
  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          // Style the Dialog to look like a mobile phone
          borderRadius: 2,
          width: '335px',
          height: '880px',
          // No scroll inside the Dialog,
          // let the iframe handle scrolling
          overflow: 'hidden',
          position: 'relative',
        }
      }}
    >
      {/* Iframe that points to our new route */}
      <iframe
        src={`/users/menu-preview/restaurant/${selectedRestaurant}/${menuId}`}
        style={{
          // Make the iframe fill the "phone"
          width: '100%',
          height: '100%',
          border: 'none',
        }}
        title="Menu Preview"
      />
    </Dialog>
  );
};

export default OnlineMenuModal;
