import React, { useState, useLayoutEffect, useRef, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Alert,
  Box,
  Paper,
  Typography,
  TextField,
} from '@mui/material';
import { useRestaurant } from '../../../contexts/RestaurantContext';
import { useAlert } from '../../../contexts/AlertContext';
import { useResponsive } from '../../../hooks/useResponsive';
import GridLayout from 'react-grid-layout';
import { WidthProvider } from 'react-grid-layout';
import { updateTablePositions, updateArea } from '../../../services/apiService';
import CustomLoader from '../../Common/CustomLoader';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';

const ReactGridLayout = WidthProvider(GridLayout);

const TablesLayout = ({ open, onClose, tables, area }) => {
  const { updateAreaInContext, updateTablesPositionsInArea } = useRestaurant();
  const { showToast } = useAlert();
  const { isXs, isPortrait, isLandscape } = useResponsive();

  const dialogProps = {
    fullScreen: isXs,
    PaperProps: !isXs
      ? { sx: { width: '90vw', height: '90vh', maxWidth: 'none', position: 'relative' } }
      : {},
  };

  const MIN_CELL_WIDTH = 80;
  const TABLE_SCALE = 1;
  const LANDSCAPE_COLUMNS = 8;
  const PORTRAIT_COLUMNS = 3;
  const columns = isPortrait ? PORTRAIT_COLUMNS : LANDSCAPE_COLUMNS;
  const marginArray = [10, 10];

  const [layout, setLayout] = useState([]);
  const [originalLayout, setOriginalLayout] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const [areaName, setAreaName] = useState('');
  const [originalAreaName, setOriginalAreaName] = useState('');

  const gridContainerRef = useRef(null);
  const [containerWidth, setContainerWidth] = useState(0);

  const baseCellSize =
    containerWidth > 0
      ? Math.max(
        MIN_CELL_WIDTH,
        Math.floor((containerWidth - marginArray[0] * (columns - 1)) / columns)
      )
      : MIN_CELL_WIDTH;
  const computedRowHeight = baseCellSize * TABLE_SCALE;

  useLayoutEffect(() => {
    const updateContainerWidth = () => {
      if (gridContainerRef.current) {
        setContainerWidth(gridContainerRef.current.clientWidth);
      }
    };
    updateContainerWidth();
    window.addEventListener('resize', updateContainerWidth);
    return () => window.removeEventListener('resize', updateContainerWidth);
  }, []);

  useEffect(() => {
    if (open) {
      const timeoutId = setTimeout(() => {
        if (gridContainerRef.current) {
          setContainerWidth(gridContainerRef.current.clientWidth);
        }
      }, 50);
      return () => clearTimeout(timeoutId);
    }
  }, [open]);

  useEffect(() => {
    if (open && Array.isArray(tables)) {
      const validTables = tables.filter((table) => table != null);
      let initialLayout;
      if (isPortrait) {
        initialLayout = validTables
          .sort((a, b) => a.number - b.number)
          .map((table, index) => ({
            i: table.id.toString(),
            x: index % PORTRAIT_COLUMNS,
            y: Math.floor(index / PORTRAIT_COLUMNS),
            w: 1,
            h: 1,
            tableData: table,
          }));
      } else {
        initialLayout = validTables.map((table) => ({
          i: table.id.toString(),
          x: table.position_x || 0,
          y: table.position_y || 0,
          w: table.width || 1,
          h: table.height || 1,
          tableData: table,
        }));
      }
      setLayout(initialLayout);
      setOriginalLayout(initialLayout);
    }
  }, [open, tables, isPortrait]);

  useEffect(() => {
    if (open && area) {
      setAreaName(area.name || '');
      setOriginalAreaName(area.name || '');
    }
  }, [open, area]);

  const handleLayoutChange = (newLayout) => {
    const mergedLayout = newLayout.map((item) => {
      const orig = originalLayout.find((o) => o.i === item.i);
      return {
        ...item,
        tableData: orig ? orig.tableData : {},
      };
    });
    setLayout(mergedLayout);
  };

  const handleConfirm = async () => {
    setLoading(true);
    setError(null);
    const updatedTables = layout.map((item) => ({
      id: parseInt(item.i, 10),
      position_x: item.x,
      position_y: item.y,
      width: item.w,
      height: item.h,
    }));

    try {
      await updateTablePositions(updatedTables);
      const areaId = layout[0]?.tableData?.area;
      if (areaId) {
        updateTablesPositionsInArea(areaId, updatedTables);
      }

      let updatedArea;
      if (areaName !== originalAreaName) {
        updatedArea = await updateArea(area.id, { name: areaName });
        setOriginalAreaName(areaName);
        updateAreaInContext(updatedArea);
      }

      setLoading(false);
      showToast({ message: 'Posizione dei tavoli aggiornata', variant: 'success' });
      onClose();
    } catch (err) {
      console.error('Error updating table positions:', err);
      setError('Si è verificato un errore durante l’aggiornamento. Riprova più tardi.');
      setLoading(false);
    }
  };

  const handleCancel = () => {
    setLayout(originalLayout);
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={handleCancel}
      fullScreen={dialogProps.fullScreen}
      PaperProps={dialogProps.PaperProps}
    >
      <DialogTitle>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography variant="h6">
            Disponi i tavoli
          </Typography>
          <TextField
            size="small"
            value={areaName}
            onChange={(e) => setAreaName(e.target.value)}
            label="Nome area"
            variant="outlined"
          />
        </Box>
      </DialogTitle>
      <DialogContent dividers sx={{ position: 'relative' }}>
        {error && (
          <Box mb={2}>
            <Alert severity="error" onClose={() => setError(null)}>
              {error}
            </Alert>
          </Box>
        )}
        {isPortrait && (
          <Box mb={2}>
            <Alert severity="warning">
              Ruota il dispositivo per abilitare la modifica dei tavoli.
            </Alert>
          </Box>
        )}

        {/* Loader overlay */}
        {loading && (
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              zIndex: 9999,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              bgcolor: 'rgba(255,255,255,0.7)',
            }}
          >
            <CustomLoader />
          </Box>
        )}

        {/* Grid container */}
        <Box ref={gridContainerRef} sx={{ width: '100%' }}>
          <ReactGridLayout
            layout={layout}
            onLayoutChange={handleLayoutChange}
            cols={columns}
            rowHeight={computedRowHeight}
            margin={marginArray}
            compactType={false}
            isDraggable={isLandscape}
            isResizable={false}
            autoSize={true}
            useCSSTransforms={true}
          >
            {layout.map((item) => (
              <Paper
                key={item.i}
                elevation={1}
                sx={{
                  backgroundColor: '#3e3eff7f',
                  border: '1px solid #3e3effe3',
                  borderRadius: '8px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  cursor: isLandscape ? 'grab' : 'default',
                  height: '100%',
                  minWidth: `${MIN_CELL_WIDTH * TABLE_SCALE}px`,
                  userSelect: 'none',
                  boxShadow: (theme) => theme.shadows[1],
                  transition: 'box-shadow 0.3s ease-in-out',
                  '&:hover': {
                    boxShadow: (theme) => theme.shadows[6],
                  },
                }}
              >
                <Box display="flex" flexDirection="column">
                  <Typography color="white" variant="h6" fontWeight="550">
                    Tavolo {item.tableData?.number || 'N/A'}
                  </Typography>
                  <Typography color="white" variant="body2" fontWeight="600">
                    {item.tableData?.number_of_seats || 'N/A'} posti
                  </Typography>
                </Box>
              </Paper>
            ))}
          </ReactGridLayout>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} disabled={loading}>
          Cancella
        </Button>
        <Button onClick={handleConfirm} variant="contained" disabled={!isLandscape || loading}>
          Conferma
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TablesLayout;
