// src/components/InvoiceListModal.jsx
import React, { useState, useEffect } from 'react';
import {
  Modal,
  Box,
  Typography,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@mui/material';
import { listInvoices } from '../../services/stripeApiService';

const InvoiceListModal = ({ open, onClose }) => {
  const [invoices, setInvoices] = useState([]);

  useEffect(() => {
    if (open) {
      listInvoices()
        .then((data) => setInvoices(data))
        .catch((err) => console.error('Error fetching invoices:', err));
    }
  }, [open]);

  // Sort invoices by created_at descending
  const sortedInvoices = [...invoices].sort((a, b) => {
    const dateA = new Date(a.created_at);
    const dateB = new Date(b.created_at);
    return dateB - dateA;
  });

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          width: 600,
          maxHeight: '80vh',
          overflowY: 'auto',
          p: 4,
          bgcolor: 'background.paper',
          borderRadius: 2,
          margin: 'auto',
          marginTop: '5%',
        }}
      >
        <Typography variant="h6" sx={{ mb: 4 }}>
          Le tue fatture
        </Typography>
        {sortedInvoices.length === 0 ? (
          <Typography variant="body1">Nessuna fattura disponibile.</Typography>
        ) : (
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Data</TableCell>
                  <TableCell>Fattura</TableCell>
                  <TableCell align="right">Totale</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sortedInvoices.map((invoice) => (
                  <TableRow key={invoice.stripe_invoice_id}>
                    <TableCell>
                      {invoice.period_start
                        ? new Date(invoice.period_start).toLocaleDateString('it-IT')
                        : new Date(invoice.created_at).toLocaleDateString('it-IT')}
                    </TableCell>
                    <TableCell>
                      <a
                        href={invoice.invoice_pdf_url}
                        target="_blank"
                        rel="noopener noreferrer"
                        sx={{ textDecoration: 'underline', color: 'primary' }}
                      >
                        {invoice.stripe_invoice_id}
                      </a>
                    </TableCell>
                    <TableCell align="right">
                      €{parseFloat(invoice.amount_due).toFixed(2)}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 4 }}>
          <Button variant="outlined" onClick={onClose}>
            Chiudi
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default InvoiceListModal;
