import React, { useEffect, useState, useCallback } from 'react';
import {
  Box,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Alert,
} from '@mui/material';
import { useAlert } from '../../../contexts/AlertContext';
import {
  fetchRestaurantSettings,
  updateRestaurantSettings,
  fetchReservationTimeSlots,
  updateReservationTimeSlotsBulk,
} from '../../../services/apiService';
import CustomTimePicker from '../../Common/CustomTimePicker';
import ReservationSlots from './ReservationSlots';
import { useResponsive } from '../../../hooks/useResponsive';

// Utility functions for time conversion
const timeToMinutes = (timeStr) => {
  if (!timeStr || typeof timeStr !== 'string') return 0;
  const [hh, mm] = timeStr.split(':');
  return parseInt(hh, 10) * 60 + parseInt(mm, 10);
};

const minutesToTime = (mins) => {
  const hh = String(Math.floor(mins / 60)).padStart(2, '0');
  const mm = String(mins % 60).padStart(2, '0');
  return `${hh}:${mm}`;
};

const MIN_SLOT_GAP = 15;

const ReservationSettings = ({ selectedRestaurant, noRestaurants }) => {
  // Destructure responsive values from your hook
  const { isMobile, isTablet } = useResponsive();
  // Use a dynamic width for labels
  const labelWidth = isMobile || isTablet ? '100%' : '300px';

  // Policy settings state (API values stored as "HH:MM:SS")
  const [duration, setDuration] = useState('02:00');
  const [notice, setNotice] = useState('02:00');
  const [timeSlots, setTimeSlots] = useState([]);

  // Dialog state for editing policy settings
  const [openPolicyDialog, setOpenPolicyDialog] = useState(false);
  const [dialogDuration, setDialogDuration] = useState('02:00');
  const [dialogNotice, setDialogNotice] = useState('02:00');

  // Reservation Hours dialog state
  const [openReservationDialog, setOpenReservationDialog] = useState(false);
  // reservationHours: grouped per day, e.g. 
  // [{ day_of_week: 0, is_open: true/false, time_slots: [{ id, opening_time, closing_time }, ...] }, ...]
  const [reservationHours, setReservationHours] = useState([]);

  const { showToast } = useAlert();

  // Default time slot (for a new slot in an empty day)
  const defaultTimeSlotReservation = { opening_time: '12:00', closing_time: '14:00' };

  // Helper to parse errors and return the appropriate message.
  const parseSlotError = (error) => {
    let rawMessage = error.response?.data || error.message || "";
    let message = "";
    if (typeof rawMessage === "string") {
      message = rawMessage;
    } else if (rawMessage.message && typeof rawMessage.message === "string") {
      message = rawMessage.message;
    } else {
      message = JSON.stringify(rawMessage);
    }
    if (
      message.includes("Time slot must fall within the restaurant's opening hours") ||
      message.includes("Cannot create a time slot on a day when the restaurant is closed")
    ) {
      return "Assicurati che il ristorante sia aperto nelle ore selezionate";
    }
    return "Si è verificato un errore. Controlla bene i campi";
  };

  // Load restaurant settings from backend
  const loadSettings = useCallback(async () => {
    try {
      const data = await fetchRestaurantSettings(selectedRestaurant);
      const apiDuration = data.default_reservation_duration || '02:00:00';
      const apiNotice = data.minimum_notice_period || '02:00:00';
      setDuration(apiDuration);
      setNotice(apiNotice);
    } catch (error) {
      console.error('Failed to load restaurant settings:', error);
    }
  }, [selectedRestaurant]);

  // Load reservation time slots for the restaurant (flat array)
  const loadTimeSlots = useCallback(async () => {
    try {
      const slots = await fetchReservationTimeSlots();
      const filtered = slots.filter((s) => s.restaurant === selectedRestaurant);
      setTimeSlots(filtered);
    } catch (error) {
      console.error('Failed to load time slots:', error);
    }
  }, [selectedRestaurant]);

  useEffect(() => {
    if (!selectedRestaurant) return;
    loadSettings();
    loadTimeSlots();
  }, [selectedRestaurant, loadSettings, loadTimeSlots]);

  // ------------------
  // Policy Dialog Handlers
  // ------------------
  const openDialog = () => {
    setDialogDuration(duration.substring(0, 5)); // "02:00:00" -> "02:00"
    setDialogNotice(notice.substring(0, 5));
    setOpenPolicyDialog(true);
  };

  const handleDialogConfirm = async () => {
    try {
      // Convert back to "HH:MM:00" format before sending to API
      const updatedDuration = dialogDuration.length === 5 ? `${dialogDuration}:00` : dialogDuration;
      const updatedNotice = dialogNotice.length === 5 ? `${dialogNotice}:00` : dialogNotice;
      await updateRestaurantSettings(selectedRestaurant, {
        default_reservation_duration: updatedDuration,
        minimum_notice_period: updatedNotice,
      });
      setDuration(updatedDuration);
      setNotice(updatedNotice);
      setOpenPolicyDialog(false);
      showToast({
        message: 'Impostazioni aggiornate con successo',
        variant: 'success',
      });
    } catch (error) {
      console.error('Failed to update settings:', error);
      showToast({
        message: "Errore durante l'aggiornamento delle impostazioni.",
        variant: 'error'
      });
    }
  };

  const handleDialogCancel = () => {
    setOpenPolicyDialog(false);
  };

  // -------------------------------
  // Reservation Hours Dialog Handlers
  // -------------------------------
  // When opening the dialog, group the flat timeSlots array into a weekly structure.
  const openReservationDialogHandler = () => {
    const grouped = [];
    for (let i = 0; i < 7; i++) {
      grouped.push({
        day_of_week: i,
        is_open: false,
        time_slots: [],
      });
    }
    timeSlots.forEach((slot) => {
      const dayIndex = Number(slot.day_of_week);
      if (grouped[dayIndex]) {
        grouped[dayIndex].is_open = true;
        grouped[dayIndex].time_slots.push({
          id: slot.id, // preserve id if exists
          opening_time: slot.start_time.substring(0, 5), // "HH:MM:SS" -> "HH:MM"
          closing_time: slot.end_time.substring(0, 5),
        });
      }
    });
    // Do not sort here; let the user control the order.
    setReservationHours(grouped);
    setOpenReservationDialog(true);
  };

  const handleReservationDialogCancel = () => {
    setOpenReservationDialog(false);
  };

  // -------------------------------
  // Handlers passed to ReservationSlots
  // -------------------------------
  // When adding a new slot, if there is at least one existing slot for the day,
  // calculate the new slot's default times based on the previous slot.
  const handleAddTimeSlot = (dayIndex) => {
    setReservationHours((prev) => {
      const updated = [...prev];
      const daySlots = updated[dayIndex].time_slots;
      if (daySlots.length > 0) {
        const lastSlot = daySlots[daySlots.length - 1];
        const lastCloseMins = timeToMinutes(lastSlot.closing_time);
        const newOpeningMins = lastCloseMins + MIN_SLOT_GAP;
        const newOpening = minutesToTime(newOpeningMins);
        // Calculate default duration in minutes (e.g., 2 hours = 120 minutes)
        const defaultDuration = timeToMinutes(defaultTimeSlotReservation.closing_time) - timeToMinutes(defaultTimeSlotReservation.opening_time);
        const newClosing = minutesToTime(newOpeningMins + defaultDuration);
        daySlots.push({ opening_time: newOpening, closing_time: newClosing });
      } else {
        daySlots.push({ ...defaultTimeSlotReservation });
      }
      return updated;
    });
  };

  const handleRemoveTimeSlot = (dayIndex, slotIndex) => {
    setReservationHours((prev) => {
      const updated = [...prev];
      updated[dayIndex].time_slots.splice(slotIndex, 1);
      return updated;
    });
  };

  const handleDayOpenChange = (e, dayIndex) => {
    setReservationHours((prev) => {
      const updated = [...prev];
      updated[dayIndex].is_open = e.target.checked;
      if (!e.target.checked) {
        updated[dayIndex].time_slots = [];
      } else if (updated[dayIndex].time_slots.length === 0) {
        updated[dayIndex].time_slots.push({ ...defaultTimeSlotReservation });
      }
      return updated;
    });
  };

  // When confirming, build a flat list of updated slots.
  const handleReservationDialogConfirm = async () => {
    try {
      const updatedSlots = [];
      reservationHours.forEach((day) => {
        if (day.is_open) {
          day.time_slots.forEach((slot) => {
            updatedSlots.push({
              id: slot.id, // may be undefined for new slots
              restaurant: selectedRestaurant,
              day_of_week: day.day_of_week,
              start_time: slot.opening_time + ':00', // convert to "HH:MM:SS"
              end_time: slot.closing_time + ':00',
            });
          });
        }
      });

      const payload = {
        restaurant: selectedRestaurant,
        slots: updatedSlots,
      };

      await updateReservationTimeSlotsBulk(payload);
      await loadTimeSlots();
      setOpenReservationDialog(false);
      showToast({
        message: 'Orari di prenotazione aggiornati con successo',
        variant: 'success',
      });
    } catch (error) {
      console.error('Error updating reservation hours:', error);
      const errorMsg = parseSlotError(error);
      showToast({
        message: errorMsg,
        duration: 3000,
        variant: 'error',
      });
    }
  };

  // -------------------------------
  // Display Reservation Hours Summary
  // -------------------------------
  const getGroupedReservationSummary = () => {
    const dayNames = ['Lunedì', 'Martedì', 'Mercoledì', 'Giovedì', 'Venerdì', 'Sabato', 'Domenica'];
    const grouped = {};
    timeSlots.forEach((slot) => {
      const day = Number(slot.day_of_week);
      if (!grouped[day]) {
        grouped[day] = [];
      }
      grouped[day].push(`${slot.start_time.substring(0, 5)} - ${slot.end_time.substring(0, 5)}`);
    });
    // Sort days numerically so they display in the correct order
    return Object.keys(grouped)
      .sort((a, b) => a - b)
      .map((day) => (
        <Box
          key={day}
          sx={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            mb: 1,
            gap: 1,
            flexWrap: 'wrap',
          }}
        >
          <Typography variant="body1" sx={{ width: labelWidth }}>
            {dayNames[day]}
          </Typography>
          <Box
            sx={{
              display: 'flex',
              gap: '10px',           // This gap will show the container's background
              backgroundColor: 'white', // Container background is white
              height: 20,
              px: 0.5,
            }}
          >
            {grouped[day].map((slot, index) => (
              <Typography
                key={index}
                variant="body2"
                sx={{
                  fontWeight: 550,
                  backgroundColor: '#b2f79abb',
                  color: 'darkgreen',
                  borderRadius: '5px',
                  px: 0.5,
                  height: 20,
                }}
              >
                {slot}
              </Typography>
            ))}
          </Box>

        </Box>
      ));
  };

  return (
    <Box>
      {/* Restaurant Policy Settings */}
      <Typography variant="h6" gutterBottom sx={{ p: 1 }}>
        Durata consumazione e preavviso
      </Typography>
      <Box
        sx={{
          display: 'flex',
          flexDirection: isMobile || isTablet ? 'column' : 'row',
          justifyContent: 'space-between',
          alignItems: isMobile || isTablet ? 'stretch' : 'flex-start',
          p: 2,
          mb: 2,
          border: '1px solid #ccc',
          borderRadius: '10px',
          gap: 3,
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: 1, flexWrap: 'wrap' }}>
            <Typography variant="body1" sx={{ width: labelWidth }}>
              Durata media della consumazione
            </Typography>
            <Typography variant="body2" sx={{ backgroundColor: '#b2f79abb', color: 'darkgreen', height: 20, px: 0.5, borderRadius: '5px' }}>
              <b>{duration.substring(0, 5)}</b> ore
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: 1, flexWrap: 'wrap' }}>
            <Typography variant="body1" sx={{ width: labelWidth }}>
              Preavviso minimo per la prenotazione
            </Typography>
            <Typography variant="body2" sx={{ backgroundColor: '#b2f79abb', color: 'darkgreen', height: 20, px: 0.5, borderRadius: '5px' }}>
              <b>{notice.substring(0, 5)}</b> ore
            </Typography>
          </Box>
        </Box>
        <Box sx={{ display: 'flex', alignItems: isMobile || isTablet ? 'center' : 'flex-end', mt: isMobile || isTablet ? 1 : 0 }}>
          <Button
            sx={{ fontSize: '12px', fontWeight: 600, p: 0.75 }}
            variant="contained"
            onClick={openDialog}
            disabled={noRestaurants}
          >
            Modifica
          </Button>
        </Box>
      </Box>

      {/* Reservation Hours Section */}
      <Typography variant="h6" gutterBottom sx={{ p: 1 }}>
        Orari di prenotazione
      </Typography>
      <Box
        sx={{
          display: 'flex',
          flexDirection: isMobile || isTablet ? 'column' : 'row',
          justifyContent: 'space-between',
          alignItems: isMobile || isTablet ? 'stretch' : 'flex-start',
          p: 2,
          mb: 2,
          border: '1px solid #ccc',
          borderRadius: '10px',
          gap: 3,
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
          {timeSlots.length > 0 ? (
            getGroupedReservationSummary()
          ) : (
            <Typography variant="body1">Nessun orario di prenotazione impostato.</Typography>
          )}
        </Box>
        <Box sx={{ display: 'flex', alignItems: isMobile || isTablet ? 'center' : 'flex-end', mt: isMobile || isTablet ? 1 : 0 }}>
          <Button
            sx={{ fontSize: '12px', fontWeight: 600, p: 0.75 }}
            variant="contained"
            onClick={openReservationDialogHandler}
            disabled={noRestaurants}
          >
            Modifica
          </Button>
        </Box>
      </Box>
      {noRestaurants && (
        <Typography variant="caption" color="grey" >
          Non hai ancora creato un ristorante. Configuralo nella sezione <strong>"Il mio Ristorante"</strong> per gestirne le prenotazioni.
        </Typography>
      )}

      {/* Policy Settings Dialog */}
      <Dialog PaperProps={{ sx: { maxWidth: 'xs', p: 1 } }} open={openPolicyDialog} onClose={handleDialogCancel}>
        <Typography sx={{ p: 1, fontWeight: 550 }}>Durata consumazione e preavviso</Typography>
        <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <Typography sx={{ fontSize: '13px', fontWeight: 550, mt: 1 }}>Durata media della consumazione</Typography>
          <CustomTimePicker
            value={dialogDuration}
            onChange={setDialogDuration}
            timeInterval={15}
            minTime="00:15"
            maxTime="05:00"
            endAdornment="Ore"
          />
          <Alert severity='info'>
            La durata della consumazione ci aiuta a determinare quanto a lungo dovremo bloccare un tavolo prenotato
            (il sistema gestirà il dato in modo flessibile).
          </Alert>
          <Typography sx={{ fontSize: '13px', fontWeight: 550, mt: 1 }}>Preavviso minimo</Typography>
          <CustomTimePicker
            value={dialogNotice}
            onChange={setDialogNotice}
            timeInterval={60}
            minTime="00:00"
            maxTime="24:00"
            endAdornment="Ore"
          />
          <Alert severity='info'>
            Il preavviso minimo impedisce prenotazioni troppo a ridosso dell'orario di arrivo.
            Scegli un valore che ti permetta di organizzarti al meglio.
          </Alert>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogCancel}>Cancella</Button>
          <Button variant="contained" onClick={handleDialogConfirm}>
            Conferma
          </Button>
        </DialogActions>
      </Dialog>

      {/* Reservation Hours Dialog */}
      <Dialog
        PaperProps={{
          sx: {
            minWidth: '330px',
            width: { xs: '90%', sm: '70%', md: '600px' },
          },
        }}
        open={openReservationDialog}
        onClose={handleReservationDialogCancel}
      >
        <DialogTitle>Imposta gli orari per le prenotazioni</DialogTitle>
        <DialogContent sx={{ mt: 1 }}>
          <ReservationSlots
            reservationHours={reservationHours}
            setReservationHours={setReservationHours}
            defaultTimeSlotReservation={defaultTimeSlotReservation}
            handleAddTimeSlot={handleAddTimeSlot}
            handleRemoveTimeSlot={handleRemoveTimeSlot}
            handleDayOpenChange={handleDayOpenChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleReservationDialogCancel}>Cancella</Button>
          <Button variant="contained" onClick={handleReservationDialogConfirm}>
            Conferma
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default ReservationSettings;
