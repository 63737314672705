import React, { useState } from 'react';
import { Button, Menu, MenuItem, styled, Grow } from '@mui/material';
import ArrowDropDownRoundedIcon from '@mui/icons-material/ArrowDropDownRounded';
// Optionally, import Collapse if you want per-item animations

// Create a styled button without any underline and with custom styling options.
const StyledOptionsButton = styled(Button)(({ theme, fontSize, customcolor }) => ({
  color: customcolor || theme.palette.primary.main,
  fontSize: fontSize || '0.85rem',
  fontWeight: 550,
  textTransform: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  backgroundColor: 'transparent',
  '&:hover': {
    backgroundColor: 'transparent',
  },
  '& .MuiButton-endIcon': {
    marginLeft: '2px', // Adjusted space between text and icon
  },
}));

// Optionally, create a custom styled MenuItem for enhanced styling.
const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  fontSize: '0.85rem',
  padding: theme.spacing(1, 2),
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
}));

/**
 * OptionsButton Component
 *
 * Props:
 * - label: The text to display on the button.
 * - options: Array of objects with at least a 'label' and an optional 'onClick' callback.
 * - fontSize: (Optional) Custom font size.
 * - customcolor: (Optional) Custom text color.
 * - ...props: Additional props passed to the Button.
 */
const OptionsButton = ({ label, options = [], fontSize, customcolor, ...props }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleButtonClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOptionClick = (option) => {
    if (option.onClick) {
      option.onClick();
    }
    handleClose();
  };

  return (
    <>
      <StyledOptionsButton
        onClick={handleButtonClick}
        fontSize={fontSize}
        customcolor={customcolor}
        endIcon={
          <ArrowDropDownRoundedIcon
            sx={{
              transition: 'transform 0.3s ease-in-out',
              transform: open ? 'rotate(180deg)' : 'rotate(0deg)',
            }}
          />
        }
        {...props}
      >
        {label}
      </StyledOptionsButton>

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        TransitionComponent={Grow}
        TransitionProps={{ timeout: 300 }}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        slotProps={{
          paper: {
            sx: {
              borderRadius: 2,
              boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)',
              mt: 0, // margin top to separate from button
            },
          },
        }}
      >
        {options.map((option, index) => (
          <StyledMenuItem key={index} onClick={() => handleOptionClick(option)}>
            {option.label}
          </StyledMenuItem>
        ))}
      </Menu>
    </>
  );
};

export default OptionsButton;
