/**
 * CustomLoader Component
 *
 * Displays a loading overlay using MUI's Backdrop and CircularProgress.
 *
 * Props:
 * - open (boolean): Controls the visibility of the loader. Defaults to false.
 * - color (string): The color of the CircularProgress indicator.
 *   For the "opaque" variant, if not provided, defaults to 'primary'.
 *   For the "transparent" variant, the loader always displays in white.
 * - size (number): The size of the CircularProgress indicator. Defaults to 40.
 * - text (string): Optional text to display below the loader. Defaults to an empty string.
 * - textColor (string): The color of the optional text.
 * - absolute (boolean): If true, uses absolute positioning (covers only the parent container);
 *   if false, uses fixed positioning (covers the entire screen). Defaults to false.
 * - variant (string): Determines the background style and loader color.
 *   "transparent" (default) displays a semi-transparent overlay with a white loader.
 *   "opaque" displays an opaque white overlay with a primary-colored loader (unless overridden).
 */
import React from 'react';
import { Backdrop, CircularProgress, Box, Typography } from '@mui/material';

const CustomLoader = ({
  open = false,
  color,
  size = 64,
  text = 'Carico la pagina...',
  textColor,
  absolute = false,
  variant = 'transparent', // "transparent" or "opaque"
}) => {
  // Determine background color and loader color based on the variant prop.
  let backgroundColor, loaderElement;

  if (variant === 'opaque') {
    backgroundColor = '#fff';
    const loaderColor = color || 'primary';
    loaderElement = <CircularProgress color={loaderColor} size={size} />;
  } else {
    // Transparent variant: semi-transparent background with white loader.
    loaderElement = <CircularProgress size={size} style={{ color: 'white' }} />;
  }

  const computedTextColor = textColor || (variant === 'opaque' ? 'primary' : 'white');

  return (
    <Backdrop
      // The Backdrop is always rendered, but its visibility is controlled via the `open` prop.
      // We adjust opacity and pointer events to either show or hide it.
      open={open}
      sx={{
        position: absolute ? 'absolute' : 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 9999, // High z-index to overlay other elements
        backgroundColor: backgroundColor,
      }}
    >
      <Box display="flex" flexDirection="column" alignItems="center">
        {loaderElement}
        {text && (
          <Typography variant="body1" sx={{ fontWeight: 550, mt: 2, color: computedTextColor }}>
            {text}
          </Typography>
        )}
      </Box>
    </Backdrop>
  );
};

export default CustomLoader;
