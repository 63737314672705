import React, { useEffect, useState } from 'react';
import { Button, Typography, Box, Container } from '@mui/material';
import { useNavigate, useSearchParams } from 'react-router-dom';
import logo from '../../assets/logo_tabo-inverted.png';
import { confirmStripePayment } from '../../services/stripeApiService';

const PaymentSuccessUrl = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const sessionId = searchParams.get('session_id'); // Retrieve session ID from URL
  const [paymentStatus, setPaymentStatus] = useState(null);
  const tableId = searchParams.get('tableId');
  const [error, setError] = useState(null);

  useEffect(() => {
    if (sessionId) {
      // Call your backend to verify the payment
      confirmStripePayment(sessionId)
        .then((data) => {
          if (data.success) {
            setPaymentStatus('confirmed');
            // Check if the order is fully paid
            if (data.order_paid) {
              // Remove currentOrderId from localStorage
              localStorage.removeItem('currentOrderId');
              localStorage.removeItem('session_key');
            }
          } else {
            setPaymentStatus('failed');
            setError(data.error);
          }
        })
        .catch((error) => {
          setPaymentStatus('failed');
          setError('An error occurred while confirming the payment.');
          console.error('Error confirming payment:', error);
        });
    }
  }, [sessionId]);

  const handleBackToMenu = () => {
    if (tableId) {
      const menuUrl = `${process.env.REACT_APP_API_URL}users/qr-redirect/${tableId}`;
      window.location.href = menuUrl; // Redirecting to the menu URL with tableId
    } else {
      // Fallback in case tableId is not available
      navigate('/');
    }
  };

  return (
    <Container maxWidth="sm" sx={{ textAlign: 'center', paddingTop: '50px' }}>
      <Box mb={3}>
        <img src={logo} alt="TaboMenu Logo" style={{ height: '50px' }} />
      </Box>
      <Typography variant="h4" gutterBottom>
        {paymentStatus === 'confirmed' ? 'Payment Successful!' : 'Confirming Payment...'}
      </Typography>
      {paymentStatus === 'confirmed' ? (
        <Typography variant="body1" color="textSecondary">
          Thank you for your payment. Your order has been successfully processed.
        </Typography>
      ) : paymentStatus === 'failed' ? (
        <Typography variant="body1" color="error">
          {error || 'Payment confirmation failed. Please try again later.'}
        </Typography>
      ) : null}
      <Box mt={5}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleBackToMenu}
          sx={{ fontSize: '16px', padding: '10px 20px' }}
        >
          Back to Menu
        </Button>
      </Box>
    </Container>
  );
};

export default PaymentSuccessUrl;
