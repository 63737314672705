import React from 'react';
import ReactDOM from 'react-dom/client';
import axios from 'axios';
import './index.css';
import './App.css';
import App from './App';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import theme from './themes/MUI.theme';
import reportWebVitals from './reportWebVitals';
import { AuthProvider } from './contexts/AuthContext';
import { RestaurantProvider } from './contexts/RestaurantContext';
import { MenuProvider } from './contexts/MenuContext';
import { SidebarProvider } from './contexts/SidebarContext';
import { WebSocketProvider } from './contexts/WebSocketContext';
import { BrowserRouter as Router } from 'react-router-dom';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import api from './services/apiInstance';
import { AlertProvider } from './contexts/AlertContext';
import { DailyUsageProvider } from './contexts/DailyUsageContext';
import ErrorBoundary from './components/ErrorBoundary';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

// Use environment variable for API base URL
const API_BASE_URL = process.env.REACT_APP_API_URL;

// Configure Axios for CSRF tokens
axios.defaults.xsrfCookieName = 'csrftoken';
axios.defaults.xsrfHeaderName = 'X-CSRFToken';
axios.defaults.withCredentials = true;

// CSRF token setup
api.get(`${API_BASE_URL}auth/csrf/`)
  .then(response => {
  })
  .catch(error => {
    console.error('Error setting CSRF cookie:', error);
  });

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
/*  <React.StrictMode> */
    <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Router>
          <ErrorBoundary>
            <AuthProvider>
              <RestaurantProvider>
                <MenuProvider>
                  <SidebarProvider>
                    <WebSocketProvider>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <AlertProvider>
                          <DailyUsageProvider storageKey="apiDailyUsage" dailyLimit={25}>
                            <App />
                          </DailyUsageProvider>
                        </AlertProvider>
                      </LocalizationProvider>
                    </WebSocketProvider>
                  </SidebarProvider>
                </MenuProvider>
              </RestaurantProvider>
            </AuthProvider>
          </ErrorBoundary>
        </Router>
      </ThemeProvider>
    </GoogleReCaptchaProvider>
/*  </React.StrictMode>, */
);

reportWebVitals();