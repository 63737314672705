// MenuCarousel.jsx
import React from 'react';
import { Box } from '@mui/material';

const shuffleArray = (array) => {
  // A simple implementation of the Fisher-Yates shuffle
  const shuffled = [...array];
  for (let i = shuffled.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
  }
  return shuffled;
};

const MenuCarousel = ({ categories, maxImages = 8, randomize = true }) => {
  // Combine images from items and combos
  let images = [];
  categories.forEach((category) => {
    if (category.items && Array.isArray(category.items)) {
      category.items.forEach((item) => {
        if (item.picture) {
          images.push({ src: item.picture, alt: item.name });
        }
      });
    }
    if (category.combos && Array.isArray(category.combos)) {
      category.combos.forEach((combo) => {
        if (combo.picture) {
          images.push({ src: combo.picture, alt: combo.name });
        }
      });
    }
  });

  if (randomize) {
    images = shuffleArray(images);
  }
  // Limit the number of images
  images = images.slice(0, maxImages);

  return (
    <Box
      sx={{
        display: 'flex',
        overflowX: 'auto',
        margin: 2,
        padding: 2,
        gap: 2,
      }}
    >
      {images.map((img, index) => (
        <Box
          key={index}
          sx={{
            flex: '0 0 auto',
            width: 150,
            height: 150,
            border: '1px solid #ccc',
            borderRadius: '10px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            overflow: 'hidden',
          }}
        >
          <img
            src={img.src}
            alt={img.alt}
            style={{ width: '100%', height: '100%', objectFit: 'cover' }}
          />
        </Box>
      ))}
    </Box>
  );
};

export default MenuCarousel;
