// AlertContext.js
import React, { createContext, useContext, useState, useCallback, useMemo } from 'react';
import NotificationContainer from '../components/Common/NotificationContainer';

const AlertContext = createContext({
  showToast: () => {},
  showAlert: () => {},
});

export const useAlert = () => useContext(AlertContext);

export const AlertProvider = ({ children }) => {
  // Notification state is kept here and passed to NotificationContainer directly
  const [notification, setNotification] = useState({
    visible: false,
    message: '',
    type: 'toast',      // 'toast' or 'alert'
    variant: 'info',    // 'success', 'error', 'info', or 'warning'
    style: {},
    textStyle: {},
    position: 'top',
    duration: 2000,
  });

  // Memoize functions using useCallback so that their identity stays the same.
  const showToast = useCallback(({
    message,
    variant = 'info',
    style = {},
    textStyle = {},
    position = 'top',
    duration = 2000,
  }) => {
    setNotification({
      visible: true,
      message,
      type: 'toast',
      variant,
      style,
      textStyle,
      position,
      duration,
    });
  }, []);

  const showAlert = useCallback(({
    message,
    variant = 'info',
    style = {},
    textStyle = {},
    duration = 2000,
  }) => {
    setNotification({
      visible: true,
      message,
      type: 'alert',
      variant,
      style,
      textStyle,
      duration,
    });
  }, []);

  const hideNotification = useCallback(() => {
    setNotification(prev => ({ ...prev, visible: false }));
  }, []);

  // Memoize the context value to contain only static functions.
  const contextValue = useMemo(() => ({
    showToast,
    showAlert,
  }), [showToast, showAlert]);

  return (
    <AlertContext.Provider value={contextValue}>
      {children}
      {/* Render NotificationContainer outside of context consumers so that it uses notification state */}
      <NotificationContainer notification={notification} hideNotification={hideNotification} />
    </AlertContext.Provider>
  );
};
