import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { fetchModulesAndBundles, previewSubscriptionUpdate, previewNewSubscription } from '../../services/stripeApiService';
import { useAuth } from '../../contexts/AuthContext';
import {
  Box,
  Typography,
  Card,
  CardContent,
  Button,
  Stack,
  Divider,
} from '@mui/material';
import PurchaseModulesDialog from './PurchaseModulesDialog';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import CustomLoader from '../Common/CustomLoader';
// Assets
import OrderPayIcon from '../../assets/icons/order-pay.png';
import ReservationIcon from '../../assets/icons/reservations.png';
import KdsIcon from '../../assets/icons/kds.png';
import BundleIcon from '../../assets/icons/bundle.png';
import { formatPrice } from '../../utils/formatPrice';
import logo from '../../assets/images/logo_tabomenu.png';

const SubscriptionPage = () => {
  const navigate = useNavigate();
  const { userProfile, fetchUserProfile } = useAuth();
  const [localLoading, setLocalLoading] = useState(false);
  // Existing subscription info.
  const existingStripeSubId = userProfile?.subscription?.stripe_subscription_id || null;
  const trialStarted = Boolean(userProfile?.trial_end_date);
  // Local state for active modules, selected modules, modal, and payment method.
  const [activeModuleNames, setActiveModuleNames] = useState([]);
  const [selectedModules, setSelectedModules] = useState([]);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState(null);
  const [modulesData, setModulesData] = useState([]);
  // Preview details
  const [summaryData, setSummaryData] = useState(null);

  // Fetch modules and bundles on component mount.
  useEffect(() => {
    const fetchData = async () => {
      try {
        const products = await fetchModulesAndBundles();
        const mappedProducts = products.map((product) => {
          switch (product.name) {
            case 'Ordini e Pagamenti':
              return {
                ...product,
                iconSrc: OrderPayIcon,
                iconAlt: 'Ordini Pagamenti Icon',
                features: [
                  'Gestionale completo su iOS e Android',
                  'Ordini e pagamenti da smartphone',
                  'Menu digitale con ordinazioni al tavolo',
                  'Dashboard per le transazioni',
                ],
              };
            case 'Prenotazioni':
              return {
                ...product,
                iconSrc: ReservationIcon,
                iconAlt: 'Prenotazioni Icon',
                features: [
                  'Ricevi e gestisci prenotazioni online',
                  'Gestione automatica degli slot',
                  'Contrasto ai no-show',
                  'Integrazione con Google',
                ],
              };
            case 'Display Cucina':
              return {
                ...product,
                iconSrc: KdsIcon,
                iconAlt: 'Display Cucina Icon',
                features: [
                  'Gestione delle ordinazioni in cucina',
                  'Ricezione ordini in sede e online',
                  'Automatizzazione dei flussi',
                  'Integrazione con modulo Ordini',
                ],
              };
            case 'Tutto Incluso':
              return {
                ...product,
                iconSrc: BundleIcon,
                iconAlt: 'Tutto incluso Icon',
                features: [
                  'Ordini e pagamenti online',
                  'Prenotazioni',
                  'Gestionale di sala e display cucina',
                  'Supporto clienti dedicato',
                ],
              };
            default:
              return {
                ...product,
                features: product.features || [],
              };
          }
        });
        // Define the desired order
        const moduleOrder = [
          'Ordini e Pagamenti',
          'Prenotazioni',
          'Display Cucina',
          'Tutto Incluso',
        ];
        // Sort the modules
        mappedProducts.sort((a, b) => {
          return moduleOrder.indexOf(a.name) - moduleOrder.indexOf(b.name);
        });
        setModulesData(mappedProducts);
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    };

    fetchData();
  }, []);

  // Update active modules and payment method when userProfile changes.
  useEffect(() => {
    if (userProfile?.subscription?.effective_modules) {
      const names = userProfile.subscription.effective_modules.map((mod) => mod.name);
      setActiveModuleNames(names);
    }
    // Build a structured paymentMethod object if card details are present.
    if (
      userProfile?.subscription?.default_payment_method_id &&
      userProfile.subscription.card_brand
    ) {
      setPaymentMethod({
        id: userProfile.subscription.default_payment_method_id,
        card_brand: userProfile.subscription.card_brand,
        card_last4: userProfile.subscription.card_last4,
        card_exp_month: userProfile.subscription.card_exp_month,
        card_exp_year: userProfile.subscription.card_exp_year,
      });
    } else {
      setPaymentMethod(null);
    }
  }, [userProfile]);

  // Toggle module selection; "Tutto Incluso" is mutually exclusive.
  const handleToggleModule = (moduleId) => {
    if (moduleId.toString().startsWith("bundle-")) {
      setSelectedModules(selectedModules.includes(moduleId) ? [] : [moduleId]);
    } else {
      setSelectedModules((prev) =>
        prev.includes(moduleId)
          ? prev.filter((m) => m !== moduleId)
          : [...prev.filter((m) => !m.toString().startsWith("bundle-")), moduleId]
      );
    }
  };

  // Destructure the object with defensive mapping
  const handlePreviewResponse = (previewData) => {
    const data = previewData.preview ? previewData.preview : previewData;
    return {
      subtotal: data.subtotal,
      total_vat: data.total_vat,
      grand_total: data.grand_total,
      immediate_charge: data.immediate_charge,
      trial_period_days: data.trial_period_days,
      next_payment_attempt: data.next_payment_attempt,
      currency: data.currency,
      line_items: data.line_items,
      payment_method: data.payment_method ? data.payment_method : null,
    };
  };

  // When user clicks the action button, preview the update or new subscription
  const handleAddModules = async () => {
    setLocalLoading(true);
    const newlySelectedModules = modulesData.filter((m) => selectedModules.includes(m.id));
    const newlySelectedPriceIds = newlySelectedModules.map((m) => m.stripe_price_id);
    if (!newlySelectedPriceIds.length) {
      alert('Nessun modulo selezionato!');
      setLocalLoading(false);
      return;
    }
    try {
      let previewData;
      if (!existingStripeSubId) {
        // For a new subscription, call the new preview endpoint.
        previewData = await previewNewSubscription(newlySelectedPriceIds);
      } else {
        // For an existing subscription, call the update preview endpoint.
        previewData = await previewSubscriptionUpdate(newlySelectedPriceIds);
      }
      const mappedPreview = handlePreviewResponse(previewData);
      if (!mappedPreview.subtotal) {
        throw new Error("Anteprima non valida ricevuta dal server.");
      }
      // Optionally update payment method if provided in the preview response.
      if (mappedPreview.payment_method) {
        setPaymentMethod(mappedPreview.payment_method);
      }
      setSummaryData(mappedPreview);
      setShowConfirmModal(true);
    } catch (error) {
      console.error('Error during preview:', error);
      alert("Si è verificato un errore nel calcolare l'anteprima.");
    } finally {
      setLocalLoading(false);
    }
  };

  const buttonLabel = existingStripeSubId
    ? `Aggiorna Abbonamento`
    : `Aggiungi ${selectedModules.length === 1 ? 'Modulo' : 'Moduli'}`;

  const priceStyles = {
    symbol: { fontWeight: 'normal', fontSize: '0.75em' },
    period: { fontWeight: 'normal', fontSize: '0.5em' },
  };

  return (
    <>
      <Box
        sx={{
          position: 'relative',
          minHeight: '100vh',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          p: 3,
        }}
      >
        {/* Logo */}
        <Box
          component="img"
          src={logo}
          alt="TaboMenu Logo"
          sx={{ position: 'absolute', top: 30, left: 30, height: 24 }}
        />
        <Button
          variant="text"
          color="primary"
          sx={{ fontSize: '13px', position: 'absolute', top: 30, right: 30, height: 24 }}
          onClick={() => navigate('/dashboard/my-restaurants')}
        >
          Vai al dashboard
        </Button>
        {/* Titles */}
        <Typography variant="h3" sx={{ mt: { xs: 10, sm: 8, md: 6 }, textAlign: 'center' }}>
          I nostri piani
        </Typography>
        {!userProfile?.trial_end_date ? (
          <Typography variant="body1" color="secondary" sx={{ mb: 4, mt: 1, textAlign: 'center', fontWeight: 550 }}>
            Prova per un mese gratuitamente. Nessun addebito.
          </Typography>
        ) : (
          <Box sx={{ height: 30 }} />
        )}
        {/* Module Cards */}
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            gap: 2,
            justifyContent: 'center',
            width: '100%',
            maxWidth: 1200,
          }}
        >
          {modulesData.map((mod) => {
            const isSelected = selectedModules.includes(mod.id);
            const isActive = activeModuleNames.includes(mod.name);
            return (
              <Card
                key={mod.id}
                onClick={() => !isActive && handleToggleModule(mod.id)}
                sx={{
                  position: 'relative',
                  flex: '1 1 220px',
                  minWidth: { xs: 260, sm: 280, md: 340, lg: 270 },
                  borderRadius: '20px',
                  cursor: isActive ? 'not-allowed' : 'pointer',
                  boxShadow: isSelected ? 10 : 2,
                  transition: 'all 0.3s ease',
                  ':hover': { boxShadow: isActive ? 2 : 5 },
                }}
              >
                {!isActive && isSelected && (
                  <Box
                    sx={{
                      position: 'absolute',
                      top: 10,
                      right: 8,
                      backgroundColor: 'white',
                      borderRadius: '50%',
                      p: '2px',
                    }}
                  >
                    <CheckCircleOutlineOutlinedIcon sx={{ color: 'green', fontSize: 28 }} />
                  </Box>
                )}
                <CardContent
                  sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', height: '100%' }}
                >
                  <Typography color="primary" variant="h6" sx={{ mb: 1 }}>
                    {mod.name}
                  </Typography>
                  <Box sx={{ my: 1 }}>
                    <Box component="img" src={mod.iconSrc} alt={mod.iconAlt} sx={{ height: 60 }} />
                  </Box>
                  <Typography variant="h3">
                    {formatPrice(mod.price_subtotal)}
                    <Box component="span" sx={priceStyles.symbol}>€</Box>
                    <Box component="span" sx={priceStyles.period}>/mese</Box>
                  </Typography>
                  <Divider sx={{ width: '100%', my: 2 }} />
                  <Stack spacing={1} sx={{ mb: 2, width: '100%' }}>
                    {mod.features.map((feature, idx) => (
                      <Box key={idx} sx={{ display: 'flex', alignItems: 'flex-start' }}>
                        <CheckCircleOutlineOutlinedIcon sx={{ color: 'green', mr: 1, fontSize: 18 }} />
                        <Typography variant="body2">{feature}</Typography>
                      </Box>
                    ))}
                  </Stack>
                  {isActive && (
                    <Typography variant="body1" color="secondary" sx={{ mt: 'auto' }}>
                      Modulo attivo
                    </Typography>
                  )}
                </CardContent>
              </Card>
            );
          })}
        </Box>
        {/* Action Button */}
        <Box sx={{ mt: 4, mb: 2 }}>
          <Button variant="rounded" color="primary" onClick={handleAddModules} disabled={!selectedModules.length}>
            {buttonLabel}
          </Button>
        </Box>
        <Typography variant="caption" sx={{ mb: 1, textAlign: 'center', color: 'gray' }}>
          Tutti i moduli includono l'IA Generativa Tabo Assistant per ottimizzare operazioni e vendite.
        </Typography>

        {/* Confirmation Modal */}
        {showConfirmModal && summaryData && (
          <PurchaseModulesDialog
            open={showConfirmModal}
            onClose={() => setShowConfirmModal(false)}
            isNewSubscription={!existingStripeSubId}
            trialStarted={trialStarted}
            summaryData={summaryData}
            modulesToAdd={modulesData.filter((m) => selectedModules.includes(m.id))}
            paymentMethod={paymentMethod}
            onPaymentMethodUpdated={(newPM) => setPaymentMethod(newPM)}
            onPaymentSuccess={() => {
              setShowConfirmModal(false);
              fetchUserProfile && fetchUserProfile();
            }}
          />
        )}
        {localLoading && (
          <CustomLoader open={true} text="Preparo i moduli..." />
        )}
      </Box>
    </>
  );
};

export default SubscriptionPage;
