// v1.30
import React, { useState, useEffect, useCallback } from 'react';
import { useMenu } from '../../../contexts/MenuContext';
import { 
  Button, Typography, Box, FormControl, InputLabel, MenuItem, Select, Alert 
} from '@mui/material';
import {
  fetchAvailableMenusForAssociation,
  fetchCategoriesAvailableForAssociation,
  fetchMenuItemsAvailableForAssociation,
  fetchCombosAvailableForAssociation,
  fetchCategoryDetails,
  updateCategoryMenus,
  fetchMenuItemDetails,
  updateMenuItemCategories,
  fetchComboDetails,
  updateComboCategories
} from '../../../services/apiService';
import CustomLoader from '../../Common/CustomLoader';

const AssignmentForms = ({ context, onClose }) => {
  // New state for the source menu selection and its available options
  const [availableSourceMenus, setAvailableSourceMenus] = useState([]);
  const [selectedSourceMenu, setSelectedSourceMenu] = useState('');

  const [availableCategories, setAvailableCategories] = useState([]);
  const [availableMenuItems, setAvailableMenuItems] = useState([]);

  // Loading states for various async operations
  const [loadingMenus, setLoadingMenus] = useState(false);
  const [loadingAssociations, setLoadingAssociations] = useState(false);
  const [isAssigning, setIsAssigning] = useState(false);

  // target menu and target category come from your MenuContext
  const {
    selectedMenu, 
    selectedCategory, // when importing from inside a category, this becomes the target category
    setSelectedCategory, 
    loadMenuCategories, 
    loadMenuItems,
    loadCombos, 
    selectedMenuItem, 
    setSelectedMenuItem
  } = useMenu();

  // Reset all selections when the component mounts or when the context changes
  const resetForm = useCallback(() => {
    if (context === 'assignCategory') {
      setSelectedCategory('');
    }
    setSelectedMenuItem('');
    setSelectedSourceMenu('');
  }, [context, setSelectedCategory, setSelectedMenuItem]);

  useEffect(() => {
    resetForm();
  }, [context, resetForm]);

  // --- STEP 1: Fetch Available Source Menus ---
  useEffect(() => {
    const fetchMenus = async () => {
      setLoadingMenus(true);
      try {
        const menus = await fetchAvailableMenusForAssociation(selectedMenu);
        // Map them for the select element
        setAvailableSourceMenus(menus.map(menu => ({ value: menu.id, label: menu.name })));
      } catch (error) {
        console.error("Error fetching source menus:", error);
      } finally {
        setLoadingMenus(false);
      }
    };

    fetchMenus();
  }, [selectedMenu]);

  // --- STEP 2: Fetch associations based on the chosen source menu ---
  useEffect(() => {
    const fetchCategories = async () => {
      if (!selectedSourceMenu) return;
      setLoadingAssociations(true);
      try {
        const categories = await fetchCategoriesAvailableForAssociation(selectedSourceMenu, selectedMenu);
        setAvailableCategories(categories.map(category => ({ value: category.id, label: category.name })));
      } catch (error) {
        console.error("Error fetching categories:", error);
      } finally {
        setLoadingAssociations(false);
      }
    };

    const fetchItemsAndCombos = async () => {
      if (!selectedSourceMenu) return;
      setLoadingAssociations(true);
      try {
        // When in the "assignMenuItem" context, the category from which the import is initiated 
        // (i.e. selectedCategory) automatically becomes the target category.
        const menuItems = await fetchMenuItemsAvailableForAssociation(
          selectedSourceMenu,  // source menu
          selectedMenu,        // target menu (the menu you are editing)
          null,                // optional filter; we're not filtering by a specific source category here
          selectedCategory     // target category (from where the import is initiated)
        );
        const combos = await fetchCombosAvailableForAssociation(
          selectedSourceMenu,
          selectedMenu,
          null,
          selectedCategory
        );
        const combinedOptions = [
          ...menuItems.map(item => ({ value: item.id, label: item.name, type: 'item' })),
          ...combos.map(combo => ({ value: combo.id, label: combo.name, type: 'combo' }))
        ];
        setAvailableMenuItems(combinedOptions);
      } catch (error) {
        console.error("Error fetching menu items and combos:", error);
      } finally {
        setLoadingAssociations(false);
      }
    };

    if (context === 'assignCategory') {
      fetchCategories();
    } else if (context === 'assignMenuItem') {
      fetchItemsAndCombos();
    }
  }, [context, selectedMenu, selectedCategory, selectedSourceMenu]);

  // --- STEP 3: Assignment functions remain largely the same ---
  const assignCategoryToMenu = async (event) => {
    event.preventDefault();

    if (!selectedCategory || !selectedMenu) {
      console.error("No category or menu selected");
      return;
    }
    
    setIsAssigning(true);
    try {
      const currentCategoryDetails = await fetchCategoryDetails(selectedCategory);
      const existingMenuIds = currentCategoryDetails.menus;
      // Add the target menu to the category’s menus
      const updatedMenuIds = Array.from(new Set([...existingMenuIds, parseInt(selectedMenu)]))
        .filter(Boolean);

      await updateCategoryMenus(selectedCategory, { menus: updatedMenuIds });

      onClose();
      loadMenuCategories(selectedMenu);
      loadMenuItems(selectedMenu);
    } catch (error) {
      console.error('Error assigning category to menu:', error);
    } finally {
      setIsAssigning(false);
    }
  };

  const assignItemsToCategoryAndMenu = async (event) => {
    event.preventDefault();

    if (!selectedMenuItem || !selectedCategory) {
      console.error("No menu item or category selected");
      return;
    }
    
    setIsAssigning(true);
    try {
      let updatedCategoryIds = [];

      if (selectedMenuItem.type === 'item') {
        const currentItemDetails = await fetchMenuItemDetails(selectedMenuItem.value);
        const existingCategoryIds = currentItemDetails.categories;
        const newCategoryId = parseInt(selectedCategory);

        updatedCategoryIds = Array.from(new Set([...existingCategoryIds, newCategoryId])).filter(Boolean);
        await updateMenuItemCategories(selectedMenuItem.value, updatedCategoryIds);
      } else if (selectedMenuItem.type === 'combo') {
        const currentComboDetails = await fetchComboDetails(selectedMenuItem.value);
        const existingCategoryIds = currentComboDetails.categories;
        const newCategoryId = parseInt(selectedCategory);

        updatedCategoryIds = Array.from(new Set([...existingCategoryIds, newCategoryId])).filter(Boolean);
        await updateComboCategories(selectedMenuItem.value, updatedCategoryIds);
      }

      onClose();
      loadMenuCategories(selectedMenu);
      loadMenuItems(selectedMenu);
      loadCombos(selectedMenu);
    } catch (error) {
      console.error('Error assigning item or combo:', error);
    } finally {
      setIsAssigning(false);
    }
  };

  // --- Composite loading flag ---
  const isLoading = loadingMenus || loadingAssociations;

  return (
    <Box sx={{ padding: 2 }}>
      {(isLoading || isAssigning) && <CustomLoader />}
      <Typography variant="body1" sx={{ fontWeight: 550, mb: 4 }}>
        {context === 'assignCategory' ? 'Importa Categoria' : 'Importa voce di menu'}
      </Typography>

      {/* --- STEP 4: Render the new Source Menu select element --- */}
      {availableSourceMenus.length > 0 ? (
        <FormControl fullWidth margin="normal" sx={{ mt: 2 }}>
          <InputLabel>Seleziona menu di origine</InputLabel>
          <Select
            sx={{ height: '50px' }}
            value={selectedSourceMenu}
            onChange={(e) => setSelectedSourceMenu(e.target.value)}
            label="Seleziona menu di origine"
          >
            {availableSourceMenus.map(menu => (
              <MenuItem key={menu.value} value={menu.value}>
                {menu.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      ) : (
        <Alert severity="warning" style={{ marginTop: '20px', marginBottom: '20px' }}>
          Nessun menu di origine disponibile per l'associazione.
        </Alert>
      )}

      {context === 'assignCategory' ? (
        <>
          {!loadingAssociations && selectedSourceMenu && availableCategories.length === 0 ? (
            <Alert severity="warning" style={{ marginTop: '20px', marginBottom: '20px' }}>
              Nessuna categoria disponibile per l'associazione.<br />
              Puoi assegnare nuove categorie solo da altri menu esistenti.
            </Alert>
          ) : (
            <FormControl fullWidth margin="normal" sx={{ mt: 4 }}>
              <InputLabel>Seleziona una Categoria</InputLabel>
              <Select
                sx={{ height: '50px' }}
                value={selectedCategory}
                onChange={(e) => setSelectedCategory(e.target.value)}
                label="Seleziona una Categoria"
              >
                {availableCategories.map(category => (
                  <MenuItem key={category.value} value={category.value}>
                    {category.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        </>
      ) : (
        <>
          {selectedSourceMenu && !loadingAssociations && availableMenuItems.length === 0 ? (
            <Alert severity="warning" style={{ marginTop: '20px', marginBottom: '20px' }}>
              Nessuna voce di menu disponibile per l'associazione.<br />
              Puoi solo assegnare nuove voci di menu da altri menu già esistenti.
            </Alert>
          ) : (
            <FormControl fullWidth margin="normal">
              <InputLabel>Seleziona voce di menu</InputLabel>
              <Select
                value={selectedMenuItem}
                onChange={(e) => setSelectedMenuItem(e.target.value)}
                label="Seleziona voce di menu"
              >
                {availableMenuItems.map(item => (
                  <MenuItem key={item.value} value={item}>
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        </>
      )}
      <Box sx={{ mt: 4 }}>
        <Button
          variant="contained"
          color="primary"
          onClick={context === 'assignCategory' ? assignCategoryToMenu : assignItemsToCategoryAndMenu}
          disabled={
            (!selectedSourceMenu) ||
            (!selectedMenuItem) ||
            (context === 'assignCategory' && availableCategories.length === 0) ||
            (context !== 'assignCategory' && availableMenuItems.length === 0) ||
            isAssigning
          }
        >
          Importa
        </Button>
      </Box>
    </Box>
  );
};

export default AssignmentForms;