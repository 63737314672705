import { useState, useEffect, useCallback } from 'react';
import { useMenu } from '../contexts/MenuContext';
import { useSidebar } from '../contexts/SidebarContext';
import {
  deleteOrDissociateCombo,
  deleteOrDissociateMenu,
  deleteOrDissociateMenuItem,
  updateMenuOrder,
  deleteOrDissociateCategory
} from '../services/apiService';
import { fetchMenus } from '../services/apiService';

/**
 * Custom hook to encapsulate all menu-related logic and handlers.
 */
const useMenuHook = () => {
  /** Local state for component-specific UI flags and expansions */
  const [showMenuWizard, setShowMenuWizard] = useState(false);
  const [isAIWizardOpen, setIsAIWizardOpen] = useState(false);
  const [askTaboDialogOpen, setAskTaboDialogOpen] = useState(false);
  const [showMenuAreaAssignment, setShowMenuAreaAssignment] = useState(false);
  const [editingCategory, setEditingCategory] = useState(null);
  const [expandedCategories, setExpandedCategories] = useState({});
  const [editingMenuItem, setEditingMenuItem] = useState(null);
  const [expandedItems, setExpandedItems] = useState({});
  const [editingCombo, setEditingCombo] = useState(null);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [categoryActionSelection, setCategoryActionSelection] = useState(null);
  const [menuItemActionSelection, setMenuItemActionSelection] = useState(null);
  const [isWizardOpen, setIsWizardOpen] = useState(false);
  const [showPreview, setShowPreview] = useState(false);
  const [loading, setLoading] = useState(true);

  /** Get shared state and functions from contexts */
  const { openSidebar } = useSidebar();
  const {
    loadCombinedMenu,
    menus,
    setMenus,
    selectedMenu,
    setSelectedMenu,
    setEditingMenu,
    setSelectedCategory,
    menuCategories,
    setMenuCategories,
    menuItems,
    setMenuItems,
    combos,
    setCombos,
    allergenOptions,
    setAllergenOptions,
    setDietGroupOptions,
    dietGroupOptions,
    setSelectedAllergens,
    setSelectedDietGroups,
    allergens,
    dietGroups,
  } = useMenu();

  /** Prepare dropdown options for menus and for adding categories/items */
  const selectOptions = menus.map(menu => ({ value: menu.id, label: menu.name }));
  const addCategoryOptions = [
    { value: 'newCategory', label: 'Nuova' },
    { value: 'existingCategory', label: 'Esistente' }
  ];
  const addMenuItemOptions = [
    { value: 'newMenuItem', label: 'Nuovo' },
    { value: 'existingMenuItem', label: 'Esistente' }
  ];

  /** Opens the menu wizard */
  const openWizard = useCallback(() => {
    setIsWizardOpen(true);
  }, []);

  /** Closes the menu wizard */
  const closeWizard = useCallback(() => {
    setIsWizardOpen(false);
  }, []);

  /** Opens the AI menu wizard */
  const openAIWizard = useCallback(() => {
    setIsAIWizardOpen(true);
  }, []);

  /** Closes the AI menu wizard */
  const closeAIWizard = useCallback(() => {
    setIsAIWizardOpen(false);
  }, []);

  /** Opens the Ask Tabo Dialog */
  const openAskTaboDialog = useCallback(() => {
    setAskTaboDialogOpen(true);
  }, []);

  /** Closes the Ask Tabo Dialog */
  const closeAskTaboDialog = useCallback(() => {
    setAskTaboDialogOpen(false);
  }, []);

  /** On mount, load menus and select the latest one */
  useEffect(() => {
    const loadData = async () => {
      await loadCombinedMenu();
      setLoading(false);
    };

    loadData();
  }, [loadCombinedMenu]);

  /** Handler for when a new menu is created */
  const handleNewMenuCreated = useCallback(async (newMenu) => {
    // Update local state with the new menu
    setMenus((prevMenus) => [newMenu, ...prevMenus]);
    setSelectedMenu(newMenu.id.toString());

    // Explicitly refresh the combined data for the new menu and retrieve categories
    const categories = await loadCombinedMenu(newMenu.id);

    // Expand categories as needed
    if (categories && categories.length > 0) {
      const expandedCategoriesState = {};
      categories.forEach((cat) => {
        expandedCategoriesState[cat.id] = true;
      });
      setExpandedCategories(expandedCategoriesState);
    }
  }, [setMenus, setSelectedMenu, loadCombinedMenu, setExpandedCategories]);

  /** When allergens or diet groups change, update the options for selection */
  useEffect(() => {
    const allergenOpts = allergens.map(allergen => ({
      value: allergen.id,
      label: allergen.name
    }));
    const dietGroupOpts = dietGroups.map(dietGroup => ({
      value: dietGroup.id,
      label: dietGroup.name
    }));

    setAllergenOptions(allergenOpts);
    setDietGroupOptions(dietGroupOpts);
  }, [allergens, dietGroups, setAllergenOptions, setDietGroupOptions]);

  /**
   * Closes the menu wizard, refreshes menus, categories, and items.
   * After refreshing, it closes the wizard dialog.
   */
  const handleWizardCompletion = useCallback(async () => {
    setLoading(true);
    setShowMenuWizard(false);  // if you still need it
    const categories = await loadCombinedMenu();

    // expand categories as needed
    if (categories && categories.length > 0) {
      const expandedCategoriesState = {};
      categories.forEach((cat) => {
        expandedCategoriesState[cat.id] = true;
      });
      setExpandedCategories(expandedCategoriesState);
    }
    setLoading(false);
    setIsWizardOpen(false);
  }, [loadCombinedMenu, setExpandedCategories]);

  /** Toggle expansion for a single category */
  const toggleCategory = useCallback((categoryId) => {
    setExpandedCategories(prev => ({ ...prev, [categoryId]: !prev[categoryId] }));
  }, []);

  /** Toggle expansion for a single item */
  const toggleItem = useCallback((itemId) => {
    setExpandedItems(prev => ({ ...prev, [itemId]: !prev[itemId] }));
  }, []);

  /**
   * Expand or collapse all categories and items.
   * When expanding, mark every category and every item/combo as expanded.
   */
  const toggleGlobalExpansion = useCallback((expand) => {
    setExpandedCategories(expand ? Object.fromEntries(menuCategories.map(cat => [cat.id, true])) : {});
    setExpandedItems(expand ? Object.fromEntries([
      ...menuItems.map(item => [item.id, true]),
      ...combos.map(combo => [combo.id, true])
    ]) : {});
  }, [menuCategories, menuItems, combos]);

  /**
   * Handles drag and drop actions for categories, items, and combos.
   * Updates the state and makes an API call to update the order.
   */
  const onDragEnd = useCallback(async (result) => {
    const { source, destination, type } = result;

    if (!destination) {
      return; // Dropped outside the list
    }

    if (type === 'CATEGORY') {
      const newCategories = Array.from(menuCategories);
      const [reorderedCategory] = newCategories.splice(source.index, 1);
      newCategories.splice(destination.index, 0, reorderedCategory);
      setMenuCategories(newCategories);

      const categoryOrder = newCategories.map(category => category.id);
      await updateMenuOrder({ menu_id: selectedMenu, categoryOrder });
    } else if (type === 'ITEM') {
      if (source.droppableId === destination.droppableId) {
        const categoryId = parseInt(source.droppableId.split('-')[1]);
        const itemsInCategory = menuItems.filter(item => item.categories.includes(categoryId));

        const sourceIndexInMenuItems = menuItems.findIndex(item => item.id === itemsInCategory[source.index].id);
        const destinationIndexInMenuItems = menuItems.findIndex(item => item.id === itemsInCategory[destination.index].id);

        const newItems = Array.from(menuItems);
        const [reorderedItem] = newItems.splice(sourceIndexInMenuItems, 1);
        newItems.splice(destinationIndexInMenuItems, 0, reorderedItem);

        setMenuItems(newItems);

        const itemOrder = newItems
          .filter(item => item.categories.includes(categoryId))
          .map((item, index) => ({ id: item.id, order: index, category_id: categoryId }));

        await updateMenuOrder({ menu_id: selectedMenu, itemOrder });
      }
      // Add extra logic here if handling moves between different categories
    } else if (type === 'COMBOS') {
      if (source.droppableId === destination.droppableId) {
        const categoryId = parseInt(source.droppableId.split('-')[1]);
        const combosInCategory = combos.filter(combo => combo.categories.includes(categoryId));

        const sourceIndexInCombos = combos.findIndex(combo => combo.id === combosInCategory[source.index].id);
        const destinationIndexInCombos = combos.findIndex(combo => combo.id === combosInCategory[destination.index].id);

        const newCombos = Array.from(combos);
        const [reorderedCombo] = newCombos.splice(sourceIndexInCombos, 1);
        newCombos.splice(destinationIndexInCombos, 0, reorderedCombo);

        setCombos(newCombos);

        const comboOrder = newCombos
          .filter(combo => combo.categories.includes(categoryId))
          .map((combo, index) => ({
            id: combo.id,
            order: index,
            category_id: categoryId
          }));

        await updateMenuOrder({ menu_id: selectedMenu, comboOrder });
      }
    }
  }, [menuCategories, menuItems, combos, selectedMenu, setMenuCategories, setMenuItems, setCombos]);

  /**
   * Opens the sidebar for editing a menu.
   * Finds the menu by its ID and then triggers the edit sidebar.
   */
  const openEditMenuSidebar = useCallback((menuId) => {
    const menuToEdit = menus.find(menu => menu.id === parseInt(menuId));
    if (menuToEdit) {
      setEditingMenu(menuToEdit);
      openSidebar('editMenu');
    } else {
      console.error("Menu to edit not found for ID:", menuId);
    }
  }, [menus, openSidebar, setEditingMenu]);

  /**
   * Opens the sidebar for creating or assigning a category.
   * Resets the editing category and any previous action selection.
   */
  const handleAddCategorySelection = useCallback((selection) => {
    if (selection.value === 'newCategory') {
      openSidebar('createCategory');
    } else if (selection.value === 'existingCategory') {
      openSidebar('assignCategory');
    }
    setEditingCategory(null);
    setCategoryActionSelection(null);
  }, [openSidebar, setEditingCategory]);

  /** Opens the sidebar for editing an existing category */
  const openEditCategorySidebar = useCallback((category) => {
    setEditingCategory(category);
    openSidebar('editCategory');
  }, [openSidebar, setEditingCategory]);

  /**
   * Opens the sidebar for adding a menu item or combo.
   * Sets the selected category and resets the editing item state.
   */
  const handleAddMenuItemSelection = useCallback((selection, categoryId) => {
    if (selection.value === 'newMenuItem') {
      setSelectedCategory([categoryId]);
      openSidebar('createMenuItem');
    } else if (selection.value === 'existingMenuItem') {
      setSelectedCategory(categoryId);
      openSidebar('assignMenuItem');
    }
    setEditingMenuItem(null);
    setMenuItemActionSelection(null);
  }, [openSidebar, setSelectedCategory, setEditingMenuItem]);

  /**
   * Opens the sidebar for editing a menu item.
   * Pre-selects allergens and diet groups based on the menu item's data.
   */
  const openEditMenuItemSidebar = useCallback((menuItem) => {
    setEditingMenuItem(menuItem);
    setSelectedCategory(menuItem.categories);
    openSidebar('editMenuItem');

    const preSelectedAllergens = menuItem.allergens.map(allergenId => {
      const foundAllergen = allergenOptions.find(option => option.value === allergenId);
      return foundAllergen || null;
    }).filter(Boolean);

    const preSelectedDietGroups = menuItem.diet_groups.map(dietGroupId => {
      const foundDietGroup = dietGroupOptions.find(option => option.value === dietGroupId);
      return foundDietGroup || null;
    }).filter(Boolean);

    setSelectedAllergens(preSelectedAllergens);
    setSelectedDietGroups(preSelectedDietGroups);
  }, [openSidebar, allergenOptions, dietGroupOptions, setEditingMenuItem, setSelectedCategory, setSelectedAllergens, setSelectedDietGroups]);

  /** Opens the sidebar for editing a combo */
  const openEditComboSidebar = useCallback((combo) => {
    setEditingCombo(combo);
    setSelectedCategory(combo.categories);
    openSidebar('editCombo');
  }, [openSidebar, setEditingCombo, setSelectedCategory]);

  /**
  * Function to update menu after creation
  */
  const reloadMenus = async () => {
    const response = await fetchMenus();
    const sortedMenus = response.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
    setMenus(sortedMenus);
  };

  /**
  * Function to create and update categories
  */
  const addNewCategory = (newCategory, onCategoryAdded) => {
    setMenuCategories(prevCategories => [...prevCategories, newCategory]);
    if (onCategoryAdded) {
      onCategoryAdded(newCategory);
    }
  };
  const updateCategories = (updatedCategory) => {
    setMenuCategories(prevCategories => prevCategories.map(cat => cat.id === updatedCategory.id ? updatedCategory : cat));
  };

  /**
  * Functions to create and updates menu items
  */
  const addNewMenuItem = (newMenuItem) => {
    setMenuItems(prevItems => [...prevItems, newMenuItem]);
  };
  const updateMenuItems = (updatedMenuItem) => {
    setMenuItems(prevItems => prevItems.map(item => item.id === updatedMenuItem.id ? updatedMenuItem : item));
  };

  /**
  * Functions to create and updates combos
  */
  const addNewCombo = (newCombo) => {
    setCombos((prevCombos) => [...prevCombos, newCombo]);
  };
  const updateCombos = (updatedCombo) => {
    setCombos((prevCombos) =>
      prevCombos.map((combo) =>
        combo.id === updatedCombo.id ? updatedCombo : combo
      )
    );
  };  

  /**
   * Opens the delete confirmation modal for the given item.
   * Accepts additional parameters for menu or category context.
   */
  const openDeleteConfirmation = useCallback((id, type, currentMenuId = null, currentCategoryId = null) => {
    setItemToDelete({ id, type, currentMenuId, currentCategoryId });
    setShowConfirmationModal(true);
  }, []);

  /**
   * Closes the delete confirmation modal.
   */
  const closeConfirmationModal = useCallback(() => {
    setShowConfirmationModal(false);
  }, []);

  /**
   * Handles the deletion/dissociation process for menus, categories, items, or combos.
   * After deletion, it refreshes the menus.
   */
  const handleDeleteConfirmed = useCallback(async () => {
    if (!itemToDelete) return;

    try {
      let result = null;
      switch (itemToDelete.type) {
        case 'menu': {
          result = await deleteOrDissociateMenu(itemToDelete.id);
          break;
        }
        case 'category': {
          const menuId = itemToDelete.currentMenuId || selectedMenu;
          result = await deleteOrDissociateCategory(itemToDelete.id, menuId);
          break;
        }
        case 'menuItem': {
          result = await deleteOrDissociateMenuItem(itemToDelete.id, itemToDelete.currentCategoryId);
          break;
        }
        case 'combo': {
          result = await deleteOrDissociateCombo(
            itemToDelete.id,
            itemToDelete.currentMenuId,
            itemToDelete.currentCategoryId
          );
          break;
        }
        default:
          console.warn("Unknown deletion type:", itemToDelete.type);
          return;
      }
      // If the deletion/dissociation was successful, refresh the entire data
      if (result && (result.action_taken === 'deleted' || result.action_taken === 'dissociated')) {
        await loadCombinedMenu(selectedMenu);
      }
      // Finally, close the confirmation modal.
      closeConfirmationModal();
    } catch (error) {
      console.error(`Error occurred while deleting ${itemToDelete.type}:`, error);
    }
  }, [itemToDelete, selectedMenu, loadCombinedMenu, closeConfirmationModal]);

  /**
   * Opens the preview modal for the selected menu.
   */
  const handlePreview = useCallback(() => {
    setShowPreview(true);
  }, [setShowPreview]);

  /** Closes the menu area assignment widget */
  const closeMenuAreaAssignment = useCallback(() => {
    setShowMenuAreaAssignment(false);
  }, []);

  /** Opens the menu area assignment widget */
  const openMenuAreaAssignment = useCallback(() => {
    setShowMenuAreaAssignment(true);
  }, []);

  return {
    // Local state values
    selectedMenu,
    editingCategory,
    editingMenuItem,
    editingCombo,
    showMenuWizard,
    isAIWizardOpen,
    askTaboDialogOpen,
    showMenuAreaAssignment,
    expandedCategories,
    expandedItems,
    showConfirmationModal,
    itemToDelete,
    categoryActionSelection,
    menuItemActionSelection,
    isWizardOpen,
    loading,
    // Shared dropdown options
    selectOptions,
    addCategoryOptions,
    addMenuItemOptions,
    // Handlers and functions
    openWizard,
    closeWizard,
    handleWizardCompletion,
    openAIWizard,
    closeAIWizard,
    openAskTaboDialog,
    closeAskTaboDialog,
    handleNewMenuCreated,
    toggleCategory,
    toggleItem,
    toggleGlobalExpansion,
    onDragEnd,
    openEditMenuSidebar,
    handleAddCategorySelection,
    openEditCategorySidebar,
    handleAddMenuItemSelection,
    openEditMenuItemSidebar,
    openEditComboSidebar,
    openDeleteConfirmation,
    closeConfirmationModal,
    handleDeleteConfirmed,
    handlePreview,
    closeMenuAreaAssignment,
    openMenuAreaAssignment,
    showPreview,
    setShowPreview,
    setCategoryActionSelection,
    setMenuItemActionSelection,
    reloadMenus,
    menuCategories,
    updateCategories,
    addNewCategory,
    addNewMenuItem,
    updateMenuItems,
    addNewCombo,
    updateCombos,
  };
};

export default useMenuHook;
