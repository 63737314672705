// NotificationContainer.jsx
import React, { useEffect } from 'react';
import { Snackbar, Alert as MuiAlert, Box, Modal, Fade, Button, } from '@mui/material';
import { Player } from '@lottiefiles/react-lottie-player';
import successAnimation from '../../assets/animations/success.json';

const NotificationContainer = ({ notification, hideNotification }) => {
  const {
    visible,
    message,
    type,
    variant,
    style,
    textStyle,
    position = 'top',
    duration = 2000,
    hasButton,
  } = notification;

  // Automatically hide the toast after the specified duration.
  useEffect(() => {
    if (visible && type === 'toast') {
      const timer = setTimeout(() => {
        hideNotification();
      }, duration);
      return () => clearTimeout(timer);
    }
  }, [visible, type, duration, hideNotification]);

  // Automatically hide the alert after a specified time (includes a slight delay for fade-out)
  useEffect(() => {
    if (visible && type === 'alert') {
      const timer = setTimeout(() => {
        hideNotification();
      }, duration + 300);
      return () => clearTimeout(timer);
    }
  }, [visible, type, duration, hideNotification]);

  // Define the paths to your Lottie animation JSON files.
  const animations = {
    success: successAnimation,
    //   error: '/animations/error.json',
    //   info: '/animations/info.json',
    //   warning: '/animations/warning.json',
  };

  // Determine vertical position for the toast
  const verticalPosition = position === 'top' ? 'top' : 'bottom';

  return (
    <>
      {type === 'toast' && (
        <Snackbar
          open={visible}
          anchorOrigin={{ vertical: verticalPosition, horizontal: 'center' }}
          onClose={hideNotification}
          autoHideDuration={duration}
          sx={style}
        >
          <MuiAlert
            onClose={hideNotification}
            severity={variant}
            action={
              hasButton && (
                <Button color="inherit" size="small" onClick={hideNotification}>
                  Chiudi
                </Button>
              )
            }
            sx={{ width: '100%', bgcolor: 'background.paper', ...textStyle }}
            elevation={6}
            variant="outlined"
          >
            {message}
          </MuiAlert>
        </Snackbar>
      )}

      {type === 'alert' && (
        <Modal
          open={visible}
          onClose={hideNotification}
          aria-labelledby="alert-notification-title"
          aria-describedby="alert-notification-description"
          closeAfterTransition
          slotProps={{
            backdrop: {
              transitionDuration: 500,
            },
          }}
        >
          <Fade in={visible}>
            <Box
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                bgcolor: 'background.paper',
                borderRadius: 2,
                boxShadow: 24,
                p: 4,
                textAlign: 'center',
                ...style,
              }}
            >
              <Player
                autoplay
                src={animations[variant] || animations.info}
                style={{ height: '150px', width: '150px', marginBottom: '1rem' }}
              />
              <Box component="p" sx={{ ...textStyle }}>
                {message}
              </Box>
            </Box>
          </Fade>
        </Modal>
      )}
    </>
  );
};

export default NotificationContainer;
