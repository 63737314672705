// src/components/OnlineOrders.jsx

import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Dialog,
  Select,
  DialogTitle,
  DialogActions,
  DialogContent,
  MenuItem,
  Tabs,
  Tab,
  Button,
  FormControl,
  InputLabel,
  Divider
} from '@mui/material';

import { useRestaurant } from '../../contexts/RestaurantContext';
import CustomLoader from '../Common/CustomLoader';

// Child components
import Devices from './orders/Devices';
import NFCOrders from './orders/NFCOrders';

// The “shop” that displays products in card form
import NFCShop from './orders/NFCShop';

// The Drawer for product details
import NFCProductDrawer from './orders/NFCProductDrawer';

// Example service calls
import { fetchNfcDevices, createNfcOrderCheckout } from '../../services/stripeApiService';

const OnlineOrders = () => {
  const {
    selectedRestaurant,
    userRestaurants,
    setSelectedRestaurant,
    restaurantsLoading
  } = useRestaurant();

  // Tab state: "dispositivi" | "menu" | "ordini"
  const [currentTab, setCurrentTab] = useState('dispositivi');

  // State for restaurant selection dialog
  const [dialogOpen, setDialogOpen] = useState(false);

  // State for devices + fetch
  const [devices, setDevices] = useState([]);
  const [loadingDevices, setLoadingDevices] = useState(false);

  // Show Shop or Tabs?
  const [showShop, setShowShop] = useState(false);

  // Drawer for product details
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [quantity, setQuantity] = useState(10);

  // ---------------------------
  // 1) Handle Restaurant Setup
  // ---------------------------
  // Set default restaurant if not already selected
  useEffect(() => {
    if (!selectedRestaurant && userRestaurants.length > 0) {
      setSelectedRestaurant(userRestaurants[0].id);
    }
  }, [userRestaurants, selectedRestaurant, setSelectedRestaurant]);

  // Show/hide the restaurant selection dialog
  const handleDialogOpen = () => setDialogOpen(true);
  const handleDialogClose = () => setDialogOpen(false);

  const handleRestaurantChange = (event) => {
    setSelectedRestaurant(event.target.value);
    setDialogOpen(false);
  };

  // --------------------------------------
  // 2) Fetch devices once a restaurant is set
  // --------------------------------------
  useEffect(() => {
    const loadDevices = async () => {
      if (!selectedRestaurant) return;
      setLoadingDevices(true);
      try {
        const data = await fetchNfcDevices(selectedRestaurant);
        setDevices(data || []);

        // If no devices, we show the shop flow immediately
        if (!data || data.length === 0) {
          setShowShop(true);
        } else {
          setShowShop(false);
        }
      } catch (err) {
        console.error('Error fetching NFC devices:', err);
      } finally {
        setLoadingDevices(false);
      }
    };

    loadDevices();
  }, [selectedRestaurant]);

  // ---------------------------
  // 3) Tabs
  // ---------------------------
  const handleTabChange = (event, newValue) => {
    setCurrentTab(newValue);
  };

  // ---------------------------
  // 4) “Shop” Flow
  // ---------------------------

  // If user wants to buy devices (from Devices or NFCOrders), show the shop
  const handleOrderMore = () => {
    setShowShop(true);
  };

  // If user wants to go back to the devices tab (only if we have devices)
  const handleBackToDevices = () => {
    setShowShop(false);
    setCurrentTab('dispositivi');
  };

  // ---------------------------
  // 5) Product Drawer
  // ---------------------------
  const openProductDrawer = (product) => {
    setSelectedProduct(product);
    setQuantity(10); // default quantity
    setDrawerOpen(true);
  };

  const closeProductDrawer = () => {
    setDrawerOpen(false);
    setSelectedProduct(null);
    setQuantity(10);
  };

  // Handle the final “Ordina ora” in the Drawer => Stripe checkout
  const handleConfirmOrder = async () => {
    if (!selectedRestaurant || !selectedProduct) {
      return; // optionally show an error
    }
    try {
      const data = await createNfcOrderCheckout({
        restaurantId: selectedRestaurant,
        productId: selectedProduct.id,
        quantity
      });
      if (data.checkoutUrl) {
        window.location.href = data.checkoutUrl;
      } else {
        throw new Error('No checkout URL returned from server.');
      }
    } catch (error) {
      console.error('Error during checkout:', error);
      // Optionally handle error or show a toast
    }
  };

  // ---------------------------
  // 6) Handle Loading States
  // ---------------------------
  if (restaurantsLoading) {
    return <CustomLoader open={true} />;
  }

  // If no restaurants exist, show a message instead of an infinite loader
  if (userRestaurants.length === 0) {
    return (
      <Box textAlign="center" mt={4}>
        <Typography variant="h6">
          Nessun ristorante associato. Per favore aggiungi un ristorante.
        </Typography>
      </Box>
    );
  }

  if (!selectedRestaurant || loadingDevices) {
    return <CustomLoader open={true} />;
  }

  // ---------------------------
  // 7) Conditional Render:
  //    - If showShop => show “Ordina dispositivi NFC” + shop
  //    - Else => show your existing tab layout
  // ---------------------------
  if (showShop) {
    return (
      <Box>
        {/* Header Area (same styling as your original) */}
        <Box
          px={2}
          py={1}
          mx={{ xs: 2, sm: 3, md: 4 }}
          mt={4}
          gap={{ xs: 2, sm: 3, md: 5 }}
          display="flex"
          flexDirection={{ xs: 'column', md: 'row' }}
          justifyContent="space-between"
          borderRadius="10px"
        >
          {/* Restaurant Selection */}
          <Box
            display="flex"
            flexDirection="column"
            alignItems="flex-end"
            sx={{ order: { xs: 1, md: 2 } }}
          >
            <Typography variant="h6">
              {userRestaurants.find((r) => r.id === selectedRestaurant)?.name || 'Ristorante non selezionato'}
            </Typography>
            <Button
              variant="text"
              disabled={userRestaurants.length === 0}
              sx={{ textDecoration: 'underline', pt: 0.5, m: 0, fontSize: '12px', minWidth: 'auto' }}
              onClick={handleDialogOpen}
            >
              Cambia
            </Button>
          </Box>

          {/* Title: Ordina dispositivi NFC */}
          <Box
            display="flex"
            justifyContent="flex-start"
            sx={{ mb: { xs: 1, md: 0 }, order: { xs: 2, md: 1 } }}
          >
            <Typography variant="h5">
              Ordina dispositivi NFC
            </Typography>
          </Box>
        </Box>

        <Divider sx={{ mx: 4, mb: 5, mt: 1 }} />

        {/* Restaurant Selection Dialog */}
        <Dialog open={dialogOpen} onClose={handleDialogClose} fullWidth maxWidth="sm">
          <DialogTitle>Seleziona ristorante</DialogTitle>
          <DialogContent>
            <FormControl sx={{ mt: 1 }} variant="outlined" fullWidth>
              <InputLabel id="restaurant-select-label">Ristorante</InputLabel>
              <Select
                labelId="restaurant-select-label"
                value={selectedRestaurant || ''}
                onChange={handleRestaurantChange}
                label="Ristorante"
              >
                {userRestaurants.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDialogClose} color="secondary">
              Annulla
            </Button>
          </DialogActions>
        </Dialog>

        {/* The "Shop" Component */}
        <Box sx={{ mx: { xs: 2, sm: 3, md: 4 }, overflowY: 'auto' }}>
          <NFCShop onCardClick={openProductDrawer} />

          {/* "Torna ai dispositivi" button IF we already have devices */}
          {devices.length > 0 && (
            <Box sx={{ textAlign: 'right', mt: 3 }}>
              <Button variant="text" onClick={handleBackToDevices}>
                Torna ai dispositivi
              </Button>
            </Box>
          )}
        </Box>

        {/* The Drawer for product details */}
        <NFCProductDrawer
          open={drawerOpen}
          product={selectedProduct}
          quantity={quantity}
          onClose={closeProductDrawer}
          onQuantityChange={setQuantity}
          onConfirmOrder={handleConfirmOrder}
        />
      </Box>
    );
  } else {
    //  Show the original TABS
    return (
      <Box>
        {/* Header Area */}
        <Box
          px={2}
          py={1}
          mx={{ xs: 2, sm: 3, md: 4 }}
          mt={4}
          gap={{ xs: 2, sm: 3, md: 5 }}
          display="flex"
          flexDirection={{ xs: 'column', md: 'row' }}
          justifyContent="space-between"
          borderRadius="10px"
        >
          {/* Restaurant Selection */}
          <Box
            display="flex"
            flexDirection="column"
            alignItems="flex-end"
            sx={{ order: { xs: 1, md: 2 } }}
          >
            <Typography variant="h6">
              {userRestaurants.find((r) => r.id === selectedRestaurant)?.name || 'Ristorante non selezionato'}
            </Typography>
            <Button
              variant="text"
              disabled={userRestaurants.length === 0}
              sx={{ textDecoration: 'underline', pt: 0.5, m: 0, fontSize: '12px', minWidth: 'auto' }}
              onClick={handleDialogOpen}
            >
              Cambia
            </Button>
          </Box>

          {/* Tabs */}
          <Box display="flex" justifyContent="flex-start" sx={{ mb: { xs: 1, md: 0 }, order: { xs: 2, md: 1 } }}>
            <Tabs value={currentTab} onChange={handleTabChange} variant="scrollable" scrollButtons="auto" sx={{ ml: 0 }}>
              <Tab label="Dispositivi" value="dispositivi" />
              <Tab label="Menu" value="menu" />
              <Tab label="Ordini" value="ordini" />
            </Tabs>
          </Box>
        </Box>

        <Divider sx={{ mx: 4, mb: 5, mt: 1 }} />

        {/* Restaurant Selection Dialog */}
        <Dialog open={dialogOpen} onClose={handleDialogClose} fullWidth maxWidth="sm">
          <DialogTitle>Seleziona ristorante</DialogTitle>
          <DialogContent>
            <FormControl sx={{ mt: 1 }} variant="outlined" fullWidth>
              <InputLabel id="restaurant-select-label">Ristorante</InputLabel>
              <Select
                labelId="restaurant-select-label"
                value={selectedRestaurant || ''}
                onChange={handleRestaurantChange}
                label="Ristorante"
              >
                {userRestaurants.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDialogClose} color="secondary">
              Annulla
            </Button>
          </DialogActions>
        </Dialog>

        {/* Tab Panels */}
        <Box sx={{ mx: { xs: 2, sm: 3, md: 4 }, overflowY: 'auto' }}>
          {currentTab === 'menu' && <Typography>Funzionalità Menu in arrivo</Typography>}
          {currentTab === 'ordini' && <NFCOrders onReorder={handleOrderMore} />}
          {currentTab === 'dispositivi' && (
            <Devices
              devices={devices}
              onOrderMore={handleOrderMore}
              selectedRestaurant={selectedRestaurant}
              // If you want a way to force reload:
              reloadDevices={(newList) => setDevices(newList)}
            />
          )}
        </Box>
      </Box>
    );
  }
};

export default OnlineOrders;
