// OnlineMenuUI v2.00
import React, { useEffect, useRef, useState, useMemo } from 'react';
import {
  Box,
  Typography,
  TextField,
  Button,
  Alert,
  InputAdornment
} from '@mui/material';
import ItemCard from './ItemCard';
import SearchIcon from '@mui/icons-material/Search';

const OnlineMenuUI = ({
  coverImageUrl,
  logoImageUrl,
  restaurant,
  categories,
  searchQuery,
  onSearchQueryChange,
  orderItems = [],
  onAddItem,
  onRemoveItem,
  openAllergenDialog,
  openBillModal,
  openReviewModal,
  isPreview,
  isReservation,
  showReviewBillButton,
  showUpdateOrderButton,
  showReviewOrderButton,
}) => {
  // State to control header visibility and active category.
  const [showCategoryHeader, setShowCategoryHeader] = useState(false);
  const [activeCategory, setActiveCategory] = useState(null);
  // Create a new ref for the header trigger (cover + restaurant info).
  const headerTriggerRef = useRef(null);
  const headerContainerRef = useRef(null);

  // Filtering logic for categories
  const filteredCategories = useMemo(() => {
    if (!categories) return [];
    const lowerQuery = searchQuery.toLowerCase();
    return categories.reduce((filtered, category) => {
      const matchesCategoryName = category.categoryName.toLowerCase().includes(lowerQuery);
      const filteredItems = category.items.filter(item =>
        item.name.toLowerCase().includes(lowerQuery)
      );
      const filteredCombos = category.combos.filter(combo =>
        combo.name.toLowerCase().includes(lowerQuery)
      );
      if (matchesCategoryName || filteredItems.length || filteredCombos.length) {
        filtered.push({
          ...category,
          items: matchesCategoryName ? category.items : filteredItems,
          combos: matchesCategoryName ? category.combos : filteredCombos
        });
      }
      return filtered;
    }, []);
  }, [categories, searchQuery]);

  // Update scroll handler to use the new ref.
  useEffect(() => {
    const handleScroll = () => {
      if (headerTriggerRef.current) {
        const rect = headerTriggerRef.current.getBoundingClientRect();
        // Adjust the threshold as needed (e.g. 0 or a positive number if you want some margin)
        setShowCategoryHeader(rect.bottom <= 0);
      } else {
        setShowCategoryHeader(true);
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  // IntersectionObserver to highlight the active category in the header.
  useEffect(() => {
    if (!categories || categories.length === 0) return;
    const categoryElements = categories.map(({ id }) =>
      document.getElementById(`category-${id}`)
    );

    const observerOptions = {
      root: null,
      rootMargin: '0px',
      threshold: 0.3,
    };

    const observer = new IntersectionObserver((entries) => {
      const visibleEntries = entries.filter(entry => entry.isIntersecting);
      if (visibleEntries.length > 0) {
        visibleEntries.sort((a, b) =>
          Math.abs(a.boundingClientRect.top) - Math.abs(b.boundingClientRect.top)
        );
        const activeCatId = visibleEntries[0].target.id.replace('category-', '');
        setActiveCategory(activeCatId);
      }
    }, observerOptions);

    categoryElements.forEach((elem) => {
      if (elem) observer.observe(elem);
    });

    return () => {
      categoryElements.forEach((elem) => {
        if (elem) observer.unobserve(elem);
      });
    };
  }, [categories]);

  // Automatically scroll the sticky header so that the active category is visible.
  useEffect(() => {
    if (activeCategory && headerContainerRef.current) {
      const activeElement = headerContainerRef.current.querySelector(
        `[data-category-id="${activeCategory}"]`
      );
      if (activeElement) {
        activeElement.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest'
        });
      }
    }
  }, [activeCategory]);

  // Category header: a horizontal scrollable list of category names.
  const CategoryHeader = () => (
    <Box
      ref={headerContainerRef}
      sx={{
        display: 'flex',
        overflowX: 'auto',
        px: '16px',
        pt: '24px', // Give space to the top here
        width: '100%',
        '&::-webkit-scrollbar': { display: 'none' },
        scrollbarWidth: 'none',
        msOverflowStyle: 'none',
      }}
    >
      {categories.map((category) => {
        const isActive = category.id.toString() === activeCategory;
        return (
          <Box
            key={category.id}
            data-category-id={category.id}
            onClick={
              // Only enable click handler if it's not a preview
              !isPreview
                ? () => {
                  const anchorElement = document.getElementById(`category-${category.id}`);
                  if (anchorElement) {
                    // Let CSS handle the offset:
                    anchorElement.scrollIntoView({ behavior: 'smooth' });
                  }
                }
                : undefined
            }
            sx={{
              mr: '16px',
              cursor: isPreview ? 'cursor' : 'pointer',
              flexShrink: 0,
              backgroundColor: isActive ? '#7272f655' : 'transparent',
              borderRadius: '8px',
              px: '8px',
              py: '6px'
            }}
          >
            <Typography
              variant="body1"
              sx={{
                fontWeight: 'bold',
                color: isActive ? '#2c2cffee' : '#333'
              }}
            >
              {category.categoryName}
            </Typography>
          </Box>
        );
      })}
    </Box>
  );

  return (
    <Box overflowX="hidden" sx={{ userSelect: 'none' }}>
      {/* Define the SVG clipPath */}
      <svg width="0" height="0" style={{ position: 'absolute' }}>
        <defs>
          <clipPath id="roman-arch-clip" clipPathUnits="objectBoundingBox">
            <path d="M0,0 H1 V1 Q0.5,0.9 0,1 Z" />
          </clipPath>
        </defs>
      </svg>
      {/* Header Trigger Container: Wrap both cover and restaurant info */}
      <Box ref={headerTriggerRef}>
        {/* Cover Image Section */}
        {!isReservation && (
          <Box sx={{ position: 'relative', mb: '80px' }}>
            <Box
              sx={{
                height: 240,
                backgroundImage: `url(${coverImageUrl})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                position: 'relative',
                display: 'flex',
                alignItems: 'flex-end',
                justifyContent: 'flex-start',
                zIndex: 30,
                clipPath: 'url(#roman-arch-clip)',
                WebkitClipPath: 'url(#roman-arch-clip)',
              }}
            >
              {/* Overlay */}
              <Box
                sx={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  backgroundColor: 'rgba(0,0,0,0.6)',
                }}
              />
            </Box>
            {/* Logo (Positioned outside the clipPath) */}
            {logoImageUrl && (
              <Box
                component="img"
                src={logoImageUrl}
                alt="Logo del ristorante"
                sx={{
                  position: 'absolute',
                  bottom: -46,
                  left: '50%',
                  transform: 'translateX(-50%)',
                  width: 120,
                  height: 120,
                  border: '4px solid white',
                  borderRadius: '10px',
                  backgroundColor: 'white',
                  zIndex: 40,
                  boxShadow: '0px 2px 4px rgba(0,0,0,0.1)',
                }}
              />
            )}
          </Box>
        )}

        {/* Restaurant Info */}
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', pb: '16px' }}>
          {restaurant.name && (
            <Typography variant="h2" sx={{ fontWeight: 550, color: '#333', textAlign: 'center' }}>
              {restaurant.name}
            </Typography>
          )}
          {restaurant.description && (
            <Typography variant="body1">
              {restaurant.description}
            </Typography>
          )}
        </Box>
      </Box>

      {/* Sticky Search Box Container */}
      <Box
        sx={{
          position: 'sticky',
          top: isReservation ? '50px' : '0px',
          zIndex: 20,
          backgroundColor: 'white',
          height: '78px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          paddingTop: '20px',
        }}
      >
        <TextField
          placeholder="Cerca nel menu"
          variant="outlined"
          fullWidth
          value={searchQuery}
          onChange={(e) => onSearchQueryChange(e.target.value)}
          sx={{
            pt: '24px',
            pb: '18px',
            maxWidth: 600,
            width: '90%',
            '& .MuiOutlinedInput-root': {
              borderRadius: '20px',
              height: '60px',
              backgroundColor: '#f5f5f5',
              '& fieldset': {
                borderColor: '#ccc',
              },
              '&:hover fieldset': {
                borderColor: '#aaa',
              },
              '&.Mui-focused fieldset': {
                borderColor: '#888',
              },
            },
            '& input': {
              color: '#333',
            },
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </Box>

      {/* Sticky Category Header Container */}
      <Box
        sx={{
          position: 'sticky',
          px: '16px',
          // Do NOT set vertical padding here
          top: isReservation ? '124px' : '74px', // Search box top + Sticky header top
          zIndex: 10,
          backgroundColor: 'white',
          height: showCategoryHeader ? '72px' : '0px',
          overflow: 'hidden',
          transition: 'box-shadow 300ms ease-in-out, height 300ms ease-in-out',
          boxShadow: showCategoryHeader && !isReservation ? '0px 2px 4px rgba(0,0,0,0.1)' : 'none',
        }}
      >
        <CategoryHeader />
      </Box>

      {/* Main Content: Menu Categories and Items */}
      <Box sx={{ mx: '16px', my: '16px' }}>
        {filteredCategories.length === 0 ? (
          <Alert severity={searchQuery ? "info" : "warning"}>
            {searchQuery
              ? "Nessun risultato"
              : "Nessun menu disponibile al momento."}
          </Alert>
        ) : (
          filteredCategories.map(({ id, categoryName, items, combos }) => (
            <Box
              key={id}
              id={`category-${id}`}
              sx={{
                mb: '16px',
                scrollMarginTop: '156px', // Sticky section's total height
              }}
            >
              <Typography variant="h3" sx={{ mt: '56px', mb: '16px' }}>
                {categoryName}
              </Typography>
              {items.map((item) => {
                const orderItem = orderItems.find((oi) => oi.id === item.id);
                return (
                  <ItemCard
                    key={item.id}
                    item={item}
                    orderItem={orderItem}
                    onAddItem={onAddItem}
                    onRemoveItem={onRemoveItem}
                    openAllergenDialog={openAllergenDialog}
                    isPreview={isPreview}
                    isReservation={isReservation}
                    type="menu_item"
                  />
                );
              })}
              {combos.map((combo) => {
                const orderCombo = orderItems.find((oi) => oi.id === combo.id);
                return (
                  <ItemCard
                    key={combo.id}
                    item={combo}
                    orderItem={orderCombo}
                    onAddItem={onAddItem}
                    onRemoveItem={onRemoveItem}
                    openAllergenDialog={openAllergenDialog}
                    isPreview={isPreview}
                    isReservation={isReservation}
                    type="combo"
                  />
                );
              })}
            </Box>
          ))
        )}
      </Box>

      {/* Bottom Buttons: Only render for non-preview mode */}
      {!isPreview && (
        <Box
          sx={{
            position: 'fixed',
            bottom: 20,
            left: 0,
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            zIndex: 1,
          }}
        >
          {showReviewOrderButton && (
            <Button
              variant="contained"
              color="primary"
              onClick={openReviewModal}
              sx={{
                width: '90%',
                height: '48px',
              }}
            >
              Rivedi l'ordine
            </Button>
          )}
          {showUpdateOrderButton && (
            <Button
              variant="contained"
              color="primary"
              onClick={openReviewModal}
              sx={{
                width: '90%',
                height: '48px',
              }}
            >
              Aggiungi all'ordine
            </Button>
          )}
          {showReviewBillButton && (
            <Button
              variant="contained"
              color="primary"
              onClick={openBillModal}
              sx={{
                width: '90%',
                height: '48px',
              }}
            >
              Controlla il conto
            </Button>
          )}
        </Box>
      )}
    </Box>
  );
};

export default OnlineMenuUI;
