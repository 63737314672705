// ConfirmationDialog.jsx
import React from 'react';
import { styled } from '@mui/system';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  CircularProgress,
} from '@mui/material';

// Styled components for the dialog and buttons
const CustomDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary,
    borderRadius: '20px',
    width: '90%',
    [theme.breakpoints.up('sm')]: {
      width: '75%',
    },
    [theme.breakpoints.up('md')]: {
      width: '50%',
    },
    [theme.breakpoints.up('lg')]: {
      width: '40%',
    },
  },
}));

const CustomDialogTitle = styled(DialogTitle)(({ theme }) => ({
  fontSize: '0.9rem',
  fontWeight: 550,
  color: theme.palette.secondary.main,
}));

const CustomDialogContent = styled(DialogContent)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  color: theme.palette.black.main,
}));

const CustomDialogContentText = styled(DialogContentText)(({ theme }) => ({
  fontSize: '0.9rem',
  fontWeight: 500,
  color: theme.palette.black.main,
}));

const CustomDialogActions = styled(DialogActions)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
}));

const CancelDialogButton = styled(Button)(({ theme }) => ({
  '&&': {
    fontSize: '0.9rem',
  },
  color: theme.palette.primary.main,
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
}));

const ConfirmDialogButton = styled(Button)(({ theme }) => ({
  '&&': {
    fontSize: '0.9rem',
  },
  color: theme.palette.primary.main,
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
}));

/**
 * ConfirmationDialog Component
 *
 * Props:
 * - visible (boolean): Whether the dialog is open.
 * - title (string): Title text for the dialog. Defaults to "Confirm Action".
 * - message (string): Message text for the dialog. Defaults to "Are you sure you want to proceed?".
 * - onConfirm (function): Callback when the confirm button is clicked.
 * - onCancel (function): Callback when the dialog is dismissed or cancel is clicked.
 * - confirmLabel (string): Text for the confirm button. Defaults to "Yes".
 * - cancelLabel (string): Text for the cancel button. Defaults to "Cancel".
 * - loading (boolean): Whether the dialog is in a loading state.
 */
const ConfirmationDialog = ({
  visible,
  title,
  message,
  onConfirm,
  onCancel,
  confirmLabel,
  cancelLabel,
  loading = false,
}) => {
  // Provide default values if not passed as props.
  const dialogTitle = title || 'Conferma azione';
  const dialogMessage = message || 'Sei sicuro di voler procedere?';
  const confirmText = confirmLabel || 'Conferma';
  const cancelText = cancelLabel || 'Cancella';

  return (
    <CustomDialog
      open={visible}
      onClose={onCancel}
      aria-labelledby="confirmation-dialog-title"
      aria-describedby="confirmation-dialog-description"
    >
      <CustomDialogTitle id="confirmation-dialog-title">
        {dialogTitle}
      </CustomDialogTitle>
      <CustomDialogContent>
        <CustomDialogContentText id="confirmation-dialog-description">
          {dialogMessage}
        </CustomDialogContentText>
      </CustomDialogContent>
      <CustomDialogActions>
        <CancelDialogButton onClick={onCancel} disabled={loading}>
          {cancelText}
        </CancelDialogButton>
        <ConfirmDialogButton onClick={onConfirm} disabled={loading}>
          {loading ? <CircularProgress size={20} color="inherit" /> : confirmText}
        </ConfirmDialogButton>
      </CustomDialogActions>
    </CustomDialog>
  );
};

export default ConfirmationDialog;
