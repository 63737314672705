import React from 'react';
import { Dialog, DialogContent, Typography, Button, Box, Divider } from '@mui/material';

const BillReviewModal = ({ isOpen, onClose, billDetails, onOpenPaymentDrawer }) => {
  // Recupera la sessione dall'archivio locale
  const sessionKey = localStorage.getItem('session_key') || '';

  // Funzione di aiuto per formattare la data in un formato leggibile (italiano)
  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleDateString('it-IT', {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    });
  };

  // Funzione di aiuto per formattare i prezzi in euro
  const formatPrice = (price) => (price ? Number(price).toFixed(2) : '0.00');

  // Funzione per renderizzare gli articoli con un titolo
  const renderItems = (items, title) => (
    <>
      <Typography variant="h6" sx={{ mt: 2, mb: 1 }}>
        {title}
      </Typography>
      {items && items.length > 0 ? (
        items.map((item, index) => (
          <Box
            key={`${title.toLowerCase()}-item-${index}`}
            sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}
          >
            <Typography>{item.name}</Typography>
            <Typography>
              {item.quantity} x €{formatPrice(item.price)}
            </Typography>
          </Box>
        ))
      ) : (
        <Typography>Nessun altro ordine</Typography>
      )}
      <Divider sx={{ my: 2 }} />
    </>
  );

  if (!isOpen || !billDetails) return null;

  // Filtra gli articoli in base al session_key
  const yourItems = (billDetails.all_items || []).filter(
    (item) => item.session_key === sessionKey
  );
  const otherItems = (billDetails.all_items || []).filter(
    (item) => item.session_key !== sessionKey
  );
  const amountDue = parseFloat(billDetails.amount_due || 0);

  // Gestione dell'apertura del Payment Drawer
  const handleOpenPayment = () => {
    onClose(); // Chiude il BillReviewModal
    onOpenPaymentDrawer(); // Apre il PaymentDrawer
  };

  return (
    <Dialog open={isOpen} onClose={onClose} fullWidth maxWidth="sm">
      <DialogContent dividers>
        <Typography sx={{ fontWeight: 'bold' }}>
          {billDetails.restaurant_name}
        </Typography>
        <Typography>{billDetails.restaurant_address}</Typography>
        <Typography>Tavolo #{billDetails.table_number}</Typography>
        <Typography>{formatDate(billDetails.issue_date)}</Typography>
        <Divider sx={{ my: 2 }} />

        {renderItems(yourItems, 'Il tuo ordine')}
        {renderItems(otherItems, 'Altri ordini presenti')}

        <Typography variant="h6" sx={{ pb: 1 }}>
          <strong>Totale: €{formatPrice(billDetails.total_amount)}</strong>
        </Typography>

        <Typography variant="body1" color="primary">
          Totale pagato: €{formatPrice(billDetails.total_paid)}
        </Typography>

        <Typography variant="body1" color={amountDue > 0 ? 'secondary' : 'primary'}>
          Importo dovuto: €{formatPrice(amountDue)}
        </Typography>

        {amountDue > 0 && (
          <Button
            variant="contained"
            color="primary"
            onClick={handleOpenPayment}
            sx={{ mt: 2, width: '100%' }}
          >
            Paga il conto
          </Button>
        )}

        <Button
          onClick={onClose}
          variant="outlined"
          color="secondary"
          sx={{ mt: 2, width: '100%' }}
        >
          Chiudi
        </Button>
      </DialogContent>
    </Dialog>
  );
};

export default BillReviewModal;
