import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import {
  Box,
  TextField,
  Button,
  Typography,
  LinearProgress,
  Alert,
} from '@mui/material';
import LockOpenRoundedIcon from '@mui/icons-material/LockOpenRounded';

const PasswordResetForm = () => {
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [passwordStrength, setPasswordStrength] = useState(0);
  const [formErrors, setFormErrors] = useState({});
  const { uidb64, token } = useParams();
  const navigate = useNavigate();
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  // Function to calculate password strength
  const getPasswordStrength = (password) => {
    if (password.length === 0) return 0; // No feedback if nothing is typed.

    const lengthRequirement = password.length >= 8;
    const digitRequirement = /[0-9]/.test(password);
    const uppercaseRequirement = /[A-Z]/.test(password);
    const lowercaseRequirement = /[a-z]/.test(password);
    const specialCharRequirement = /[^A-Za-z0-9]/.test(password);

    let strength = 0;
    if (password.length > 0) strength = 1;
    if (lengthRequirement && digitRequirement && lowercaseRequirement) strength += 2;
    if (uppercaseRequirement && lowercaseRequirement && lengthRequirement) strength += 1;
    if (specialCharRequirement) strength += 1;

    return strength; // Returns a value between 0 and 5
  };

  // Function to get password strength text
  const getPasswordStrengthText = (strength) => {
    switch (strength) {
      case 0:
        return "Password must contain at least 8 characters, a digit and a letter.";
      case 1:
      case 2:
        return "Your password is too weak";
      case 3:
        return "Your password looks good, but could be stronger.";
      case 4:
        return "Good! Your password is strong.";
      case 5:
        return "Excellent! Your password is very strong.";
      default:
        return "Password must contain at least 8 characters, a digit and a letter.";
    }
  };

  const handleNewPasswordChange = (event) => {
    const newPasswordValue = event.target.value;
    setNewPassword(newPasswordValue);
    const newStrength = getPasswordStrength(newPasswordValue);
    setPasswordStrength(newStrength);
  };

  const handleConfirmNewPasswordChange = (event) => {
    setConfirmNewPassword(event.target.value);
  };

  // Form Validation
  const validateForm = () => {
    const errors = {};
    const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d).{8,}$/;

    if (!newPassword || !passwordRegex.test(newPassword)) {
      errors.password = 'Password must contain at least 8 characters, a digit and a letter.';
    }

    if (newPassword !== confirmNewPassword) {
      errors.confirmPassword = 'Passwords do not match.';
    }

    setFormErrors(errors);

    // The form is valid if the errors object has no properties
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setSuccessMessage('');
    setErrorMessage('');

    if (validateForm()) {
      try {
        // Construct the endpoint dynamically
        const baseUrl = process.env.REACT_APP_API_URL;
        const resetPasswordEndpoint = `${baseUrl}auth/reset/${uidb64}/${token}/`;
        await axios.post(resetPasswordEndpoint, {
          new_password: newPassword,
          confirm_new_password: confirmNewPassword,
        });

        setSuccessMessage('Password has been changed. You are being redirected to login');
        setTimeout(() => navigate('/login'), 3000); // Redirect after 3 seconds
      } catch (error) {
        if (error.response && error.response.status === 400) {
          setErrorMessage(error.response.data.error); // Use the error message from Django
        } else {
          setErrorMessage('An error occurred. Please try again.');
        }
      }
    }
  };

  // Determine color based on password strength
  const getPasswordStrengthColor = (strength) => {
    if (strength < 1) return 'primary';
    if (strength <= 2) return 'error';
    if (strength <= 3) return 'secondary';
    if (strength >= 4) return 'success';
    return 'success';
  };

  return (
    <Box
      sx={{
        padding: {
          xs: '4% 10% 2% 10%',
          sm: '4% 20% 2% 20%',
          md: '4% 35% 2% 35%',
        },
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Typography variant="h2" gutterBottom>
        Change your password
      </Typography>
      <LockOpenRoundedIcon color="primary" sx={{ fontSize: 92, mb: 2 }} />
      <Typography fontSize="large">
        Enter your new password below:
      </Typography>
      <Box
        component="form"
        onSubmit={handleSubmit}
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <TextField
          fullWidth
          type="password"
          label="New Password"
          value={newPassword}
          onChange={handleNewPasswordChange}
          margin="normal"
          variant="outlined"
          error={Boolean(formErrors.password)}
          helperText={formErrors.password}
          autoComplete="new-password"
        />
        <LinearProgress
          variant="determinate"
          value={(passwordStrength / 5) * 100}
          color={getPasswordStrengthColor(passwordStrength)}
          sx={{ width: '99%', mt: 1 }}
        />
        <Typography variant="body2" sx={{ mt: 1 }}>
          {getPasswordStrengthText(passwordStrength)}
        </Typography>
        <TextField
          fullWidth
          type="password"
          label="Confirm New Password"
          value={confirmNewPassword}
          onChange={handleConfirmNewPasswordChange}
          margin="normal"
          variant="outlined"
          error={Boolean(formErrors.confirmPassword)}
          helperText={formErrors.confirmPassword}
          autoComplete="new-password"
        />
        {successMessage && (
          <Alert severity="success" sx={{ width: '100%', mt: 1 }}>
            {successMessage}
          </Alert>
        )}
        {errorMessage && (
          <Alert severity="error" sx={{ width: '100%', mt: 1 }}>
            {errorMessage}
          </Alert>
        )}
        <Button
          type="submit"
          variant="contained"
          color="primary"
          fullWidth
          sx={{ mt: 2 }}
        >
          Update Password
        </Button>
      </Box>
    </Box>
  );
};

export default PasswordResetForm;
