import React from 'react';
import { Box, FormControl, InputLabel, MenuItem, Button } from '@mui/material';
import CustomSelect from '../../Common/CustomSelect';
import { useRestaurant } from '../../../contexts/RestaurantContext';
import { useResponsive } from '../../../hooks/useResponsive';
import OptionsButton from '../../Common/OptionsButton';

const RestaurantHeader = ({
  restaurantOptions,
  handleRestaurantChange,
  onCreateRestaurant,
  onCreateArea,
  openSidebarForEditing,
  toggleDeleteModal,
  handleToggleDefault,
}) => {
  const { selectedRestaurant, setSelectedRestaurant, areas } = useRestaurant();
  const { isMobile } = useResponsive();

  // Determine if area creation should be disabled.
  const disableCreateArea = areas.length === 0 || areas.length >= 5;

  // Mobile options for the OptionsButton
  const mobileOptions = [
    { label: 'Crea ristorante', onClick: onCreateRestaurant, disabled: disableCreateArea },
    { label: 'Crea area', onClick: onCreateArea, disabled: disableCreateArea },
  ];

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="flex-start"
      width="100%"
      padding="20px"
    >
      {/* Left Section */}
      <Box display="flex" flexDirection="column">
        <FormControl variant="outlined">
          <InputLabel id="restaurant-select-label">Ristorante</InputLabel>
          <CustomSelect
            labelId="restaurant-select-label"
            value={selectedRestaurant || ''}
            onChange={(event) => {
              setSelectedRestaurant(event.target.value);
              handleRestaurantChange(event.target.value);
            }}
            label="Ristorante"
            sx={{ maxWidth: isMobile ? 200 : null }}
          >
            {restaurantOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </CustomSelect>
        </FormControl>
        <Box display="flex" width="100%">
          <Button
            variant="text"
            style={{ fontSize: isMobile ? '11px' : '14px' }}
            onClick={() => openSidebarForEditing(selectedRestaurant)}
          >
            Modifica
          </Button>
          <Button
            variant="text"
            style={{ fontSize: isMobile ? '11px' : '14px' }}
            onClick={() => toggleDeleteModal(selectedRestaurant)}
          >
            Elimina
          </Button>
          <Button
            variant="text"
            style={{ fontSize: isMobile ? '11px' : '14px' }}
            onClick={handleToggleDefault}
          >
            {isMobile ? 'Predefinito' : 'Imposta predefinito'}
          </Button>
        </Box>
      </Box>

      {/* Right Section */}
      {isMobile ? (
        <OptionsButton
          label="Opzioni"
          options={mobileOptions}
          sx={{ fontSize: { xs: '14px', sm: '16px' } }}
        />
      ) : (
        <Box display="flex">
          <Button
            variant="text"
            style={{ fontSize: isMobile ? '12px' : '15px', fontWeight: 550 }}
            onClick={onCreateRestaurant}
            disabled={disableCreateArea}
          >
            Crea ristorante
          </Button>
          <Button
            variant="text"
            color="secondary"
            style={{ fontSize: isMobile ? '12px' : '15px', fontWeight: 550 }}
            onClick={onCreateArea}
            disabled={disableCreateArea}
          >
            Crea area
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default RestaurantHeader;
