import React, { createContext, useContext } from 'react';
import useDailyUsage from '../hooks/useDailyUsage'; // Adjust the path as necessary

// Create the context
const DailyUsageContext = createContext();

// Provider component that uses your hook
export const DailyUsageProvider = ({ children, storageKey, dailyLimit }) => {
  const [usage, increment] = useDailyUsage(storageKey, dailyLimit);

  return (
    <DailyUsageContext.Provider value={{ usage, increment }}>
      {children}
    </DailyUsageContext.Provider>
  );
};

// Custom hook for consuming the context
export const useDailyUsageContext = () => {
  const context = useContext(DailyUsageContext);
  if (!context) {
    throw new Error('useDailyUsageContext must be used within a DailyUsageProvider');
  }
  return context;
};
