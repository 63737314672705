import React from 'react';
import { Box, Typography } from '@mui/material';
import MailLockRounded from '@mui/icons-material/MailLockRounded';

const PasswordEmailSent = () => (
  <Box
    sx={{
      padding: {
        xs: '10% 10% 2% 10%',
        sm: '10% 20% 2% 20%',
        md: '10% 35% 2% 35%',
      },
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    }}
  >
    <Typography variant="h1" gutterBottom>
      Almost there.
    </Typography>
    <MailLockRounded color="primary" sx={{ fontSize: 92, mb: 2 }} />
    <Typography fontSize="large" textAlign="center">
      We have sent a link to reset your password.
      <br />
      Please follow the instructions provided in the email.
    </Typography>
  </Box>
);

export default PasswordEmailSent;
