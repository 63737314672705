// DefaultMenu.jsx
import React, { useEffect, useState } from 'react';
import { fetchDefaultMenuDetails } from '../../services/usersApiService';
import { Divider } from '@mui/material';
import OnlineMenuUI from '../Common/OnlineMenuUI';
import AllergenDialog from './AllergenDialog';
import cover from '../../assets/images/default-cover-image.png';
import CustomLoader from '../Common/CustomLoader';
import MenuCarousel from '../Common/MenuCarousel';

const DefaultMenu = ({ restaurantId, isReservation = true }) => {
  const [menuData, setMenuData] = useState({});
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState('');
  const [allergenDialogOpen, setAllergenDialogOpen] = useState(false);
  const [currentAllergens, setCurrentAllergens] = useState([]);
  const [currentItemName, setCurrentItemName] = useState('');

  useEffect(() => {
    const fetchMenu = async () => {
      setLoading(true);
      try {
        const data = await fetchDefaultMenuDetails(restaurantId);
        setMenuData(data);
      } catch (error) {
        console.error('Failed to fetch default menu details:', error);
      } finally {
        setLoading(false);
      }
    };

    if (restaurantId) {
      fetchMenu();
    }
  }, [restaurantId]);

  if (loading) {
    return <CustomLoader open={true} />;
  }

  // Map the fetched data to what OnlineMenuUI expects.
  const categories = menuData.categories || [];
  const restaurant = menuData.restaurant || {};
  const coverImageUrl = restaurant.cover_image || cover;

  const openAllergenDialog = (allergens, itemName) => {
    setCurrentAllergens(allergens);
    setCurrentItemName(itemName);
    setAllergenDialogOpen(true);
  };

  const closeAllergenDialog = () => {
    setAllergenDialogOpen(false);
  };

  return (
    <div>
      {/* Render the new carousel section at the very top */}
      <MenuCarousel categories={categories} />
      <Divider sx={{ m: 3 }} />

      {/* Render OnlineMenuUI without the cover and search box */}
      <OnlineMenuUI
        coverImageUrl={coverImageUrl}
        restaurant={restaurant}
        categories={categories}
        searchQuery={searchQuery}
        onSearchQueryChange={setSearchQuery}
        orderItems={[]} // No ordering in preview mode
        onAddItem={() => { }} // Dummy function; no ordering functionality here
        onRemoveItem={() => { }} // Dummy function
        openAllergenDialog={openAllergenDialog}
        showReviewBillButton={false}
        currentOrderId={null}
        openBillModal={() => { }}
        openReviewModal={() => { }}
        isReservation={isReservation}
      />

      <AllergenDialog
        open={allergenDialogOpen}
        onClose={closeAllergenDialog}
        allergens={currentAllergens}
        itemName={currentItemName}
      />
    </div>
  );
};

export default DefaultMenu;
