import React, { useState, useEffect } from 'react';
import {
  Box,
  Container,
  Typography,
  Tabs,
  Tab,
  AppBar,
  Toolbar,
} from '@mui/material';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import { useParams } from 'react-router-dom';
import { fetchUserRestaurantDetails } from '../../services/usersApiService';
import ReservationWidget from './ReservationWidget';
import DefaultMenu from './DefaultMenu';
import logo from '../../assets/logo_tabomenu.png';

// Helper function to format the address string
const getFullAddress = (address) => {
  if (!address) return '';
  const { address_line_1, address_line_2, city, state, postal_code, country } = address;
  return [address_line_1, address_line_2, city, state, postal_code, country]
    .filter(part => part && part !== 'null')
    .join(', ');
};

const ReservationPage = () => {
  const { restaurantId } = useParams();
  const [restaurant, setRestaurant] = useState(null);
  const [tabIndex, setTabIndex] = useState(0);

  useEffect(() => {
    const loadRestaurant = async () => {
      try {
        const data = await fetchUserRestaurantDetails(restaurantId);
        const restaurantObj = Array.isArray(data) ? data[0] : data;
        setRestaurant(restaurantObj);
      } catch (error) {
        console.error('Errore nel recupero dei dettagli del ristorante:', error);
      }
    };

    loadRestaurant();
  }, [restaurantId]);

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  // Build the formatted address if available.
  const fullAddress =
    restaurant && restaurant.restaurant_address
      ? getFullAddress(restaurant.restaurant_address)
      : '';

  // Build the map URL
  const mapUrl = `https://www.google.com/maps/embed/v1/place?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&q=${encodeURIComponent(fullAddress)}&zoom=15`;

  return (
    <>
      {/* Header with logo */}
      <AppBar
        position="fixed"
        sx={{
          backgroundColor: '#fff',
          boxShadow: '0px 2px 8px rgba(0,0,0,0.1)',
        }}
      >
        <Toolbar>
          <Box component="img" sx={{ width: 150 }} src={logo} alt="TaboMenu Logo" />
        </Toolbar>
      </AppBar>

      {/* Main Content */}
      <Container maxWidth="xl" sx={{ padding: {xs: 1.5, sm: 2}, marginTop: {xs: '60px', sm: '70px', md: '80px'} }}>
        <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }}>
          {/* Left Column */}
          <Box sx={{ flex: 1, px: {xs: 0, sm: 1, md: 2}, marginRight: { md: '20px', xs: 0 } }}>
            <Tabs
              value={tabIndex}
              onChange={handleTabChange}
              indicatorColor="primary"
              textColor="primary"
              sx={{ mb: 2 }}
            >
              <Tab label="Informazioni" />
              <Tab label="Menù" />
            </Tabs>
            {tabIndex === 0 && (
              <Box py={2}>
                <Typography variant="h4" gutterBottom>
                  {restaurant ? restaurant.name : 'Ristorante'}
                </Typography>
                <Typography variant="body1" paragraph>
                  {restaurant ? restaurant.description : 'Caricamento...'}
                </Typography>
                {restaurant && restaurant.restaurant_address && fullAddress && (
                  <>
                    <Typography
                      variant="body1"
                      paragraph
                      sx={{ display: 'flex', alignItems: 'center' }}
                    >
                      <LocationOnOutlinedIcon sx={{ fontSize: '21px', marginRight: '8px' }} />
                      {fullAddress}
                    </Typography>
                    {restaurant.phone_number && (
                      <Typography
                        variant="body1"
                        paragraph
                        sx={{ display: 'flex', alignItems: 'center' }}
                      >
                        <LocalPhoneOutlinedIcon sx={{ fontSize: '21px', marginRight: '8px' }} />
                        {restaurant.phone_number}
                      </Typography>
                    )}
                    <iframe
                      width="100%"
                      height="375"
                      title="Mappa del ristorante"
                      style={{ border: 0, borderRadius: '8px' }}
                      src={mapUrl}
                      allowFullScreen
                    ></iframe>
                  </>
                )}
              </Box>
            )}
            {tabIndex === 1 && (
              <Box sx={{ p: 2}}>
                <DefaultMenu restaurantId={restaurantId} />
              </Box>
            )}
          </Box>

          {/* Right Column with Sticky ReservationWidget */}
          <Box
            sx={{
              flex: 1,
              position: { md: 'sticky', xs: 'static' },
              top: '100px',
              alignSelf: 'flex-start',
              maxWidth: { xs: '100%', md: '500px' },
            }}
          >
            {restaurant && (
              <ReservationWidget reservationData={null} restaurantId={restaurant} />
            )}
          </Box>
        </Box>
      </Container>
    </>
  );
};

export default ReservationPage;
