// src/utils/priceValidation.js

/**
 * Validates a price string.
 * The price must have up to 4 digits before the decimal point and up to 2 digits after.
 *
 * @param {string} price - The price input as a string.
 * @returns {boolean} - True if the price is valid, false otherwise.
 */
export const isValidPrice = (price) => {
    const regex = /^\d{0,4}(\.\d{0,2})?$/;
    return regex.test(price);
  };
  
  /**
   * Formats a valid price string to a fixed two-decimal format.
   * If the string is not a valid number, it returns an empty string.
   *
   * @param {string} price - The price input as a string.
   * @returns {string} - The formatted price or an empty string.
   */
  export const formatPrice = (price) => {
    const parsed = parseFloat(price);
    return isNaN(parsed) ? "" : parsed.toFixed(2);
  };
  