import React, { createContext, useState, useContext, useMemo, useCallback } from 'react';

const SidebarContext = createContext();

export const useSidebar = () => useContext(SidebarContext);

export const SidebarProvider = ({ children }) => {
  const [showSidebar, setShowSidebar] = useState(false);
  const [sidebarContext, setSidebarContext] = useState('');

  const openSidebar = useCallback((context) => {
    setSidebarContext(context);
    setShowSidebar(true);
  }, []);

  const closeSidebar = useCallback(() => {
    setShowSidebar(false);
    setSidebarContext('');
  }, []);

  const value = useMemo(() => ({
    showSidebar,
    sidebarContext,
    openSidebar,
    closeSidebar,
  }), [showSidebar, sidebarContext, openSidebar, closeSidebar]);

  return <SidebarContext.Provider value={value}>{children}</SidebarContext.Provider>;
};
