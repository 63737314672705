import React from 'react';
import { Dialog, DialogContent, DialogActions, Button, Box, Typography, Divider, CircularProgress } from '@mui/material';
import CustomMarkdown from '../../components/Common/CustomMarkdown';

const InsightsDialog = ({
  insightsOpen,
  handleCloseInsights,
  loadingInsights,
  logo,
  formattedStartDate,
  formattedEndDate,
  insights,
}) => {
  // This function handles printing the report content.
  const printReport = () => {
    // Get the inner HTML of the report content container.
    const printContents = document.getElementById("printSection").innerHTML;
    // Open a new window with specific dimensions.
    const newWindow = window.open("", "_blank", "width=800,height=600");

    // Write a basic HTML document to the new window. You can add more styling here if needed.
    newWindow.document.write(`
      <html>
        <head>
          <title>Stampa Report</title>
          <style>
            /* Basic styles for printed content */
            body {
              font-family: Arial, sans-serif;
              margin: 20px;
            }
          </style>
        </head>
        <body>
          ${printContents}
        </body>
      </html>
    `);

    // Finish writing and display the new window content.
    newWindow.document.close();
    newWindow.focus();
    // Trigger the print dialog.
    newWindow.print();
    // Close the print window after printing.
    newWindow.close();
  };

  return (
    <Dialog open={insightsOpen} onClose={handleCloseInsights} fullWidth maxWidth="md">
      <DialogContent>
        {loadingInsights ? (
          // If the insights are still loading, show a loader.
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', py: 4 }}>
            <CircularProgress />
            <Typography variant="body1" sx={{ mt: 2 }}>
              Sto generando il report...
            </Typography>
          </Box>
        ) : (
          <>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
              <Box
                component="img"
                src={logo}
                alt="Logo"
                sx={{
                  height: '60px',
                  width: 'auto',
                  display: 'block',
                }}
              />
              <Box>
                <Typography variant="h5" mb={1}>Tabo Assistant Report</Typography>
                <Typography variant="caption">
                  Periodo: {formattedStartDate} - {formattedEndDate}
                </Typography>
              </Box>
            </Box>
            <Divider sx={{ mt: 2, mb: 4 }} />
            {/* Wrap your report content in a container with an ID for printing */}
            <Box id="printSection" sx={{ p: 2 }}>
              <CustomMarkdown content={insights} />
            </Box>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseInsights} color="primary">
          Chiudi
        </Button>
        {/* "Stampa" button added on the bottom-right corner */}
        <Button onClick={printReport} color="primary" sx={{ marginLeft: 'auto' }}>
          Stampa
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default InsightsDialog;
