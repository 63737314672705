import React, { useState, useEffect } from 'react';
import {
  Box, Typography, Dialog, Select, DialogTitle, DialogActions, DialogContent,
  MenuItem, Tabs, Tab, Button, FormControl, InputLabel, Divider
} from '@mui/material';
import { useMembership } from '../../hooks/useMembership';
import { useRestaurant } from '../../contexts/RestaurantContext';
import CustomLoader from '../Common/CustomLoader';

// Child components
import Calendar from './reservation/Calendar';
import PageWidget from './reservation/PageWidget';
import ReservationSettings from './reservation/ReservationSettings';

const ReservationHome = () => {
  // Context
  const membership = useMembership();
  const {
    userRestaurants,
    selectedRestaurant,
    setSelectedRestaurant,
    restaurantsLoading
  } = useRestaurant();

  // Local state
  const [dialogOpen, setDialogOpen] = useState(false);
  const [currentTab, setCurrentTab] = useState('impostazioni');

  const noRestaurants = userRestaurants.length === 0;

  // 1) Set default restaurant if none is selected and data is available
  useEffect(() => {
    if (!selectedRestaurant && userRestaurants.length > 0) {
      setSelectedRestaurant(userRestaurants[0].id);
    }
  }, [userRestaurants, selectedRestaurant, setSelectedRestaurant]);

  // 2) Handle tab changes
  const handleTabChange = (event, newValue) => {
    setCurrentTab(newValue);
  };

  // 3) Dialog open/close for restaurant selection
  const handleDialogOpen = () => setDialogOpen(true);
  const handleDialogClose = () => setDialogOpen(false);

  const handleRestaurantChange = (event) => {
    const selectedId = event.target.value;
    setSelectedRestaurant(selectedId);
    setDialogOpen(false);
  };

  // ---------- RENDER LOGIC ----------

  // A) Show a loader if membership or restaurant data is still loading
  if (membership.isLoading || restaurantsLoading) {
    return <CustomLoader open={true} />;
  }

  // B) If user does NOT have permission for reservations:
  if (!membership.hasReservations) {
    return (
      <Box style={{ padding: '10%', textAlign: 'center' }}>
        <Typography variant="h5" gutterBottom>
          Il tuo piano attuale non supporta la funzionalità Prenotazioni.<br />
          Esegui un upgrade per sbloccarla.
        </Typography>
        <Button
          variant="contained"
          color="primary"
          size="medium"
          href="/subscription-plans"
          style={{ marginTop: '20px' }}
        >
          Vedi piani di abbonamento
        </Button>
      </Box>
    );
  }

  // MAIN RETURN
  return (
    <Box>
      {/* ---------- Header area ---------- */}
      <Box
        px={2}
        py={1}
        mx={{xs: 2, sm: 3, md: 4}}
        mt={4}
        gap={{xs: 2, sm: 3, md: 5}}
        display="flex"
        flexDirection={{xs: 'column', md: 'row'}}
        justifyContent="space-between"
        borderRadius="10px"
      >
        {/* Restaurant name section: appears first on xs/sm, aligned to right */}
        <Box
          display="flex"
          flexDirection="column"
          alignItems="flex-end"
          sx={{ order: { xs: 1, md: 2 } }}
        >
          <Typography variant="h6">
            {userRestaurants.find((r) => r.id === selectedRestaurant)?.name || 'Ristorante non selezionato'}
          </Typography>
          <Button
            variant="text"
            disabled={noRestaurants}
            sx={{ textDecoration: 'underline', pt: 0.5, m: 0, fontSize: '12px', minWidth: 'auto' }}
            onClick={handleDialogOpen}
          >
            Cambia
          </Button>
        </Box>

        {/* Tabs section: appears second on xs/sm; consider making tabs scrollable on smaller screens */}
        <Box
          display="flex"
          justifyContent="flex-start"
          sx={{ mb: { xs: 1, md: 0 }, order: { xs: 2, md: 1 } }}
        >
          <Tabs
            value={currentTab}
            onChange={handleTabChange}
            variant="scrollable" // makes tabs scrollable on small screens
            scrollButtons="auto"
            sx={{ ml: 0 }}
          >
            <Tab label="Impostazioni" value="impostazioni" />
            <Tab label="Pagina web" value="pagina_web" disabled={noRestaurants} />
            <Tab label="Calendario" value="calendario" disabled={noRestaurants} />
          </Tabs>
        </Box>
      </Box>

      <Divider sx={{ mx: 4, mb: 5, mt: 1 }} />

      {/* ---------- Restaurant selection dialog ---------- */}
      <Dialog open={dialogOpen} onClose={handleDialogClose} fullWidth maxWidth="sm">
        <DialogTitle>Seleziona ristorante</DialogTitle>
        <DialogContent>
          <FormControl sx={{ mt: 1 }} variant="outlined" fullWidth>
            <InputLabel id="restaurant-select-label">Ristorante</InputLabel>
            <Select
              labelId="restaurant-select-label"
              value={selectedRestaurant || ''}
              onChange={handleRestaurantChange}
              label="Ristorante"
            >
              {userRestaurants.map((option) => (
                <MenuItem key={option.id} value={option.id}>
                  {option.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="secondary">
            Annulla
          </Button>
        </DialogActions>
      </Dialog>

      {/* ---------- Tab Panels ---------- */}
      <Box sx={{ mx: {xs: 2, sm: 3, md: 4}, overflowY: 'auto' }}>
        {currentTab === 'calendario' && (
          <Box>
            <Calendar selectedRestaurant={selectedRestaurant} noRestaurants={noRestaurants} />
          </Box>
        )}

        {currentTab === 'pagina_web' && (
          <Box>
            <PageWidget selectedRestaurant={userRestaurants.find(r => r.id === selectedRestaurant)} noRestaurants={noRestaurants} />
          </Box>
        )}

        {currentTab === 'impostazioni' && (
          <Box>
            <ReservationSettings selectedRestaurant={selectedRestaurant} noRestaurants={noRestaurants} />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default ReservationHome;
