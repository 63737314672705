import React from 'react';
import ReactMarkdown from 'react-markdown';
import Typography from '@mui/material/Typography';

const CustomMarkdown = ({ content }) => {
  return (
    <ReactMarkdown
      components={{
        h1: ({ node, ...props }) => (
          <Typography variant="h4" sx={{ msOverflowY: 2 }} {...props} gutterBottom />
        ),
        h2: ({ node, ...props }) => (
          <Typography variant="h5" sx={{ my: 2 }} {...props} gutterBottom />
        ),
        h3: ({ node, ...props }) => (
          <Typography variant="h6" sx={{ my: 2 }} {...props} gutterBottom />
        ),
        p: ({ node, ...props }) => (
          <Typography variant="body1" sx={{ my: 2 }} {...props} paragraph />
        ),
        ul: ({ node, ...props }) => (
          <ul style={{ paddingLeft: '1.5em', marginTop: '1em' }} {...props} />
        ),
        ol: ({ node, ...props }) => (
          <ol style={{ paddingLeft: '1.5em', marginTop: '1em' }} {...props} />
        ),
        li: ({ node, ...props }) => (
          <li style={{ marginTop: '0.5em' }}>
            <Typography variant="body1" component="span" {...props} />
          </li>
        ),
        blockquote: ({ node, ...props }) => (
          <blockquote
            style={{
              margin: '1em 0',
              paddingLeft: '1em',
              borderLeft: '4px solid #ccc',
            }}
            {...props}
          />
        ),
        a: ({ node, children, ...props }) => (
          <a style={{ color: '#1976d2', textDecoration: 'none' }} {...props}>
            {children}
          </a>
        ),
      }}
    >
      {content}
    </ReactMarkdown>
  );
};

export default CustomMarkdown;
