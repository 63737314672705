import React from 'react';
import { 
  MenuItem, 
  Select, 
  FormControl, 
  InputLabel, 
  OutlinedInput, 
  InputAdornment 
} from '@mui/material';
import AccessTimeRounded from '@mui/icons-material/AccessTimeRounded';

const timeToMinutes = (timeStr) => {
  if (!timeStr || typeof timeStr !== 'string') return null;
  const [hh, mm] = timeStr.split(':');
  return parseInt(hh, 10) * 60 + parseInt(mm, 10);
};

const minutesToTime = (mins) => {
  const hh = String(Math.floor(mins / 60)).padStart(2, '0');
  const mm = String(mins % 60).padStart(2, '0');
  return `${hh}:${mm}`;
};

const generateTimeOptions = (interval, minTime, maxTime) => {
  const options = [];
  const minMins = timeToMinutes(minTime) ?? 0;
  const maxMins = timeToMinutes(maxTime) ?? (23 * 60 + 30);
  for (let i = minMins; i <= maxMins; i += interval) {
    options.push(minutesToTime(i));
  }
  return options;
};

/**
 * Props:
 * - label: string
 * - value: string (selected "HH:MM")
 * - onChange: function (receives new "HH:MM")
 * - timeInterval: number (e.g., 15)
 * - minTime: string (e.g., "00:00")
 * - maxTime: string (e.g., "23:30")
 * - placeholder: string (message to display when nothing is selected)
 * - endAdornment: string (optional label to display at the end, e.g., "Ore")
 */
const CustomTimePicker = ({
  label,
  value,
  onChange,
  timeInterval = 15,
  minTime = "00:00",
  maxTime = "23:30",
  placeholder,
  endAdornment, // new prop for adornment text
}) => {
  const timeOptions = generateTimeOptions(timeInterval, minTime, maxTime);

  // Convert any incoming value (e.g., "19:00:00") to "HH:mm"
  const trimmedValue = value ? minutesToTime(timeToMinutes(value)) : "";

  // If a placeholder is provided, show an empty string when nothing is selected.
  const computedValue = placeholder
    ? (trimmedValue || "")
    : (trimmedValue && timeOptions.includes(trimmedValue)
      ? trimmedValue
      : timeOptions[0]);

  return (
    <FormControl fullWidth>
      <InputLabel shrink>{label}</InputLabel>
      <Select
        displayEmpty
        value={computedValue}
        onChange={(e) => onChange(e.target.value)}
        label={label}
        IconComponent={(props) => <AccessTimeRounded {...props} sx={{ fontSize: '19px' }} />}
        input={
          <OutlinedInput
            label={label}
            endAdornment={
              endAdornment ? (
                <InputAdornment position="end" sx={{ mr: 3 }}>{endAdornment}</InputAdornment>
              ) : null
            }
          />
        }
        renderValue={(selected) => {
          if (!selected) {
            return <span style={{ color: '#aaa' }}>{placeholder || ""}</span>;
          }
          return selected;
        }}
        MenuProps={{
          PaperProps: {
            sx: { maxHeight: 200, width: 150 },
          },
        }}
      >
        {timeOptions.map((time) => (
          <MenuItem key={time} value={time}>
            {time}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default CustomTimePicker;
