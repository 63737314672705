import React, { createContext, useState, useEffect, useContext, useCallback } from 'react';
import {
  fetchUserRestaurants, fetchAreasForRestaurant, deleteArea, addTableToArea, deleteTable
} from '../services/apiService';
import { useAuth } from '../contexts/AuthContext';

const RestaurantContext = createContext();

export const useRestaurant = () => useContext(RestaurantContext);

export const RestaurantProvider = ({ children }) => {
  const { isAuthenticated } = useAuth();
  // State definitions
  const [restaurantId, setRestaurantId] = useState(null);
  const [userRestaurants, setUserRestaurants] = useState([]);
  const [restaurantsLoading, setRestaurantsLoading] = useState(true);
  const [selectedRestaurant, setSelectedRestaurant] = useState('');
  const [editingRestaurant, setEditingRestaurant] = useState(null);
  const [areas, setAreas] = useState([]);
  const [areasLoading, setAreasLoading] = useState(false);
  const [selectedArea, setSelectedArea] = useState(null);
  const [editingArea, setEditingArea] = useState(null);
  const [tables, setTables] = useState([]);
  const [refreshTables, setRefreshTables] = useState(0);
  const [floorMapPrompts, setFloorMapPrompts] = useState({});

  // Function to load user restaurants and set the selected one
  const loadUserRestaurants = useCallback(async () => {
    setRestaurantsLoading(true);
    try {
      const restaurants = await fetchUserRestaurants();
      setUserRestaurants(restaurants);

      // Automatically set the default restaurant if available
      const defaultRestaurant = restaurants.find(r => r.is_default);
      if (defaultRestaurant) {
        setSelectedRestaurant(defaultRestaurant.id);
      }
    } catch (error) {
      console.error('Error fetching user restaurants:', error);
    } finally {
      setRestaurantsLoading(false);
    }
  }, []);

  // Initialize restaurants only if the user is authenticated
  useEffect(() => {
    if (isAuthenticated) {
      loadUserRestaurants();
    } else {
    }
  }, [isAuthenticated, loadUserRestaurants]);

  // Function to fetch areas for a specific restaurant and decide if the floor map prompt should be shown
  const checkForFloorMap = useCallback(async (restaurantId) => {
    setAreasLoading(true);
    try {
      const fetchedAreas = await fetchAreasForRestaurant(restaurantId) || [];
      setFloorMapPrompts(prevPrompts => ({
        ...prevPrompts,
        [restaurantId]: fetchedAreas.length === 0
      }));
      // Set areas paylod with nested tables in context
      setAreas(fetchedAreas);

      if (fetchedAreas.length > 0) {
        // Automatically select the first area if available
        setSelectedArea(fetchedAreas[0].id);
      }
    } catch (error) {
      console.error('Error fetching areas for restaurant:', error);
    } finally {
      setAreasLoading(false);
    }
  }, []);

  // useEffect to re-fetch areas whenever refreshTables or selectedRestaurant changes.
  useEffect(() => {
    if (selectedRestaurant) {
      checkForFloorMap(selectedRestaurant);
    }
  }, [refreshTables, selectedRestaurant, checkForFloorMap]);

  // Function to update a restaurant after editing
  const updateEditedRestaurant = (updatedRestaurant) => {
    const updatedRestaurants = userRestaurants.map(restaurant =>
      restaurant.id === updatedRestaurant.id ? updatedRestaurant : restaurant
    );
    setUserRestaurants(updatedRestaurants);
    setSelectedRestaurant(updatedRestaurant.id);
  };

  // Function to delete an area and update local state ---
  const handleDeleteArea = async (areaId) => {
    try {
      // 1. Delete from the backend
      await deleteArea(areaId);
      // 2. Immediately remove it from context state so UI updates
      setAreas((prevAreas) => {
        if (!prevAreas) return prevAreas; // if null, just return
        return prevAreas.filter((area) => area.id !== areaId);
      });
      // 3. Optionally re-fetch from the server if desired:
      //    loadUserRestaurants();
    } catch (error) {
      console.error('Error deleting area:', error);
      // Rethrow if you want the caller to handle the error (or handle it here).
      throw error;
    }
  };

  // Function to create a new table
  const handleAddTable = async (areaId) => {
    const newTable = await addTableToArea(areaId);
    setRefreshTables(prev => prev + 1);
    return newTable;
  };

  // Function to update an area in context
  const updateAreaInContext = (updatedArea) => {
    setAreas((prevAreas) => {
      if (!prevAreas) return prevAreas;
      return prevAreas.map((area) =>
        area.id === updatedArea.id ? { ...area, ...updatedArea } : area
      );
    });
  };  

  // Function to update an area's tables after dragging/resizing
  const updateTablesPositionsInArea = (areaId, updatedTables) => {
    setAreas((prevAreas) => {
      // If prevAreas is null or undefined, just return it
      if (!prevAreas) return prevAreas;

      // Create a new array so React sees a different reference
      return prevAreas.map((area) => {
        // Match the correct area
        if (area.id === areaId) {
          // Build an updated tables array
          const newTables = area.tables.map((table) => {
            const updated = updatedTables.find((ut) => ut.id === table.id);
            // If there's a new position for this table, merge it
            return updated ? { ...table, ...updated } : table;
          });
          return { ...area, tables: newTables };
        }
        return area;
      });
    });
  };

  // Function to delete a table
  const handleDeleteTable = async (tableId) => {
    try {
      await deleteTable(tableId); // Make sure you import this function from your API services
      // Trigger the effect that re-fetches areas:
      setRefreshTables(prev => prev + 1);
    } catch (error) {
      console.error('Error deleting table:', error);
      // Optionally, handle error (e.g., show a toast notification)
    }
  };

  // Definitions
  const value = {
    // State declarations
    restaurantId, setRestaurantId,
    userRestaurants, setUserRestaurants,
    restaurantsLoading,
    selectedRestaurant, setSelectedRestaurant,
    editingRestaurant, setEditingRestaurant,
    areas, setAreas,
    areasLoading,
    selectedArea, setSelectedArea,
    editingArea, setEditingArea,
    tables, setTables,
    refreshTables, setRefreshTables,
    floorMapPrompts, setFloorMapPrompts,
    // State-related functions
    checkForFloorMap,
    loadUserRestaurants,
    updateEditedRestaurant,
    handleDeleteArea,
    handleAddTable,
    updateAreaInContext,
    updateTablesPositionsInArea,
    handleDeleteTable,
  };

  return (
    <RestaurantContext.Provider value={value}>
      {children}
    </RestaurantContext.Provider>
  );
};
