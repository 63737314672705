// ItemCard v1.85 with lazy loading
import React from 'react';
import { Box, Typography, IconButton, Tooltip } from '@mui/material';
import plus from '../../assets/icons/plus.png';
import minus from '../../assets/icons/minus.png';
import { useMembership } from '../../hooks/useMembership';

const ItemCard = ({
  item,
  orderItem,
  onAddItem,
  onRemoveItem,
  openAllergenDialog,
  isPreview,
  isReservation,
  type // expected to be 'menu_item' or 'combo'
}) => {
  const { hasOrders } = useMembership();

  return (
    <Box
      sx={{
        display: 'flex',
        minHeight: 140,
        mt: 1,
        mb: 1,
        borderBottom: '1px solid #ccc',
        pb: 1
      }}
    >
      {/* Left Column */}
      <Box
        sx={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          pr: 1
        }}
      >
        <Box sx={{ mb: 2, mt: 2 }}>
          <Typography sx={{ fontWeight: 'bold' }}>{item.name}</Typography>
          <Typography>{item.description}</Typography>
          {item.allergens && item.allergens.length > 0 && (
            <Typography
              color="secondary"
              sx={{ cursor: 'pointer', textDecoration: 'underline', mt: 1 }}
              onClick={() => openAllergenDialog(item.allergens, item.name)}
            >
              Allergeni
            </Typography>
          )}
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
          <Typography color="primary" sx={{ fontWeight: 'bold' }}>
            €{item.price}
          </Typography>
        </Box>
      </Box>
      {/* Right Column */}
      <Box
        sx={{
          width: 100,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'space-between'
        }}
      >
        {item.picture && (
          <Box
            sx={{
              width: 100,
              height: 100,
              mt: 2,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              overflow: 'hidden',
              position: 'relative',
              borderRadius: 1,
              border: '1px solid darkgrey'
            }}
          >
            <Box
              component="img"
              src={item.picture}
              alt={item.name}
              loading="lazy" // Lazy loading added for the image
              sx={{
                width: 'auto',
                height: '100%',
                objectFit: 'cover'
              }}
            />
          </Box>
        )}
        <Box
          sx={{
            width: '100%',
            height: 50,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            mt: 'auto'
          }}
        >
          {orderItem?.quantity > 0 && (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '33%'
              }}
            >
              <IconButton onClick={() => onRemoveItem(item.id)}>
                <img
                  src={minus}
                  alt="Rimuovi dal menu"
                  style={{ width: '24px', opacity: 0.85 }}
                />
              </IconButton>
            </Box>
          )}
          {orderItem?.quantity > 0 && (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '33%'
              }}
            >
              {orderItem.quantity}
            </Box>
          )}
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '33%'
            }}
          >
            {!isReservation && (
              isPreview ? (
                <Tooltip title="Non disponibile in modalità anteprima">
                  <span>
                    <IconButton disabled>
                      <img
                        src={plus}
                        alt="Aggiungi al menu"
                        style={{ width: '24px', opacity: 0.85 }}
                      />
                    </IconButton>
                  </span>
                </Tooltip>
              ) : (
                hasOrders && (
                  <IconButton onClick={() => onAddItem({ ...item, type }, item.course_type)}>
                    <img
                      src={plus}
                      alt="Aggiungi al menu"
                      style={{ width: '24px', opacity: 0.85 }}
                    />
                  </IconButton>
                )
              )
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default React.memo(ItemCard);
