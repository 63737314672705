let socket = null;

export const connectToWebSocket = (url, onMessage) => {
    socket = new WebSocket(url);

    socket.onopen = () => {
    };

    socket.onmessage = (event) => {
        const data = JSON.parse(event.data);
        onMessage(data);  // Pass message data to the callback
    };

    socket.onclose = () => {
        setTimeout(() => connectToWebSocket(url, onMessage), 5000);  // Reconnect after 5 seconds
    };
};

export const sendMessage = (message) => {
    if (socket && socket.readyState === WebSocket.OPEN) {
        socket.send(JSON.stringify(message));
    }
};

export const disconnectWebSocket = () => {
    if (socket) {
        socket.close();
    }
};
